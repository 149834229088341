import React, {
  FC,
  JSX,
  useCallback,
  useEffect,
  useRef
} from "react";
import css from "../Notifications.module.css";
import { NotificationsDataType, SortNotificationsType } from "app/types";
import dayjs from "dayjs";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { Empty, Tooltip } from "antd";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { ReactComponent as NotificationTrue } from "../../../assets/icons/notification_true.svg";
import { ReactComponent as NotificationError } from "../../../assets/icons/notification_error.svg";
import { ReactComponent as NotificationZero } from "../../../assets/icons/notification_bell.svg";
import { filterNotifications } from "../../../utils/filterNotifications";
import ZeroNotifications from "../ZeroNotifications/ZeroNotifications";

interface INotificationsItemsProps {
  isNotificationsLoading: boolean;
  notifications: NotificationsDataType[];
  markNotificationAsRead: (data: NotificationsDataType) => void;
  openClientCard: (data: NotificationsDataType) => void;
  areAllViewed: boolean;
  notificationVisible: boolean;
  activeKey: string;
  isLoading: boolean;
  variant: string;
  updateListing: boolean;
  setFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationsItems: FC<INotificationsItemsProps> = ({
  isNotificationsLoading,
  notifications,
  markNotificationAsRead,
  openClientCard,
  areAllViewed,
  notificationVisible,
  activeKey,
  isLoading,
  variant,
  updateListing,
  setFetching,
}): JSX.Element => {
  const scrollContainerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const debounceTimeout: React.MutableRefObject<number | NodeJS.Timeout | null> =
    useRef<number | NodeJS.Timeout | null>(null);

  const contentTooltip = (name: string): JSX.Element => {
    return (
      <div className={css.tooltip}>
        {name}
      </div>
    );
  };

  const loadingState: boolean = !isNotificationsLoading && isLoading;

  const scrollHandler = useCallback((e: Event): void => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout((): void => {
      const target: HTMLElement = e.target as HTMLElement;
      const clientHeight: number | undefined = scrollContainerRef.current?.clientHeight;

      if (clientHeight !== undefined && target.scrollHeight - (target.scrollTop + clientHeight) < 300) {
        setFetching(true);
      }
    }, 300);
  }, []);

  useEffect(() => {
    const handleScroll = (e: Event): void => {
      scrollHandler(e);
    };

    const container: HTMLDivElement | null = scrollContainerRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }

      if (debounceTimeout?.current) {
        clearTimeout(debounceTimeout?.current);
      }
    };
  }, [scrollHandler, activeKey]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [activeKey]);
  
  const buttonText = (notificationEntity: string): string => {
    switch (notificationEntity) {
      case "report":
        return "К отчету";
      case "absence-substitution-schema":
        return "К исполнителю";
      default:
        return "К задаче";
    }
  };

  return (
    <div
      className={areAllViewed ? css.scroll : css.scrollViewedAll}
      ref={scrollContainerRef}
      key={activeKey}
    >
      {loadingState ?
        ((notifications?.length ? (
              filterNotifications(notifications)?.map((el: SortNotificationsType, index: number) => {
                const bodyNotifications: JSX.Element[] = el.notifications.map((
                    notification: NotificationsDataType,
                    notificationIndex: number
                  ) => {
                    const dateTime: string = notification.created_at.slice(11, 16);
                    const dataMonth: string = dayjs(notification.created_at).format("DD.MM.YYYY");

                    const taskNotification: boolean = notification?.notification_entity !== "report";
                    const notificationOk: boolean = notification?.notification_type !== "error";

                    const renderClientName: boolean =
                      !!notification?.client_short_name || !!notification?.client_full_name;

                    const clientName: string = notification?.client_full_name ?? notification?.client_short_name;

                    const renderTaskType: boolean = !!notification?.task_type;

                    return (
                      <div key={notification?.uuid}>
                        <div className={css.line}/>
                        <ul>
                          <li className="flex justify-between mb-[7px]">
                            <div className="flex">
                              {taskNotification
                                ? (
                                  <>
                                    <div
                                      className={renderClientName
                                        ? `${css.notificationsTaskData} mr-1 cursor-pointer max-w-[125px]` : ""}
                                      onClick={() => openClientCard(notification)}
                                    >
                                      {notificationVisible ? (
                                        <Tooltip
                                          placement="topLeft"
                                          title={contentTooltip(clientName)}
                                          trigger="hover"
                                        >
                                          {notification?.client_short_name ?? notification?.client_full_name}
                                        </Tooltip>
                                      ) : (
                                        <div>
                                          {notification?.client_short_name ?? notification?.client_full_name}
                                        </div>
                                      )}
                                    </div>
                                    <div className={renderTaskType ? css.notificationsTaskData : ""}>
                                      {notification?.task_type}
                                    </div>
                                  </>
                                ) : null
                              }
                            </div>
                            <div className="ml-3">
                              <span className={`${css.notificationData} inline-block mb-1 mr-1`}>
                                {dataMonth}
                              </span>
                              <span className={`${css.notificationData} inline-block mb-1`}>
                                {dateTime}
                              </span>
                            </div>
                          </li>
                          <li className="flex justify-start items-center mr-2">
                            {!notification.viewed_at &&
                              (<span className="inline-block mr-2 ml-1 pb-1">
                                  {notificationOk
                                    ? <NotificationTrue/>
                                    : <NotificationError/>
                                  }
                               </span>
                              )
                            }
                            <span className={`${css.notificationsHeader} mb-1`}>
                              {notification?.header ?? ""}
                            </span>
                          </li>
                        </ul>
                        <div className={`${css.notificationsBody} mb-3`} key={notificationIndex}>
                          {notification.text}
                        </div>
                        <div>
                          <ButtonCustom
                            text={buttonText(notification?.notification_entity)}
                            type="primary"
                            ghost
                            className={notificationOk
                              ? css.notificationOk
                              : css.notificationError
                            }
                            onClick={() => markNotificationAsRead(notification)}
                          />
                        </div>
                      </div>
                    );
                  }
                );
                return (
                  <div className="relative" key={index}>
                    <div className={`${css.notificationsDate} mb-3`}>
                      {el.date}
                    </div>
                    <div>
                      {bodyNotifications}
                    </div>
                  </div>
                );
              })) :
              <Empty
                className="pt-[33vh]"
                image={<NotificationZero />}
                imageStyle={{ height: "34px", textAlign: "center", marginBottom: "12px" }}
                description={<ZeroNotifications variant={variant} />}
              />
          )
        ) : (
          <div className="text-center mt-80 pb-6">
            <LoadingCustom/>
          </div>
        )
      }
      {updateListing && (
        <div className="text-center mt-10 pb-10">
          <LoadingCustom />
        </div>
      )}
    </div>
  );
};

export default NotificationsItems;
