import React, {
  FC,
  JSX,
  useEffect,
  useMemo,
  useState
} from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import css from "./ClientCard.module.css";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { Tabs, TabsProps, Tooltip } from "antd";
import AboutClient from "./ClientCardItem/AboutClient/AboutClient";
import Tasks from "./ClientCardItem/Tasks/Tasks";
import Documents from "./ClientCardItem/Documents/Documents";
import EmployeesListing from "./ClientCardItem/Employees/EmployeesListing/EmployeesListing";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import {
  fetchDocumentClient,
  fetchSchemaClient,
  fetchSchemaClientLabourRegulation,
  getClientLabourRegulationData,
  getClientTariffInfo,
  setClientCardTagKey,
} from "../../../actions/client.actions";
import { AppStateType } from "../../../reducers/mainReducer";
import ClientCardTechInfo from "./ClientCardItem/ClientCardTechInfo/ClientCardTechInfo";
import * as jose from "jose";
import { getUserTokenDecryptionData } from "../../../actions/account.actions";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow_left_icon.svg";
import Accounts from "./ClientCardItem/Accounts/Accounts";
import Comments from "./ClientCardItem/Comments/Comments";
import { UpdateDocumentType } from "app/types";
import { updateDocumentFailure } from "../../../actions/document.actions";
import { roleResolver } from "utils/roleResolver";
import { ReactComponent as ClientActive } from "../../../assets/icons/client_active.svg";
import { ReactComponent as ClientNotActive } from "../../../assets/icons/client_not_active.svg";
import { ReactComponent as ClientDraft } from "../../../assets/icons/client_draft.svg";
import { renderClientStatus } from "../../../utils/renderClientStatus";

interface IClientCardProps {
}

const ClientCard: FC<IClientCardProps> = (): JSX.Element => {
  const [isEmployeeCardOpen, setIsEmployeeCardOpen] = useState<boolean>(false);
  const [clientLabour, setClientLabour] = useState<boolean>(true);
  const [isClientLoaded, setClientLoaded] = useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const paramsData: any = useParams();
  const path: string = useLocation().pathname;
  const params = useMemo(() => paramsData, [path]);

  const {
    documentClient,
    schemaClient,
    clientTagKey
  } = useSelector((state: AppStateType) => state.client);

  const updatedDoc: UpdateDocumentType | null = useSelector((state: AppStateType) => state.document.updatedDoc);

  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect((): void => {
    dispatch(getUserTokenDecryptionData());
  }, [dispatch]);

  const techSupportRole: boolean = roleResolver(decodedToken).isTechSupport;

  useEffect(() => {
    if (params) {
      dispatch(fetchSchemaClient());
      dispatch(fetchSchemaClientLabourRegulation());
      dispatch(fetchDocumentClient(params?.id))
        .finally(() => setClientLoaded(true));
    }
  }, [params]);

  useEffect(() => {
    if (updatedDoc) {
      dispatch(fetchDocumentClient(params?.id));
      dispatch(updateDocumentFailure());
    }
  }, [updatedDoc]);

  useEffect(() => {
    if(documentClient?.fields?.labour_remuneration_regulations && clientLabour) {
      dispatch(getClientLabourRegulationData(documentClient?.fields?.labour_remuneration_regulations[0].uuid));
      setClientLabour(false);
    }
  }, [documentClient?.fields?.labour_remuneration_regulations]);

  useEffect(() => {
    if (documentClient) {
      dispatch(getClientTariffInfo(documentClient?.fields?.client_tariff?.[0]?.uuid ?? ""));
    }
  }, [documentClient]);

  const returnToClientsOrTaskCard = (): void => {
    const hasTaskUuid: boolean = path?.split("/")?.includes("task");

    if (hasTaskUuid) {
      const taskUuid: string = path?.split("/")?.[2];
      navigate(`/task/${taskUuid}/`);
    } else {
      navigate("/clients");
    }
  };

  //данные верхнего меню страницы
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "О клиенте",
      destroyInactiveTabPane: true,
      children: (
        <AboutClient
          clientInfo={documentClient}
          isClientLoaded={isClientLoaded}
        />
      ),
    },
    {
      key: "2",
      label: "Задачи",
      destroyInactiveTabPane: true,
      children: (
        <Tasks
          clientInfo={documentClient}
        />
      ),
    },
    {
      key: "3",
      label: "Документы",
      destroyInactiveTabPane: true,
      children: (
        <Documents
          clientInfo={documentClient}
          schemaClient={schemaClient}
        />
      ),
    },
    {
      key: "4",
      label: "Сотрудники",
      destroyInactiveTabPane: true,
      children: (
        <EmployeesListing />
      ),
    },
    {
      key: "5",
      label: "Счета",
      destroyInactiveTabPane: true,
      children: (
        <Accounts/>
      ),
    },
    {
      key: "6",
      label: "Комментарии",
      destroyInactiveTabPane: true,
      children: (
        <Comments />
      ),
    },
    {
      key: "7",
      label: techSupportRole ? "Доп. Информация" : null,
      destroyInactiveTabPane: true,
      children: (
        techSupportRole ? <ClientCardTechInfo /> : null
      ),
    },
  ];

  const [activeKey, setActiveKey] = useState<string>(clientTagKey);

  useEffect(() => {
    setActiveKey(clientTagKey);
  }, [clientTagKey]);

  const handleTabChange = (key: string): void => {
    setActiveKey(() => {
      dispatch(setClientCardTagKey(key));
      return key;
    });
  };

  const contentTooltip: JSX.Element = (
    <div className={css.tooltip}>
      <span>Статус организации</span>
      <div className="flex items-center">
        <ClientActive/>
        <span className="ml-2">- Активный</span>
      </div>
      <div className="flex items-center">
        <ClientNotActive/>
        <span className="ml-2">- Не активный</span>
      </div>
      <div className="flex items-center">
        <ClientDraft/>
        <span className="ml-2">- Черновик</span>
      </div>
    </div>
  );

  return (
    <div className={css.container}>
      <div className={css.cardsHeader}>
        {!isEmployeeCardOpen && (
          <>
            <div>
              <ButtonCustom
                className={css.buttonReturn}
                type="link"
                text="Назад"
                onClick={returnToClientsOrTaskCard}
              >
                <ArrowLeft/>
              </ButtonCustom>
            </div>
            <div className="flex items-center">
              {documentClient && (
                <Tooltip
                  placement="topLeft"
                  title={contentTooltip}
                  trigger="hover"
                  arrow={{pointAtCenter: true}}
                >
                  {renderClientStatus(documentClient?.fields?.status_client)}
                </Tooltip>
              )}
              <h1 className={`${css.clientCardHeader} ml-3`}>
                {documentClient?.fields?.name}
              </h1>
            </div>
          </>
        )}
        <Tabs
          className={css.clientCardTabs}
          defaultActiveKey={activeKey}
          items={itemsTabs}
          activeKey={activeKey}
          tabBarStyle={isEmployeeCardOpen ? {display: "none"} : {}}
          onChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default ClientCard;
