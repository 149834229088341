import React, { FC, JSX, useState } from "react";
import css from "../AboutClient.module.css";
import { Card, Skeleton } from "antd";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { JWTPayload } from "jose";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { roleResolver } from "../../../../../../utils/roleResolver";
import { DocumentClientType, RegistrationContactDetailsType } from "app/types";
import { viewContactDetails } from "../../../../../../api/client.api";
import CardLabel from "../../../../../../utils/CardLabel/CardLabel";

interface IClientContactInfoProps {
  showModal: (text: string) => void;
}

const ClientContactInfo: FC<IClientContactInfoProps> = ({
  showModal
}): JSX.Element => {
  const clientInfo: DocumentClientType | null =
    useSelector((state: AppStateType) => state.client.documentClient);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;
  const isTechSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const isResponsibleAccountant: boolean =
    decodedToken?.sub === clientInfo?.fields?.responsible_reference?.[0]?.uuid ||
    decodedToken?.sub === clientInfo?.fields?.temporary_responsible_reference?.[0]?.uuid;
  const hasAccess: boolean = isResponsibleAccountant || isAdmin || isTechSupport;

  const [displayNumber, setDisplayNumber] = useState<boolean>(true);
  const [displayEmail, setDisplayEmail] = useState<boolean>(true);

  const toggleLoadingNumber = async (): Promise<void> => {
    const toggleData: RegistrationContactDetailsType = {
      client_uuid: clientInfo?.fields?.uuid,
      contact_data_type: "phone",
    };

    await viewContactDetails(toggleData);
    setDisplayNumber(!displayNumber);
  };

  const toggleLoadingEmail = async (): Promise<void> => {
    const toggleData: RegistrationContactDetailsType = {
      client_uuid: clientInfo?.fields?.uuid,
      contact_data_type: "email",
    };

    await viewContactDetails(toggleData);
    setDisplayEmail(!displayEmail);
  };

  return (
    <Card className={css.card}>
      <CardLabel title="Контакты клиента" />
      <ul className="flex flex-col">
        <li className="flex items-center">
          <span className={css.cardItemLabel}>
            Номер телефона
          </span>
          <div className={`${css.cardItemValue} flex items-center`}>
            <Skeleton
              title={false}
              loading={displayNumber}
              className={css.skeletonMask}
              paragraph={{ rows: 1, width: "100%" }}
            >
              <span className={css.skeletonMask}>
                {clientInfo?.fields?.phone ?? "-"}
              </span>
            </Skeleton>
            <ButtonCustom
              type="link"
              text={displayNumber ? "Показать" : "Скрыть"}
              onClick={toggleLoadingNumber}
            />
          </div>
        </li>
        <li className="flex items-center mb-[9px]">
          <span className={css.cardItemLabel}>
            Электронная почта
          </span>
          <div className={`${css.cardItemValue} flex items-center`}>
            <Skeleton
              title={false}
              loading={displayEmail}
              className={css.skeletonMask}
              paragraph={{ rows: 1, width: "100%" }}
            >
              <span className={css.skeletonMask}>
                {clientInfo?.fields?.email ?? "-"}
              </span>
            </Skeleton>
            <ButtonCustom
              type="link"
              text={displayEmail ? "Показать" : "Скрыть"}
              onClick={toggleLoadingEmail}
            />
          </div>
        </li>
        {hasAccess && (
          <li className="flex items-center">
            <ButtonCustom
              type="primary"
              text="Редактировать"
              ghost
              onClick={() => showModal("Контакты клиента")}
            />
          </li>
        )}
      </ul>
    </Card>
  );
};

export default ClientContactInfo;