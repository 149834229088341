import {
  DocumentSchemaLabelMapType,
  SchemaFieldsType,
  SchemaLabourRegulationFieldsType,
  SchemaLabourRegulationType,
  SchemaType
} from "app/types";

export const documentSchemaLabelsMapper = (documentSchema: SchemaType | SchemaLabourRegulationType | null) => {
  return documentSchema?.fields?.reduce((
    acc: DocumentSchemaLabelMapType,
    field: SchemaFieldsType | SchemaLabourRegulationFieldsType
  ) => {
    acc[field.name] = { label: field.label };

    return acc;
  }, {} as DocumentSchemaLabelMapType) ?? {};
};
