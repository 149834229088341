import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_SCHEMA_EMPLOYEE_SUCCESS,
  GET_SCHEMA_EMPLOYEE_FAILURE,
  GET_DOCUMENT_EMPLOYEE_SUCCESS,
  GET_DOCUMENT_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_LISTING_SUCCESS,
  GET_EMPLOYEE_LISTING_FAILURE,
  GET_EMPLOYEE_PAGE_SIZE,
  GET_EMPLOYEE_PAGE_NUMBER,
  UPDATE_DOCUMENT_EMPLOYEE_SUCCESS,
  UPDATE_DOCUMENT_EMPLOYEE_FAILURE,
  EMPLOYEE_LISTING_FILTER_DATA,
  EMPLOYEE_LISTING_PARAMS_DATA,
  CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT
} from "../constants/actionTypes";
import * as EmployeeAPI from "../api/employee.api";
import {
  DocumentEmployeeType,
  EmployeeListingType,
  FilterEmployeeType,
  SchemaType,
} from "app/types";

export type EmployeeActionsTypes =
  | GetSchemaEmployeeSuccessType
  | GetSchemaEmployeeFailureType
  | GetDocumentEmployeeSuccessType
  | GetDocumentEmployeeFailureType
  | GetEmployeeListingSuccessType
  | GetEmployeeListingFailureType
  | UpdateEmployeeListingSuccessType
  | UpdateEmployeeListingFailureType
  | GetEmployeesPageSizeType
  | GetEmployeesPageNumberType
  | SetClientsEmployeesListingFilterData
  | SetClientsEmployeesListingParamsData
  | SetClientsEmployeeListingLoadingDefault;

// Логика получения схемы сотрудника
export type GetSchemaEmployeeSuccessType = {
  type: typeof GET_SCHEMA_EMPLOYEE_SUCCESS;
  payload: {
    schemaEmployee: SchemaType;
  };
};

const getSchemaEmployeeSuccess = (schemaEmployee: SchemaType): GetSchemaEmployeeSuccessType => ({
  type: GET_SCHEMA_EMPLOYEE_SUCCESS,
  payload: {
    schemaEmployee
  }
});

export type GetSchemaEmployeeFailureType = {
  type: typeof GET_SCHEMA_EMPLOYEE_FAILURE;
  payload: {
    error: any;
  };
};

const getSchemaEmployeeFailure = (error?: any): GetSchemaEmployeeFailureType => ({
  type: GET_SCHEMA_EMPLOYEE_FAILURE,
  payload: {
    error
  }
});

export const fetchSchemaEmployee = ():
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeAPI.getSchemaEmployee();

    dispatch(getSchemaEmployeeSuccess(response.data));
  } catch (error: any) {
    dispatch(getSchemaEmployeeFailure(error.message));
  }
};

// Логика обновления данных сотрудника по uuid документа
export type UpdateEmployeeListingSuccessType = {
  type: typeof UPDATE_DOCUMENT_EMPLOYEE_SUCCESS;
  payload: {
    documentEmployee: DocumentEmployeeType;
  };
};

const updateEmployeeListingSuccess = (documentEmployee: DocumentEmployeeType)
  : UpdateEmployeeListingSuccessType => ({
  type: UPDATE_DOCUMENT_EMPLOYEE_SUCCESS,
  payload: {
    documentEmployee
  }
});

export type UpdateEmployeeListingFailureType = {
  type: typeof UPDATE_DOCUMENT_EMPLOYEE_FAILURE;
  payload: {
    error: any;
  };
};

export const updateEmployeeListingFailure = (error?: any): UpdateEmployeeListingFailureType => ({
  type: UPDATE_DOCUMENT_EMPLOYEE_FAILURE,
  payload: {
    error
  }
});

export const updateEmployeeData = (uuid: string, data?: any):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeAPI.updateDocumentEmployee(uuid, data);

    dispatch(updateEmployeeListingSuccess(response.data));
  } catch (error: any) {
    dispatch(updateEmployeeListingFailure(error.message));
  }
};

// Логика получения данных схемы сотрудника по uuid документа
export type GetDocumentEmployeeSuccessType = {
  type: typeof GET_DOCUMENT_EMPLOYEE_SUCCESS;
  payload: {
    documentEmployee: DocumentEmployeeType;
  };
};

const getDocumentEmployeeSuccess = (documentEmployee: DocumentEmployeeType)
  : GetDocumentEmployeeSuccessType => ({
  type: GET_DOCUMENT_EMPLOYEE_SUCCESS,
  payload: {
    documentEmployee
  }
});

export type GetDocumentEmployeeFailureType = {
  type: typeof GET_DOCUMENT_EMPLOYEE_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentEmployeeFailure = (error?: any): GetDocumentEmployeeFailureType => ({
  type: GET_DOCUMENT_EMPLOYEE_FAILURE,
  payload: {
    error
  }
});

export const fetchDocumentEmployee = (uuid: string):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeAPI.getDocumentEmployee(uuid);

    dispatch(getDocumentEmployeeSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentEmployeeFailure(error.message));
  }
};

//Логика получения листинга сотрудников клиента
export type GetEmployeeListingSuccessType = {
  type: typeof GET_EMPLOYEE_LISTING_SUCCESS;
  payload: {
    employeeListing: EmployeeListingType;
  };
};

const getEmployeesListingSuccess = (employeeListing: EmployeeListingType): GetEmployeeListingSuccessType => ({
  type: GET_EMPLOYEE_LISTING_SUCCESS,
  payload: {
    employeeListing
  }
});

export type GetEmployeeListingFailureType = {
  type: typeof GET_EMPLOYEE_LISTING_FAILURE;
  payload: {
    error: any;
  };
};

export const getEmployeesListingFailure = (error?: any): GetEmployeeListingFailureType => ({
  type: GET_EMPLOYEE_LISTING_FAILURE,
  payload: {
    error
  }
});

//+пагинация листинга сотрудников
export type GetEmployeesPageSizeType = {
  type: typeof GET_EMPLOYEE_PAGE_SIZE;
  payload: {
    size: number;
  };
};

export const getEmployeesPageSize = (size: number): GetEmployeesPageSizeType => ({
  type: GET_EMPLOYEE_PAGE_SIZE,
  payload: {
    size
  }
});

export type GetEmployeesPageNumberType = {
  type: typeof GET_EMPLOYEE_PAGE_NUMBER,
  payload: {
    page: number;
  };
};

export const getEmployeesPageNumber = (page: number): GetEmployeesPageNumberType => ({
  type: GET_EMPLOYEE_PAGE_NUMBER,
  payload: {
    page
  }
});

export const getEmployeeListingData = (params: null | FilterEmployeeType = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeAPI.getEmployeesListing(params);

    dispatch(getEmployeesListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getEmployeesListingFailure(error.message));
  }
};

//Логика фильтрации листинга клиента
export type SetClientsEmployeesListingFilterData = {
  type: typeof EMPLOYEE_LISTING_FILTER_DATA;
  payload: {
    clientsEmployeeFilter: FilterEmployeeType | null;
  };
};

export const setClientsEmployeesListingFilter = (
  clientsEmployeeFilter: FilterEmployeeType | null
): SetClientsEmployeesListingFilterData => ({
  type: EMPLOYEE_LISTING_FILTER_DATA,
  payload: {
    clientsEmployeeFilter
  }
});

export type SetClientsEmployeesListingParamsData = {
  type: typeof EMPLOYEE_LISTING_PARAMS_DATA;
  payload: {
    clientsEmployeeParams: FilterEmployeeType | null;
  };
};

export const setClientsEmployeesListingParamsData = (
  clientsEmployeeParams: FilterEmployeeType | null
): SetClientsEmployeesListingParamsData => ({
  type: EMPLOYEE_LISTING_PARAMS_DATA,
  payload: {
    clientsEmployeeParams
  }
});

export type SetClientsEmployeeListingLoadingDefault = {
  type: typeof CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT;
  payload: {
    loadingDefault: boolean;
  };
};

export const setClientsEmployeeListingLoadingDefault = (
  loadingDefault: boolean
): SetClientsEmployeeListingLoadingDefault => ({
  type: CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT,
  payload: {
    loadingDefault
  }
});
