import React, { FC, JSX } from "react";
import css from "./ZeroClientTasks.module.css";
import { ReactComponent as TaskDefault } from "../../../../../../assets/icons/empty-tasks.svg";

interface IZeroClientTasksProps {
}

const ZeroClientTasks: FC<IZeroClientTasksProps> = (): JSX.Element => {

  return (
    <div className={css.container}>
      <div className={css.label}>
        <div className={css.icon}><TaskDefault/></div>
        <div className={css.textHeader}>Задачи отсутствуют</div>
      </div>
    </div>
  );
};

export default ZeroClientTasks;
