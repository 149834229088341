import React, { JSX } from "react";
import { Popover } from "antd";
import css from "./RenderSkills.module.css";

enum VisibleSkillsNumber {
  Three = 3,
}

export const renderSkills = (skill: string[]): JSX.Element | null => {
  if (!skill?.length) {
    return null;
  }

  let visibleSkillsData: string[];
  let hiddenSkillsData: string[];

  if (skill?.length > VisibleSkillsNumber.Three) {
    visibleSkillsData = skill?.slice(0, 3);
    hiddenSkillsData = skill?.slice(3);

    const popoverContent: JSX.Element = (
      <div className={css.popoverStyle}>
        {hiddenSkillsData.map((skill: string, index: number) =>
          <div key={index} className={`${css.skillStyle} mb-[8px]`}>{skill}</div>
        )}
      </div>
    );

    return (
      <div className={`${css.tableCell} flex items-center`}>
        {visibleSkillsData.map((skill: string, index: number) =>
          <div key={index} className={css.skillStyle}>{skill}</div>
        )}
        <Popover content={popoverContent}>
          <div className={css.ellipsisStyle}>...</div>
        </Popover>
      </div>
    );
  } else {
    return (
      <div className={`${css.tableCell} flex`}>
        {skill?.map((skill: string, index: number) =>
          <div key={index} className={css.skillStyle}>{skill}</div>
        )}
      </div>
    );
  }
};
