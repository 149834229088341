import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { ButtonCustom } from "../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./BankDetailsCard.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../../reducers/mainReducer";
import { DocumentSchemaLabelMapType, SchemaType } from "app/types";
import TagCustom from "../../../../../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument
  from "../../../../../../ClientProfileCard/ZeroClientProfileDocument/ZeroClientProfileDocument";
import { documentSchemaLabelsMapper } from "../../../../../../../utils/documentSchemaLabelsMapper";

interface IBankDetailsCardProps {
  showImageViewer: (docType: string, uuid: string) => void;
  bankDocUuid: string;
}

const BankDetailsCard: FC<IBankDetailsCardProps> = ({
  showImageViewer,
  bankDocUuid
}): JSX.Element => {
  const [isConfirmedBankDetail, setConfirmedBankDetail] = useState<boolean>(false);
  const schemaEmployee: SchemaType | null = useSelector((state: AppStateType) => state.employee.schemaEmployee);

  const {
    bankDoc,
    bankDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  const bankDetailsDocsLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaEmployee);
  const bankDetailsInfoLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(bankDocSchema);

  useEffect((): void => {
    if (bankDoc?.fields?.document_status === "APPROVED") {
      setConfirmedBankDetail(true);
    } else {
      setConfirmedBankDetail(false);
    }
  }, [bankDoc]);

  const renderConfirmationDetails = (): JSX.Element => {
    return isConfirmedBankDetail ? (
      <TagCustom color="success" text="Согласовано"/>
    ) : (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderBankDetailCause = (cause?: string): JSX.Element => {
    return (
      <div className={`${css.cause} mt-5 mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {cause}
        </div>
      </div>
    );
  };

  const renderBankDetailField = (label: string, value: string | number): JSX.Element => (
    <li className="textBlockWidth">
      <span className="inline-block gray-color text-sm">
        {label}
      </span>
      <span className="flex-grow text-sm">
        {value}
      </span>
    </li>
  );

  return (
    <div className={`${css.container} bg-white border-bottom-gray`}>
      <div className={`${css.containerDocs} p-5 mb-3 bg-white`}>
        <h1 className="header-text mb-5">
          {bankDetailsDocsLabels?.bank_document?.label}
        </h1>
        {bankDoc?.fields ? (
          <>
            <ul>
              {!!bankDoc?.fields?.bank_account_number &&
                renderBankDetailField(
                  bankDetailsInfoLabels?.bank_account_number?.label,
                  bankDoc?.fields?.bank_account_number
                )
              }
              {!!bankDoc?.fields?.correspondent_account &&
                renderBankDetailField(
                  bankDetailsInfoLabels?.correspondent_account?.label,
                  bankDoc?.fields?.correspondent_account
                )
              }
              {!!bankDoc?.fields?.bic &&
                renderBankDetailField(bankDetailsInfoLabels?.bic?.label, bankDoc?.fields?.bic)
              }
            </ul>
            <span className="checkFiles">
              <div className="flex items-center">
                <ButtonCustom
                  className="p-[7px 12px] mr-2"
                  type="primary"
                  ghost
                  text="Посмотреть файлы"
                  onClick={() => showImageViewer("bank-schema", bankDocUuid)}
                />
                {!!bankDoc?.fields?.document_status && renderConfirmationDetails()}
              </div>
              {!!bankDoc?.fields?.decline_reason && (
                isConfirmedBankDetail
                  ? null
                  : renderBankDetailCause(bankDoc?.fields?.decline_reason)
              )}
            </span>
          </>
        ) : (
          <ZeroClientProfileDocument variant="Банковские реквизиты сотрудника" />
        )}
      </div>
    </div>
  );
};

export default BankDetailsCard;
