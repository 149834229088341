import React, {
  FC,
  Key,
  JSX,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import {
  VacancyType,
  AlphaListParamsType,
} from "app/types";
import { AppDispatch } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import {
  Card,
  Table,
  Input,
  notification,
  TableColumnsType
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { TableRowSelection } from "antd/es/table/interface";
import { getVacanciesList } from "../../../../actions/alpha/vacancy.actions";
import { getResumesData } from "../../../../actions/alpha/resumes.actions";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import { downloadFile, downloadUrl } from "../../../../utils/downloadFile";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { renderAlphaDocStatus } from "../../../../utils/renderAlphaDocStatus";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import EmptyList from "../../../ui-kit/EmptyList/EmptyList";
import * as DocumentAPI from "../../../../api/document.api";
import css from "./CreateApplication.module.css";
import { INavigationProps } from "../../Resume/CreateResume/CreateResume";

interface ICreateApplicationProps {
}

interface VacancyTableDataType {
  key: React.Key;
  vacancy_name: JSX.Element | string;
  occupancy_format: JSX.Element | string;
  published_at: JSX.Element | string;
  vacancy_status: JSX.Element | string;
}

interface ResumeTableDataType {
  key: React.Key;
  name: JSX.Element | string;
  skills: JSX.Element | string | string[];
  working_hours: JSX.Element | string | null;
  cv_file: JSX.Element | string;
  cv_status: JSX.Element | string | null;
}

const vacancyColumns: TableColumnsType<VacancyTableDataType> = [
  {
    title: (
      <div className="table-title">
        Наименование вакансии
      </div>
    ),
    sorter: true,
    showSorterTooltip: false,
    dataIndex: "vacancy_name",
    width: "35%"
  },
  {
    title: (
      <div className="table-title">
        Форма найма
      </div>
    ),
    sorter: true,
    showSorterTooltip: false,
    dataIndex: "occupancy_form",
    width: "20%"
  },
  {
    title: (
      <div className="table-title">
        Дата публикации
      </div>
    ),
    dataIndex: "published_at",
    width: "15%"
  },
  {
    title: (
      <div className="table-title">
        Статус
      </div>
    ),
    dataIndex: "status",
    render: (text: string) => <a>{text}</a>,
    width: "30%"
  }
];

const resumeColumns: TableColumnsType<ResumeTableDataType> = [
  {
    title: (
      <div className="table-title">
        ФИО
      </div>
    ),
    dataIndex: "name",
    width: "30%"
  },
  {
    title: (
      <div className="table-title">
        Скиллы
      </div>
    ),
    dataIndex: "skills",
    width: "30%"
  },
  {
    title: (
      <div className="table-title">
        Режим работы
      </div>
    ),
    dataIndex: "working_hours",
    width: "15%"
  },
  {
    title: (
      <div className="table-title">
        Файл резюме
      </div>
    ),
    dataIndex: "cv_file",
    width: "10%"
  },
  {
    title: (
      <div className="table-title">
        Статус
      </div>
    ),
    dataIndex: "status",
    width: "20%"
  }
];

const { TextArea } = Input;

const CreateApplication: FC<ICreateApplicationProps> = (): JSX.Element => {
  const [vacancyListParams, setVacancyListParams] = useState<AlphaListParamsType | null>({
    page: 1,
    page_size: 5
  });
  const [resumeListParams, setResumeListParams] = useState<AlphaListParamsType | null>({
    page: 1,
    page_size: 5
  });
  const [selectedVacancies, setSelectedVacancies] = useState<Key[]>([]);
  const [selectedResumes, setSelectedResumes] = useState<Key[]>([]);
  const [applicationComment, setApplicationComment] = useState<string>("");
  const [remainingCharsComment, setRemainingCharsComment] = useState<number>(0);
  const [createDocStatus, setCreateDocStatus] = useState<number>(0);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    dispatch(getVacanciesList(vacancyListParams));
  }, []);

  useEffect(() => {
    dispatch(getResumesData(resumeListParams));
  }, []);

  useEffect((): void => {
    createAlphaDocStatus(
      createDocStatus,
      createApplicationSuccess,
      createApplicationFailure,
    );
  }, [createDocStatus]);

  const {
    vacancyList,
    vacancyPage,
    vacancyPageSize,
    vacancyTotalDocs
  } = useSelector((state: AppStateType) => state.vacancy);

  const {
    resumeData,
    resumePage,
    resumePageSize,
    resumeTotalDocs
  } = useSelector((state: AppStateType) => state.resume);

  const vacancyUuid = useLocation()?.state ?? {} as INavigationProps;

  const createApplicationSuccess = (): void => {
    notification.success({
      message: "Новая заявка успешно создана"
    });

    vacancyUuid
      ? navigate(`/alpha/vacancy/${vacancyUuid}`)
      : navigate("/alpha/applications/");
  };

  const createApplicationFailure = (): void => {
    notification.error({
      message: "Ошибка создания заявки"
    });
  };

  const onVacancySearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setVacancyListParams({
      ...vacancyListParams,
      page: 1,
      search: event.target.value ?? null
    });
  };

  const handleVacancyPageChange = (newPage: number): void => {
    setVacancyListParams({
      ...vacancyListParams,
      page: newPage
    });
  };

  const handleVacancyPageSizeChange = (newPageSize: number): void => {
    setVacancyListParams({
      ...vacancyListParams,
      page: 1,
      page_size: newPageSize
    });
  };

  const handleResumePageChange = (newPage: number): void => {
    setResumeListParams({
      ...vacancyListParams,
      page: newPage
    });
  };

  const handleResumePageSizeChange = (newPageSize: number): void => {
    setResumeListParams({
      ...vacancyListParams,
      page: 1,
      page_size: newPageSize
    });
  };

  const onResumeSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setResumeListParams({
      ...vacancyListParams,
      page: 1,
      search: event.target.value ?? null
    });
  };

  const onSelectedVacanciesChange = (newSelectedRowKeys: Key[]): void => {
    setSelectedVacancies(newSelectedRowKeys);
  };

  const vacancyRowSelection: TableRowSelection<VacancyTableDataType> | undefined = {
    selectedRowKeys: selectedVacancies,
    onChange: onSelectedVacanciesChange,
    defaultSelectedRowKeys: selectedVacancies,
    preserveSelectedRowKeys: true,
    type: "radio"
  };

  const vacancyTableData: VacancyTableDataType[] = vacancyList?.map(({ fields }: { fields: VacancyType }) => {
    return {
      key: fields.uuid,
      vacancy_name: fields.vacancy_name,
      occupancy_format: fields.occupancy_format,
      published_at: fields.published_at,
      vacancy_status: fields.vacancy_status
    };
  }) ?? [];

  const onSelectedResumesChange = (newSelectedRowKeys: Key[]): void => {
    setSelectedResumes(newSelectedRowKeys);
  };

  const resumesRowSelection: TableRowSelection<ResumeTableDataType> | undefined = {
    selectedRowKeys: selectedResumes,
    onChange: onSelectedResumesChange,
    defaultSelectedRowKeys: selectedResumes,
    preserveSelectedRowKeys: true,
    type: "radio"
  };

  const resumeTableData: ResumeTableDataType[] = resumeData?.map(({ fields }) => {
    const {
      uuid,
      skills,
      cv_file,
      cv_status,
      last_name,
      first_name,
      second_name,
      working_hours,
    } = fields;

    return {
      key: uuid,
      name: [last_name, first_name, second_name].filter(Boolean).join(" "),
      skills: skills,
      working_hours: working_hours,
      cv_file: (
        <a
          className="flex blue-color items-center mb-[4px]"
          onClick={() => downloadFile("cv_file", downloadUrl(cv_file))}
        >
          <AttachFileIcon className="flex-shrink-0 mr-1" />
          <div className={css.docFile}>Файл</div>
        </a>
      ),
      cv_status: (renderAlphaDocStatus(cv_status as string)),
    };
  }) ?? [];

  const handleApplicationCommentChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    setApplicationComment(value);
    setRemainingCharsComment(charsCount);
  };

  const onCloseForm = (): void => {
    setApplicationComment("");
    setRemainingCharsComment(0);

    vacancyUuid
      ? navigate(`/alpha/vacancy/${vacancyUuid}`)
      : navigate("/alpha/applications/");
  };

  const onSaveForm = async (): Promise<void> => {
    const newResumeData = {
      vacancy: selectedVacancies,
      resource: selectedResumes,
      request_comment: applicationComment,
    };

    try {
      const { status } = await DocumentAPI.createNewDocument("supplier-request", newResumeData);

      setCreateDocStatus(status);
      setApplicationComment("");
      setRemainingCharsComment(0);
    } catch (error: any) {
      setCreateDocStatus(error.response.status);
      console.error("Create application error:", error.response.data.message);
    }
  };

  const disableCreateDoc: boolean = !(selectedVacancies?.length && selectedResumes?.length);
  const disableSaveAsDraft: boolean = !(selectedVacancies?.length || selectedResumes?.length || remainingCharsComment);

  return (
    <div className="container-block">
      <h1 className={css.createApplicationHeader}>
        Создание новой заявки
      </h1>
      <Card className="mb-3">
        <h2 className={css.createApplicationBlockHeader}>
          <span className={css.requiredField}>*</span>Вакансия
        </h2>
        <Input
          className="w-full mb-3"
          placeholder="Найти по наименованию вакансии"
          value={vacancyListParams?.search as string}
          onChange={onVacancySearch}
          suffix={<SearchOutlined style={{ opacity: .6 }} />}
          size="large"
          allowClear
        />
        <Table
          rowSelection={vacancyRowSelection}
          columns={vacancyColumns}
          dataSource={vacancyTableData}
          pagination={false}
          locale={{
            emptyText: <EmptyList activeKey="vacancy" className="h-[35vh]" />
          }}
        />
        <PaginationCustom
          total={vacancyTotalDocs}
          showTotal={(total: number, range: number[]): string =>
            `${range[0]}-${range[1]} из ${total}`
          }
          currentPage={vacancyPage}
          defaultPageSize={10}
          pageSize={vacancyPageSize}
          defaultCurrent={1}
          handlePageChange={handleVacancyPageChange}
          handlePageSizeChange={handleVacancyPageSizeChange}
        />
      </Card>
      <Card className="mb-3">
        <h2 className={css.createApplicationBlockHeader}>
          <span className={css.requiredField}>*</span>Резюме
        </h2>
        <Input
          className="w-full mb-3"
          placeholder="Найти по ФИО"
          value={resumeListParams?.search as string}
          onChange={onResumeSearch}
          suffix={<SearchOutlined style={{ opacity: .6 }} />}
          size="large"
          allowClear
        />
        <Table
          rowSelection={resumesRowSelection}
          columns={resumeColumns}
          dataSource={resumeTableData}
          pagination={false}
          locale={{
            emptyText: <EmptyList activeKey="resume" className="h-[35vh]" />
          }}
        />
        <PaginationCustom
          total={resumeTotalDocs}
          showTotal={(total: number, range: number[]): string =>
            `${range[0]}-${range[1]} из ${total}`
          }
          currentPage={resumePage}
          defaultPageSize={10}
          pageSize={resumePageSize}
          defaultCurrent={1}
          handlePageChange={handleResumePageChange}
          handlePageSizeChange={handleResumePageSizeChange}
        />
      </Card>
      <Card className="mb-3">
        <h2 className={css.createApplicationBlockHeader}>
          Параметры заявки
        </h2>
        <div className={css.createApplicationLabel}>
          Комментарий
        </div>
        <TextArea
          className="mr-4 mb-0.5"
          maxLength={2000}
          value={applicationComment}
          placeholder="Введите ваш комментарий"
          onChange={handleApplicationCommentChange}
        />
        <div className="text-xs font-normal leading-100 counter-gray-color text-right">
          {remainingCharsComment}/2000
        </div>
      </Card>
      <Card className="mb-3">
        <div className={`${css.createContractActionBlock} text-right`}>
          <ButtonCustom
            className="mr-2"
            type="default"
            text="Отменить"
            size="large"
            ghost
            onClick={onCloseForm}
          />
          <ButtonCustom
            className="mr-2"
            type="default"
            text="Сохранить как черновик"
            size="large"
            ghost
            disabled={disableSaveAsDraft}
          />
          <ButtonCustom
            className="mr-2"
            type="primary"
            text="Создать заявку"
            size="large"
            onClick={onSaveForm}
            disabled={disableCreateDoc}
          />
        </div>
      </Card>
    </div>
  );
};

export default CreateApplication;
