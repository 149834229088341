import React, { JSX, useEffect, useState } from "react";
import { NavigateFunction, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "./reducers/mainReducer";
import Tasks from "./components/Tasks/Tasks";
import NavBar from "./components/NavBar/NavBar";
import Profile from "./components/Profile/Profile";
import Reports from "./components/Reports/Reports";
import Appeals from "./components/Appeals/Appeals";
import Clients from "./components/ClientsList/Clients";
import StubPage from "./pages/StubPage/StubPage";
import LoginForm from "./components/LoginForm/LoginForm";
import TaskCards from "./components/Tasks/TaskCards/TaskCards";
import ClientCard from "./components/ClientsList/ClientCard/ClientCard";
import ResetPasswordForm from "./components/LoginForm/ResetPasswordForm";
import ConfirmNewPasswordForm from "./components/LoginForm/ConfirmPasswordForm";
import ClientProfileCard from "./components/ClientProfileCard/ClientProfileCard";
import Performers from "./components/Performers/Performers";
import PerformerCard from "./components/Performers/PerformerCard/PerformerCard";
import { Content } from "antd/es/layout/layout";
import { Affix, Layout } from "antd";
import { decodeToken } from "./actions/account.actions";
import { AppDispatch } from "./store/store";
import { getNotificationsNumber } from "./actions/notifications.actions";
import { NotificationsDataType } from "app/types";
import EmployeeCard from "./components/ClientsList/ClientCard/ClientCardItem/Employees/EmployeeCard/EmployeeCard";
import CheckOrganizationOneC from "./components/ClientProfileCard/CheckOrganizationOneC/CheckOrganizationOneC";
import { roleResolver } from "./utils/roleResolver";
import VacancyCard from "./components/Alpha/Vacancy/VacancyCard/VacancyCard";
import CreateVacancy from "./components/Alpha/Vacancy/CreateVacancy/CreateVacancy";
import Vacancy from "./components/Alpha/Vacancy/Vacancy";
import CreateResume from "./components/Alpha/Resume/CreateResume/CreateResume";
import ResumeListing from "./components/Alpha/Resume/ResumeListing/ResumeListing";
import ResumeCard from "./components/Alpha/Resume/ResumeCard/ResumeCard";
import ContractorsListing from "./components/Alpha/Contractors/ContractorsListing/ContractorsListing";
import ContractorCard from "./components/Alpha/Contractors/ContractorCard/ContractorCard";
import ContractsListing from "./components/Alpha/Contracts/ContractsListing/ContractsListing";
import CreateContractor from "./components/Alpha/Contractors/CreateContractor/CreateContractor";
import ContractCard from "./components/Alpha/Contracts/ContractCard/ContractCard";
import CreateContract from "./components/Alpha/Contracts/CreateContract/CreateContract";
import CreateApplication from "./components/Alpha/Applications/CreateApplication/CreateApplication";
import ApplicationsList from "./components/Alpha/Applications/ApplicationsList/ApplicationList";
import ApplicationCard from "./components/Alpha/Applications/ApplicationCard/ApplicationCard";

const emptyPathName: number = 1;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const App = (): JSX.Element => {
  const [isNavBarHidden, setNavBarHidden] = useState<boolean>(false);
  const [isResetFormOpened, setResetFormOpened] = useState<boolean>(false);
  const [isConfirmNewPasswordFormOpened, setConfirmNewPasswordFormOpened] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const params = useLocation();

  const isStubPage: boolean = params.pathname?.length === emptyPathName;

  useEffect(() => {
    window.document.title = window.location.origin === prodDomain
      ? "Бизнес Конвейер"
      : "Личный кабинет бухгалтера";
  }, []);

  const { authToken, decodedToken } = useSelector((state: AppStateType) => state.account);
  const notifications: NotificationsDataType[] =
    useSelector((state: AppStateType) => state.notifications.notifications);

  useEffect((): void => {
    if (authToken) {
      dispatch(decodeToken(authToken));
      dispatch(getNotificationsNumber());
    }
  }, [authToken]);

  useEffect(() => {
    if (!authToken && !isStubPage) {
      navigate("/login");
    }

    if (isStubPage) {
      setNavBarHidden(true);
    }
  }, [navigate, authToken]);

  const administrator: boolean = roleResolver(decodedToken).isAdmin;
  const techSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const isAvailable: boolean = administrator || techSupport;
  const isAlphaAdmin: boolean = roleResolver(decodedToken).isAlphaAdmin;
  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;
  const isAlphaUser: boolean = isAlphaAdmin || isAlphaContractor;

  return (
    <Layout hasSider={!!authToken}>
      {!isNavBarHidden && (
        <Affix
          offsetTop={authToken ? 0.1 : 0}
          style={{
            width: `${collapsed ? "68px" : "200px"}`,
            transition: "width .2s ease-in-out"
        }}
        >
          {!!authToken &&
            <NavBar
              setNavBarHidden={setNavBarHidden}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />}
        </Affix>
      )}
      <Content
        style={{
          backgroundColor: "#F3F4F6",
          transition: "width 0.3s ease-out",
          height: "1190px",
        }}
      >
        <Routes>
          <Route path="/" element={<StubPage />} />
          <Route path="/login" element={
            <LoginForm
              setNavBarHidden={setNavBarHidden}
              setResetFormOpened={setResetFormOpened}
            />
          }/>
          <Route path="/reset-password" element={
            <ResetPasswordForm
              setResetFormOpened={setResetFormOpened}
            />
          }/>
          <Route path="/confirm-password" element={<ConfirmNewPasswordForm />} />
          {!!authToken && !isAlphaUser && (
            <>
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/task/:id" element={
                <TaskCards setNavBarHidden={setNavBarHidden}/>
              }/>
              <Route path="/task/:id/clients/:id" element={<ClientCard />}/>
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/:id" element={<ClientCard />} />
              <Route path="/clients/:id/employee/:employeeId" element={<EmployeeCard />} />
              <Route path="/clients/:id/task/:id" element={
                <TaskCards setNavBarHidden={setNavBarHidden}/>
              }/>
              <Route path="/appeals" element={<Appeals />} />
              <Route path="/reports/*" element={<Reports />} />
              {isAvailable && (
                <>
                  <Route path="/performers" element={<Performers />} />
                  <Route path="/performers/:id" element={<PerformerCard />} />
                </>
              )}
              <Route path="/profile" element={<Profile />} />
              <Route path="/task/:id/questionnaire/:id/*" element={
                <ClientProfileCard setNavBarHidden={setNavBarHidden} />
              }/>
              <Route path="/task/:id/questionnaire/:id/validation/*" element={
                <CheckOrganizationOneC setNavBarHidden={setNavBarHidden} />
              }/>
              <Route path="/profile/absent/:id" element={<PerformerCard isAbsentCard />} />
            </>
          )}
          {!!authToken && isAlphaUser && (
            <>
              <Route path="/alpha/vacancies" element={<Vacancy />} />
              <Route path="/alpha/vacancy/:id" element={<VacancyCard />} />
              <Route path="/alpha/create_vacancy" element={<CreateVacancy />} />
              <Route path="/alpha/resumes" element={<ResumeListing />} />
              <Route path="/alpha/resume/:id" element={<ResumeCard />} />
              <Route path="/alpha/create_resume" element={<CreateResume />} />
              <Route path="/alpha/contractors" element={<ContractorsListing />} />
              <Route path="/alpha/contractor/:id" element={<ContractorCard />} />
              <Route path="/alpha/contractor/:id/create_resume" element={<CreateResume />} />
              <Route path="/alpha/create_contractor" element={<CreateContractor />} />
              <Route path="/alpha/contracts" element={<ContractsListing />} />
              <Route path="/alpha/contract/:id" element={<ContractCard />} />
              <Route path="/alpha/create_contract" element={<CreateContract />} />
              <Route path="/alpha/update_vacancy/:id" element={<CreateVacancy />} />
              <Route path="/alpha/update_resume/:id" element={<CreateResume />} />
              <Route path="/alpha/create_application" element={<CreateApplication />} />
              <Route path="/alpha/applications" element={<ApplicationsList />} />
              <Route path="/alpha/application/:id" element={<ApplicationCard />} />
              <Route path="/alpha/my_company" element={<Profile />} />
              <Route path="/alpha/update_contract/:id" element={<CreateContract />} />
            </>
          )}
        </Routes>
      </Content>
    </Layout>
  );
};

export default App;
