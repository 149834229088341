import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { Form, Input, Select } from "antd";
import { ContractorsListType, SelectOptionsType, VacancyListType } from "app/types";
import * as DocumentAPI from "../../../../../../api/document.api";
import { hiringManager } from "../../mocks";
import css from "../../CreateVacancy.module.css";

interface IEmployerProps {
  vacancyData: VacancyListType | null;
}

const Employer: FC<IEmployerProps> = ({ vacancyData }): JSX.Element => {
  const [clientCompanies, setClientCompanies] = useState<ContractorsListType[]>([]);

  useEffect(() => {
    DocumentAPI.getDocumentList("client-company")
    .then((response) => setClientCompanies(response.data.results))
    .catch((err) => console.error("Get client-company error:", err));
  }, []);

  const clientCompaniesOptions: SelectOptionsType[] = clientCompanies?.map(({ fields }: ContractorsListType) => {
    return {
      key: fields?.uuid,
      value: fields?.uuid,
      label: fields?.name,
    };
  }) ?? [];

  return (
    <div className={`${css.containerBlock} p-5 mb-3 bg-white border-bottom-gray`}>
      <h1 className={css.createVacancyBlockHeader}>
        Наниматель
      </h1>
      <div className="flex justify-between">
        <Form.Item
          className="m-0 mr-[20px] w-[32%]"
          id="project_name"
          name="project_name"
          label="Наименование проекта"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите наименование проекта"
            }
          ]}
          initialValue={vacancyData?.fields?.project_name ?? null}
        >
          <Input
            className="w-full"
            placeholder="Введите наименование проекта"
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          className="m-0 mr-[20px] w-[32%]"
          id="employer"
          name="employer"
          label="Наниматель"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите нанимателя"
            }
          ]}
          initialValue={vacancyData?.fields?.employer?.length
            ? vacancyData?.fields?.employer?.[0]?.label
            : null
          }
        >
          <Select
            className="w-full"
            placeholder="Выберите нанимателя"
            options={clientCompaniesOptions}
            listHeight={196}
            showSearch
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          className="m-0 w-[32%]"
          id="hiring_manager"
          name="hiring_manager"
          label="Нанимающий менеджер"
          initialValue={vacancyData?.fields?.hiring_manager ?? null}
        >
          <Select
            className="w-full"
            placeholder="Выберите нанимающего менеджера"
            options={hiringManager}
            listHeight={196}
            showSearch
            allowClear
            size="large"
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default Employer;
