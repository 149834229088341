import React, { FC, JSX, ReactNode } from "react";
import { Pagination, Select } from "antd";
import css from "./PaginationCustom.module.css";

const { Option } = Select;

interface IPaginationCustomProps {
  className?: string;
  total?: number;
  showTotal?: ((total: number, range: [number, number]) => ReactNode) | undefined;
  currentPage?: number;
  defaultPageSize?: number;
  pageSize: number;
  defaultCurrent?: number;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  handlePageChange?: ((newPage: number) => void) | undefined;
  handlePageSizeChange?: ((newPageSize: number) => void) | undefined;
}

type pageSizeOptionType = [number, number, number, string];

const pageSizeOption: pageSizeOptionType = [5, 10, 20, "Все"];

const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

enum PageOption {
  allPageSize= 50,
  firstPage = 1,
}

export const PaginationCustom: FC<IPaginationCustomProps> = ({
  className,
  total,
  currentPage,
  defaultPageSize = 10,
  pageSize,
  defaultCurrent = 1,
  handlePageChange,
  handlePageSizeChange,
}): JSX.Element => {
  const isFullPageSize: boolean = pageSize === PageOption.allPageSize;
  const totalDocs: number = isFullPageSize ? PageOption.allPageSize : total as number;
  const onePage: number = isFullPageSize ? PageOption.firstPage : totalDocs;

  const renderPageSizeSelectOptions: JSX.Element[] = pageSizeOption.map((size: number | string, index: number) => {
    const optionValue: string | number = size === "Все" ? totalDocs : size;
    const optionText: string | number = size === "Все" ? "Все" : `${size} / на странице`;

    return (
      <Option key={index} value={optionValue}>
        {optionText}
      </Option>
    );
  });

  return (
    <div className={css.pagination}>
      <Pagination
        className={`${css.paginationCustom} ${className}`}
        total={onePage}
        current={currentPage}
        defaultPageSize={defaultPageSize}
        pageSize={pageSize}
        defaultCurrent={defaultCurrent}
        onChange={handlePageChange}
      />
      <Select
        value={pageSize}
        variant="borderless"
        filterOption={filterOption}
        onChange={handlePageSizeChange}
        className={css.select}
      >
        {renderPageSizeSelectOptions}
      </Select>
    </div>
  );
};
