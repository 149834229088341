export default {
  setItem: async (key: string, value: string): Promise<void> => {
    await Promise.resolve();
    localStorage.setItem(key, value);
  },
  getItem: async (key: string): Promise<string|null> => {
    await Promise.resolve();
    return localStorage.getItem(key);
  },
};
