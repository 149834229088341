import React, {
  FC,
  JSX,
  Key,
  useState,
  useEffect,
} from "react";
import { AppDispatch } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { AlphaListParamsType, ContractType } from "app/types";
import { AppStateType } from "../../../../reducers/mainReducer";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import {
  Table,
  Input,
  Button,
  Dropdown,
  MenuProps,
  TableColumnsType,
} from "antd";
import { getContractsList } from "../../../../actions/alpha/contracts.actions";
import DeleteContractModal from "./DeleteContractModal/DeleteContractModal";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import EmptyList from "../../../ui-kit/EmptyList/EmptyList";
import TagCustom from "../../../ui-kit/TagCustom/TagCustom";
import { renderAlphaDocStatus } from "../../../../utils/renderAlphaDocStatus";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import { deadlineDays } from "../../../../utils/deadlineDays";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings-icon.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search_icon.svg";
import css from "./ContractsListing.module.css";
import dayjs from "dayjs";
import { checkServiceStatus } from "../../../../utils/checkServiceStatus";

interface IContractsListingProps {
}

interface IContractData {
  key: React.Key;
  duration: string;
  contractor: string;
  contractee: string;
  contract_sum: number;
  contract_number: string;
  contract_status: string;
  contractual_date: string;
}

const ContractsListing: FC<IContractsListingProps> = ():JSX.Element => {
  const [deleteContractKey, setDeleteContractKey] = useState<Key | null>(null);
  const [deleteContractNumber, setDeleteContractNumber] = useState<string | null>(null);
  const [isDeleteContractModalOpen, setDeleteContractModalOpen] = useState<boolean>(false);
  const [contractsListParams, setContractsListParams] = useState<AlphaListParamsType | null>( {
    page: 1,
    page_size: 5,
  });

  const {
    contractsList,
    contractsPage,
    contractsPageSize,
    contractsTotalDocs,
  } = useSelector((state: AppStateType) => state.contracts);

  const navigate: NavigateFunction = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getContractsList(contractsListParams));
  }, [contractsListParams]);

  const handleContractsPageChange = (newPage: number): void => {
    setContractsListParams({
      ...contractsListParams,
      page: newPage,
    });
  };

  const handleContractsPageSizeChange = (newPageSize: number): void => {
    setContractsListParams({
      ...contractsListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const renderContractStringField = (text: string): string => dayjs(text)?.isValid()
    ? dayjs(text).format("DD.MM.YY")
    : text;

  const renderContractNumberField = (text: number, isSum?: boolean): string | number => isSum ? `${text} ₽` : text;

  const renderContractTableLink = (text: string | number, record: IContractData, isSum?: boolean) => (
    <Link
      className={css.tableCell}
      to={`/alpha/contract/${record?.key}`}
    >
      {typeof text === "string" ? renderContractStringField(text) : renderContractNumberField(text, isSum)}
    </Link>
  );

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setContractsListParams);

  const deleteContract = (data: IContractData): void => {
    setDeleteContractModalOpen(true);
    setDeleteContractKey(data?.key);
    setDeleteContractNumber(data?.contract_number);
  };

  const contractsColumns: TableColumnsType<IContractData> = [
    {
      title: (
        <div className="table-title">
          № Договора
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "contract_number",
      width: "12%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "contract_number"),
      }),
      render: (text: string, record: IContractData) => renderContractTableLink(+text, record),
    },
    {
      title: (
        <div className="table-title">
          Дата договора
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "contractual_date",
      width: "10%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "contractual_date"),
      }),
      render: (text: string, record: IContractData) => renderContractTableLink(text, record),
    },
    {
      title: (
        <div className="table-title">
          Срок действия
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "duration",
      width: "29%",
      render: (_: string, record: IContractData) => (
        <div className={`${css.tableCell} flex items-center gap-3`}>
          <div>{renderContractTableLink(record.duration, record)}</div>
          <TagCustom
            color={checkServiceStatus(record?.contractual_date)}
            text={record.duration ? deadlineDays(record?.contractual_date) as string : ""}
          />
        </div>
      ),
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "duration"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Заказчик
        </div>
      ),
      dataIndex: "contractor",
      sorter: true,
      showSorterTooltip: false,
      width: "14%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "contractor"),
      }),
      render: (text: string, record: IContractData) => renderContractTableLink(text, record),
    },
    {
      title: (
        <div className="table-title">
          Исполнитель
        </div>
      ),
      dataIndex: "contractee",
      sorter: true,
      showSorterTooltip: false,
      width: "14%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "contractee"),
      }),
      render: (text: string, record: IContractData) => renderContractTableLink(text, record),
    },
    {
      title: (
        <div className="table-title">
          Сумма по договору
        </div>
      ),
      dataIndex: "contract_sum",
      sorter: true,
      showSorterTooltip: false,
      width: "14%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "contract_sum"),
      }),
      render: (text: string, record: IContractData) => renderContractTableLink(text, record, !!text),
    },
    {
      title: (
        <div className="table-title">
          Статус
        </div>
      ),
      dataIndex: "contract_status",
      sorter: true,
      showSorterTooltip: false,
      width: "20%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "status"),
      }),
      render: (text: string) => <div className={css.tableCell}>{renderAlphaDocStatus(text)}</div>,
    },
    {
      title: "",
      key: "settings",
      width: "4%",
      render: (_, record: IContractData) => {
        const menuItems: MenuProps["items"] = [
          {
            label: <div>Редактировать</div>,
            key: "1",
            onClick: () => navigate(`/alpha/update_contract/${record?.key}`),
          },
          {
            label: <div>Удалить</div>,
            key: "2",
            danger: true,
            onClick: () => deleteContract(record),
          },
        ];

        return (
          <Dropdown.Button
            menu={{ items: menuItems }}
            buttonsRender={([_leftButton, _rightButton]) => [
              null,
              <Button
                className={css.settingsBtn}
                key={record?.key}
                type="text"
                icon={<SettingsIcon />}
              />,
            ]}
          />
        );
      },
    },
  ];

  const contractsData: IContractData[] = contractsList?.map(({ fields }) => {
    const {
      uuid,
      duration,
      contractor,
      contractee,
      contract_sum,
      contract_number,
      contract_status,
      contractual_date,
    } = fields as ContractType;

    return {
      key: uuid,
      duration,
      contractor: contractor?.[0]?.label,
      contractee: contractee?.[0]?.label,
      contract_sum,
      contract_number,
      contract_status,
      contractual_date,
    };
  });

  return (
    <div className={css.container}>
      <div className="flex items-center content-center justify-between mb-3">
        <h1 className={css.containerHeader}>Мои договора</h1>
        <ButtonCustom
          className="mr-3"
          text="Создать новый договор"
          type="primary"
          size="large"
          onClick={() => navigate("/alpha/create_contract", { state: { isCreateContract: true } })}
        />
      </div>
      <div className="p-4 mb-3 bg-white rounded-lg">
        <Input
          className="mb-3"
          size="large"
          allowClear
          suffix={<SearchIcon style={{ width: "20px", opacity: .7 }} />}
          placeholder="Найти по номеру договора, заказчику или исполнителю"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContractsListParams({
            ...contractsListParams,
            search: e.target.value,
          })}
        />
        <Table
          columns={contractsColumns}
          dataSource={contractsData}
          pagination={false}
          locale={{
            emptyText: <EmptyList activeKey="contracts" className="h-[35vh]" />
          }}
        />
        <PaginationCustom
          className={css.paginationShowTotal}
          total={contractsTotalDocs}
          showTotal={(total: number, range: number[]): string =>
            `${range[0]}-${range[1]} из ${total}`
          }
          currentPage={contractsPage}
          defaultPageSize={10}
          pageSize={contractsPageSize}
          defaultCurrent={1}
          handlePageChange={handleContractsPageChange}
          handlePageSizeChange={handleContractsPageSizeChange}
         />
      </div>
      <DeleteContractModal
        isModalOpen={isDeleteContractModalOpen}
        setIsModalOpen={setDeleteContractModalOpen}
        contractNumber={deleteContractNumber}
      />
    </div>
  );
};

export default ContractsListing;
