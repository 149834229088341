import React, { JSX } from "react";
import css from "../components/Tasks/Tasks.module.css";
import { Link } from "react-router-dom";
import { TasksListingDataType } from "app/types";
import { ReactComponent as MiddleIcon } from "../assets/icons/middle_priority.svg";
import { ReactComponent as HighIcon } from "../assets/icons/high_priority.svg";
import { Tooltip } from "antd";

export const priorityIcon = (record: TasksListingDataType): JSX.Element | null => {
  const isHighPriority: boolean = record?.fields?.priority === "Высокий";
  
  const contentTooltip = (text: string): JSX.Element => (
    <div className={css.tooltip}>
      {text}
    </div>
  );
  
  return (
    <Link
      className={css.tableCellStatus}
      to={`/task/${record?.fields?.uuid}`}
    >
      <Tooltip
        placement="top"
        title={contentTooltip(isHighPriority ? "Высокий приоритет" : "Средний приоритет")}
        trigger="hover"
        arrow={{ pointAtCenter: true }}
      >
        <div className={isHighPriority ? css.highPriority : css.middlePriority}>
          {isHighPriority ? <HighIcon className="flex-shrink-0" /> : <MiddleIcon className="flex-shrink-0" />}
        </div>
      </Tooltip>
    </Link>
  );
};