import React, { FC, JSX, useState } from "react";
import css from "./DocumentsInfo.module.css";
import { Collapse, CollapseProps } from "antd";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross_icon.svg";

interface IDocumentsInfo {
}

const linkUserGuid: string = "https://www.smbconnect.ru/media/other/%D0%9E%D0%B1%D1%89%D0%B5%D0%B5_%D1%80%D1%83%D" +
    "0%BA%D0%BE%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE.pdf";
const linkUserInstrOnline: string = "https://www.smbconnect.ru/media/other/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1" +
    "%83%D0%BA%D1%86%D0%B8%D1%8F_%D0%BF%D0%BE_%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BA%D0%B5_SAAS.pdf";
const linkUserInstrInstall: string = "https://www.smbconnect.ru/media/other/%D0%98%D0%BD%D1%81%D1%82%D1%80%D" +
    "1%83%D0%BA%D1%86%D0%B8%D1%8F_%D0%BF%D0%BE_%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BA%D0%B5_STANDALONE.pdf";
const linkUserProcessDescription: string = "https://www.smbconnect.ru/media/other/%D0%9E%D0%BF%D0%B8%D1%81%D0%B0" +
    "%D0%BD%D0%B8%D0%B5_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D0%BE%D0%B2_%D0%B6%D0%B8%D0%B7%D0%BD%D0%B5%D0%" +
    "BD%D0%BD%D0%BE%D0%B3%D0%BE_%D1%86%D0%B8%D0%BA%D0%BB%D0%B0.pdf";

const DocumentsInfo: FC<IDocumentsInfo> = (): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string | string[]>([]);

  const handleCollapseChange = (key: string | string[]): void => {
    setActiveKey(key);
  };

  const collapseLabel = (text: string, isActive: boolean): JSX.Element => (
    <div className={css.collapseContainer}>
      <div className={css.collapseLabel}>
        {text}
      </div>
      <div className={`${css.collapseIcon} ${isActive ? css.activeIcon : ""}`}>
        <CrossIcon />
      </div>
    </div>
  );

  const renderLinkToDocument = (text: string, linkTo: string): JSX.Element => {
    return (
      <a
        href={linkTo}
        target="_blank"
        rel="noreferrer noopener"
        className={css.instructionOption}
      >
        {text}
      </a>
    );
  };

  const getItems: (panelStyle: React.CSSProperties) => CollapseProps["items"] = (panelStyle) => [
    {
      key: "1",
      label: collapseLabel("Руководство пользователя", activeKey.includes("1")),
      children: (
        renderLinkToDocument("Руководство пользователя ПО «Бизнес Конвейер»", linkUserGuid)
      ),
      style: panelStyle,
      showArrow: false,
    },
    {
      key: "2",
      label: collapseLabel("Инструкция по установке", activeKey.includes("2")),
      children: (
        <div className="flex flex-col">
          {renderLinkToDocument("Инструкция по установке ПО «Бизнес Конвейер», онлайн версия", linkUserInstrOnline)}
          {renderLinkToDocument(
              "Инструкция по установке ПО «Бизнес Конвейер», устанавливаемая версия",
              linkUserInstrInstall
          )}
        </div>
      ),
      style: panelStyle,
      showArrow: false,
    },
    {
      key: "3",
      label: collapseLabel("Описание жизненного цикла", activeKey.includes("3")),
      children: (
        renderLinkToDocument(
          "Описание процессов, обеспечивающих поддержание жизненного цикла ПО 'Бизнес Конвейер'",
          linkUserProcessDescription
        )
      ),
      style: panelStyle,
      showArrow: false,
    },
  ];

  const panelStyle: React.CSSProperties = {
    marginBottom: 3,
    background: "#ffffff",
    borderRadius: 0,
    border: "none",
    transition: "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
    overflow: "hidden",
  };

  return (
      <div className="max-w-[1200px] mx-auto mb-20" id="documents">
        <div className="pt-[30px] pb-10">
          <div className={css.instructionsText}>
            Инструкции:
          </div>
        </div>
        <div className="max-w-[640px] m-auto">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            style={{ background: "#F3F4F6" }}
            items={getItems(panelStyle)}
            onChange={handleCollapseChange}
          />
        </div>
      </div>
  );
};

export default DocumentsInfo;