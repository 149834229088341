import React, {
  ChangeEvent,
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { Dropdown, Input, MenuProps, message } from "antd";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as DropDownMenu } from "../../../../../assets/icons/settings-icon.svg";
import { ReactComponent as Success } from "../../../../../assets/icons/check_circle_icon_green.svg";
import { ReactComponent as Error } from "../../../../../assets/icons/warning_circle_icon.svg";
import css from "./Comments.module.css";
import { createComments } from "../../../../../api/client.api";
import * as jose from "jose";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { AppDispatch } from "../../../../../store/store";
import { getClientCommentsData } from "../../../../../actions/client.actions";
import { CommentDataType, DocumentClientType } from "app/types";
import dayjs from "dayjs";
import DraftWarning from "../../../../../utils/DraftWarning/DraftWarning";

interface ICommentsProps {
}

const Comments: FC<ICommentsProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const documentClient: DocumentClientType | null = useSelector((state: AppStateType) => state.client.documentClient);
  const clientCommentsData: CommentDataType[] = useSelector((state: AppStateType) => state.client.clientCommentsData);

  const [text, setText] = useState<string>("");
  const [remainingCharsComment, setRemainingCharsComment] = useState<number>(0);
  const [editCommentId, setEditCommentId] = useState<number | null>(null);
  const [commentsListingUpdated, setCommentsListingUpdated] = useState<boolean>(false);

  const {TextArea} = Input;
  const [messageApi, contextHolder] = message.useMessage();

  //получения комментариев
  useEffect(() => {
    dispatch(getClientCommentsData({"client.uuid": documentClient?.fields?.uuid }));
  }, [commentsListingUpdated]);

  const successVariant = (text: string): string => {
    switch (text) {
      case "добавлен":
        return "Комментарий успешно добавлен";
      case "удален":
        return "Комментарий успешно удален";
      case "изменен":
        return "Комментарий успешно изменен";
      default:
        return "";
    }
  };

  const errorVariant = (text: string): string => {
    switch (text) {
      case "добавлен":
        return "При добавлении комментария произошла ошибка, пожалуйста, повторите позже";
      case "удален":
        return "При удалении комментария произошла ошибка, пожалуйста, повторите позже";
      case "изменен":
        return "При изменении комментария произошла ошибка, пожалуйста, повторите позже";
      default:
        return "";
    }
  };

  const messageSuccess = (text: string): void => {
    messageApi.open({
      content: (
        <div className="flex items-center">
          <Success className="flex-shrink-0 mr-[4px]"/>
          <div className={css.messageText}>
            {successVariant(text)}
          </div>
        </div>
      ),
      icon: false,
    });
  };

  const messageError = (text: string): void => {
    messageApi.open({
      content: (
        <div className="flex items-center">
          <Error className="flex-shrink-0 mr-[4px]"/>
          <div className={css.messageText}>
            {errorVariant(text)}
          </div>
        </div>
      ),
      icon: false,
    });
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    setText(value);
    setRemainingCharsComment(charsCount);
  };

  const handleSaveComment = async (): Promise<void> => {
    if (text.trim() !== "") {
      try {
        const response = await createComments({
          commentator: decodedToken?.sub,
          comment: text,
          client: documentClient?.fields?.uuid,
        });

        if (response.status === 200) {
          messageSuccess("добавлен");
          setCommentsListingUpdated(!commentsListingUpdated);
        }
      } catch {
        messageError("добавлен");
      }
    }

    setText("");
    setRemainingCharsComment(0);
  };

  //Логика редактирования комментария
  // const cancelChangeComment = (): void => {
  //   setEditCommentId(null);
  //   setText("");
  //   setRemainingCharsComment(0);
  // };

  const capitalizedText = (text: string): string => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <div>
      <DraftWarning />
      <div className={`${css.container} mb-3 bg-white border-bottom-gray`}>
        {contextHolder}
        <div className={css.headerContainer}>
          <h1 className="header-text mb-1">
            Комментарии
          </h1>
          <h4 className={`${css.textStyle} gray-color`}>
            Все комментарии в этом разделе служебного характера, клиент не увидит их
          </h4>
        </div>
        {!!clientCommentsData.length && (
          <div className={css.messagesContainer}>
            {clientCommentsData.map((comment: CommentDataType) => {
              const menuItems: MenuProps["items"] = [
                {
                  label: (
                    <div>
                      Изменить
                    </div>
                  ),
                  key: "1",
                },
                {
                  label: (
                    <div>
                      Удалить
                    </div>
                  ),
                  key: "2",
                  danger: true,
                },
              ];

              // const editCommentData: boolean = !!(editCommentId && (editCommentId === comment.id));

              return (
                // <div className={editCommentData ? css.editMessageBlock : css.messageBlock} key={comment.id}>
                <div className={css.messageBlock} key={comment.id}>
                  <div className={css.textStyle}>
                    <div className="gray-color">
                      <span className="mr-2">
                        {dayjs(comment?.created_at)?.format("DD.MM.YYYY HH:mm")}
                      </span>
                      <span>
                        {comment?.fields?.commentator?.[0].label}
                      </span>
                    </div>
                    <div className={css.commentText}>
                      {capitalizedText(comment?.fields?.comment)}
                    </div>
                  </div>
                  {/*Логика редактирования и удаления комментария*/}
                  {/*{!editCommentData && (*/}
                  {/*  <Dropdown*/}
                  {/*    key={comment.id}*/}
                  {/*    menu={{items: menuItems}}*/}
                  {/*    className="cursor-pointer"*/}
                  {/*  >*/}
                  {/*    <DropDownMenu/>*/}
                  {/*  </Dropdown>*/}
                  {/*)}*/}
                </div>
              );
            })}
          </div>
        )}
        <div className={css.inputBlock}>
          <div className="w-full">
            <TextArea
              rows={editCommentId ? 4 : 2}
              maxLength={2000}
              className="mr-4"
              value={text}
              placeholder="Ваш комментарий"
              onChange={handleTextAreaChange}
            />
            <div className="text-xs font-normal leading-100 counter-gray-color text-right">
              {remainingCharsComment}/2000
            </div>
          </div>
          <div className="flex flex-col">
            <ButtonCustom
              disabled={!text}
              type="primary"
              text={editCommentId ? "Сохранить" : "Отправить"}
              className={css.buttonSend}
              onClick={handleSaveComment}
            />
            {editCommentId && (
              <ButtonCustom
                type="default"
                text="Отменить"
                className={css.buttonCancel}
                // onClick={cancelChangeComment}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
