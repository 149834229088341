import axios from "../utils/axios";
import { FilterEmployeeType } from "app/types";

const API_EMPLOYEE: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_EMPLOYEE;

//ручка получения схемы сотрудника
export const getSchemaEmployee = async (): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/schemas/employee");
};

//ручка получения данных сотрудника по uuid документа
export const getDocumentEmployee = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/employee/uuid/${uuid}`);
};

//ручка обновления данных сотрудника по uuid документа
export const updateDocumentEmployee = async(uuid?: string, data?: any): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/employee/uuid/${uuid}`, data);
};

//ручка получения листинга сотрудников клиента
export const getEmployeesListing = async (params: null | FilterEmployeeType = null): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/d/employee", params);
};
