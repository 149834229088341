import React, { FC, JSX } from "react";
import css from "./ZeroNotifications.module.css";

interface IZeroNotificationsProps {
  variant: string;
}

const ZeroNotifications: FC<IZeroNotificationsProps> = ({variant}): JSX.Element => {

  const variantText = (text: string): string => {
    switch (text) {
      case "Все":
        return "";
      case "Задачи":
        return "по задачам ";
      case "Отчеты":
        return "по отчетам ";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className={css.textHeader}>{`Уведомлений ${variantText(variant)}нет`}</div>
      <div className={css.textBody}>{`Здесь будут отображаться ваши уведомления ${variantText(variant)}`}</div>
    </div>
  );
};

export default ZeroNotifications;
