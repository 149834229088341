import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import { ContractorType } from "app/types";
import {
  Form,
  Input,
  Modal,
  FormInstance,
} from "antd";
import { updateDocumentData } from "../../../../../../actions/document.actions";

interface IContractorCardEditInfoModalProps {
  contractorUuid: string;
  contractorData: ContractorType | null
  setContractorContactsEdited: Dispatch<SetStateAction<boolean>>;
  isContractorContactsEdited: boolean;
  isContractorCardEditInfoModalOpen: boolean;
  setContractorCardEditInfoModalOpen: Dispatch<SetStateAction<boolean>>;
  setContractorUpdated: Dispatch<SetStateAction<boolean>>;
}

//TODO: Компонент пока моковый, после готового АПИ будем пересматривать логику и верстку
const ContractorCardEditInfoModal: FC<IContractorCardEditInfoModalProps> = ({
  contractorUuid,
  contractorData,
  isContractorContactsEdited,
  setContractorContactsEdited,
  isContractorCardEditInfoModalOpen,
  setContractorCardEditInfoModalOpen,
  setContractorUpdated,
}): JSX.Element => {
  const [isFormHasValues, setFormHasValues] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormInstance | null>(null);

  const [form] = Form.useForm();

  const dispatch = useDispatch<AppDispatch>();

  const onContractorCardEditFormChange = (_: unknown, allValues: FormInstance): void => {
    setFormData(allValues);
  };

  useEffect(() => {
    const formValues = form.getFieldsValue();
    const hasValues: boolean = Object.values(formValues).some((field) => !field);
    setFormHasValues(hasValues);
  }, [formData, form]);

  const closeContractorCardEditInfoModal = (): void => {
    setContractorContactsEdited(false);
    setContractorCardEditInfoModalOpen(false);
  };

  const confirmContractorCardEditInfoModal = (): void => {
    // Обновление контрагента
    const updateData = {
      ...contractorData,
      ...formData
    };

    if (contractorUuid) {
      dispatch(updateDocumentData("client-company", contractorUuid, updateData));
      setContractorUpdated(true);
    }

    setContractorContactsEdited(false);
    setContractorCardEditInfoModalOpen(false);
  };

  const renderContractorCardEditInfoModalHeader = (): JSX.Element => {
    return isContractorContactsEdited
      ? <span className="inline-block mb-[10px]">Редактирование контактных данных</span>
      : <span className="inline-block mb-[10px]">Редактирование данных о контрагенте</span>;
  };

  const renderEditContractorContactsForm = (): JSX.Element => {
    return (
      <>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onContractorCardEditFormChange}
        >
          <div className="flex flex-col justify-start">
            <Form.Item
              className="w-full mb-[20px]"
              id="phone"
              name="phone"
              label="Номер телефона"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите номер телефона",
                },
              ]}
              initialValue={contractorData?.phone}
            >
              <Input
                allowClear
                className="w-full"
                size="large"
                placeholder="Введите номер телефона"
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              id="email"
              name="email"
              label="Электронная почта"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите электронную почту",
                },
              ]}
              initialValue={contractorData?.email}
            >
              <Input
                allowClear
                className="w-full"
                size="large"
                placeholder="Введите электронную почту"
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  };

  const renderEditContractorDataForm = (): JSX.Element => {
    return (
      <>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onContractorCardEditFormChange}
        >
          <div className="flex flex-col justify-start">
            <Form.Item
              className="w-full mb-[20px]"
              id="name"
              name="name"
              label="Наименование"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите наименование контрагента",
                },
              ]}
              initialValue={contractorData?.name}
            >
              <Input
                allowClear
                className="w-full"
                size="large"
                placeholder="Введите наименование контрагента"
              />
            </Form.Item>
            <Form.Item
              className="w-full mb-[20px]"
              id="inn"
              name="inn"
              label="ИНН"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите ИНН",
                },
              ]}
              initialValue={contractorData?.inn}
            >
              <Input
                allowClear
                className="w-full"
                size="large"
                placeholder="Введите ИНН"
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              id="registered_country"
              name="registered_country"
              label="Страна регистрации"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите страну регистрации",
                },
              ]}
              initialValue={contractorData?.registered_country}
            >
              <Input
                allowClear
                className="w-full"
                size="large"
                placeholder="Введите cтрану регистрации"
              />
            </Form.Item>
          </div>
        </Form>
      </>
    );
  };

  return (
    <Modal
      title={renderContractorCardEditInfoModalHeader()}
      open={isContractorCardEditInfoModalOpen}
      okText="Сохранить"
      okButtonProps={{ size: "large", disabled: isFormHasValues }}
      onOk={confirmContractorCardEditInfoModal}
      cancelText="Отменить"
      cancelButtonProps={{ size: "large" }}
      onCancel={closeContractorCardEditInfoModal}
    >
      {!isContractorContactsEdited ? (
        renderEditContractorDataForm()
      ) : (
        renderEditContractorContactsForm()
      )}
    </Modal>
  );
};

export default ContractorCardEditInfoModal;
