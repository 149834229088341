import React, {
  Dispatch,
  FC,
  JSX,
  useEffect,
  useMemo,
  useState
} from "react";
import css from "./CheckOrganizationOneC.module.css";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import {
  fetchDocumentClientProfile,
  fetchSchemaClientProfile,
  getDocumentClientProfileFailure
} from "../../../actions/clientProfile.actions";
import { getDocumentFailure } from "../../../actions/document.actions";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { AppStateType } from "../../../reducers/mainReducer";
import { fetchDocumentTask, getStatusTask } from "../../../actions/tasks.actions";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { Collapse, CollapseProps, theme } from "antd";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow_down_icon.svg";
import CheckOneCOrganisation from "./CheckOneCItem/CheckOneCOrganisation/CheckOneCOrganisation";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check_circle_icon_green.svg";
import CheckOneCAccounts from "./CheckOneCItem/CheckOneCAccounts/CheckOneCAccounts";
import CheckOneCEmployees from "./CheckOneCItem/CheckOneCEmployees/CheckOneCEmployees";
import FinalizeTaskOneC from "./FinalizeTaskOneC/FinalizeTaskOneC";
import EmployeeCard from "../ClientProfileCardEmployees/EmployeeCard/EmployeeCard";

interface ICheckOrganizationOneCProps {
  setNavBarHidden: Dispatch<React.SetStateAction<boolean>>;
}

type RouteParams = {
  id: string;
};

const CheckOrganizationOneC: FC<ICheckOrganizationOneCProps> = ({
   setNavBarHidden,
}): JSX.Element => {

  const { id } = useParams<RouteParams>();
  const params: string | undefined = useMemo(() => id, []);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [employeeCard, setEmployeeCard] = useState<boolean>(false);
  const [clientProfileSlug, setClientProfileSlug] = useState<string>("");
  const [isDocumentFetched, setDocumentFetched] = useState<boolean>(false);
  const [checkSuccess, setCheckSuccess] = useState<boolean>(false);

  const location: string = useLocation().pathname;

  const showEmployeeCard: boolean = location.includes("employee");

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const {
    statusTask,
    documentTask,
  } = useSelector((state: AppStateType) => state.tasks);

  const fifthStepError: boolean = statusTask?.statuses?.[4]?.status === "error"
    || statusTask?.statuses?.[5]?.status === "error";

  useEffect(() => {
    if (documentTask) {
      setLoading(true);
    }
  }, [documentTask]);

  useEffect(() => {
    const currentTaskUuid: string = location?.split("/")?.[2];

    if (statusTask === null) {
      dispatch(getStatusTask(currentTaskUuid));
    }

    if (statusTask !== null && !fifthStepError) {
      navigate(-1);
    }
  }, [statusTask]);

  useEffect(() => {
    if (!employeeCard && showEmployeeCard) {
      const currentUrl: string = window.location.href;
      const pattern: RegExp = /employee=[a-f0-9-]+$/;
      if (pattern.test(currentUrl)) {
        const newUrl: string = currentUrl.replace(pattern, "");
        window.history.replaceState({}, "", newUrl);
      }
    }

  }, []);

  useEffect(() => {
    setNavBarHidden(true);
  }, []);

  useEffect(() => {
    if (!documentTask) {
      const documentTaskId: string = location?.split("/")?.[2];
      dispatch(fetchDocumentTask(documentTaskId));
    }
  }, [
    dispatch,
    location,
    documentTask,
  ]);

  useEffect(() => {
    if (documentTask?.fields?.client_profile_ooo) {
      setClientProfileSlug("client-profile-ooo");
    }

    if (documentTask?.fields?.client_profile_ip) {
      setClientProfileSlug("client-profile-ip");
    }
  }, [documentTask, params, isDocumentFetched]);

  useEffect(() => {
    if (!isDocumentFetched && clientProfileSlug?.length) {
      setDocumentFetched(true);
      dispatch(fetchSchemaClientProfile(clientProfileSlug));
      dispatch(fetchDocumentClientProfile(clientProfileSlug, params ?? ""));
    }
  }, [clientProfileSlug, params, isDocumentFetched]);

  const navigateToTaskCard = (): void => {
    const taskId: string = location
      ? location?.split("/")[2]
      : "";

    navigate(`/task/${taskId}`);
    setNavBarHidden(false);
    dispatch(getDocumentClientProfileFailure());
    dispatch(getDocumentFailure());
  };

  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 12,
    padding: "4px",
    background: "#FFF",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const lastChildren: React.CSSProperties = {
    padding: "4px",
    background: "#FFF",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const headerText = (text: string): JSX.Element => {
    return (
      <div className="flex">
        <div className={css.headerText}>{text}</div>
      </div>
    );
  };

  const getItems: (panelStyle: React.CSSProperties, lastChildren: React.CSSProperties) => CollapseProps["items"] =
    (panelStyle, lastChildren) => [
    {
      key: "1",
      label: headerText("Создание организации"),
      children: <CheckOneCOrganisation />,
      style: panelStyle,
    },
    {
      key: "2",
      label: headerText("Создание счетов организации"),
      children: <CheckOneCAccounts />,
      style: panelStyle,
    },
    {
      key: "3",
      label: headerText("Создание сотрудников организации"),
      children: <CheckOneCEmployees setEmployeeCard={setEmployeeCard} />,
      style: lastChildren,
    },
  ];

  const renderEmployeeCardOrCheck1C: JSX.Element = employeeCard ? (
    <EmployeeCard variant="validation" setEmployeeCard={setEmployeeCard}/>
  ) : (
      <div className={css.container}>
        <div className={css.containerHeader}>
          Откройте 1С и дополните недостающие данные вручную.
        </div>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          className={css.collapse}
          expandIconPosition="end"
          expandIcon={({ isActive }) => <ArrowIcon className={isActive ? css.up : css.down} />}
          items={getItems(panelStyle, lastChildren)}
        />
        {checkSuccess &&
          (
            <div className={`${css.actions} mt-5 mb-3`}>
              <div className={`${css.actionsHead} mb-1`}>
                <CheckIcon className="mr-2"/>
                Все ошибки устранены
              </div>
              <div className={css.actionsBody}>
                Вы успешно перенесли данные в 1С, карточка организации создана.
              </div>
            </div>
          )
        }
        <div className="mt-[12px]">
          <FinalizeTaskOneC />
        </div>
    </div>
    );

  return (isLoading && fifthStepError ? (
    <div className="w-full h-full z-20 bg-[#F7F8F9]">
      <div className={`flex justify-between items-center bg-white w-full ${css.headerContainer}`}>
        <div className="flex flex-col">
          <h1 className={`mb-1 ${css.headerLabel}`}>
            Проверка создания карточки организации в 1С
          </h1>
        </div>
        <ButtonCustom
          size="large"
          text="Выйти из задачи"
          type="default"
          className={css.buttonExit}
          onClick={navigateToTaskCard}
        />
      </div>
      {renderEmployeeCardOrCheck1C}
    </div>
  ) : (
    <div className="h-full text-center pt-[30rem] bg-[#F7F8F9]">
      <LoadingCustom />
    </div>
  ));
};

export default CheckOrganizationOneC;
