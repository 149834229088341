import React, { FC, JSX } from "react";
import css from "./QuestionsInfo.module.css";
import { ButtonCustom } from "../../../../components/ui-kit/ButtonCustom/ButtonCustom";

interface IQuestionsInfoProps {
}

const QuestionsInfo: FC<IQuestionsInfoProps> = (): JSX.Element => {
  return (
    <div className={css.questionsBlockStyle}>
      <div className={css.questionsBlock} id="questions">
        <div className={css.questionsLabel}>
          Остались вопросы?
        </div>
        <div className={css.questionsText}>
          Мы с радостью расскажем подробнее о сфере применения наших решений и ответим на все ваши вопросы
        </div>
        <div className={css.inputGroup}>
          <input
            className={`${css.questionInput} ${css.commonQuestionStyle}`}
            type="email"
            placeholder="Ваш email"
          />
          <input
            className={`${css.questionInput} ${css.commonQuestionStyle}`}
            type="tel"
            placeholder="+7 999-999-99-99"
          />
          <select className={css.commonQuestionStyle}>
            <option value="price">Хочу узнать цену</option>
            <option value="callback">Хочу обратный звонок</option>
          </select>
          <ButtonCustom
            type="default"
            className={css.questionButton}
            text="ОТПРАВИТЬ"
          />
        </div>
        <div className={css.questionsFooter}>
          <div>
            Нажимая &quot;Отправить&quot;, я даю согласие на
          </div>
          <a
            href="https://www.smbconnect.ru/media/other/VTBC_Privacy_Policy.pdf"
            target="_blank"
            rel="noreferrer noopener"
            className={css.dataProcessing}
          >
            обработку своих персональных данных
          </a>
        </div>
      </div>
    </div>
  );
};

export default QuestionsInfo;