export interface ICreateVacancyProps {
  key?: number,
  value: string,
  label?: string,
}

export const employmentForm: ICreateVacancyProps[] = [
  {
    value: "Полная занятость",
    label: "Полная занятость",
  },
  {
    value: "Неполная занятость",
    label: "Неполная занятость",
  },
  {
    value: "Внештатная занятость",
    label: "Внештатная занятость",
  },
];

export const skills: ICreateVacancyProps[] = [
  {
    key: 1,
    value: "front-end",
  },
  {
    key: 2,
    value: "back-end",
  },
  {
    key: 3,
    value: "Sping-boot",
  },
];

export const operatingMode: ICreateVacancyProps[] = [
  {
    value: "Ненормированный рабочий день",
    label: "Ненормированный рабочий день",
  },
  {
    value: "Гибкий режим рабочего времени",
    label: "Гибкий режим рабочего времени",
  },
  {
    value: "Работа вахтовым методом",
    label: "Работа вахтовым методом",
  },
];

export const hiringManager: ICreateVacancyProps[] = [
  {
    value: "Морти Смит",
    label: "Морти Смит",
  },
  {
    value: "Саммер Смит",
    label: "Саммер Смит",
  },
  {
    value: "Рик Санчез",
    label: "Рик Санчез",
  },
];
