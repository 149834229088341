export const getDaysWord = (remainingDays: number): string => {
  if (remainingDays === 0) {
    return "дня";
  } else if (remainingDays === 1) {
    return "день";
  } else if (remainingDays % 10 === 1 && remainingDays !== 11) {
    return "день";
  } else if (
    remainingDays % 10 >= 2 &&
    remainingDays % 10 <= 4 &&
    (remainingDays < 10 || remainingDays > 20)
  ) {
    return "дня";
  } else {
    return "дней";
  }
};

export const getVerbForm = (remainingDays: number): { verbForm: string, adjustedDays: number } => {
  if (remainingDays === 0) {
    return { verbForm: "осталось менее", adjustedDays: 1 };
  } else if (remainingDays === 1) {
    return { verbForm: "остался", adjustedDays: remainingDays };
  } else if (remainingDays % 10 === 1 && remainingDays !== 11) {
    return { verbForm: "остался", adjustedDays: remainingDays };
  } else {
    return { verbForm: "осталось", adjustedDays: remainingDays };
  }
};