import {
  GET_RESUMES_LISTING_SUCCESS,
  GET_RESUMES_LISTING_FAILURE,
  GET_RESUME_SUCCESS,
  GET_RESUME_FAILURE,
} from "../../constants/actionTypes";
import { ResumeActionsTypes } from "../../actions/alpha/resumes.actions";
import { ResumesResultsType } from "app/types";

type InitialResumeType = {
  error: any;
  resume: ResumesResultsType | null;
  resumeData: null | ResumesResultsType[];
  resumePage: number;
  resumePageSize: number;
  resumeTotalDocs: number;
  isListingLoading: boolean,
}

const initialState: InitialResumeType = {
  error: null,
  resume: null,
  resumeData: null,
  resumePage: 1,
  resumePageSize: 5,
  resumeTotalDocs: 0,
  isListingLoading: true,
};

const resume = (
  state: InitialResumeType = initialState,
  action: ResumeActionsTypes
): InitialResumeType => {
  switch (action.type) {
    case GET_RESUMES_LISTING_SUCCESS:
      return {
        ...state,
        resumeData: action.payload.resumeListing.results,
        resumePage: action.payload.resumeListing.page,
        resumePageSize: action.payload.resumeListing.page_size,
        resumeTotalDocs: action.payload.resumeListing.total_docs,
        error: null,
        isListingLoading: false
      };
    case GET_RESUMES_LISTING_FAILURE:
      return {
        ...state,
        resumeData: null,
        error: action.payload.error,
        isListingLoading: false
      };
    case GET_RESUME_SUCCESS:
      return {
        ...state,
        resume: action.payload.resume,
        error: null,
      };
    case GET_RESUME_FAILURE:
      return {
        ...state,
        resume: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default resume;
