import React, { FC, JSX } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface ILoadingCustomProps {
  className?: string;
  fontSize?: number;
}

const LoadingCustom: FC<ILoadingCustomProps> = ({
  className = "",
  fontSize = 40
}): JSX.Element => {

  const antIcon: JSX.Element = <LoadingOutlined style={{ fontSize: fontSize }} spin />;

  return (
    <Spin indicator={antIcon} className={className}/>
  );
};

export default LoadingCustom;
