import {
  GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE,
  GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS,
  GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE,
  GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS,
  UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE,
  UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS
} from "../constants/actionTypes";
import { EmployeeProfileActionsTypes } from "../actions/employeeProfile.actions";
import { DocumentEmployeeProfileType, SchemaType } from "app/types";

type InitialEmployeeProfileCardType = {
  error: any;
  schemaEmployeeProfile: null | SchemaType;
  documentEmployeeProfile: null | DocumentEmployeeProfileType;
  isDocumentEmployeeLoading: boolean;
};

const initialState: InitialEmployeeProfileCardType = {
  error: null,
  schemaEmployeeProfile: null,
  documentEmployeeProfile: null,
  isDocumentEmployeeLoading: true,
};

const employeeProfile = (
  state: InitialEmployeeProfileCardType = initialState,
  action: EmployeeProfileActionsTypes
): InitialEmployeeProfileCardType => {
  switch (action.type) {
    // Логика получения схемы сотрудника
    case GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        schemaEmployeeProfile: action.payload.schemaEmployee,
        error: null,
      };
    case GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE:
      return {
        ...state,
        schemaEmployeeProfile: null,
        error: action.payload.error,
      };
    // Логика получения данных схемы сотрудника по uuid документа
    case GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        documentEmployeeProfile: action.payload.documentEmployeeProfile,
        isDocumentEmployeeLoading: false,
        error: null,
      };
    case GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE:
      return {
        ...state,
        documentEmployeeProfile: null,
        isDocumentEmployeeLoading: false,
        error: action.payload.error,
      };
    // Логика обновления данных сотрудника по uuid документа
    case UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        documentEmployeeProfile: action.payload.documentEmployeeProfile,
        isDocumentEmployeeLoading: false,
        error: null,
      };
    case UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE:
      return {
        ...state,
        documentEmployeeProfile: null,
        isDocumentEmployeeLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default employeeProfile;
