import React, { FC, JSX } from "react";
import TagCustom from "../TagCustom/TagCustom";
import { checkServiceStatus } from "../../../utils/checkServiceStatus";
import { deadlineDays } from "../../../utils/deadlineDays";
import dayjs from "dayjs";
import css from "./RenderListItem.module.css";

interface IRenderListItemProps {
  value: string | number;
  label: string;
  type?: "sum"|"text"|"date"|"expiration";
  duration?: string;
  className?: string;
}

const ListItem: FC<IRenderListItemProps> = ({
  value,
  label,
  type,
  duration,
  className,
}): false | JSX.Element => {
  const renderValueType = () => {
    switch (type) {
      case "sum":
        return `${value} ₽`;
      case "date":
        return dayjs(value).format("DD.MM.YYYY");
      case "text":
      default:
        return value;
    }
  };

  return !!value && (
    <li className="flex items-center mb-2">
      <span className={`${className} ${css.listItemLabel}`}>
        {label}
      </span>
      <span>
        {type === "expiration" ? (
          dayjs(duration)?.isValid() && (
            <span className={css.contractParamsData}>
              <span className="inline-block mr-3">
                до {dayjs(duration).format("DD.MM.YYYY")}
              </span>
              <TagCustom
                color={checkServiceStatus(duration)}
                text={deadlineDays(value as string) as string}
              />
            </span>
          )
        ): renderValueType()}
      </span>
    </li>
  );
};

export default ListItem;
