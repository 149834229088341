import { SetStateAction } from "react";
import { FormInstance } from "antd";
import { SchemaFieldsType, SchemaType } from "app/types";

export const isDocRequiredValuesFilled = (
  docSchema: SchemaType | null,
  docForm: FormInstance,
  setDocApprovalDisabled: (value: SetStateAction<boolean>) => void
): void => {
  const currentPassportRequiredFields: SchemaFieldsType[] =
    docSchema?.fields?.filter(({ name, required_for_finalize }) =>
      required_for_finalize && Object.keys(docForm.getFieldsValue())?.includes(name)) ?? [];

  const currentPassportRequiredFieldsName: string[] =
    currentPassportRequiredFields?.flatMap(({ name }) => [name]);

  const currentPassportRequiredValues = Object.fromEntries(
    Object.entries(docForm.getFieldsValue()).filter(([key]) => currentPassportRequiredFieldsName.includes(key))
  );

  const isRequiredValuesFilled: boolean = Object.values(currentPassportRequiredValues)?.every((value) => !!value);

  if (isRequiredValuesFilled) {
    return setDocApprovalDisabled(false);
  } else {
    return setDocApprovalDisabled(true);
  }
};
