import React, { FC, JSX, useEffect,} from "react";
import css from "./CheckOneCOrganisation.module.css";
import { getCompanyDocumentData } from "../../../../../actions/document.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { handlePhoneNumberChange } from "../../../../../utils/phoneNumberMask";

interface ICheckOneCOrganisationProps {
}

const CheckOneCOrganisation: FC<ICheckOneCOrganisationProps> = (): JSX.Element => {

  const {
    schemaClientProfile,
    documentClientProfile,
  } = useSelector((state: AppStateType) => state.clientProfile);

  const clientProfileOrgLabels = {
    opf: schemaClientProfile?.fields?.find(i => i.name === "opf"),
    kpp: schemaClientProfile?.fields?.find(i => i.name === "kpp"),
    inn: schemaClientProfile?.fields?.find(i => i.name === "inn"),
    name: schemaClientProfile?.fields?.find(i => i.name === "name"),
    phone: schemaClientProfile?.fields?.find(i => i.name === "phone"),
    email: schemaClientProfile?.fields?.find(i => i.name === "email"),
    patent: schemaClientProfile?.fields?.find(i => i.name === "patent"),
    tax_system: schemaClientProfile?.fields?.find(i => i.name === "tax_system"),
    usn_document: schemaClientProfile?.fields?.find(i => i.name === "usn_document"),
    company_ogrn: schemaClientProfile?.fields?.find(i => i.name === "company_ogrn"),
    primary_okved: schemaClientProfile?.fields?.find(i => i.name === "primary_okved"),
    secondary_okved: schemaClientProfile?.fields?.find(i => i.name === "secondary_okved"),
    registered_address: schemaClientProfile?.fields?.find(i => i.name === "registered_address"),
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const usnDocUuid: string = documentClientProfile?.fields?.usn_document?.length
      ? documentClientProfile?.fields?.usn_document?.[0]?.uuid
      : "";

    const bankDocUuid: string = documentClientProfile?.fields?.bank_document?.length
      ? documentClientProfile?.fields?.bank_document?.[0]?.uuid
      : "";

    const patentDocUuid: string = documentClientProfile?.fields?.patent?.length
      ? documentClientProfile?.fields?.patent?.[0]?.uuid
      : "";

    if (usnDocUuid) {
      dispatch(getCompanyDocumentData("usn-schema", usnDocUuid));
    }

    if (bankDocUuid) {
      dispatch(getCompanyDocumentData("bank-schema", bankDocUuid));
    }

    if (patentDocUuid) {
      dispatch(getCompanyDocumentData("patent-schema", patentDocUuid));
    }
  }, [documentClientProfile]);

  return (
    <div className={`${css.container} min-h-full`}>
      <div className="mb-5 border-bottom-gray">
        <ul className="mb-3">
          {!!documentClientProfile?.fields?.name && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.name?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.name}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.registered_address && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.registered_address?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.registered_address}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.opf && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.opf?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.opf}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.tax_system && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.tax_system?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.tax_system}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.phone && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.phone?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {handlePhoneNumberChange(documentClientProfile?.fields?.phone)}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.inn && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.inn?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.inn}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.primary_okved && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.primary_okved?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.primary_okved}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.email && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.email?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.email}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.kpp && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.kpp?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.kpp}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.secondary_okved?.length && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.secondary_okved?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.secondary_okved?.map((okved: string, idx: number) => (
                  <span className="pr-2" key={idx}>{okved}</span>
                ))}
              </span>
            </li>
          )}
          {!!documentClientProfile?.fields?.company_ogrn && (
            <li className="textBlock1C">
              <span className="flex-none gray-color text1CStyle">
                {clientProfileOrgLabels?.company_ogrn?.label}
              </span>
              <span className="flex-grow text1CStyle">
                {documentClientProfile?.fields?.company_ogrn}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CheckOneCOrganisation;
