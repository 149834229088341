import React, { JSX } from "react";
import LoadingCustom from "../../../components/ui-kit/LoadingCustom/LoadingCustom";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check_circle_green.svg";
import { ReactComponent as ErrorCheckIcon} from "../../../assets/icons/error_red.svg";
import css from "./TaskCardNotification.module.css";
import { notification } from "antd";

const notificationsProcessingContent = (): JSX.Element => {
  return (
    <div className="flex">
      <LoadingCustom fontSize={24} className="mr-4"/>
      <div>
        <div className={css.headerContent}>
          Файлы ожидают обработки
        </div>
        <div className={css.bodyContent}>
          Действие подтверждено, файлы в обработке.
          Это займет некоторое время, пожалуйста, подождите.
        </div>
      </div>
    </div>
  );
};

const notificationsProcessingSuccessContent = (): JSX.Element => {
  return (
    <div className="flex">
      <CheckIcon className="w-[30px] h-6 mr-2"/>
      <div>
        <div className={css.headerContent}>
          Файлы обработаны
        </div>
        <div className={css.bodyContent}>
          Файлы успешно обработаны. Действие по задаче выполнено.
        </div>
      </div>
    </div>
  );
};

const notificationsProcessingFailureContent = (): JSX.Element => {
  return (
    <div className="flex">
      <ErrorCheckIcon className="w-[50px] h-6 mr-3"/>
      <div>
        <div className={css.headerContent}>
          Ошибка обработки файлов
        </div>
        <div className={css.bodyContent}>
          Произошла ошибка при обработке файлов.
          Действие по задаче не выполнено. Повторите попытку снова.
        </div>
      </div>
    </div>
  );
};

const contentVariant = (value: string): JSX.Element | null => {
  switch (value) {
    case "processing":
      return notificationsProcessingContent();
    case "success":
      return notificationsProcessingSuccessContent();
    case "failure":
      return notificationsProcessingFailureContent();
    default:
      return null;
  }
};

export const notificationProcessing = (checkUploadFiles: boolean, value: string): void | null => {
  if (checkUploadFiles) {
    notification.open({
      key: value,
      message: null,
      duration: value !== "processing" ? 5 : null,
      description: contentVariant(value),
      style: { backgroundColor: "#FFF", width: "400px", borderRadius: "8px" },
    });
  }
};