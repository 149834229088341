import axios from "../utils/axios";

const API_EMPLOYEE_PROFILE: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_EMPLOYEE_PROFILE;

//ручка получения схемы сотрудника
export const getSchemaEmployeeProfile = async (): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/schemas/employee-profile");
};

//ручка получения данных сотрудника по uuid документа
export const getDocumentEmployeeProfile = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/employee-profile/uuid/${uuid}`);
};

//ручка получения данных сотрудника по uuid документа
export const updateDocumentEmployeeProfile = async(uuid?: string, data?: any): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/employee-profile/uuid/${uuid}`, data);
};
