import React, {
  FC,
  JSX,
  Key,
  Dispatch,
  useState,
  useEffect,
} from "react";
import css from "./ContractorCardResume.module.css";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search_icon.svg";
import { ReactComponent as AttachFileIcon } from "../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as SettingsIcon } from "../../../../../assets/icons/settings-icon.svg";
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  MenuProps,
  Table,
  TableColumnsType
} from "antd";
import { PaginationCustom } from "../../../../ui-kit/PaginationCustom/PaginationCustom";
import { renderAlphaDocStatus } from "../../../../../utils/renderAlphaDocStatus";
import { TableRowSelection } from "antd/es/table/interface";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { renderSkills } from "../../../../../utils/RenderSkills/RenderSkills";
import { operatingModeListing } from "./operatingModeListing";
import { AlphaListParamsType, ResumesFieldsType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { useTableSorter } from "../../../../../utils/handleSorterTable";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { getResumesData } from "../../../../../actions/alpha/resumes.actions";
import { downloadFile, downloadUrl } from "../../../../../utils/downloadFile";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../../utils/roleResolver";

export interface IResumeListing {
  key: React.Key;
  name?: string,
  skills: string[],
  cv_file: string,
  cv_status: string | null,
  working_hours: string | null,
}

export interface IContractorCardResumeProps {
  contractorUuid: string;
}

const ContractorCardResume: FC<IContractorCardResumeProps> = ({ contractorUuid }): JSX.Element => {
  const [contractorsResumeListParams, setContractorsResumeListParams] = useState<AlphaListParamsType | null>( {
    page: 1,
    page_size: 5,
  });
  const [selectedResume, setSelectedResume] = useState<Key[]>([]);
  const [selectedWorkingHours, setSelectedWorkingHours] = useState<string[]>([]);
  const [deleteResumeKey, setDeleteResumeKey] = useState<Key | null>(null);
  const [deleteResumeName, setDeleteResumeName] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setContractorsResumeListParams);

  const {
    resumeData,
    resumePage,
    resumePageSize,
    resumeTotalDocs,
  } = useSelector((state: AppStateType) => state.resume);

  useEffect(() => {
    dispatch(getResumesData(contractorsResumeListParams));
  }, [contractorsResumeListParams]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setContractorsResumeListParams({
      ...contractorsResumeListParams,
      search: e.target.value,
    });
  };

  const handleResumePageChange = (newPage: number): void => {
    setContractorsResumeListParams({
      ...contractorsResumeListParams,
      page: newPage,
    });
  };

  const handleResumePageSizeChange = (newPageSize: number): void => {
    setContractorsResumeListParams({
      ...contractorsResumeListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const deleteResume = (data: IResumeListing): void => {
    setDeleteResumeKey(data?.key);
    setDeleteResumeName(data?.name ?? "");
  };

  const onSelectResumeChange = (newSelectedRowKeys: Key[]): void => {
    setSelectedResume(newSelectedRowKeys);
  };

  const rowResumeSelection: TableRowSelection<IResumeListing> | undefined = {
    selectedRowKeys: selectedResume,
    onChange: onSelectResumeChange,
    defaultSelectedRowKeys: selectedResume,
    preserveSelectedRowKeys: true
  };

  const hasSelectedResume: boolean = selectedResume.length > 0;

  const onWorkingHoursFilterChange = (e: CheckboxChangeEvent, workingHours: string): void => {
    const removeWorkingHoursFilter: string[] =
      selectedWorkingHours?.filter((filter: string): boolean => filter !== workingHours);
    setSelectedWorkingHours(e.target.checked ? [...selectedWorkingHours, workingHours] : removeWorkingHoursFilter);
  };

  const isWorkingHoursFilterChecked = (workingHoursType: string): boolean => {
    return selectedWorkingHours?.includes(workingHoursType);
  };

  const clearOperatingModeFilters = (): void => {
    setSelectedWorkingHours([]);
  };

  const applyWorkingHoursFilter = (): void => {
    setContractorsResumeListParams({
      ...contractorsResumeListParams,
      cv_status: selectedWorkingHours?.length ? `[${selectedWorkingHours}]` : null,
    });
  };

  const tableWorkingHoursFilters = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          {operatingModeListing.map((operatingMode: string, index: number) => (
            <Checkbox
              key={index}
              className="table-checkbox"
              onChange={(e: CheckboxChangeEvent) => onWorkingHoursFilterChange(e, operatingMode)}
              checked={isWorkingHoursFilterChecked(operatingMode)}
            >
              {operatingMode}
            </Checkbox>
          ))}
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={clearOperatingModeFilters}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={(): void => {
              confirm();
              applyWorkingHoursFilter();
            }}
          />
        </div>
      </div>
    );
  };

  const resumeColumns: TableColumnsType<IResumeListing> = [
    {
      title: (
        <div className="table-title">
          ФИО
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "name",
      width: "30%",
      render: (text: string) => <div className={css.tableCell}>{text}</div>,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "name"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Скиллы
        </div>
      ),
      dataIndex: "skill",
      width: "26%",
      render: (_, record: IResumeListing) => renderSkills(record?.skills),
    },
    {
      title: (
        <div className="table-title">
          Режим работы
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "working_hours",
      width: "15%",
      filtered: !!selectedWorkingHours?.length,
      filterDropdown: ({ confirm }) => tableWorkingHoursFilters(confirm),
      render: (text: string) => <div className={css.tableCell}>{text}</div>,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "working_hours"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Файл резюме
        </div>
      ),
      dataIndex: "cv_file",
      render: (text: string) =>
        <div className={css.tableCell}>
          <a className="max-w-[300px] flex blue-color items-center mb-[4px]">
            <AttachFileIcon className="flex-shrink-0 mr-1" />
            <div
              className={css.docFile}
              onClick={() => downloadFile(text, downloadUrl(text))}
            >
              {text}
            </div>
          </a>
        </div>,
      width: "14%",
    },
    {
      title: (
        <div className="table-title">
          Статус
        </div>
      ),
      dataIndex: "cv_status",
      render: (text: string) => renderAlphaDocStatus(text),
      width: "12%"
    },
    {
      title: "",
      key: "settings",
      width: "3%",
      render: (_, record: IResumeListing) => {
        const menuItems: MenuProps["items"] = [
          {
            label: <div>Снять с публикации</div>,
            key: "1"
          },
          {
            label: <div>Удалить</div>,
            key: "2",
            danger: true,
            onClick: () => deleteResume(record)
          }
        ];

        return (
          <Dropdown.Button
            menu={{ items: menuItems }}
            buttonsRender={([_leftButton, _rightButton]) => [
              null,
              <Button
                className={css.settingsBtn}
                key={record?.key}
                type="text"
                icon={<SettingsIcon />}
              />
            ]}
          />
        );
      }
    }
  ];

  const contractorResumesData: IResumeListing[] = resumeData?.map(({ fields }) => {
    const {
      uuid,
      first_name,
      second_name,
      last_name,
      cv_file,
      cv_status,
      skills,
      working_hours,
    } = fields as ResumesFieldsType;

    return {
      key: uuid,
      name: [last_name, first_name, second_name].filter(Boolean).join(" "),
      cv_file,
      cv_status,
      skills,
      working_hours,
    };
  }) ?? [];

  return (
    <div className="p-[18px] mb-3 bg-white rounded-[8px]">
      <div className="flex items-center content-center justify-between mb-[20px]">
        <h1 className={css.containerHeader}>Резюме</h1>
        {isAlphaContractor && (
          <ButtonCustom
            text="Создать резюме"
            type="primary"
            size="large"
            onClick={() =>
              contractorUuid
                ? navigate(`/alpha/contractor/${contractorUuid}/create_resume`, {state: {contractorUuid}})
                : navigate("/alpha/create_resume")
            }
          />
        )}
      </div>
      <div className="flex items-center justify-between mb-[12px]">
        {hasSelectedResume && (
          <div className={`flex items-center ${hasSelectedResume ? "mr-[6px]" : ""}`}>
            <ButtonCustom
              className="mr-[12px]"
              text="Снять с публикации"
              type="primary"
              size="large"
              ghost
              disabled={!hasSelectedResume}
              onClick={() => setSelectedResume([])} //в дальнейшем поменять логику onClick
            />
            <ButtonCustom
              className="mr-[12px]"
              text="Удалить"
              type="primary"
              size="large"
              ghost
              disabled={!hasSelectedResume}
              onClick={() => setSelectedResume([])} //в дальнейшем поменять логику onClick
            />
          </div>
        )}
        <Input
          className={css.input}
          suffix={<SearchIcon className={css.searchIcon} />}
          placeholder="Найти по ФИО"
          allowClear
          value={contractorsResumeListParams?.search ?? ""}
          onChange={handleInputChange}
        />
      </div>
      <Table
        rowSelection={rowResumeSelection}
        columns={resumeColumns}
        dataSource={contractorResumesData}
        pagination={false}
      />
      <PaginationCustom
        className={css.paginationShowTotal}
        total={resumeTotalDocs}
        showTotal={(total: number, range: number[]): string =>
          `${range[0]}-${range[1]} из ${total}`
        }
        currentPage={resumePage}
        defaultPageSize={10}
        pageSize={resumePageSize}
        defaultCurrent={1}
        handlePageChange={handleResumePageChange}
        handlePageSizeChange={handleResumePageSizeChange}
      />
    </div>
  );
};

export default ContractorCardResume;
