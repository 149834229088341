import React, { FC, JSX } from "react";
import css from "./PriceInfo.module.css";
import pricePicture from "../../../../assets/icons/BK_price_picture.svg";

interface IPriceInfoProps {
  scrollToAnchor: (id: string) => void;
}

const PriceInfo: FC<IPriceInfoProps> = ({
  scrollToAnchor
}): JSX.Element => {
  return (
    <div className={css.priceBlock} id="price">
      <div className={css.priceStyle}>
        <div className="flex flex-col justify-start items-start w-1/2">
          <div className={css.priceTextLabel}>
            Стоимость
          </div>
          <div className={css.priceTextInfo}>
            Стоимость программного обеспечения рассчитывается индивидуально.
            Для точного расчета стоимости, пожалуйста, отправьте нам заявку удобным способом:
          </div>
          <div className={css.priceTextInfo}>
            <div>Контактный телефон</div>
            <div>8-800-222-42-14</div>
          </div>
          <div className={css.priceTextInfo}>
            <div>Электронная почта</div>
            <div>help@vtbconnect.ru</div>
          </div>
          <div
            onClick={(): void => scrollToAnchor("questions")}
            className={css.writeToUsButton}
          >
            Напишите нам
          </div>
        </div>
        <div className={css.imgPrice}>
          <img
            src={pricePicture}
            alt="Image description"
            className={css.priceImg}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceInfo;