export const handleInnNumberChange = (innNumber: string): string => {
  // Удаление всего, кроме цифр
  const inputValue: string = innNumber.replace(/\D/g, "");
  // Используем регулярное выражение для извлечения сегментов номера
  const matches: RegExpMatchArray | null = inputValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);

  let formattedInn: string = "";

  // Если есть совпадения, добавляем соответствующие части в форматированный номер
  if (matches) {
    // Добавляем первые 3 цифры и разделитель " "
    formattedInn += matches[1] ? `${matches[1]} ` : "";

    // Добавляем следующие 3 цифры и разделитель " "
    formattedInn += matches[2] ? `${matches[2]} ` : "";

    // Добавляем следующие 3 цифры и разделитель " "
    formattedInn += matches[3] ? `${matches[3]} ` : "";

    // Добавляем последние 3 цифры и разделитель " "
    formattedInn += matches[4] ? `${matches[4]} ` : "";
  }

  return formattedInn;
};
