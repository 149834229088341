import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./PersonalInformation.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../../reducers/mainReducer";
import dayjs from "dayjs";
import { handleSnilsNumberChange } from "../../../../../../../utils/snilsNumberMask";
import { handleInnNumberChange } from "../../../../../../../utils/innNumberMask";
import { DocumentSchemaLabelMapType, SchemaType } from "app/types";
import TagCustom from "../../../../../../ui-kit/TagCustom/TagCustom";
import ContractCard from "../Contract/ContractCard";
import ZeroClientProfileDocument
  from "../../../../../../ClientProfileCard/ZeroClientProfileDocument/ZeroClientProfileDocument";
import { documentSchemaLabelsMapper } from "../../../../../../../utils/documentSchemaLabelsMapper";

interface IPersonalInformationProps {
  showImageViewer: (docType: string, uuid: string) => void;
  passportUuid: string;
  snilsUuid: string;
  innUuid: string;
  contractUuid: string;
  contractDocType: string;
}

const PersonalInformation: FC<IPersonalInformationProps> = ({
  showImageViewer,
  passportUuid,
  snilsUuid,
  innUuid,
  contractUuid,
  contractDocType,
}): JSX.Element => {
  const [isConfirmedPassport, setConfirmedPassport] = useState<boolean>(false);
  const [isConfirmedSnils, setConfirmedSnils] = useState<boolean>(false);
  const [isConfirmedInn, setConfirmedInn] = useState<boolean>(false);

  const schemaEmployee: SchemaType | null = useSelector((state: AppStateType) => state.employee.schemaEmployee);

  const {
    innDoc,
    innDocSchema,
    snilsDoc,
    passportDoc,
    passportDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (innDoc?.fields?.document_status === "APPROVED") {
      setConfirmedInn(true);
    } else {
      setConfirmedInn(false);
    }

    if (snilsDoc?.fields?.document_status === "APPROVED") {
      setConfirmedSnils(true);
    } else {
      setConfirmedSnils(false);
    }

    if (passportDoc?.fields?.document_status === "APPROVED") {
      setConfirmedPassport(true);
    } else {
      setConfirmedPassport(false);
    }
  }, [innDoc, snilsDoc, passportDoc]);

  const personalDocsCardLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaEmployee);
  const personalInfoCardLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(passportDocSchema);
  const personalInfoInnLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(innDocSchema);

  const renderAgreed = (): JSX.Element => {
    return (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderConfirmationPassport = (): JSX.Element => {
    return (
      isConfirmedPassport ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderConfirmationSnils = (): JSX.Element => {
    return (
      isConfirmedSnils ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderConfirmationInn = (): JSX.Element => {
    return (
      isConfirmedInn ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderCause = (cause?: string): JSX.Element => {
    return (
      <div className={`${css.cause} mt-5 mb-3`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {cause}
        </div>
      </div>
    );
  };

  const renderPersonalInformationField = (label: string, value: string | number): JSX.Element => (
    <li className="textBlockWidth">
      <span className="inline-block gray-color text-sm">
        {label}
      </span>
      <span className="flex-grow text-sm">
        {value}
      </span>
    </li>
  );

  return (
    <>
      <div className="flex justify-between">
        <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
          <h1 className="header-text mb-5">
            {personalDocsCardLabels?.passport_document?.label ?? "Паспорт"}
          </h1>
          {passportDoc?.fields ? (
            <>
              <div>
                <ul>
                  {!!passportDoc?.fields?.last_name &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.last_name?.label,
                      passportDoc?.fields?.last_name
                    )
                  }
                  {!!passportDoc?.fields?.first_name &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.first_name?.label,
                      passportDoc?.fields?.first_name
                    )
                  }
                  {!!passportDoc?.fields?.second_name &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.second_name?.label,
                      passportDoc?.fields?.second_name
                    )
                  }
                  {!!passportDoc?.fields?.date_of_birth &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.date_of_birth?.label,
                      dayjs(passportDoc?.fields?.date_of_birth).format("DD.MM.YYYY")
                    )
                  }
                  {!!passportDoc?.fields?.sex &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.sex?.label,
                      passportDoc?.fields?.sex
                    )
                  }
                  {!!passportDoc?.fields?.series_number &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.series_number?.label,
                      `
                        ${passportDoc?.fields?.series_number}
                        ${passportDoc?.fields?.passport_number}
                      `
                    )
                  }
                  {!!passportDoc?.fields?.issued_date &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.issued_date?.label,
                      dayjs(passportDoc?.fields?.issued_date).format("DD.MM.YYYY")
                    )
                  }
                  {!!passportDoc?.fields?.issued_by &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.issued_by?.label,
                      passportDoc?.fields?.issued_by
                    )
                  }
                  {!!passportDoc?.fields?.citizenship &&
                    renderPersonalInformationField(
                      personalInfoCardLabels?.citizenship?.label,
                      passportDoc?.fields?.citizenship
                    )
                  }
                </ul>
              </div>
              <span className="checkFiles">
                <div className="flex items-center">
                  <ButtonCustom
                    className="p-[7px 12px] mr-2"
                    type="primary"
                    ghost
                    text="Посмотреть файлы"
                    onClick={() =>
                      showImageViewer(
                        "passport-schema",
                        passportUuid
                      )
                    }
                  />
                  {!!passportDoc?.fields?.document_status && renderConfirmationPassport()}
                </div>
                {!!passportDoc?.fields?.decline_reason && (
                  isConfirmedPassport
                    ? null
                    : renderCause(passportDoc?.fields?.decline_reason)
                )}
              </span>
            </>
          ) : (
            <ZeroClientProfileDocument variant="Паспорт сотрудника" />
          )}
        </div>
        <ContractCard
          showImageViewer={showImageViewer}
          contractUuid={contractUuid}
          contractDocType={contractDocType}
        />
      </div>
      <div className="flex justify-between">
        <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
          <h1 className="header-text mb-5">
            {personalDocsCardLabels?.snils_document?.label ?? "СНИЛС"}
          </h1>
          {snilsDoc?.fields ? (
            <>
              <div>
                <ul>
                  {!!snilsDoc?.fields?.snils_number && (
                    <ul>
                      <li className="textBlockWidth">
                        <span className="inline-block mb-1 gray-color text-sm">
                          {personalDocsCardLabels?.snils_document?.label}
                        </span>
                        <span className="flex-grow text-sm">
                          {handleSnilsNumberChange(snilsDoc?.fields?.snils_number)}
                        </span>
                      </li>
                    </ul>
                  )}
                </ul>
              </div>
              <span className="checkFiles">
                <div className="flex items-center">
                  <ButtonCustom
                    className="p-[7px 12px] mr-2"
                    type="primary"
                    ghost
                    text="Посмотреть файлы"
                    onClick={() =>
                      showImageViewer(
                        "snils-schema",
                        snilsUuid
                      )
                    }
                  />
                  {!!snilsDoc?.fields?.document_status && renderConfirmationSnils()}
                </div>
                {!!snilsDoc?.fields?.decline_reason && (
                  isConfirmedSnils
                    ? null
                    : renderCause(snilsDoc?.fields?.decline_reason)
                )}
              </span>
            </>
          ) : (
            <ZeroClientProfileDocument variant="СНИЛС сотрудника" />
          )}
        </div>
        <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
          <h1 className="header-text mb-5">
            {personalInfoInnLabels?.inn_document?.label ?? "ИНН"}
          </h1>
          {innDoc?.fields ? (
            <>
              <div>
                <ul>
                  {!!innDoc?.fields?.inn && (
                    <li className="textBlockWidth">
                      <span className="inline-block mb-1 gray-color text-sm">
                        {personalInfoInnLabels?.inn?.label}
                      </span>
                      <span className="flex-grow text-sm">
                        {handleInnNumberChange(innDoc?.fields?.inn ?? "")}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
              <span className="checkFiles">
                <div className="flex items-center">
                  <ButtonCustom
                    className="p-[7px 12px] mr-2"
                    type="primary"
                    ghost
                    text="Посмотреть файлы"
                    onClick={() => showImageViewer(
                      "inn-schema",
                      innUuid
                    )}
                  />
                  {!!innDoc?.fields?.document_status && renderConfirmationInn()}
                </div>
                {!!innDoc?.fields?.decline_reason && (
                  isConfirmedInn ? null : renderCause(innDoc?.fields?.decline_reason))}
              </span>
            </>
          ) : (
            <ZeroClientProfileDocument variant="ИНН сотрудника" />
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
