import { NotificationsDataType, SortNotificationsType } from "app/types";

export const filterNotifications = (notificationArr: NotificationsDataType[]): SortNotificationsType[] => {
  const currentDate: Date = new Date();

  const filteredNotifications: { [key: string]: NotificationsDataType[] } = {};

  notificationArr.forEach((notification: NotificationsDataType): void => {
    const date: Date = new Date(notification.created_at);
    const sortableKey: string = date.toISOString().slice(0, 10);

    if (filteredNotifications[sortableKey]) {
      filteredNotifications[sortableKey].push(notification);
    } else {
      filteredNotifications[sortableKey] = [notification];
    }
  });

  const todayDateKey: string = currentDate.toISOString().slice(0, 10);

  let result: SortNotificationsType[] =
    Object.entries(filteredNotifications).map(([dateKey, notifications]: [string, NotificationsDataType[]]) => {
      const date: Date = new Date(dateKey);
      const dayMonthKey: string = date.toLocaleDateString("ru-RU", { day: "numeric", month: "long" });
      return {
        date: (dateKey === todayDateKey) ? "Сегодня" : dayMonthKey,
        dateKey,
        notifications,
      };
    });

  result = result.sort((a: SortNotificationsType, b: SortNotificationsType): number => {
    if (a.dateKey === "Сегодня") {
      return -1;
    }
    if (b.dateKey === "Сегодня") {
      return 1;
    }
    const aDateKey: string[] = a.dateKey.split(" ");
    const bDateKey: string[] = b.dateKey.split(" ");

    const aDay: number = parseInt(aDateKey[0]);
    const bDay: number = parseInt(bDateKey[0]);

    if (aDay !== bDay) {
      return bDay - aDay;
    } else {
      return b.dateKey.localeCompare(a.dateKey, "ru", { sensitivity: "base" });
    }
  });

  return result;
};
