export const translateContractType = (contractType?: string): string => {
  if (contractType === "trudovoy") return "Трудовой договор";
  switch (contractType) {
    case "trudovik":
    case "trudovoy":
      return "Трудовой договор";
    case "gph":
      return "Договор ГПХ";
    default:
      return "Трудовой договор";
  }
};
