import React, { FC, SetStateAction, useEffect, useState } from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Input,
  Radio,
  Select,
  Divider,
  Tooltip,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import {
  getEmployeeDocumentData,
  getDocumentSchema,
} from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import { isDocFieldRequired } from "../../../../utils/isDocFieldRequired";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormSnilsProps {
  snilsForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  isEmployee?: boolean;
}

const ClientProfileCardImageViewerFormSnils: FC<IClientProfileCardImageViewerFormSnilsProps> = ({
  snilsForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  isEmployee,
}) => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    snilsDoc,
    snilsDocSchema
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (!!docApproveStatus && isDocApprovalDisabled || isContactCenter) {
      setDocVerified(true);
    } else {
      setDocVerified(false);
    }
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(snilsDocSchema, snilsForm, setDocApprovalDisabled);
  }, [snilsForm, snilsDocSchema, onFormValuesChange]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = snilsDoc?.fields?.decline_reason ?? "";

      if (docMigrationDeclineReason) {
        snilsForm.setFieldValue("declineReason", docMigrationDeclineReason);
      } else {
        snilsForm.setFieldValue(
          "declineReason",
          "Необходимо приложить верный документ (приложен не тот документ)"
        );
      }
    }

    onFormValuesChange();
  }, [docApproveStatus, snilsDoc, snilsForm]);

  useEffect(() => {
    const documentStatus: string = snilsDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = snilsDoc?.fields?.files
    ?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, snilsDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("snils-schema"));
      dispatch(getEmployeeDocumentData("snils-schema", documentUuid));
    }
  }, [dispatch, documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    const docSnilsNumber: string = snilsDoc?.fields?.snils_number ?? "";
    const docSnilsStatus: string = snilsDoc?.fields?.document_status ?? "";
    const docSnilsDeclineReason: string = snilsDoc?.fields?.decline_reason ?? "";
    const shouldUpdateDocField: boolean = !!docSnilsNumber
      || !!docSnilsStatus;

    if (shouldUpdateDocField) {
      handleSnilsChange(docSnilsNumber);
      onApproveChange(docSnilsStatus);

      snilsForm.setFieldValue("isApproved", docSnilsStatus);
    }

    if (docSnilsDeclineReason) {
      snilsForm.setFieldValue("declineReason", docSnilsDeclineReason);
    }
  }, [snilsDoc, snilsForm]);

  const handleSnilsChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string" 
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    const formattedSnils: string = matches
      ? matches.slice(1, 4).filter(Boolean).join("-") + (matches[4] ? ` ${matches[4]}` : "")
      : "";

    if (formattedSnils?.length === 14 || formattedSnils?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    snilsForm.setFieldValue("snils_number", formattedSnils);
  };

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isSnilsFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  return (
    <div className="flex flex-col h-90vh">
      <h2 className="mb-2 text-xl font-bold">
        {`СНИЛС ${isEmployee ? "сотрудника" : "генерального директора"}`}
      </h2>
      {!isSnilsFormDisabled && (
        <p className="mb-4 gray-color w-full">
          После проверки правильности документа подтвердите его согласованность.
        </p>
      )}
      <Form
        form={snilsForm}
        name="snilsForm"
        onChange={onFormValuesChange}
        onClick={onFormValuesChange}
        validateTrigger={validateTriggersArr}
      >
        <Form.Item
          name="snils_number"
          rules={[
            {
              max: 15,
              message: "Значение должно быть 11 цифр.",
            },
            {
              min: 14,
              message: "Значение должно быть 11 цифр.",
            },
            {
              required: isFormItemRequiredRule("snils_number", snilsDocSchema),
              message: "",
            }
          ]}
          validateTrigger={validateTriggersArr}
        >
          <Input
            size="large"
            placeholder="СНИЛС"
            onChange={handleSnilsChange}
            readOnly={isSnilsFormDisabled}
            status={!isSnilsFormDisabled ? isDocFieldRequired(
              snilsDocSchema,
              "snils_number",
              snilsForm
            ) : ""}
          />
        </Form.Item>
        {!isSnilsFormDisabled && (
          <>
            <Divider className="mt-1"/>
            <div className="flex justify-between">
              <Tooltip
                title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              >
                <Form.Item name="isApproved">
                  <Radio.Group
                    onChange={onApproveChange}
                    value={docApproveStatus}
                    disabled={isDocApprovalDisabled}
                  >
                    <Radio value="APPROVED">Согласовать</Radio>
                    <Radio value="DECLINED">Отклонить</Radio>
                  </Radio.Group>
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {docApproveStatus === "DECLINED" && (
                <Form.Item
                  name="declineReason"
                  initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                >
                  <Select
                    placeholder="Причина"
                    size="large"
                    options={[
                      {
                        value: "Необходимо приложить верный документ (приложен не тот документ)",
                        label: "Необходимо приложить верный документ (приложен не тот документ)",
                      },
                      {
                        value: "Плохое качество документа, нечитаемый скан",
                        label: "Плохое качество документа, нечитаемый скан",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
      </Form>
      <div className="text-right mt-auto">
        <Divider className="mt-0"/>
        <ButtonCustom
          id="close"
          className={`${css.buttonBack} mr-3`}
          type="default"
          onClick={closeForm}
          text={isSnilsFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isSnilsFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={() => {
              setCanSaveDoc(true);
              dispatch(getUnAgreedDocumentType(true));
            }}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormSnils;
