import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import css from "./ClientProfileCardManagerDetails.module.css";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import ClientProfileCardImageViewer from "../ClientProfileCardImageViewer/ClientProfileCardImageViewer";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import { UpdateDocumentType } from "app/types";
import { getCompanyDocumentData } from "../../../actions/document.actions";
import { AppDispatch } from "../../../store/store";
import dayjs from "dayjs";
import { handleSnilsNumberChange } from "../../../utils/snilsNumberMask";
import { TaskQuestionnaireCardSteps } from "../ClientProfileCard";
import { onlyOneDirector } from "../../../utils/onlyOneDirector";
import TagCustom from "../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument from "../ZeroClientProfileDocument/ZeroClientProfileDocument";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { documentSchemaLabelsMapper } from "../../../utils/documentSchemaLabelsMapper";
import { DocumentSchemaLabelMapType } from "app/types";

interface ITaskQuestionnaireManagerDetailProps {
  closeForm: () => void;
  saveCompanyFormData: (docUuid: string, data: any) => void
  documentType: string;
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isImageViewerOpen: boolean;
  isValidationCompleted: boolean;
}

const ClientProfileCardManagerDetails: FC<ITaskQuestionnaireManagerDetailProps> = ({
  closeForm,
  saveCompanyFormData,
  documentType,
  documentUuid,
  showImageViewer,
  currentStep,
  setCurrentStep,
  isImageViewerOpen,
  isValidationCompleted,
}): JSX.Element => {
  const [passportDocUuid, setPassportDocUuid] = useState<string>("");
  const [passportDocument, setPassportDocument] = useState<UpdateDocumentType | null>(null);
  const [decisionDocUuid, setDecisionDocUuid] = useState<string>("");
  const [decisionDocument, setDecisionDocument] = useState<UpdateDocumentType | null>(null);
  const [snilsDocUuid, setSnilsDocUuid] = useState<string>("");
  const [snilsDocument, setSnilsDocument] = useState<UpdateDocumentType | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const {
    schemaClientProfile,
    documentClientProfile,
  } = useSelector((state: AppStateType) => state.clientProfile);

  const {
    snilsCompanyDoc,
    passportCompanyDoc,
    decisionCompanyDoc,
  } = useSelector((state: AppStateType) => state.document);

  const clientProfileOrgLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClientProfile);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (snilsDocUuid) {
      dispatch(getCompanyDocumentData("snils-schema", snilsDocUuid));
      setIsDataLoaded(true);
    }

    if (passportDocUuid) {
      dispatch(getCompanyDocumentData("passport-schema", passportDocUuid));
      setIsDataLoaded(true);
    }

    if (decisionDocUuid) {
      dispatch(getCompanyDocumentData("founders-decision-schema", decisionDocUuid));
      setIsDataLoaded(true);
    }
  }, [decisionDocUuid, dispatch, documentClientProfile, passportDocUuid, snilsDocUuid]);

  const renderAgreed = (): JSX.Element => {
    return (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderConfirmationPassport = (): JSX.Element => {
    return (
      passportDocument?.fields?.document_status !== "DECLINED" ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderConfirmationDecision = (): JSX.Element => {
    return (
      decisionDocument?.fields?.document_status !== "DECLINED" ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderConfirmationSnils = (): JSX.Element => {
    return (
      snilsDocument?.fields?.document_status !== "DECLINED" ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderCause = (cause: string): JSX.Element => {
    return (
      <div className={`${css.cause}`}>
        <div className="inline-block mb-1 gray-color text-sm font-[300]">
          Причина
        </div>
        <div className="text-[14px] font-normal leading-5">
          {cause}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const passportDocUuid: string = documentClientProfile?.fields?.passport_document?.length
      ? documentClientProfile?.fields?.passport_document?.[0]?.uuid
      : "";

    setPassportDocUuid(passportDocUuid);
    setPassportDocument(passportCompanyDoc);
  }, [documentClientProfile, passportCompanyDoc]);

  useEffect(() => {
    const decisionDocUuid: string = documentClientProfile?.fields?.founders_decision?.length
      ? documentClientProfile?.fields?.founders_decision?.[0]?.uuid
      : "";

    setDecisionDocUuid(decisionDocUuid);
    setDecisionDocument(decisionCompanyDoc);
  }, [documentClientProfile, decisionCompanyDoc]);

  useEffect(() => {
    const snilsDocUuid: string = documentClientProfile?.fields?.snils_document?.length
      ? documentClientProfile?.fields?.snils_document?.[0]?.uuid
      : "";

    setSnilsDocUuid(snilsDocUuid);
    setSnilsDocument(snilsCompanyDoc);
  }, [documentClientProfile, snilsCompanyDoc]);

  const pageChange = (stepChange: number) => {
    setCurrentStep(stepChange);
    window.scrollTo(0, 0);
  };

  const directorLastName = (): JSX.Element | null => {
    const lastName: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.last_name
      : passportDocument?.fields?.last_name;

    if (!lastName) {
      return null;
    }

    return (
      <li className="flex mb-[8px]">
        <span className="document-field">
          Фамилия
        </span>
        <span className="text-[14px]">
          {lastName}
        </span>
      </li>
    );
  };

  const directorFirstName = (): JSX.Element | null => {
    const firstName: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.first_name
      : passportDocument?.fields?.first_name;

    if (!firstName) {
      return null;
    }

    return (
      <li className="flex mb-[8px]">
        <span className="document-field">
          Имя
        </span>
        <span className="text-[14px]">
          {firstName}
        </span>
      </li>
    );
  };

  const directorSecondName = (): JSX.Element | null => {
    const secondName: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.second_name
      : passportDocument?.fields?.second_name;

    if (!secondName) {
      return null;
    }

    return (
      <li className="flex mb-[8px]">
        <span className="document-field">
          Отчество
        </span>
        <span className="text-[14px]">
          {secondName}
        </span>
      </li>
    );
  };

  //логика работы лоудера
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    {/*TODO отключать лоудер только после того как получили ответы от всех запросов на получение документов*/}

    const timer: NodeJS.Timeout = setTimeout(() => setLoader(true), 500);
    return () => clearTimeout(timer);
  }, [passportDocument, decisionDocument, snilsDocument, isDataLoaded]);
  
  const renderManagerData = (label: string, text: string | undefined):JSX.Element => (
    <li className="flex mb-2">
      <span className="document-field">
       {label}
      </span>
      <span className="text-sm">
       {text}
      </span>
    </li>
  );

  return (
    <>
      {loader ? (
        <>
          <div className={`${css.container} p-[18px] mb-3 bg-white`}>
            <h1 className="header-text mb-5">
              Паспортные данные руководителя
            </h1>
            {passportDocument ? (
              <div className="border-bottom-gray">
                <div>
                  <div className={css.informationCEO}>
                    <ul>
                      {directorLastName()}
                      {directorFirstName()}
                      {directorSecondName()}
                      {!!passportDocument?.fields?.date_of_birth && renderManagerData(
                        "Дата рождения",
                        dayjs(passportDocument?.fields?.date_of_birth).format("DD.MM.YYYY")
                      )}
                      {!!passportDocument?.fields?.sex && renderManagerData(
                        "Пол",
                        passportDocument?.fields?.sex
                      )}
                      {!!passportDocument?.fields?.passport_number && renderManagerData(
                        "Серия номер паспорта",
                        `${passportDocument?.fields?.series_number}
                             ${passportDocument?.fields?.passport_number}`
                      )}
                      {!!passportDocument?.fields?.issued_date && renderManagerData(
                        "Дата выдачи",
                        dayjs(passportDocument?.fields?.issued_date).format("DD.MM.YYYY")
                      )}
                      {!!passportDocument?.fields?.issued_by && renderManagerData(
                        "Кем выдан",
                        passportDocument?.fields?.issued_by
                      )}
                    </ul>
                    <div className={
                      `flex items-center 
                      ${passportDocument?.fields?.document_status === "DECLINED" ? "mb-3" : "m-0"}`
                    }>
                      <ButtonCustom
                        id="checkPassport"
                        className="w-40 mr-2"
                        type="primary"
                        ghost
                        text="Проверить файлы"
                        onClick={() =>
                          showImageViewer(
                            "passport-schema",
                            passportDocUuid ?? ""
                          )
                        }
                      />
                      {!!passportDocument?.fields?.document_status && renderConfirmationPassport()}
                    </div>
                    <div>
                      {passportDocument?.fields?.document_status === "DECLINED" && (
                        !passportDocument?.fields?.decline_reason
                          ? null
                          : renderCause(passportDocument?.fields?.decline_reason)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ZeroClientProfileDocument variant="Паспорт руководителя" />
            )}
          </div>
          <div className={`${css.container} p-[18px] mb-3 bg-white`}>
            <div className={`${css.organisationInfo}`}>
              <>
                <div className="header-text mb-5">
                  {
                    clientProfileOrgLabels?.["founders_decision"]?.label
                    ?? "Решение учредителя по формированию уставного капитала и назначения руководителя"
                  }
                </div>
                {decisionDocument ? (
                  <div className={css.informationCEO}>
                    <span>
                      <div className={
                        `flex items-center 
                        ${decisionDocument?.fields?.document_status === "DECLINED" ? "mb-3" : "m-0"}`
                      }>
                        <ButtonCustom
                          id="checkFoundersDecision"
                          className="w-40 mr-2"
                          type="primary"
                          ghost
                          text="Проверить файлы"
                          onClick={() =>
                            showImageViewer(
                              "founders-decision-schema",
                              decisionDocUuid ?? ""
                            )
                          }
                        />
                        {!!decisionDocument?.fields?.document_status && renderConfirmationDecision()}
                      </div>
                      {decisionDocument?.fields?.document_status === "DECLINED" && (
                        !decisionDocument?.fields?.decline_reason
                          ? null
                          : renderCause(decisionDocument?.fields?.decline_reason)
                      )}
                    </span>
                  </div>
                ) : (
                  <ZeroClientProfileDocument variant="Решение учредителя" />
                )}
              </>
            </div>
          </div>
          <div className={`${css.container} p-[18px] mb-3 bg-white`}>
            <div className={css.organisationInfo}>
              <>
                <div className="header-text mb-5">
                  {`${clientProfileOrgLabels?.["snils_document"]?.label} генерального директора`}
                </div>
                {snilsDocument ? (
                  <>
                    <ul className="mb-5">
                      {!!snilsDocument?.fields?.snils_number &&
                        renderManagerData(
                          "Номер СНИЛС",
                         handleSnilsNumberChange(snilsDocument?.fields?.snils_number)
                        )
                      }
                    </ul>
                    <div className={css.informationCEO}>
                      <span>
                        <div className={
                          `flex items-center 
                          ${snilsDocument?.fields?.document_status === "DECLINED" ? "mb-[12px]" : "m-0"}`
                        }>
                          <ButtonCustom
                            id="checkSnils"
                            className="w-40 mr-2"
                            type="primary"
                            ghost
                            text="Проверить файлы"
                            onClick={() =>
                              showImageViewer(
                                "snils-schema",
                                snilsDocUuid
                              )
                            }
                          />
                          {snilsDocument?.fields?.document_status && renderConfirmationSnils()}
                        </div>
                        {snilsDocument?.fields?.document_status === "DECLINED" && (
                          !snilsDocument?.fields?.decline_reason
                            ? null
                            : renderCause(snilsDocument?.fields?.decline_reason)
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  <ZeroClientProfileDocument variant="СНИЛС директора" />
                )}
              </>
            </div>
          </div>
          <div className={`${css.container} p-[18px] bg-white border-bottom-gray text-end`}>
            <ButtonCustom
              id="stepBack"
              className={`${css.buttonBack} mr-4`}
              key="cancel"
              size="large"
              type="default"
              text="Назад"
              onClick={() => pageChange(TaskQuestionnaireCardSteps.OrganisationStep)}
            />
            <ButtonCustom
              id="stepForward"
              type="primary"
              className={css.buttonOk}
              text="Продолжить"
              size="large"
              onClick={() => pageChange(TaskQuestionnaireCardSteps.EmployeesStep)}
            />
          </div>
        </>
      ) : (
        <div className={`flex items-center justify-center ${css.container} h-[70vh] bg-white border-bottom-gray`}>
          <LoadingCustom className="w-full" />
        </div>
      )}
      {isImageViewerOpen && (
        <ClientProfileCardImageViewer
          closeForm={closeForm}
          saveCompanyFormData={saveCompanyFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          isImageViewerOpen={isImageViewerOpen}
          isNotAgreedDocs={!(currentStep === TaskQuestionnaireCardSteps.ManagerDetailsStep)}
          isValidationCompleted={isValidationCompleted}
          isCompany
        />
      )}
    </>
  );
};

export default ClientProfileCardManagerDetails;
