import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../../reducers/mainReducer";
import {
  GET_RESUMES_LISTING_SUCCESS,
  GET_RESUMES_LISTING_FAILURE,
  GET_RESUME_SUCCESS,
  GET_RESUME_FAILURE,
} from "../../constants/actionTypes";
import { AlphaListParamsType, ResumesListingType, ResumesResultsType } from "app/types";
import * as ResumesAPI from "../../api/alpha/resume.api";

// Логика получения листинга резюме
export type ResumeActionsTypes =
  | GetResumesListingSuccessType
  | GetResumeListingFailureType
  | GetResumeSuccessType
  | GetResumeFailureType
  ;

export type GetResumesListingSuccessType = {
  type: typeof GET_RESUMES_LISTING_SUCCESS;
  payload: {
    resumeListing: ResumesListingType;
  };
};

const getResumesListingSuccess = (resumeListing: ResumesListingType): GetResumesListingSuccessType => ({
  type: GET_RESUMES_LISTING_SUCCESS,
  payload: {
    resumeListing
  }
});

export type GetResumeListingFailureType = {
  type: typeof GET_RESUMES_LISTING_FAILURE;
  payload: {
    error: any;
  };
};

const getResumeListingFailure = (error: any): GetResumeListingFailureType => ({
  type: GET_RESUMES_LISTING_FAILURE,
  payload: {
    error
  }
});

export const getResumesData = (params?: AlphaListParamsType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ResumeActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ResumesAPI.getResumes(params);

    dispatch(getResumesListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getResumeListingFailure(error.message));
  }
};

export type GetResumeSuccessType = {
  type: typeof GET_RESUME_SUCCESS;
  payload: {
    resume: ResumesResultsType;
  };
};

const getResumeSuccess = (resume: ResumesResultsType): GetResumeSuccessType => ({
  type: GET_RESUME_SUCCESS,
  payload: {
    resume
  }
});

export type GetResumeFailureType = {
  type: typeof GET_RESUME_FAILURE;
  payload: {
    error: any;
  };
};

export const getResumeFailure = (error?: any): GetResumeFailureType => ({
  type: GET_RESUME_FAILURE,
  payload: {
    error
  }
});

export const getResumeInfo = (resumeUuid: string):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ResumeActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ResumesAPI.getResume(resumeUuid);

    dispatch(getResumeSuccess(response.data));
  } catch (error: any) {
    dispatch(getResumeFailure(error.message));
  }
};
