import React, { FC, JSX, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  FormInstance,
  Button,
  Input,
  Form,
  message
} from "antd";
import css from "./LoginForm.module.css";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as Warning } from "../../assets/icons/warning_circle_icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check_circle_icon_green.svg";
import { changePassword } from "../../api/account.api";

interface IResetPasswordFormProps {
  setResetFormOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPasswordForm: FC<IResetPasswordFormProps> = ({
  setResetFormOpened
}): JSX.Element => {
  const [form] = Form.useForm<FormInstance>();

  const [email, setEmail] = useState<string>("");
  const [resetPassword, setResetPassword] = useState<boolean>(true);
  const [resendRequest, setResendRequest] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (timeLeft > 0) {
      timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      setResendRequest(false);
    }

    return () => clearTimeout(timerId);
  }, [timeLeft]);

  const openLoginFrom = (): void => {
    navigate("/login");
    setResetFormOpened(false);
  };

  const messageText = (text: string): JSX.Element => {
    return (
      <div className="flex justify-center items-center">
        <Warning className="mr-2"/>
        <div>
          {text}
        </div>
      </div>
    );
  };

  const success = async (): Promise<void>=> {
    try {
      await changePassword({email: email});

      // message.success({
      //   type: "success",
      //   content: "По указанному адресу отправлено письмо с инструкцией для восстановления пароля.",
      //   duration: 7,
      // });
      // message.open({
      //   content: messageText("Запрос на восстановления пароля возможен через 5 минут. " +
      //     "Если письмо не пришло, проверьте папку «Спам»"),
      //   icon: false,
      //   duration: 7,
      // });

    } catch {
      message.open({
        content: messageText("Произошла ошибка при попытке восстановления пароля. Попробуйте снова."),
        icon: false,
        duration: 7,
      });
    } finally {
      setResetPassword(false);
      setResendRequest(true);
      setTimeLeft(59);

      // openLoginFrom();
    }
  };

  const resend = async (): Promise<void> => {
    try {
      await changePassword({email: email});

    } catch {
      message.open({
        content: messageText("Произошла ошибка при попытке восстановления пароля. Попробуйте снова."),
        icon: false,
        duration: 7,
      });
    } finally {
      setResendRequest(true);
      setTimeLeft(59);
    }
  };

  return (
    resetPassword ? (
      <div className={css.container}>
        <h1 className={`text-center font-bold mb-[12px] ${css.header}`}>
          Личный кабинет бухгалтера
        </h1>
        <span className={css.resetText}>
          Введите адрес электронной почты, который вы используете для входа в аккаунт.
        </span>
        <Form
          form={form}
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={success}
        >
          <Form.Item
            className="mb-[20px]"
            name="email"
            rules={[
              {
                type: "email",
                message: "Введите корректный адрес электронной почты",
              },
              {
                required: true,
                message: "Пожалуйста, введите свой адрес электронной почты",
              }
            ]}
          >
            <Input
              className="p-[8px] rounded-[8px]"
              size="large"
              placeholder="E-mail"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate
            className="mb-[20px] text-center"
          >
            {() => (
              <ButtonCustom
                size="large"
                text="Сбросить пароль"
                className={`${css.button} w-full`}
                type={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                    ? "default"
                    : "primary"
                }
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                htmlType="submit"
              />
            )}
          </Form.Item>
          <Form.Item
            shouldUpdate
            className="mb-0 text-center"
          >
            <Button
              type="link"
              onClick={openLoginFrom}
            >
              Вернуться к авторизации
            </Button>
          </Form.Item>
        </Form>
      </div>
    ) : (
      <div className={`flex flex-col items-center ${css.container}`}>
        <h1 className={`text-center font-bold mb-[20px] ${css.header}`}>
          Личный кабинет бухгалтера
        </h1>
        <div className={css.checkBox}>
          <CheckIcon className="flex flex-shrink-0 mr-[10px]"/>
          <div className={css.checkText}>
            На электронную почту {email} отправлено письмо с инструкцией для восстановления пароля.
          </div>
        </div>
        <ButtonCustom
          type="link"
          className="mb-[20px]"
          text={resendRequest ? `Отправить повторно (через ${timeLeft} сек.)` : "Отправить повторно"}
          disabled={resendRequest}
          onClick={resend}
        />
        <ButtonCustom
          type="link"
          className="mb-[20px] p-[0px]"
          text="Перепутали email?"
          onClick={(): void => {
            setEmail("");
            form.resetFields();
            setResetPassword(true);
          }}
        />
        <ButtonCustom
          type="link"
          text="Вернуться к авторизации"
          onClick={openLoginFrom}
        />
      </div>
    )
  );
};

export default ResetPasswordForm;
