import React, { FC, JSX } from "react";
import { ReactComponent as TaskDefault } from "../../../assets/icons/empty-tasks.svg";
import css from "./EmptyList.module.css";

interface IEmptyListProps {
  activeKey: string;
  className?: string;
}

const EmptyList: FC<IEmptyListProps> = ({ activeKey, className }): JSX.Element => {

  const headerText = (activeKey: string): string => {
    const messages: { [key: string]: string } = {
      applications: "Заявок нет",
      applicationsNew: "Новых заявок нет",
      applicationsWaitDecision: "Заявок на согласовании нет",
      applicationsArchive: "Заявок в архиве нет",
      vacancy: "Вакансий нет",
      resume: "Резюме нет",
      clients: "Клиент не найден",
      noClients: "Клиентов нет",
      employees: "Сотрудник не найден",
      noEmployees: "Сотрудников нет",
      contracts: "Договоров нет",
      absentees: "Записей отсутствий нет",
      performers: "Ничего не найдено",
      noPerformers: "Исполнителей нет",
    };
    
    return messages[activeKey] || "Информация отсутствует";
  };

    const bodyText = (activeKey: string): string => {
      const messages: { [key: string]: string } = {
        applications: "Здесь будут отображаться все ваши заявки",
        applicationsNew: "Здесь будут отображаться ваши новые заявки",
        applicationsWaitDecision: "Здесь будут отображаться ваши заявки на согласовании",
        applicationsArchive: "Здесь будут отображаться ваши заявки в архиве",
        vacancy: "Здесь будут отображаться все вакансии",
        resume: "Здесь будут отображаться все резюме",
        clients: "Проверьте правильность параметров поиска",
        noClients: "Здесь будут отображаться все клиенты",
        employees: "Проверьте правильность параметров поиска",
        noEmployees: "Здесь будут отображаться все сотрудники",
        contracts: "Здесь будут отображаться все ваши договора",
        absentees: "Здесь будут отображены ваши записи об отсутствии",
        performers: "Проверьте правильность параметров поиска",
        noPerformers: "Здесь будут отображаться все исполнители",
      };
      
      return messages[activeKey] || "";
    };

  return (
    <div className={`${css.container} ${className}`}>
      <div className={css.label}>
        <div className={css.icon}><TaskDefault/></div>
        <div>
          <div className={css.textHeader}>{headerText(activeKey)}</div>
          <div className={css.textBody}>{bodyText(activeKey)}</div>
        </div>
      </div>
    </div>
  );
};

export default EmptyList;
