import React, { FC, JSX } from "react";
import css from "./ZeroEmployees.module.css";
import { ReactComponent as EmployeesZero } from "../../../../../../assets/icons/employees_empty.svg";

interface IZeroEmployeesProps {
  variant: string
}

const ZeroEmployees: FC<IZeroEmployeesProps> = ({ variant }): JSX.Element => {

  const bodyText = (variant: string): string => {
    switch (variant) {
      case "clientCard":
        return "У клиента пока нет сотрудников или он не предоставил информацию о них";
      case "clientProfile":
        return "У клиента нет сотрудников или мы не получили о них данные";
      default:
        return "";
    }
  };

  return (
    <div className={css.container}>
      <div className={css.label}>
        <div className={css.icon}><EmployeesZero/></div>
        <div>
          <div className={css.textHeader}>Сотрудников нет</div>
          <div className={css.textBody}>
            {bodyText(variant)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZeroEmployees;
