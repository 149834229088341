import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS,
  GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE,
  GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS,
  GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE,
  UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS,
  UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE,
} from "../constants/actionTypes";
import * as EmployeeProfileAPI from "../api/employeeProfile.api";
import { DocumentEmployeeProfileType, SchemaType } from "app/types";

export type EmployeeProfileActionsTypes =
  | GetSchemaEmployeeProfileSuccessType
  | GetSchemaEmployeeProfileFailureType
  | GetDocumentEmployeeProfileSuccessType
  | GetDocumentEmployeeProfileFailureType
  | UpdateDocumentEmployeeProfileSuccessType
  | UpdateDocumentEmployeeProfileFailureType;

// Логика получения схемы сотрудника
export type GetSchemaEmployeeProfileSuccessType = {
  type: typeof GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS;
  payload: {
    schemaEmployee: SchemaType;
  };
};

const getSchemaEmployeeProfileSuccess = (schemaEmployee: SchemaType): GetSchemaEmployeeProfileSuccessType => ({
  type: GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS,
  payload: {
    schemaEmployee
  }
});

export type GetSchemaEmployeeProfileFailureType = {
  type: typeof GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE;
  payload: {
    error: any;
  };
};

export const getSchemaEmployeeProfileFailure = (error?: any): GetSchemaEmployeeProfileFailureType => ({
  type: GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const fetchSchemaEmployeeProfile = ():
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeProfileActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeProfileAPI.getSchemaEmployeeProfile();

    dispatch(getSchemaEmployeeProfileSuccess(response.data));
  } catch (error: any) {
    dispatch(getSchemaEmployeeProfileFailure(error.message));
  }
};

// Логика получения данных схемы сотрудника по uuid документа
export type GetDocumentEmployeeProfileSuccessType = {
  type: typeof GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS;
  payload: {
    documentEmployeeProfile: DocumentEmployeeProfileType;
  };
};

const getDocumentEmployeeProfileSuccess = (documentEmployeeProfile: DocumentEmployeeProfileType)
  : GetDocumentEmployeeProfileSuccessType => ({
  type: GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS,
  payload: {
    documentEmployeeProfile
  }
});

export type GetDocumentEmployeeProfileFailureType = {
  type: typeof GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentEmployeeProfileFailure = (error?: any): GetDocumentEmployeeProfileFailureType => ({
  type: GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const fetchDocumentEmployeeProfile = (uuid: string):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeProfileActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeProfileAPI.getDocumentEmployeeProfile(uuid);

    dispatch(getDocumentEmployeeProfileSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentEmployeeProfileFailure(error.message));
  }
};

// Логика обновления данных сотрудника по uuid документа
export type UpdateDocumentEmployeeProfileSuccessType = {
  type: typeof UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS;
  payload: {
    documentEmployeeProfile: DocumentEmployeeProfileType;
  };
};

const updateDocumentEmployeeProfileSuccess = (documentEmployeeProfile: DocumentEmployeeProfileType)
  : UpdateDocumentEmployeeProfileSuccessType => ({
  type: UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS,
  payload: {
    documentEmployeeProfile
  }
});

export type UpdateDocumentEmployeeProfileFailureType = {
  type: typeof UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE;
  payload: {
    error: any;
  };
};

export const updateDocumentEmployeeProfileFailure = (error?: any): UpdateDocumentEmployeeProfileFailureType => ({
  type: UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const updateEmployeeProfileData = (uuid: string, data?: any):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    EmployeeProfileActionsTypes
  > => async (dispatch) => {
  try {
    const response = await EmployeeProfileAPI.updateDocumentEmployeeProfile(uuid, data);

    dispatch(updateDocumentEmployeeProfileSuccess(response.data));
  } catch (error: any) {
    dispatch(updateDocumentEmployeeProfileFailure(error.message));
  }
};
