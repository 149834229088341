import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch } from "../../../../../store/store";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search_icon.svg";
import { Form, Input, Table, TableColumnsType } from "antd";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { ClientBanksDataParamsType } from "app/types";
import {
  getClientBanksDataInfo,
  getClientBanksPageNumber,
  getClientBanksPageSize,
  getClientBanksSearch,
  getClientBanksSorter,
} from "../../../../../actions/client.actions";
import css from "./Accounts.module.css";
import ZeroSearch from "../../../ZeroSearch/ZeroSearch";
import { PaginationCustom } from "../../../../ui-kit/PaginationCustom/PaginationCustom";
import { useTableSorter } from "../../../../../utils/handleSorterTable";
import ZeroAccounts from "./ZeroAccounts/ZeroAccounts";
import DraftWarning from "../../../../../utils/DraftWarning/DraftWarning";

interface AccountsTableDataType {
  key: React.Key | number;
  name: JSX.Element | string | undefined;
  bic: JSX.Element | string | undefined;
  checkingAccount: JSX.Element | string | undefined;
}

type FormPropsType = {
  accountValue: string | null;
}

enum ListingPage {
  firstPage = 1,
}

interface IAccountsProps {
}

const Accounts: FC<IAccountsProps> = (): JSX.Element => {
  const [form] = Form.useForm();
  const values: FormPropsType = Form.useWatch([], form);
  const { accountValue } = values || {};
  
  const [clientsUuid, setClientsUuid] = useState<string>("");
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const path: string = useLocation().pathname;

  const {
    clientBanksData,
    banksPageNumber,
    banksPageSize,
    banksSorter,
    banksSearch
  } = useSelector((state: AppStateType) => state.client);

  const [currentPage, setCurrentPage] = useState<number>(banksPageNumber);
  const [pageSize, setPageSize] = useState<number>(banksPageSize);
  const [paramsData, setParamsData] = useState<ClientBanksDataParamsType | null>({
    page: banksPageNumber,
    size: banksPageSize,
    search: banksSearch,
    order_by: banksSorter,
  });

  useEffect(() => {
    const findUuid: RegExpMatchArray | null = path.match(/\/clients\/([^/]+)/);
    const clientUuid: string = findUuid?.[1] ?? "";
    setClientsUuid(clientUuid);
  }, [path]);

  useEffect(() => {
    if (clientsUuid) {
      setLoadingData(true);
      dispatch(getClientBanksDataInfo(clientsUuid, paramsData))
        .finally(() => {
          setLoadingData(false);
        });
      dispatch(getClientBanksSorter(paramsData?.order_by ? paramsData?.order_by : "-name"));
    }
  }, [clientsUuid, paramsData]);

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(():void => {
      const hasInputValue: boolean = accountValue?.trim() !== ""
        && typeof accountValue === "string"
        && (!paramsData?.search || paramsData?.search !== accountValue);

      if (hasInputValue) {
        setParamsData((prev: ClientBanksDataParamsType| null) => {
          const updatedParams: ClientBanksDataParamsType = {
            ...prev,
            search: accountValue?.trim()
          };

          return updatedParams;
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [accountValue]);

  const emptyDescription = (): JSX.Element => (
    <>
      {!isLoadingData && !!accountValue && <ZeroSearch />}
      {!isLoadingData && !accountValue && <ZeroAccounts />}
    </>
  );

  const onAccountSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue("accountValue", e.target.value);

    setCurrentPage(ListingPage.firstPage);
    dispatch(getClientBanksPageNumber(ListingPage.firstPage));
    dispatch(getClientBanksSearch(e.target.value));
  };

  const onSearchClear = (): void => {
    setParamsData({ ...paramsData, search: "" });
  };

  const accountsTableData: AccountsTableDataType[] = clientBanksData?.map(({
    bic,
    name,
    acc,
  }, index: number) => {
    return {
      key: index,
      name: name,
      bic: bic,
      checkingAccount: acc,
    };
  }) ?? [];

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter("-name", setParamsData);

  const accountsTableColumns: TableColumnsType<AccountsTableDataType> = [
    {
      title: (
        <span className="table-title">
        Наименование банка
      </span>
      ),
      dataIndex: "name",
      sorter: true,
      showSorterTooltip: false,
      width: "35%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "name"),
      }),
    },
    {
      title: (
        <span className="table-title">
        Бик
      </span>
      ),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      sortIcon: (): null => null,
      dataIndex: "bic",
      width: "30%",
    },
    {
      title: (
        <span className="table-title">
        Расчетный счет
      </span>
      ),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      sortIcon: (): null => null,
      dataIndex: "checkingAccount",
      width: "35%",
    }
  ];

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getClientBanksPageNumber(newPage));
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    dispatch(getClientBanksPageSize(newPageSize));
    setCurrentPage(ListingPage.firstPage);
    dispatch(getClientBanksPageNumber(ListingPage.firstPage));
  };

  useEffect(() => {
    setPageSize(banksPageSize);
    setCurrentPage(banksPageNumber);
  }, []);
  
  const initialValues: FormPropsType = {
    accountValue: banksSearch ?? null,
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
    >
      <DraftWarning />
      <div className={css.accountsContainer}>
        <h1 className={css.accountsHeader}>
          Счета клиента
        </h1>
        <Form.Item name="accountValue" noStyle>
          <Input
            id="accountValue"
            className={css.accountsSearchInput}
            allowClear
            suffix={<SearchIcon className={css.accountsSearchIcon} />}
            placeholder="Наименование банка, БИК или расчетный счет"
            onChange={onAccountSearchChange}
            onClear={onSearchClear}
          />
        </Form.Item>
        <Table
          columns={accountsTableColumns}
          dataSource={accountsTableData}
          loading={isLoadingData}
          pagination={false}
          locale={{ emptyText: emptyDescription() }}
        />
        {accountsTableData.length ? (
          <PaginationCustom
            className={css.paginationShowTotal}
            currentPage={currentPage}
            defaultPageSize={banksPageSize}
            pageSize={pageSize}
            defaultCurrent={banksPageNumber}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        ) : (
          <div className={css.noBanks}/>
        )}
      </div>
    </Form>
  );
};

export default Accounts;
