import React, { FC, JSX, useEffect, useState } from "react";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "../DocumentsCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { getDocumentSchema, getEmployeeDocumentData } from "../../../../../../actions/document.actions";
import { AppDispatch } from "../../../../../../store/store";
import TagCustom from "../../../../../ui-kit/TagCustom/TagCustom";
import { DocumentEmployeeProfileType, UpdateDocumentType } from "app/types";
import ZeroClientProfileDocument from "../../../../ZeroClientProfileDocument/ZeroClientProfileDocument";

interface IIncomeDocProps {
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  incomeDocUuid: string;
}

const IncomeDoc: FC<IIncomeDocProps> = ({
  documentUuid,
  showImageViewer,
  incomeDocUuid
}): JSX.Element | null => {
  const [isIncomeConfirmed, setIncomeConfirmed] = useState<boolean>(false);
  const [schemaName, setSchemaName] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const incomeDoc: UpdateDocumentType | null = useSelector((state: AppStateType) => state.document.incomeDoc);

  const documentEmployeeProfile: DocumentEmployeeProfileType | null =
    useSelector((state: AppStateType) => state.employeeProfile.documentEmployeeProfile);

  useEffect(() => {
    if (incomeDoc?.fields?.document_status === "APPROVED") {
      setIncomeConfirmed(true);
    } else {
      setIncomeConfirmed(false);
    }
  }, [incomeDoc]);

  const renderAgreed = (): JSX.Element => {
    return (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderIncomeDeclineReason = (): JSX.Element => {
    return (
      <div className={`${css.cause} mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {incomeDoc?.fields?.decline_reason}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (schemaName) {
      dispatch(getEmployeeDocumentData(schemaName, documentUuid));
    }
  }, [dispatch, schemaName]);

  const openImageViewer = (text: string): void => {
    setSchemaName(() => {
      dispatch(getDocumentSchema(text));
      return text;
    });
  };

  return (
    <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
      <h1 className="header-text mb-5">
        Документ для вычета НДФЛ
      </h1>
      {incomeDoc?.fields ? (
        <span className="mb-2">
          {!!documentEmployeeProfile?.fields?.income_tax_deduction?.length && (
            <div className="flex items-center mb-5">
              <ButtonCustom
                id="checkEmployeeNdfl"
                className="w-36 mr-2"
                type="primary"
                ghost
                text="Проверить файлы"
                onClick={() => {
                  showImageViewer("ndfl-schema", incomeDocUuid);
                  openImageViewer("ndfl-schema");
                }
                }
              />
              {!!incomeDoc?.fields?.document_status && (isIncomeConfirmed ? renderAgreed() : renderNotAgreed())}
            </div>
          )}
          {!!incomeDoc?.fields?.decline_reason && (isIncomeConfirmed ? null : renderIncomeDeclineReason())}
        </span>
      ) : (
        <ZeroClientProfileDocument variant="НДФЛ сотрудника" />
      )}
    </div>
  );
};

export default IncomeDoc;
