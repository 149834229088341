import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_REPORTS_LISTING_SUCCESS,
  GET_REPORTS_LISTING_FAILURE,
  GET_REPORTS_LISTING_PAGE_SIZE,
  GET_REPORTS_LISTING_PAGE_NUMBER,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAILURE,
  REPORTS_LISTING_PARAMS_DATA,
  REPORTS_CREATION_PARAMS_DATA,
} from "../constants/actionTypes";
import {
  NewReportDataType,
  ReportsListingType,
  CreateReportDataType,
  ReportsListingFilterType,
  ReportsCreationFilterType,
} from "app/types";
import * as ReportsAPI from "../api/reports.api";

export type ReportsActionsTypes =
  | GetReportsListingSuccessType
  | GetReportsListingFailureType
  | GetReportsPageSizeType
  | GetReportsPageNumberType
  | CreateReportSuccessType
  | CreateReportFailureType
  | SetReportsListingParamsData
  | SetReportsCreationParamsData;

// Логика получения листинга отчетов
export type GetReportsListingSuccessType = {
  type: typeof GET_REPORTS_LISTING_SUCCESS;
  payload: {
    reportsListing: ReportsListingType;
  };
};

const getReportsListingSuccess = (reportsListing: ReportsListingType): GetReportsListingSuccessType => ({
  type: GET_REPORTS_LISTING_SUCCESS,
  payload: {
    reportsListing
  }
});

export type GetReportsListingFailureType = {
  type: typeof GET_REPORTS_LISTING_FAILURE;
  payload: {
    error: any;
  };
};

const getReportsListingFailure = (error: any): GetReportsListingFailureType => ({
  type: GET_REPORTS_LISTING_FAILURE,
  payload: {
    error
  }
});

//+пагинация отчетов
export type GetReportsPageSizeType = {
  type: typeof GET_REPORTS_LISTING_PAGE_SIZE;
  payload: {
    pageSize: number;
  };
};

export const getReportsPageSize = (pageSize: number): GetReportsPageSizeType => ({
  type: GET_REPORTS_LISTING_PAGE_SIZE,
  payload: {
    pageSize
  }
});

export type GetReportsPageNumberType = {
  type: typeof GET_REPORTS_LISTING_PAGE_NUMBER;
  payload: {
    pageNumber: number;
  };
};

export const getReportsPageNumber = (pageNumber: number): GetReportsPageNumberType => ({
  type: GET_REPORTS_LISTING_PAGE_NUMBER,
  payload: {
    pageNumber
  }
});

export type SetReportsListingParamsData = {
  type: typeof REPORTS_LISTING_PARAMS_DATA;
  payload: {
    reportsParams: ReportsListingFilterType | null;
  };
};

export const setReportsListingParamsData = (
  reportsParams: ReportsListingFilterType | null
): SetReportsListingParamsData => ({
  type: REPORTS_LISTING_PARAMS_DATA,
  payload: {
    reportsParams
  }
});

export const getReportsData = (params: null | ReportsListingFilterType = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ReportsActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ReportsAPI.getReports(params);

    dispatch(getReportsListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getReportsListingFailure(error.message));
  }
};

// Логика создания отчета
export type CreateReportSuccessType = {
  type: typeof CREATE_REPORT_SUCCESS;
  payload: {
    newReport: NewReportDataType;
    createReportStatus: number;
  };
};

const createReportSuccess = (newReport: NewReportDataType, createReportStatus: number): CreateReportSuccessType => ({
  type: CREATE_REPORT_SUCCESS,
  payload: {
    newReport,
    createReportStatus,
  }
});

export type CreateReportFailureType = {
  type: typeof CREATE_REPORT_FAILURE;
  payload: {
    status?: number,
    error?: any;
  };
};

export const createReportFailure = (status?: number, error?: any): CreateReportFailureType => ({
  type: CREATE_REPORT_FAILURE,
  payload: {
    status,
    error,
  }
});

export const createNewReport = (newReportData: CreateReportDataType):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ReportsActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ReportsAPI.createReport(newReportData);

    dispatch(createReportSuccess(response.data, response.status));
  } catch (error: any) {
    dispatch(createReportFailure(error.response.status, error.message));
  }
};

export type SetReportsCreationParamsData = {
  type: typeof REPORTS_CREATION_PARAMS_DATA;
  payload: {
    reportsCreationParams: ReportsCreationFilterType | null;
  };
};

export const setReportsCreationParams = (
  reportsCreationParams: ReportsCreationFilterType | null
): SetReportsCreationParamsData => ({
  type: REPORTS_CREATION_PARAMS_DATA,
  payload: {
    reportsCreationParams
  }
});
