import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  DatePicker
} from "antd";
import { employmentForm } from "../../mocks";
import {
  VacancyListType,
  SelectOptionsType,
  ParticipantsListType,
  OccupancyLegalFormListType,
} from "app/types";
import dayjs, { Dayjs } from "dayjs";
import * as DocumentAPI from "../../../../../../api/document.api";
import css from "../../CreateVacancy.module.css";

interface IParametersProps {
  vacancyData: VacancyListType | null;
  clearDateIfUnlimited: (isUnlimited: boolean) => void;
}

const { TextArea } = Input;

const Parameters: FC<IParametersProps> = ({
  vacancyData,
  clearDateIfUnlimited
}): JSX.Element => {
  const [occupancyLegalForm, setOccupancyLegalForm] = useState<OccupancyLegalFormListType[]>([]);
  const [participants, setParticipants] = useState<ParticipantsListType[]>([]);
  const [isUnlimitedCompletionDate, setUnlimitedCompletionDate] = useState<boolean>(false);

  useEffect(() => {
    clearDateIfUnlimited(isUnlimitedCompletionDate);
  }, [isUnlimitedCompletionDate]);

  useEffect(() => {
    DocumentAPI.getDocumentList("employment-form")
    .then((response) => setOccupancyLegalForm(response.data.results))
    .catch((err) => console.error("Get employment-form error:", err));

    DocumentAPI.getDocumentList("supplier-company")
    .then((response) => setParticipants(response.data.results))
    .catch((err) => console.error("Get participants error:", err));
  }, []);

  const occupancyLegalFormOptions: SelectOptionsType[] =
    occupancyLegalForm?.map(({ fields }: OccupancyLegalFormListType) => {
      return {
        key: fields?.uuid,
        value: fields?.uuid,
        label: fields?.employment_name,
      };
    }
  ) ?? [];

  const participantsOptions: SelectOptionsType[] =
    participants?.map(({ fields }: ParticipantsListType) => {
      return {
        key: fields?.uuid,
        value: fields?.uuid,
        label: fields?.name,
      };
    }
  ) ?? [];

  return (
    <div className={`${css.containerBlock} p-5 mb-3 bg-white border-bottom-gray`}>
      <h1 className={css.createVacancyBlockHeader}>
        Параметры вакансии
      </h1>
      <Form.Item
        className="m-0 mb-[20px]"
        id="vacancy_name"
        name="vacancy_name"
        label="Наименование позиции"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите наименование позиции"
          }
        ]}
        initialValue={vacancyData?.fields?.vacancy_name ?? null}
      >
        <Input
          className="w-full"
          size="large"
          allowClear
          placeholder="Введите наименование позиции"
        />
      </Form.Item>
      <Form.Item
        className="m-0 mb-[20px]"
        id="vacancy_description"
        name="vacancy_description"
        label="Описание позиции"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите описание позиции"
          }
        ]}
        initialValue={vacancyData?.fields?.vacancy_description ?? null}
      >
        <TextArea
          rows={2}
          size="large"
          className="text-base font-normal leading-5 text-gray-900"
          placeholder="Введите описание позиции"
        />
      </Form.Item>
      <div className="flex justify-between flex-wrap mb-[20px]">
        <Form.Item
          className="m-0 mb-[20px] basis-[32%]"
          id="occupancy_format"
          name="occupancy_format"
          label="Форма занятости"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите форму занятости",
            },
          ]}
          initialValue={vacancyData?.fields?.occupancy_format ?? null}
        >
          <Select
            className="w-full"
            placeholder="Выберите форму занятости"
            options={employmentForm}
            listHeight={196}
            showSearch
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          className="m-0 basis-[32%]"
          id="occupancy_legal_form"
          name="occupancy_legal_form"
          label="Форма найма"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите форму найма",
            },
          ]}
          initialValue={vacancyData?.fields?.occupancy_legal_form?.length
            ? vacancyData?.fields?.occupancy_legal_form?.[0]?.label
            : null
          }
        >
          <Select
            className="w-full"
            placeholder="Выберите форму найма"
            options={occupancyLegalFormOptions}
            listHeight={196}
            showSearch
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          className="m-0 basis-[32%]"
          id="division"
          name="division"
          label="Структурное подразделение"
          initialValue={vacancyData?.fields?.division?.length
            ? vacancyData?.fields?.division?.[0]?.label
            : null
          }
        >
          <Input
            className="w-full"
            size="large"
            allowClear
            placeholder="Введите структурное подразделение"
          />
        </Form.Item>
        <Form.Item
          className="m-0 basis-[32%]"
          id="financing_source"
          name="financing_source"
          label="Код источника финансирования"
          initialValue={vacancyData?.fields?.financing_source ?? null}
        >
          <Input
            className="w-full"
            size="large"
            allowClear
            placeholder="Введите код источника финансирования"
          />
        </Form.Item>
        <Form.Item
          className="m-0 basis-[32%]"
          id="external_id"
          name="external_id"
          label="ID потребности во внешней системе"
          initialValue={vacancyData?.fields?.external_id ?? null}
        >
          <Input
            className="w-full"
            size="large"
            allowClear
            placeholder="Введите идентификатор потребности"
          />
        </Form.Item>
        <div className="m-0 basis-[32%]" />
      </div>
      <div className="mb-[20px]">
        <Form.Item
          id="participants"
          name="participants"
          className="m-0"
          label="Перечень юр. лиц, кому доступна потребность из справочника юр. лиц"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите юр.лиц",
            },
          ]}
          initialValue={vacancyData?.fields?.participants?.length
            ? vacancyData?.fields?.participants?.[0]?.label
            : null
          }
        >
          <Select
            placeholder="Перечень юр.лиц"
            options={participantsOptions}
            listHeight={196}
            showSearch
            allowClear
            size="large"
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="m-0 mb-[8px] w-[32%]"
          id="end_date"
          name="end_date"
          label="Срок окончания работ"
          initialValue={vacancyData?.fields?.end_date ?? null}
        >
          <DatePicker
            size="large"
            className="w-full"
            format="DD.MM.YYYY"
            placeholder="Выберите дату"
            disabledDate={(current: Dayjs) => {
              return dayjs().isAfter(current);
            }}
            disabled={isUnlimitedCompletionDate}
          />
        </Form.Item>
        <Form.Item
          id="unlimited"
          name="unlimited"
          noStyle
        >
          <Checkbox onChange={(e) => setUnlimitedCompletionDate(e.target.checked)}>
            Бессрочный
          </Checkbox>
        </Form.Item>
      </div>
    </div>
  );
};

export default Parameters;
