import { UserListingType } from "app/types";

export const filterResponsible = (usersListing: UserListingType[]): UserListingType[] => {
  return usersListing.filter((user: UserListingType) => {
    return (user?.roles?.includes("assistant-role") ||
      user?.roles?.includes("accountant-role") ||
      user?.roles?.includes("supervisor-role") ||
      user?.roles?.includes("contact-center-role"));
  });
};
