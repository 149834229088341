import {
  GET_VACANCY_FAILURE,
  GET_VACANCY_LIST_FAILURE,
  GET_VACANCY_LIST_SUCCESS,
  GET_VACANCY_SUCCESS
} from "../../constants/actionTypes";
import { VacancyActionsTypes } from "../../actions/alpha/vacancy.actions";
import { VacancyListType } from "app/types";

type InitialVacancyType = {
  error: any;
  vacancyList: VacancyListType[];
  vacancyPage: number;
  vacancyTotalDocs: number;
  vacancyPageSize: number;
  isVacancyLoading: boolean,
  vacancyData: VacancyListType | null;
}

const initialState: InitialVacancyType = {
  error: null,
  vacancyList: [],
  vacancyPage: 1,
  vacancyTotalDocs: 5,
  vacancyPageSize: 5,
  isVacancyLoading: true,
  vacancyData: null,
};

const vacancy = (
  state: InitialVacancyType = initialState,
  action: VacancyActionsTypes
): InitialVacancyType => {
  switch (action.type) {
    case GET_VACANCY_LIST_SUCCESS:
      return {
        ...state,
        vacancyPage: action.payload.vacanciesList.page,
        vacancyList: action.payload.vacanciesList.results,
        vacancyPageSize: action.payload.vacanciesList.page_size,
        vacancyTotalDocs: action.payload.vacanciesList.total_docs,
        isVacancyLoading: false,
        error: null
      };
    case GET_VACANCY_LIST_FAILURE:
      return {
        ...state,
        vacancyPage: 0,
        vacancyList: [],
        vacancyPageSize: 0,
        vacancyTotalDocs: 0,
        isVacancyLoading: false,
        error: action.payload.error
      };
    case GET_VACANCY_SUCCESS:
      return {
        ...state,
        vacancyData: action.payload.vacancyData,
        error: null
      };
    case GET_VACANCY_FAILURE:
      return {
        ...state,
        vacancyData: null,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default vacancy;
