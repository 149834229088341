import {
  GET_CONTRACTS_LIST_SUCCESS,
  GET_CONTRACTS_LIST_FAILURE,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
} from "../../constants/actionTypes";
import { ContractsListType } from "app/types";
import { ContractsActionsTypes } from "../../actions/alpha/contracts.actions";

type InitialContractsCardType = {
  error: any;
  contractsList: ContractsListType[];
  contractsPage: number;
  contractsTotalDocs: number;
  contractsPageSize: number;
  isContractsLoading: boolean,
  contractData: ContractsListType | null;
}

const initialState: InitialContractsCardType = {
  error: null,
  contractsList: [],
  contractsPage: 1,
  contractsTotalDocs: 5,
  contractsPageSize: 5,
  isContractsLoading: true,
  contractData: null,
};

const contracts = (
  state: InitialContractsCardType = initialState,
  action: ContractsActionsTypes
): InitialContractsCardType => {
  switch (action.type) {
    case GET_CONTRACTS_LIST_SUCCESS:
      return {
        ...state,
        contractsPage: action.payload.contractsList.page,
        contractsList: action.payload.contractsList.results,
        contractsPageSize: action.payload.contractsList.page_size,
        contractsTotalDocs: action.payload.contractsList.total_docs,
        isContractsLoading: false,
        error: null
      };
    case GET_CONTRACTS_LIST_FAILURE:
      return {
        ...state,
        contractsPage: 0,
        contractsList: [],
        contractsPageSize: 0,
        contractsTotalDocs: 0,
        isContractsLoading: false,
        error: action.payload.error
      };
    case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        contractData: action.payload.contractData,
        error: null
      };
    case GET_CONTRACT_FAILURE:
      return {
        ...state,
        contractData: null,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default contracts;
