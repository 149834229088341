import React, { FC, JSX } from "react";
import { Tooltip } from "antd";

interface IShortFormatTooltipTextProps {
  text: string;
  endSlicePoint: number;
  startSlicePoint?: number;
}

export const ShortFormatTooltipText: FC<IShortFormatTooltipTextProps> = ({
  text,
  endSlicePoint,
  startSlicePoint = 0,
}): JSX.Element | string => {
  return text?.length > endSlicePoint ?
    (
      <Tooltip
        className="ml-1"
        title={text?.length > endSlicePoint ? text : ""}
      >
        {text?.slice(startSlicePoint, endSlicePoint)}
        ...
      </Tooltip>
    ) : text;
};
