import {
  CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT,
  EMPLOYEE_LISTING_FILTER_DATA,
  EMPLOYEE_LISTING_PARAMS_DATA,
  GET_DOCUMENT_EMPLOYEE_FAILURE,
  GET_DOCUMENT_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_LISTING_FAILURE,
  GET_EMPLOYEE_LISTING_SUCCESS,
  GET_EMPLOYEE_PAGE_NUMBER,
  GET_EMPLOYEE_PAGE_SIZE,
  GET_SCHEMA_EMPLOYEE_FAILURE,
  GET_SCHEMA_EMPLOYEE_SUCCESS,
  UPDATE_DOCUMENT_EMPLOYEE_FAILURE,
  UPDATE_DOCUMENT_EMPLOYEE_SUCCESS
} from "../constants/actionTypes";
import { EmployeeActionsTypes } from "../actions/employee.actions";
import {
  DocumentEmployeeType,
  EmployeeListingDataType,
  FilterEmployeeType,
  SchemaType
} from "app/types";

type InitialEmployeeCardType = {
  error: any;
  schemaEmployee: null | SchemaType;
  documentEmployee: null | DocumentEmployeeType;
  isDocumentEmployeeLoading: boolean;
  employeeListing: null | EmployeeListingDataType[];
  employeeListingLoading: boolean;
  employeesPageSize: number;
  employeesPage: number;
  totalDocs: number | null;
  clientsEmployeeFilter: FilterEmployeeType | null;
  clientsEmployeeParams: FilterEmployeeType | null;
};

const initialState: InitialEmployeeCardType = {
  error: null,
  schemaEmployee: null,
  documentEmployee: null,
  isDocumentEmployeeLoading: true,
  employeeListing: null,
  employeeListingLoading: true,
  employeesPageSize: 10,
  employeesPage: 1,
  totalDocs: null,
  clientsEmployeeFilter: null,
  clientsEmployeeParams: null,
};

const employee = (
  state: InitialEmployeeCardType = initialState,
  action: EmployeeActionsTypes
): InitialEmployeeCardType => {
  switch (action.type) {
    // Логика получения схемы сотрудника
    case GET_SCHEMA_EMPLOYEE_SUCCESS:
      return {
        ...state,
        schemaEmployee: action.payload.schemaEmployee,
        error: null,
      };
    case GET_SCHEMA_EMPLOYEE_FAILURE:
      return {
        ...state,
        schemaEmployee: null,
        error: action.payload.error,
      };
    // Логика получения данных схемы сотрудника по uuid документа
    case GET_DOCUMENT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        documentEmployee: action.payload.documentEmployee,
        isDocumentEmployeeLoading: false,
        error: null,
      };
    case GET_DOCUMENT_EMPLOYEE_FAILURE:
      return {
        ...state,
        documentEmployee: null,
        isDocumentEmployeeLoading: false,
        error: action.payload.error,
      };
    //Логика получения листинга сотрудников клиента
    case GET_EMPLOYEE_LISTING_SUCCESS:
      return {
        ...state,
        employeeListing: action.payload.employeeListing.results,
        totalDocs: action.payload.employeeListing.total_docs,
        employeeListingLoading: false,
        error: null,
      };
    case GET_EMPLOYEE_LISTING_FAILURE:
      return {
        ...state,
        employeeListing: null,
        employeeListingLoading: false,
        error: action.payload.error,
      };
    case GET_EMPLOYEE_PAGE_SIZE:
      return {
        ...state,
        employeesPageSize: action.payload.size,
      };
    case GET_EMPLOYEE_PAGE_NUMBER:
      return {
        ...state,
        employeesPage: action.payload.page,
      };
    case EMPLOYEE_LISTING_FILTER_DATA:
      return {
        ...state,
        clientsEmployeeFilter: action.payload.clientsEmployeeFilter,
      };
    case EMPLOYEE_LISTING_PARAMS_DATA:
      return {
        ...state,
        clientsEmployeeParams: action.payload.clientsEmployeeParams,
      };
    case CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT:
      return {
        ...state,
        employeeListingLoading: action.payload.loadingDefault,
      };
    //Логика обновления данных сотрудника
    case UPDATE_DOCUMENT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        documentEmployee: action.payload.documentEmployee,
        error: null,
      };
    case UPDATE_DOCUMENT_EMPLOYEE_FAILURE:
      return {
        ...state,
        documentEmployee: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default employee;
