import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./BankDetailsCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../../actions/document.actions";
import TagCustom from "../../../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument from "../../../ZeroClientProfileDocument/ZeroClientProfileDocument";
import { renderEmployeeItem } from "../EmployeeCard";

interface IBankDetailsCardProps {
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  bankDocUuid: string;
}

const BankDetailsCard: FC<IBankDetailsCardProps> = ({
  documentUuid,
  showImageViewer,
  bankDocUuid
}): JSX.Element | null => {
  const [isConfirmedBankDetail, setConfirmedBankDetail] = useState<boolean>(false);
  const [schemaName, setSchemaName] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const {
    schemaEmployeeProfile,
    documentEmployeeProfile
  } = useSelector((state: AppStateType) => state.employeeProfile);

  const {
    bankDoc,
    bankDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  const bankDetailsDocsLabels = {
    bank_document: schemaEmployeeProfile?.fields?.find(i => i.name === "bank_document"),
  };

  const bankDetailsInfoLabels = {
    bic: bankDocSchema?.fields?.find(i => i.name === "bic"), //БИК банка
    correspondent_account: bankDocSchema?.fields
      ?.find(i => i.name === "correspondent_account"), //Банковские реквизиты
    bank_account_number: bankDocSchema?.fields
      ?.find(i => i.name === "bank_account_number"), //Номер счета в банке
  };

  useEffect((): void => {
    if (bankDoc?.fields?.document_status === "APPROVED") {
      setConfirmedBankDetail(true);
    } else {
      setConfirmedBankDetail(false);
    }
  }, [bankDoc]);

  const renderConfirmationDetails = (): JSX.Element => {
    return isConfirmedBankDetail ? (
      <TagCustom color="success" text="Согласовано"/>
    ) : (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderBankDetailCause = (cause?: string): JSX.Element => {
    return (
      <div className={`${css.cause} mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {cause}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (schemaName) {
      dispatch(getEmployeeDocumentData(schemaName, documentUuid));
    }
  }, [dispatch, schemaName]);

  const openImageViewer = (text: string): void => {
    setSchemaName(() => {
      dispatch(getDocumentSchema(text));
      return text;
    });
  };

  return (
    <div>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          {bankDetailsDocsLabels?.bank_document?.label}
        </h1>
        {bankDoc?.fields ? (
          <>
            <ul>
              {!!bankDoc?.fields?.bic && renderEmployeeItem(
                bankDetailsInfoLabels?.bic?.label,
                bankDoc?.fields?.bic
              )}
              {!!bankDoc?.fields?.bank_account_number && renderEmployeeItem(
                bankDetailsInfoLabels?.bank_account_number?.label,
                bankDoc?.fields?.bank_account_number
               )}
              {!!bankDoc?.fields?.correspondent_account && renderEmployeeItem(
                bankDetailsInfoLabels?.correspondent_account?.label,
                bankDoc?.fields?.correspondent_account
              )}
            </ul>
            <span className="mb-2">
              {!!documentEmployeeProfile?.fields?.bank_document?.length && (
                <div className="flex items-center mb-5">
                  <ButtonCustom
                    id="checkEmployeeBank"
                    className="w-36 mr-2"
                    type="primary"
                    ghost
                    text="Проверить файлы"
                    onClick={() => {
                      showImageViewer("bank-schema", bankDocUuid);
                      openImageViewer("bank-schema");
                    }
                    }
                  />
                  {!!bankDoc?.fields?.document_status && renderConfirmationDetails()}
                </div>
              )}
              {!isConfirmedBankDetail && (
                !bankDoc?.fields?.decline_reason ? null : renderBankDetailCause(bankDoc?.fields?.decline_reason)
              )}
            </span>
          </>
        ) : (
          <ZeroClientProfileDocument variant="Банковские реквизиты сотрудника" />
        )}
      </div>
    </div>
  );
};

export default BankDetailsCard;
