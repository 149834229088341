import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  Form,
  Input,
  Select,
  FormProps,
  DatePicker,
  FormInstance,
  notification,
} from "antd";
import {
  useNavigate,
  useLocation,
  NavigateFunction,
} from "react-router-dom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  ContractorsListType,
  ContractsListType,
  ParticipantsListType,
  SelectOptionsType
} from "app/types";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import css from "./CreateContract.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { updateDocument, createNewDocument, getDocumentList } from "../../../../api/document.api";
import { getContractData, getContractFailure } from "../../../../actions/alpha/contracts.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import dayjs from "dayjs";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";

interface ICreateContractProps {
}

const CreateContract: FC<ICreateContractProps> = (): JSX.Element => {
  const [isContractValid, setContractValid] = useState<boolean>(false);
  const [isFormHasValue, setFormHasValue] = useState<boolean>(false);
  const [participants, setParticipants] = useState<ParticipantsListType[]>([]);
  const [clientCompanies, setClientCompanies] = useState<ContractorsListType[]>([]);
  const [createDocStatus, setCreateDocStatus] = useState<number>(0);
  const [contractUuid, setContractUuid] = useState<string>("");

  const navigate: NavigateFunction = useNavigate();

  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  const dispatch = useDispatch<AppDispatch>();

  const path: string = useLocation().pathname;

  useEffect(() => {
    //Получаем uuid договора
    const contractUuid: string = path?.split("/")?.slice(-1)?.toString();

    setContractUuid(contractUuid);
    dispatch(getContractData(contractUuid));
  }, [path]);

  useEffect((): void => {
    createAlphaDocStatus(
      createDocStatus,
      createContractSuccess,
      createContractError,
    );
  }, [createDocStatus]);

  useEffect((): void => {
    dispatch(getDocumentSchema("contract-schema"));
  }, []);

  const contractData: ContractsListType | null =
    useSelector((state: AppStateType) => state.contracts.contractData) ?? null;

  const onCreatContractFinish: FormProps<FormInstance>["onFinish"] = async (values: FormInstance): Promise<void> => {
    try {
      if (contractUuid) {
        const { status } = await updateDocument("contract-schema", contractUuid, values);
        setCreateDocStatus(status);
        dispatch(getContractFailure());
      } else {
        const { status } = await createNewDocument("contract-schema", values);
        setCreateDocStatus(status);
        dispatch(getContractFailure());
      }
    } catch (error: any) {
      setCreateDocStatus(error.response.status);
      console.error("Create contract error:", error.response.data.message);
    }
  };

  const onCloseForm = (): void => {
    form.resetFields();

    dispatch(getContractFailure());

    isContractCard
      ? navigate(`/alpha/contract/${contractUuid}`)
      : navigate("/alpha/contracts");
  };

  const createContractSuccess = (): void => {
    notification.success({
      message: "Договор успешно создан",
    });

    setCreateDocStatus(0);

    isContractCard
      ? navigate(`/alpha/contract/${contractUuid}`)
      : navigate("/alpha/contracts");
  };

  const createContractError = (): void => {
    notification.error({
      message: "Ошибка создания договора",
    });

    setCreateDocStatus(0);
  };

  useEffect((): void => {
    form
    .validateFields({ validateOnly: true })
    .then(() => setContractValid(true))
    .catch(() => setContractValid(false));

    if (values) {
      Object.values(values)?.some(value => !!value)
        ? setFormHasValue(true)
        : setFormHasValue(false);
    }
  }, [form, values]);

  useEffect(() => {
    getDocumentList("client-company")
    .then((response) => setClientCompanies(response.data.results))
    .catch((err) => console.error("Get client-company error:", err));

    getDocumentList("supplier-company")
    .then((response) => setParticipants(response.data.results))
    .catch((err) => console.error("Get participants error:", err));
  }, []);

  const clientCompaniesOptions: SelectOptionsType[] = clientCompanies?.map(({ fields }: ContractorsListType) => {
    return {
      key: fields?.uuid,
      value: fields?.uuid,
      label: fields?.name,
    };
  }) ?? [];

  const participantsOptions: SelectOptionsType[] =
    participants?.map(({ fields }: ParticipantsListType) => {
        return {
          key: fields?.uuid,
          value: fields?.uuid,
          label: fields?.name,
        };
      }
    ) ?? [];

  const {
    isContractCard,
    isCreateContract,
  } = useLocation()?.state ?? {};

  return (
    contractData || isCreateContract ? (
      <div className={css.container}>
        <h1 className={css.createContractHeader}>
          Создание договора
        </h1>
        <div className={css.createContractForm}>
          <Form
            name="create_contract_form"
            form={form}
            layout="vertical"
            onFinish={onCreatContractFinish}
          >
            <div className={css.createContractFormBlock}>
              <h2 className={css.createContractBlockHeader}>
                Основные параметры
              </h2>
              <div className="flex justify-between mb-5">
                <Form.Item
                  className="w-[32%] mr-5 mb-0"
                  id="contract_number"
                  name="contract_number"
                  label="Номер договора"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите номер договора"
                    }
                  ]}
                  initialValue={contractData?.fields?.contract_number ?? null}
                >
                  <Input
                    allowClear
                    className="w-full"
                    size="large"
                    placeholder="Введите номер договора"
                  />
                </Form.Item>
                <Form.Item
                  className="w-[32%] mr-5 mb-0"
                  id="contractual_date"
                  name="contractual_date"
                  label="Дата договора"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, выберите дату"
                    }
                  ]}
                  initialValue={contractData?.fields?.contractual_date
                    ? dayjs(contractData?.fields?.contractual_date)
                    : null}
                >
                  <DatePicker
                    className="w-full"
                    size="large"
                    value={null}
                    format="DD.MM.YYYY"
                    allowClear
                    inputReadOnly={false}
                    placeholder="Выберите дату"
                  />
                </Form.Item>
                <Form.Item
                  className="w-[32%] mb-0"
                  id="duration"
                  name="duration"
                  label="Срок действия договора"
                  initialValue={contractData?.fields?.duration
                    ? dayjs(contractData?.fields?.duration)
                    : null}
                >
                  <DatePicker
                    className="w-full"
                    size="large"
                    value={null}
                    format="DD.MM.YYYY"
                    allowClear
                    inputReadOnly={false}
                    placeholder="Выберите дату"
                  />
                </Form.Item>
              </div>
              <div className="flex mb-5">
                <Form.Item
                  className="w-[32%] mr-[34px] mb-0"
                  id="contractor"
                  name="contractor"
                  label="Заказчик"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, выберите заказчика"
                    }
                  ]}
                  initialValue={contractData?.fields?.contractor?.[0]?.label ?? null}
                >
                  <Select
                    id="operatingMode"
                    placeholder="Выберите заказчика"
                    options={clientCompaniesOptions}
                    listHeight={196}
                    showSearch
                    allowClear
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  className="w-[32%] mr-5 mb-0"
                  id="contractor_owner"
                  name="contractor_owner"
                  label="Владелец договора на стороне заказчика"
                  initialValue={contractData?.fields?.contractor_owner ?? null}
                >
                  <Select
                    id="operatingMode"
                    placeholder="Выберите пользователя"
                    listHeight={196}
                    showSearch
                    allowClear
                    size="large"
                  />
                </Form.Item>
              </div>
              <div className="flex mb-5">
                <Form.Item
                  className="w-[32%] mr-[34px] mb-0"
                  id="contractee"
                  name="contractee"
                  label="Исполнитель"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, выберите исполнителя"
                    }
                  ]}
                  initialValue={contractData?.fields?.contractee?.[0]?.label ?? null}
                >
                  <Select
                    id="operatingMode"
                    placeholder="Выберите исполнителя"
                    options={participantsOptions}
                    listHeight={196}
                    showSearch
                    allowClear
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  className="w-[32%] mr-5 mb-0"
                  id="contractee_owner"
                  name="contractee_owner"
                  label="Владелец договора на стороне исполнителя"
                  initialValue={contractData?.fields?.contractee_owner ?? null}
                >
                  <Select
                    id="operatingMode"
                    placeholder="Выберите исполнителя"
                    listHeight={196}
                    showSearch
                    allowClear
                    size="large"
                  />
                </Form.Item>
              </div>
              <Form.Item
                className="w-[32%] mr-5 mb-0"
                id="contract_sum"
                name="contract_sum"
                label="Сумма договора"
                initialValue={contractData?.fields?.contract_sum ?? null}
              >
                <Input
                  className="w-full"
                  id="contract_sum"
                  addonAfter="₽"
                  size="large"
                  placeholder="Введите сумму договора"
                />
              </Form.Item>
            </div>
            <div className={css.createContractFormBlock}>
              <div className={`${css.createContractActionBlock} text-right`}>
                <ButtonCustom
                  className="mr-2"
                  type="default"
                  text="Отменить"
                  size="large"
                  ghost
                  onClick={onCloseForm}
                />
                {contractData?.fields?.contract_status !== "DRAFT" && (
                  <ButtonCustom
                    className="mr-2"
                    type="default"
                    text="Сохранить как черновик"
                    size="large"
                    ghost
                    onClick={onCloseForm}
                    disabled={!isFormHasValue}
                  />
                )}
                <ButtonCustom
                  className="mr-2"
                  type="primary"
                  text={contractData?.fields?.uuid ? "Подтвердить и сохранить" : "Создать договор"}
                  size="large"
                  htmlType="submit"
                  disabled={!isContractValid}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom />
      </div>
    )
  );
};

export default CreateContract;
