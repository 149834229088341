import { Dispatch, SetStateAction, useEffect } from "react";
import { FilterTasksType } from "app/types";

export interface UseInfiniteScrollHookParams {
  totalDocs: number;
  paramsData: FilterTasksType | null;
  pagePerScroll: number;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  setLoadingData: Dispatch<SetStateAction<boolean>>;
}

export const useInfiniteScroll = ({
  totalDocs,
  paramsData,
  pagePerScroll,
  setParamsData,
  setLoadingData,
}: UseInfiniteScrollHookParams): void => {
  useEffect(() => {
    const handleScroll = (): void => {
      if (!paramsData) return;

      const offsetPositionY: number = 300;
      const totalHeight: number = document.documentElement.scrollHeight;
      const windowHeight: number = window.innerHeight;
      const currentScroll: number = window.scrollY;
      const hasScrollToBottom: boolean = windowHeight + currentScroll >= (totalHeight - offsetPositionY);
      const isAllPages: boolean = (paramsData?.page_size as number) >= pagePerScroll;
      const isLastPage: boolean = (paramsData?.page_size as number) >= totalDocs;

      if (isAllPages && hasScrollToBottom && !isLastPage) {
        const newPageSize: number = pagePerScroll + (paramsData?.page_size as number);

        setLoadingData(true);
        setParamsData((prevParams: FilterTasksType | null) => ({ ...prevParams, page_size: newPageSize }));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return (): void => {
      window.removeEventListener("scroll", handleScroll);
      setLoadingData(false);
    };
  }, [paramsData?.page_size, pagePerScroll]);
};
