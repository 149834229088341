import { message } from "antd";
import { downLoadFile } from "../api/document.api";

export const downloadFile = async (filename: string, url: string): Promise<void> => {

  try {
    const response = await downLoadFile(url);

    if (response.status !== 200) {
      throw new Error("Файл недоступен на сайте");
    }

    const downloadUrl: string = window.URL.createObjectURL(response.data);
    const element: HTMLAnchorElement = document.createElement("a");
    element.href = downloadUrl;
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    message.error("Ошибка при скачивании файла");
  }
};

export const downloadUrl = (uuid: string): string => {
  return `/api/mdm/storage/v1/${uuid}`;
};
