import { AbstractDocType, DocumentClientProfileType } from "app/types";

export enum GeneralDirector {
  justOne = 1
}

export const onlyOneDirector = (documentClientProfile: DocumentClientProfileType | null): boolean => {

  if (documentClientProfile) {
    const numberOfDirectors: number = documentClientProfile?.fields?.passport_document?.length;
    const passportDocument: AbstractDocType = documentClientProfile?.fields?.passport_document?.[0];

    if (numberOfDirectors > GeneralDirector.justOne) {
      return false;
    }

    return !(numberOfDirectors === GeneralDirector.justOne &&
      passportDocument?.first_name === null ||
      passportDocument?.first_name === "" ||
      passportDocument?.second_name === null ||
      passportDocument?.second_name === "" ||
      passportDocument?.last_name === null ||
      passportDocument?.last_name === "");
  }

  return true;
};
