import React, { FC, JSX, useEffect } from "react";
import { Card, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import {
  DocumentClientType,
  ClientTechInfoDataType,
  TaskCardTechInfoDataType,
  DocumentClientFieldsType,
} from "app/types";
import ReactJson from "react-json-view";

interface ClientCardTechInfoTypeType {
  key: React.ReactNode;
  name: string;
  keyName: string;
  value: string | number | boolean | DocumentClientFieldsType | JSX.Element;
  children?: ClientCardTechInfoTypeType[] | void | null;
}

const techInfoColumns: ColumnsType<ClientCardTechInfoTypeType> = [
  {
    title: "Наименование",
    dataIndex: "name",
    key: "name",
    width: "33%"
  },
  {
    title: "Ключ",
    dataIndex: "keyName",
    key: "keyName",
    width: "33%"
  },
  {
    title: "Значение",
    dataIndex: "value",
    key: "value",
    width: "33%"
  }
];

interface IClientCardTechInfoProps {
}

const ClientCardTechInfo: FC<IClientCardTechInfoProps> = (): JSX.Element => {

  const {
    documentClient,
    schemaClient,
  } = useSelector((state: AppStateType) => state.client);

  const translateFieldName = (fieldName: string): string => {
    switch (fieldName) {
      case "schema_name":
        return "Наименование схемы";
      case "schema_version":
        return "Версия схемы";
      case "created_by":
        return "Автор схемы";
      case "created_at":
        return "Дата создания";
      case "updated_at":
        return "Дата обновления";
      case "deleted":
        return "Документ удален";
      case "fields":
        return "Поля документа";
      case "id":
        return "Идентификатор";
      default:
        return fieldName;
    }
  };

  const filteredClientSchemaName = (fieldName: string): string => {
    return schemaClient?.fields?.filter(({ name }: { name: string }) => name === fieldName)?.[0]?.label ?? fieldName;
  };

  const techInfoChildrenData = (key: string, value: string | number | boolean | DocumentClientFieldsType) => {
    return key === "fields" ? (
      Object.entries(value).map(([key, value]: TaskCardTechInfoDataType) => {
        return {
          key: key,
          name: filteredClientSchemaName(key),
          keyName: key,
          value: typeof value === "object" && value !== null
            ? <ReactJson theme="grayscale:inverted" src={value} />
            : value
        };
      })
    ) : null;
  };

  const sortedTechInfoData: ClientTechInfoDataType[] = Object.entries(documentClient as DocumentClientType)
  ?.sort((
    prevElem: ClientTechInfoDataType,
    currElem: ClientTechInfoDataType
  ) => {
    // Поместим элементы с ключом 'fields' в конец массива
    if (prevElem[0] === "fields") {
      return 1;
    }
    if (currElem[0] === "fields") {
      return -1;
    }
    return 0;
  });

  const clientCardTechInfoData: ClientCardTechInfoTypeType[] = sortedTechInfoData?.map((
    [key, value] : ClientTechInfoDataType
  ) => {
    return {
      key: key,
      name: translateFieldName(key),
      keyName: key,
      value: value,
      children: techInfoChildrenData(key, value)
    };
  });

  return (
    <div>
      <Card
        className="h-full"
        title="Техническая информация"
      >
        <div>
          <Table
            columns={techInfoColumns}
            dataSource={clientCardTechInfoData}
            pagination={false}
          />
        </div>
      </Card>
    </div>
  );
};

export default ClientCardTechInfo;
