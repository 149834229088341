export type DataType = {
  key: number,
  opf: string,
  tax_system: string,
  employess: boolean,
  agency_agreements: boolean,
  patent: boolean,
  appointments_number: string,
}

export const dataTable: DataType[] = [
  {
    key: 1,
    opf: "ИП",
    tax_system: "УСН Доходы",
    employess: true,
    agency_agreements: true,
    patent: true,
    appointments_number: "15"
  },
  {
    key: 2,
    opf: "ИП",
    tax_system: "УСН Доходы минус расходы",
    employess: false,
    agency_agreements: false,
    patent: false,
    appointments_number: "12"
  },
  {
    key: 3,
    opf: "ООО",
    tax_system: "ОСНО",
    employess: true,
    agency_agreements: false,
    patent: true,
    appointments_number: "15"
  },
  {
    key: 4,
    opf: "OOO",
    tax_system: "УСН Доходы",
    employess: false,
    agency_agreements: true,
    patent: false,
    appointments_number: "15"
  }
];
