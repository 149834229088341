import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
} from "react";
import { FormInstance } from "antd";
import { taxSystem } from "../../../../utils/catalog";
import FiltersComponent from "../FiltersComponent";

interface ITaxSystemFiltersProps {
  form: FormInstance,
  isModalOpen: boolean,
  selectedTaxSystem: string[],
  setSelectedTaxSystem: Dispatch<SetStateAction<string[]>>,
}

const TaxSystemFilters: FC<ITaxSystemFiltersProps> = ({
  form,
  isModalOpen,
  selectedTaxSystem,
  setSelectedTaxSystem
}): JSX.Element => {
  return (
    <FiltersComponent
      key="taxTask"
      form={form}
      isModalOpen={isModalOpen}
      selectedItems={selectedTaxSystem}
      setSelectedItems={setSelectedTaxSystem}
      taskTypes={taxSystem}
      formFieldName="taxTask"
      headerText="По системе налогообложения клиента"
    />
  );
};

export default TaxSystemFilters;