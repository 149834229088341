import { JSX } from "react";
import dayjs from "dayjs";
import TagCustom from "../components/ui-kit/TagCustom/TagCustom";
import { TagCustomColorType } from "app/types";
import { getDaysWord, getVerbForm } from "./deadlineItems";

enum Days {
  one = 1,
  two = 2,
  thirty = 30,
  sixty = 60
}

const DeadLine = (
  data: string | undefined,
  isReportPage?: boolean,
  isTaskData?: boolean
): string | null | JSX.Element => {
  if (data) {
    const targetDate: Date = new Date(data);
    targetDate.setHours(23, 59, 59);
    
    const now: Date = new Date();

    if (targetDate > now) {
      const delta: number = targetDate.getTime() - now.getTime();
      const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
      const remainingDays: number = Math.round(delta / millisecondsPerDay);
      const correctDateColor: number = parseFloat((delta / millisecondsPerDay).toFixed(1));
      const periodSuccess: number = isTaskData ? Days.two : Days.sixty;
      const periodWarningFrom: number = isTaskData ? Days.one : Days.thirty;
      const periodWarningTo: number = isTaskData ? Days.two : Days.sixty;

      const daysWord: string = getDaysWord(remainingDays);
      const { verbForm, adjustedDays } = getVerbForm(remainingDays);

      let tagCustomColor: TagCustomColorType;
      if (correctDateColor > periodSuccess) {
        tagCustomColor = "customSuccess";
      } else if (correctDateColor > periodWarningFrom && correctDateColor <= periodWarningTo) {
        tagCustomColor = "customWarning";
      } else {
        tagCustomColor = "customError";
      }

      const remainingDaysSentence: JSX.Element =
        <TagCustom
          className="ml-3 px-1.5 py-0"
          color={tagCustomColor}
          text={`${verbForm} ${adjustedDays} ${daysWord}`}
        />;

      return isReportPage
        ? remainingDaysSentence
        : (
          <span style={{ display: "flex", alignItems: "center" }}>
            до {dayjs(data)?.format("DD.MM.YYYY")} {remainingDaysSentence}
          </span>
        );
    }
    else {
      const overdue: JSX.Element =
        <TagCustom
          className="ml-3 px-1.5 py-0"
          color="customError"
          text="просрочено"
        />;
      
      return isReportPage
        ? overdue
        : (
          <span style={{ display: "flex", alignItems: "center" }}>
            до {dayjs(data)?.format("DD.MM.YYYY")} {overdue}
          </span>
        );
    }
  }

  return null;
};

export default DeadLine;
