import React, { FC, JSX } from "react";
import css from "./ZeroClients.module.css";
import { ReactComponent as ClientsZero } from "../../../../../assets/icons/clients_zero.svg";

interface IZeroClientsProps {
}

const ZeroClients: FC<IZeroClientsProps> = (): JSX.Element => {
  return (
    <div className={css.container}>
      <div className={css.label}>
        <div className={css.icon}><ClientsZero /></div>
        <div>
          <div className={css.textHeader}>Клиентов на обслуживании нет</div>
        </div>
      </div>
    </div>
  );
};

export default ZeroClients;
