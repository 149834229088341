import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "../DocumentsCard.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../../../reducers/mainReducer";
import { UpdateDocumentType } from "app/types";
import TagCustom from "../../../../../../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument
  from "../../../../../../../ClientProfileCard/ZeroClientProfileDocument/ZeroClientProfileDocument";

interface IMigrationDocProps {
  showImageViewer: (docType: string, uuid: string) => void;
  docType: string;
  migrationDocUuid: string;
}

const MigrationDoc: FC<IMigrationDocProps> = ({
  showImageViewer,
  docType,
  migrationDocUuid
}): JSX.Element => {
  const [isMigrationConfirmed, setIsMigrationConfirmed] = useState<boolean>(false);
  const migrationDoc: UpdateDocumentType | null = useSelector((state: AppStateType) => state.document.migrationDoc);

  useEffect(() => {
    if (migrationDoc?.fields?.document_status === "APPROVED") {
      setIsMigrationConfirmed(true);
    } else {
      setIsMigrationConfirmed(false);
    }
  }, [migrationDoc]);

  const renderAgreed = (): JSX.Element => {
    return (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderMigrationDeclineReason = (): JSX.Element => {
    return (
      <div className={`${css.cause} mt-5 mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {migrationDoc?.fields?.decline_reason}
        </div>
      </div>
    );
  };

  return (
    <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
      <h1 className="header-text mb-5">
        {docType === "migration-card-schema" ? "Миграционная карта" : "Патент"}
      </h1>
      {migrationDoc?.fields ? (
        <span className="checkFiles">
          <div className="flex items-center">
            <ButtonCustom
              className="p-[7px 12px] mr-2"
              type="primary"
              ghost
              text="Посмотреть файлы"
              onClick={() => showImageViewer(docType, migrationDocUuid)}
            />
            {!!migrationDoc?.fields?.document_status && (isMigrationConfirmed ? renderAgreed() : renderNotAgreed())}
          </div>
          {!!migrationDoc?.fields?.decline_reason && (!isMigrationConfirmed && renderMigrationDeclineReason())}
        </span>
      ) : (
        <ZeroClientProfileDocument variant="Миграционная карта сотрудника" />
      )}
    </div>
  );
};

export default MigrationDoc;