import axios from "../../utils/axios";
import { AlphaListParamsType } from "app/types";

const API_RESUME: string | undefined = process.env.REACT_APP_API_TASKS_URL;

//ручка получения листинга резюме
export const getResumes = async (params?: AlphaListParamsType | null): Promise<any> => {
  return axios.GET(API_RESUME, "api/mdm/documents/v1/d/cv-schema", params);
};

//ручка получения резюме
export const getResume = async (resumeUuid: string): Promise<any> => {
  return axios.GET(API_RESUME, `api/mdm/documents/v1/d/cv-schema/uuid/${resumeUuid}`);
};
