import { TasksListingDataType } from "app/types";
import React, { JSX } from "react";
import css from "../components/ClientsList/ClientCard/ClientCardItem/Tasks/Tasks.module.css";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import locale from "dayjs/locale/ru";

dayjs.extend(relativeTime);
dayjs.locale(locale);

export const RenderTaskCardDeadline = (
  text: string | null,
  record: TasksListingDataType,
  clientUuid?: string,
): JSX.Element | null => {

  if (text === null) {
    return null;
  }

  const [year, month, day]: string[] = text.slice(0, 10).split("-");
  const deadline: string = `${day}.${month}.${year}`;

  const dynamicDeadline = (date: string, record: TasksListingDataType): string | null => {
    const dateEnd: dayjs.Dayjs = dayjs(date);
    const dateToday: dayjs.Dayjs = dayjs();
    const daysDiff: string = dateEnd.diff(dateToday, "day").toFixed(0);
    const hoursDiff: string = (dateEnd.diff(dateToday, "hour") % 24).toFixed(0);

    if (!!date && (daysDiff?.startsWith("-") || hoursDiff?.startsWith("-"))) {
      const deadline: dayjs.Dayjs = dayjs(record?.fields?.deadline);
      const updateDate: dayjs.Dayjs = dayjs(record?.updated_at);
      const isFinTask: boolean = record?.fields?.status_task === "FIN_TRUE"
        || record?.fields?.status_task === "FIN_FALSE";
      const isOverdueFinTask: boolean = deadline.isBefore(updateDate) || deadline.isSame(updateDate);
      const isOverdueNonFinTask: boolean = deadline.isBefore(dateToday) || deadline.isSame(dateToday);

      if (isFinTask && isOverdueFinTask) {
        return "Срок истек";
      } else if (isOverdueNonFinTask) {
        return "Срок истек";
      }
    }

    return `${daysDiff} д. ${hoursDiff} ч.`;
  };

  const deadlineColor = (deadline: string | null): string | null => {
    const firstPart: string | undefined = deadline?.split(" ")?.[0];
    const isNumber: boolean = /^\d+$/.test(firstPart as string);
    const numericValue: number = isNumber ? parseInt(firstPart as string, 10) : 0;
    
    const twoDaysLeftOrMore: boolean = numericValue >= 2;
    const oneDayLeft: boolean = numericValue >= 1;
    const lastDayLeft: boolean = numericValue === 0;
    const daysExpired: boolean = deadline === "Срок истек";

    if (twoDaysLeftOrMore) return css.deadlineHasTime;
    if (oneDayLeft) return css.deadlineWarning;
    if (lastDayLeft) return css.deadlineExpired;
    if (daysExpired) return css.deadlineExpired;

    return null;
  };

  const showDynamicDeadline = (record: TasksListingDataType): boolean => {
    const deadline: dayjs.Dayjs = dayjs(record?.fields?.deadline);
    const updateDate: dayjs.Dayjs = dayjs(record?.updated_at);

    if (record?.fields?.status_task === "FIN_TRUE" || record?.fields?.status_task === "FIN_FALSE") {
      return (deadline.isBefore(updateDate) || deadline.isSame(updateDate));
    } else {
      return true;
    }
  };

  return (
    <Link
      className={`${css.tableCell} flex flex-col`}
      to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
    >
      {showDynamicDeadline(record) && (
        <span className={`${deadlineColor(dynamicDeadline(text, record))} block`}>
          {dynamicDeadline(text, record)}
        </span>
      )}
      <span className="text-black">до {deadline}</span>
    </Link>
  );
};
