import React, { ChangeEvent, FC, JSX, SetStateAction } from "react";
import { Form, Input, Modal, Select } from "antd";
import css from "./ModalAction.module.css";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import ZeroSearch from "../../../../ClientsList/ZeroSearch/ZeroSearch";
import { roleResolver } from "../../../../../utils/roleResolver";
import { JWTPayload } from "jose";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { ApplicationListType, ApplicationType } from "app/types";
import { AppDispatch } from "../../../../../store/store";
import { sendApplicationAction } from "../../../../../actions/alpha/application.actions";

interface IModalActionProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

interface IApplicationActionProps {
  key: string,
  value: string,
  label: string,
}

interface IFormProps {
  alphaApplicationAction: string,
  textArea: string,
}

const ModalAction: FC<IModalActionProps> = ({
  isModalOpen,
  setIsModalOpen
}): JSX.Element => {
  const [form] = Form.useForm<IFormProps>();
  const { TextArea } = Input;
  const values: IFormProps = Form.useWatch([], form);

  const dispatch = useDispatch<AppDispatch>();

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const applicationData: ApplicationListType | null
    = useSelector((state: AppStateType) => state.application.applicationData);

  const isAlphaAdmin: boolean = roleResolver(decodedToken).isAlphaAdmin;
  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;

  const selectAction: string | undefined = values?.alphaApplicationAction;
  const commentData: number = values?.textArea?.length ?? 0;

  const modalHeader = (text: string): JSX.Element => {
    return (
      <div className={css.modalHeader}>{text}</div>
    );
  };

  const actionValue = (text: string): string => {
    switch (text) {
      case "Согласовать":
        return "APPROVE";
      case "Отклонить":
        return "DECLINE";
      case "Отозвать заявку":
        return "DEACTIVE";
      default:
        return "";
    }
  };

  //TODO ручка на бэке поменяется и возможно придется пересмотреть логику в modalOk
  const modalOk = (): void => {
    setIsModalOpen(false);

    const { request_status, request_comment, ...applicationFields } = applicationData?.fields as ApplicationType;
    const applicationComment: string = applicationData?.fields?.request_comment ?? "";
    const commentValue: string | undefined = values?.textArea;
    const commentData: string | undefined = commentValue || applicationComment;
    const actionData: string | undefined =
      selectAction ? actionValue(selectAction) : "";

    const applicationDecision: any = {
      request_status: actionData,
      request_comment: commentData,
      ...applicationFields
    };

    dispatch(sendApplicationAction(applicationData?.fields?.uuid, applicationDecision));

    form.resetFields();
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const modalFooter: JSX.Element = (
    <div className="mt-5">
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        className={css.buttonOk}
        disabled={!selectAction}
        onClick={modalOk}
      />
    </div>
  );

  const applicationAction = (): IApplicationActionProps[] => {
    if (isAlphaAdmin) {
      return [
        {
          key: "1",
          label: "Согласовать",
          value: "Согласовать",
        },
        {
          key: "2",
          label: "Отклонить",
          value: "Отклонить",
        }
      ];
    }

    if (isAlphaContractor) {
      return [
        {
          key: "3",
          label: "Отозвать заявку",
          value: "Отозвать заявку",
        }
      ];
    }

    return [];
  };

  const handleSelectChangeAction = (value: string): void => {
    form.setFieldValue("alphaApplicationAction", value);
    form.resetFields(["textArea"]);
  };

  const handleSelectClear = (): void => {
    form.resetFields();
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;

    form.setFieldValue("textArea", value);
  };

  return (
    <Form form={form}>
      <Modal
        width={580}
        title={modalHeader("Действие по заявке")}
        open={isModalOpen}
        onCancel={modalCancel}
        footer={modalFooter}
      >
        <Form.Item
          name="alphaApplicationAction"
          className="mt-5 mb-0"
        >
          <Select
            id="alphaApplicationAction"
            placeholder="Выберите действие"
            options={applicationAction()}
            showSearch
            allowClear
            size="large"
            className="mb-5"
            value={selectAction}
            notFoundContent={<ZeroSearch dataFilter/>}
            onChange={handleSelectChangeAction}
            onClear={handleSelectClear}
          />
          {selectAction && (
            <>
              <div className={css.commentLabel}>
                Вы также можете оставить комментарий
              </div>
              <Form.Item
                name="textArea"
                className="mb-0"
              >
                <TextArea
                  id="textArea"
                  rows={4}
                  maxLength={2000}
                  className="text-base font-normal leading-5 text-gray-900"
                  placeholder="Ваш комментарий"
                  onChange={handleTextAreaChange}
                />
                <div className="text-xs font-normal leading-100 text-gray-700 text-right">
                  {commentData}/2000 символов
                </div>
              </Form.Item>
            </>
          )}
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default ModalAction;