import React, { FC, JSX, useEffect, useState } from "react";
import {
  Radio,
  Table,
  Modal,
  Button,
  Select,
  RadioChangeEvent,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { AbstractDocType, BankStatementDataType, DocumentClientProfileType } from "app/types";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./ClientProfileCardTransactionCheckModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { getBankStatementData } from "../../../actions/document.actions";
import { AppStateType } from "../../../reducers/mainReducer";
import { getUnAgreedDocumentType } from "../../../actions/clientProfile.actions";
import { ReactComponent as SortIcons } from "../../../assets/icons/sort_icons.svg";
import { ReactComponent as WarningIcon } from "../../../assets/icons/blue-warning.svg";
import dayjs from "dayjs";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { roleResolver } from "../../../utils/roleResolver";
import { JWTPayload } from "jose";

interface IClientProfileCardImageViewerProps {
  isStatementOpened: boolean;
  setStatementOpened: React.Dispatch<React.SetStateAction<boolean>>;
  confirmBankDoc: (docStatus: string) => void;
  docStatus: string;
  isValidationCompleted: boolean;
}

enum PaginationDefault {
  defaultPage = 1,
  defaultSize = 10
}

const ClientProfileCardTransactionCheckModal: FC<IClientProfileCardImageViewerProps> = ({
  isStatementOpened,
  setStatementOpened,
  confirmBankDoc,
  docStatus,
  isValidationCompleted,
}) => {
  const bankStatementData: BankStatementDataType[] =
    useSelector((state: AppStateType) => state.document.bankStatementData);

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const navigate: NavigateFunction = useNavigate();
  const pathname: string = useLocation()?.pathname;

  const [
    transactionDocStatus,
    setTransactionDocStatus
  ] = useState<string>(docStatus);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [bankStatement, setBankStatement] = useState<BankStatementDataType[]>([]);

  useEffect(() => {
    setBankStatement(bankStatementData);
  }, [bankStatementData]);

  //логика переключения между выписками
  const [selectedItem, setSelectedItem] = useState<string>("Выписка №1");
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const statementUuid: string | undefined =
      documentClientProfile?.fields?.bank_document[currentIndex]?.statement_uuid;
    if (statementUuid) {
      dispatch(getBankStatementData(statementUuid));
    }
  }, [dispatch, documentClientProfile, currentIndex]);

  //логика сортировки выписки
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");

  const handleSorterBankStatement = (e: React.MouseEvent<HTMLElement>, text: string): void => {
    e.preventDefault();
    const newSortOrder: "asc" | "desc" = sortOrder === "asc" ? "desc" : "asc";

    const sortedEmployees: BankStatementDataType[] = [...bankStatement].sort((
      a: BankStatementDataType,
      b: BankStatementDataType
    ): number => {
      let result;

      switch (text) {
        case "date":
          result = dayjs(a.date).diff(dayjs(b.date));
          break;
        case "sum":
          result = (a.sum || 0) - (b.sum || 0);
          break;
        default:
          result = 0;
      }

      return newSortOrder === "asc" ? result : -result;
    });

    setSortOrder(newSortOrder);
    setBankStatement(sortedEmployees);
  };

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="flex justify-between items-center cursor-pointer text-gray-400">
        <div>
          {text}
        </div>
        <span className="block">
          <SortIcons/>
        </span>
      </div>
    );
  };

  const bankStatementColumns: ColumnsType<BankStatementDataType> = [
    {
      title: tableTitle("Дата платежа"),
      dataIndex: "date",
      width: "15%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterBankStatement(e, "date"),
      }),
    },
    {
      title: tableTitle("Сумма платежа"),
      dataIndex: "sum",
      width: "15%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterBankStatement(e, "sum"),
      }),
    },
    {
      title: (
        <span className="text-gray-400">
          Плательщик
        </span>
      ),
      dataIndex: "payee",
      width: "15%",
    },
    {
      title: (
        <span className="text-gray-400">
          Получатель
        </span>
      ),
      dataIndex: "payer",
      width: "15%",
    },
    {
      title: (
        <span className="text-gray-400">
          Назначение
        </span>
      ),
      dataIndex: "description",
      width: "40%",
    },
  ];

  const bankStatementTableData: BankStatementDataType[] = bankStatement?.map(({
    sum,
    uuid,
    date,
    payee,
    payer,
    description,
  }: BankStatementDataType) => {

    return (
      {
        key: uuid,
        sum: sum,
        date: date?.split("T")?.[0],
        payee: payee,
        payer: payer,
        description: (
          <div
            dangerouslySetInnerHTML={
              { __html: description } as React.HTMLProps<HTMLDivElement>["dangerouslySetInnerHTML"]
            }
          />
        ),
      }
    );
  });

  // логика пагинации страницы
  const [currentPage, setCurrentPage] = useState<number>(PaginationDefault.defaultPage);
  const [pageSize, setPageSize] = useState<number>(PaginationDefault.defaultSize);

  const startIndex: number = (currentPage - 1) * pageSize;
  const endIndex: number = startIndex + pageSize;

  const filterBankStatementTableData: BankStatementDataType[] = bankStatementTableData
    ? bankStatementTableData?.slice(startIndex, endIndex)
    : [];

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setCurrentPage(PaginationDefault.defaultPage);
  };

  const onDocStatusChange = (docStatus: string): void => {
    setTransactionDocStatus(docStatus);
  };

  const saveBankDoc = (): void => {
    setCanUpdate(true);
    dispatch(getUnAgreedDocumentType(true));

    if (transactionDocStatus === "DECLINED") {
      const taskUuid: string = pathname?.split("/")?.[2];
      setTimeout(() => navigate(`/task/${taskUuid}`), 1000);
    }
  };

  useEffect((): void => {
    if (canUpdate && transactionDocStatus) {
      confirmBankDoc(transactionDocStatus);
      setStatementOpened(false);
    }
  }, [canUpdate, transactionDocStatus]);

  const handleSelectChange = (value: string): void => {
    setCurrentIndex(Number(value));
    setSelectedItem(`Выписка №${Number(value) + 1}`);
  };

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;

  return (
    <Modal
      open={isStatementOpened}
      onOk={() => setStatementOpened(false)}
      onCancel={() => setStatementOpened(false)}
      centered
      width="100vw"
      closable={false}
      footer={null}
      wrapClassName={`${css.modalWrap} flex`}
    >
      <div className="flex flex-col">
        <div className="flex justify-between mb-3">
          <h1 className="mb-2 text-2xl font-bold">
            Проверка выписки
          </h1>
          <Button
            id="closeModal"
            className="border-0"
            shape="circle"
            onClick={() => setStatementOpened(false)}
          >
            <CloseOutlined />
          </Button>
        </div>
        <div>
          <span className="block w-3/6 mb-2 text-gray-400">
            Выберите выписку из предложенного списка
          </span>
          <Select
            style={{ width: 175, marginBottom: 25 }}
            onChange={handleSelectChange}
            value={selectedItem}
          >
            {documentClientProfile?.fields?.bank_document?.map((_item: AbstractDocType, index: number) => (
              <Select.Option key={index} value={index.toString()}>
                Выписка №{index + 1}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Table
            bordered={true}
            className="mb-0"
            columns={bankStatementColumns}
            dataSource={filterBankStatementTableData}
            showSorterTooltip={false}
            pagination={false}
          />
          <div className="border-lightgray border-t-0 mb-10">
            <PaginationCustom
              total={bankStatementTableData?.length ? bankStatementTableData?.length : 0}
              showTotal={(total: number, range: number[]): string =>
                `${range[0]}-${range[1]} из ${total}`
              }
              pageSize={pageSize}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              defaultCurrent={PaginationDefault.defaultPage}
            />
          </div>
          {!isValidationCompleted && (
            <div className="mb-3 border-b-gray">
            <span className="block w-3/6 mb-5 text-gray-400">
              Если вы обнаружили операции по неразрешенным ОКВЭД,
              пожалуйста, отклоните выписку и подтвердите отклонение анкеты клиента
            </span>
              <Radio.Group
                className="mb-[20px]"
                onChange={(e: RadioChangeEvent) => onDocStatusChange(e.target.value)}
                value={transactionDocStatus}
                disabled={isContactCenter}
              >
                <Radio value="APPROVED">Принять</Radio>
                <Radio value="DECLINED">Отклонить</Radio>
              </Radio.Group>
              {transactionDocStatus === "DECLINED" && (
                <span className={css.declineDocMessge}>
                  <WarningIcon className="mr-[8px]"/>
                  При отклонении выписки клиент не будет взят на обслуживание,
                  задача по валидации будет завершена как отклоненная
                </span>
              )}
            </div>
          )}
        </div>
        <div className="text-right mt-5">
          <ButtonCustom
            id="close"
            className={`${css.buttonBack} mr-4`}
            key="submit"
            type="default"
            onClick={() => setStatementOpened(false)}
            text={!isValidationCompleted ? "Отменить" : "Закрыть"}
            size="large"
          />
          {!isValidationCompleted && (
            <ButtonCustom
              id="save"
              className={css.buttonOk}
              key="submit"
              type="primary"
              onClick={saveBankDoc}
              text="Подтвердить"
              size="large"
              disabled={isContactCenter}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ClientProfileCardTransactionCheckModal;
