import { ITaskActionsProps } from "../../../../../taskActions";

export const filterOrAddTaskAction = (taskAction: string, taskActions: ITaskActionsProps[]): ITaskActionsProps[] => {
  const markupTask = {
    value: "Разметка операций",
    label: "Разметка операций",
  };

  return taskAction === "Разметка операций" ? [markupTask] : taskActions;
};
