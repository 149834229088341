import React, { FC, JSX } from "react";
import css from "../AboutClient.module.css";
import ListItem from "../../../../../ui-kit/RenderListItem/ListItem";
import { Card } from "antd";
import { DocumentSchemaLabelMapType } from "app/types";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { documentSchemaLabelsMapper } from "../../../../../../utils/documentSchemaLabelsMapper";
import CardLabel from "../../../../../../utils/CardLabel/CardLabel";
import dayjs from "dayjs";

interface IOrganizationInfoProps {
}

const OrganizationInfo: FC<IOrganizationInfoProps> = (): JSX.Element => {
  const {
    documentClient,
    schemaClient
  } = useSelector((state: AppStateType) => state.client);
  const clientLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClient);
  
  const {
    name,
    registered_address,
    opf,
    inn,
    kpp,
    company_ogrn,
    oktmo,
    okpo,
    okato,
    okopf,
    okfc,
    tax_system,
    primary_okved,
    secondary_okved,
    registration_date
  } = documentClient?.fields || {};
  
  const registrationDate: string = registration_date ? dayjs(registration_date).format("DD.MM.YYYY") : "";
  
  const renderSecondaryOkved: JSX.Element | null = (
    secondary_okved?.length ? (
      <li className="flex items-center mb-2">
        <span className={css.cardItemLabel}>
          {clientLabel?.["secondary_okved"]?.label}
        </span>
        <span className={css.cardItemValue}>
          {secondary_okved.map((okved: string) => (
            <span className="pr-2" key={okved}>
              {okved}
            </span>
          ))}
        </span>
      </li>
    ) : null
  );

  return (
    <Card className={css.card}>
      <CardLabel title="Организация" />
      <ul>
        <ListItem value={name ?? ""} label={clientLabel?.["name"]?.label} />
        <ListItem value={registered_address ?? ""} label={clientLabel?.["registered_address"]?.label} />
        <ListItem value={opf ?? ""} label={clientLabel?.["opf"]?.label} />
        <ListItem value={inn ?? ""} label={clientLabel?.["inn"]?.label} />
        <ListItem value={kpp ?? ""} label={clientLabel?.["kpp"]?.label} />
        <ListItem value={company_ogrn ?? ""} label={clientLabel?.["company_ogrn"]?.label} />
        <ListItem value={oktmo ?? ""} label={clientLabel?.["oktmo"]?.label} />
        <ListItem value={okpo ?? ""} label={clientLabel?.["okpo"]?.label} />
        <ListItem value={okato ?? ""} label={clientLabel?.["okato"]?.label} />
        <ListItem value={okopf ?? ""} label={clientLabel?.["okopf"]?.label} />
        <ListItem value={okfc ?? ""} label={clientLabel?.["okfc"]?.label} />
        <ListItem value={tax_system ?? ""} label={clientLabel?.["tax_system"]?.label} />
        <ListItem value={primary_okved ?? ""} label={clientLabel?.["primary_okved"]?.label} />
        {renderSecondaryOkved}
        <ListItem value={registrationDate} label={clientLabel?.["registration_date"]?.label} />
        {/*TODO Временно скрываем до реализации МЧД*/}
        {/*<li className="flex items-center mb-[22px]">*/}
        {/*  <span className={css.cardItemLabel}>*/}
        {/*    Срок действия МЧД ?*/}
        {/*  </span>*/}
        {/*  <span className={css.cardItemValue}>*/}
        {/*    до 23.03.2023 г. осталось 12 дней ?*/}
        {/*  </span>*/}
        {/*</li>*/}
        {/*<li className="flex items-center">*/}
        {/*  <ButtonCustom type="primary" text="Ссылка на МЧД" ghost />*/}
        {/*</li>*/}
      </ul>
    </Card>
  );
};

export default OrganizationInfo;