import React, { FC, SetStateAction, useEffect, useState } from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormPatentProps {
  docType: string;
  migrationForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
}

const ClientProfileCardImageViewerFormPatent: FC<IClientProfileCardImageViewerFormPatentProps> = ({
  docType,
  migrationForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
}) => {
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const { migrationDoc, migrationDocSchema } =
    useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    if (!!docApproveStatus && isDocApprovalDisabled || isContactCenter) {
      setDocVerified(true);
    } else {
      setDocVerified(false);
    }
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(migrationDocSchema, migrationForm, setDocApprovalDisabled);

    onFormValuesChange();
  }, [migrationForm, migrationDocSchema, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = migrationDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = migrationDoc?.fields?.files
      ?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, migrationDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema(docType));
      dispatch(getEmployeeDocumentData(docType, documentUuid));
    }
  }, [dispatch, documentUuid, docType, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = migrationDoc?.fields?.decline_reason ?? "";

      if (docMigrationDeclineReason) {
        migrationForm.setFieldValue("declineReason", docMigrationDeclineReason);
      } else {
        migrationForm.setFieldValue(
          "declineReason",
          "Необходимо приложить верный документ (приложен не тот документ)"
        );
      }

      onFormValuesChange();
    }
  }, [docApproveStatus, migrationDoc, migrationForm]);

  useEffect(() => {
    const docMigrationStatus: string = migrationDoc?.fields?.document_status ?? "";
    const docMigrationDeclineReason: string = migrationDoc?.fields?.decline_reason ?? "";

    if (docMigrationStatus) {
      migrationForm.setFieldValue("isApproved", docMigrationStatus);
    }

    if (docMigrationDeclineReason) {
      migrationForm.setFieldValue("declineReason", docMigrationDeclineReason);
    }
  }, [migrationDoc, migrationForm]);

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isMigrationFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  return (
    <div className="flex flex-col h-90vh">
      <h2 className="mb-2 text-xl font-bold">
        {docType === "patent-schema" ? "Патент" : "Миграционная карта"}
      </h2>
      {!isMigrationFormDisabled && (
        <p className="mb-4 gray-color w-full">
          После проверки правильности документа подтвердите его согласованность.
        </p>
      )}
      <Form
        form={migrationForm}
        name="migration-form"
        onChange={onFormValuesChange}
        onClick={onFormValuesChange}
        validateTrigger={validateTriggersArr}
      >
        {!isMigrationFormDisabled && (
          <>
            <Divider className="mt-1"/>
            <div className="flex justify-between">
              <Tooltip
                title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              >
                <Form.Item name="isApproved">
                  <Radio.Group
                    onChange={onApproveChange}
                    value={docApproveStatus}
                    disabled={isDocApprovalDisabled}
                  >
                    <Radio value="APPROVED">Согласовать</Radio>
                    <Radio value="DECLINED">Отклонить</Radio>
                  </Radio.Group>
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {docApproveStatus === "DECLINED" && (
                <Form.Item
                  name="declineReason"
                  initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                >
                  <Select
                    placeholder="Причина"
                    size="large"
                    options={[
                      {
                        value: "Необходимо приложить верный документ (приложен не тот документ)",
                        label: "Необходимо приложить верный документ (приложен не тот документ)",
                      },
                      {
                        value: "Необходимо приложить полный документ (не все страницы)",
                        label: "Необходимо приложить полный документ (не все страницы)",
                      },
                      {
                        value: "Истек срок действия документа",
                        label: "Истек срок действия документа",
                      },
                      {
                        value: "Плохое качество документа, нечитаемый скан",
                        label: "Плохое качество документа, нечитаемый скан",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
      </Form>
      <div className="text-right mt-auto">
        <Divider className="mt-0"/>
        <ButtonCustom
          id="close"
          className={`${css.buttonBack} mr-3`}
          type="default"
          onClick={closeForm}
          text={isMigrationFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isMigrationFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={() => {
              setCanSaveDoc(true);
              dispatch(getUnAgreedDocumentType(true));
            }}
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormPatent;
