import React, {
  FC,
  JSX,
  SetStateAction,
  Dispatch
} from "react";
import { Modal } from "antd";
import css from "./ChangeTaskPriorityModal.module.css";
import { TasksListingDataType, TypicalTaskDecisionType } from "app/types";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { sendTypicalTaskDecision } from "../../../../actions/tasks.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";

interface IChangeTaskPriorityModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  taskData: TasksListingDataType | null;
  setUpdatePriorityTask: Dispatch<SetStateAction<boolean>>;
}

const ChangeTaskPriorityModal: FC<IChangeTaskPriorityModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  taskData,
  setUpdatePriorityTask
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  
  const taskNumber: number | undefined = taskData?.fields?.number;
  const isHighPriority: boolean = taskData?.fields?.priority === "Высокий";
  const correctText: string = isHighPriority ? "понизить" : "повысить";
  
  const renderModalHeader = (text: string): JSX.Element => {
    return (
      <div className={css.modalHeader}>
        {text}
      </div>
    );
  };
  
  const modalOk = (): void => {
    const newPriority: TypicalTaskDecisionType = {
      task: taskData?.fields?.uuid,
      new_priority: isHighPriority ? "Средний" : "Высокий",
      task_decision_type: "change_priority"
    };
    
    if (taskData) {
      dispatch(sendTypicalTaskDecision(taskData.schema_version, newPriority))
        .finally(() => setUpdatePriorityTask((prev: boolean) => !prev));
    }
    
    setIsModalOpen(false);
  };
  
  const modalCancel = (): void => {
    setIsModalOpen(false);
  };
  
  const modalFooter: JSX.Element = (
    <div>
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      <ButtonCustom
        id="buttonOk"
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        className={css.buttonOk}
        onClick={modalOk}
      />
    </div>
  );
  
  return (
    <Modal
      width={580}
      title={renderModalHeader("Подтверждение действия")}
      open={isModalOpen}
      onCancel={modalCancel}
      footer={modalFooter}
    >
      <div className={css.modalText}>
        Вы уверены, что хотите {correctText} приоритет задачи №{taskNumber}?
      </div>
    </Modal>
  );
};

export default ChangeTaskPriorityModal;