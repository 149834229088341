import { Dispatch, SetStateAction } from "react";

export const handleOver = (disabled: boolean, setDisabled: Dispatch<SetStateAction<boolean>>): void => {
  if (disabled) {
    setDisabled(false);
  }
};

export const handleOut = (setDisabled: Dispatch<SetStateAction<boolean>>): void => {
  setDisabled(true);
};