import React, { FC, JSX, useState } from "react";
import { Form, FormInstance, Modal, Tooltip } from "antd";
import css from "./ModalApplication.module.css";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import TariffIncrease from
    "../../../ClientsList/ClientCard/ClientCardItem/Tasks/ModalContents/PaidServicesItem/TariffIncrease/TariffIncrease";
import TerminateService from
"../../../ClientsList/ClientCard/ClientCardItem/Tasks/ModalContents/PaidServicesItem/TerminateService/TerminateService";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentTaskType, TypicalTaskDecisionType } from "app/types";
import { setPopoverTariffType } from "../../../../actions/client.actions";
import { AppDispatch } from "../../../../store/store";
import { sendTypicalTaskDecision } from "../../../../actions/tasks.actions";

interface IModalApplicationProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  formApplication: FormInstance;
}

export interface IModalApplicationRender {
  formContent?: FormInstance;
  formApplication?: FormInstance;
  changeOptions?: boolean;
  setChangeOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  setRejectApplication?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalApplication: FC<IModalApplicationProps> = ({
  isModalOpen,
  setIsModalOpen,
  formApplication,
}): JSX.Element => {
  const values = Form.useWatch([], formApplication);
  const dispatch = useDispatch<AppDispatch>();

  const [changeOptions, setChangeOptions] = useState<boolean>(true);
  const [rejectApplication, setRejectApplication] = useState<boolean>(false);

  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);
  const taskType: string | undefined = documentTask?.fields?.task_type?.[0]?.label;
  const isTaskTerminationService: boolean = taskType === "Прекращение обслуживания";

  const modalHeader = (): JSX.Element => {
    const text: string =
      !isTaskTerminationService ? "Заявка на повышение тарифа" : "Заявка на прекращение обслуживания";

    return (<div className={css.modalHeader}>{text}</div>);
  };

  const modalOk = (decisionChoice: boolean): void => {
    setIsModalOpen(false);
    setChangeOptions(true);
    setRejectApplication(false);
    dispatch(setPopoverTariffType(false));

    const {
      textTariff,
      paidConsultation,
      accountingRestoration,
      addAgencyAgreements,
      addingEmployees,
      textTerminate,
      terminateReason
    } = values || {};

    const taskUuid: string = documentTask?.fields?.uuid ?? "";
    const comment: string = textTariff ?? "";
    const textTerminateData: string = textTerminate ?? "";
    const paidConsultationData: number = paidConsultation
      ? paidConsultation
      : documentTask?.fields?.additional_number_of_consultations;
    const accountingRestorationData: number = accountingRestoration
      ? accountingRestoration
      : documentTask?.fields?.additional_accounting_restore_months;
    const addAgencyAgreementsData: number = addAgencyAgreements
      ? addAgencyAgreements
      : documentTask?.fields?.additional_agency_agreements_qty;
    const addingEmployeesData: number = addingEmployees
      ? addingEmployees
      : documentTask?.fields?.additional_number_of_employees;
    const terminateReasonData: string = terminateReason ?? documentTask?.fields?.task_reason;

    if (!isTaskTerminationService) {
      const tariffIncreaseRequest: TypicalTaskDecisionType = {
        comment,
        task: taskUuid,
        task_decision_type: "update_tariff",
        task_decision_choice: decisionChoice,
        additional_number_of_employees: addingEmployeesData,
        additional_number_of_consultations: paidConsultationData,
        additional_agency_agreements_qty: addAgencyAgreementsData,
        additional_accounting_restore_months: accountingRestorationData,
        files: [],
      };

      if (documentTask) {
        dispatch(sendTypicalTaskDecision(documentTask?.schema_version, tariffIncreaseRequest));
      }
      formApplication.resetFields();

      return;
    }

    if (isTaskTerminationService) {
      const terminationServiceRequest: TypicalTaskDecisionType = {
        task: taskUuid,
        task_decision_type: "termination_of_service",
        task_decision_choice: decisionChoice,
        comment: textTerminateData,
        task_reason: terminateReasonData,
        files: [],
      };

      if (documentTask) {
        dispatch(sendTypicalTaskDecision(documentTask?.schema_version, terminationServiceRequest));
      }
      formApplication.resetFields();

      return;
    }
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
    setChangeOptions(true);
    setRejectApplication(false);
    dispatch(setPopoverTariffType(false));

    if (documentTask && !isTaskTerminationService) {
      formApplication.setFieldsValue({
        paidConsultation: documentTask?.fields?.additional_number_of_consultations ?? 0,
        accountingRestoration: documentTask?.fields?.additional_accounting_restore_months ?? 0,
        addingEmployees: documentTask?.fields?.additional_number_of_employees ?? 0,
        addAgencyAgreements: documentTask?.fields?.additional_agency_agreements_qty ?? 0,
        textTariff: "",
        textTerminate: "",
      });
    }

    if (documentTask && isTaskTerminationService) {
      formApplication.setFieldsValue({
        terminateReason: documentTask?.fields?.task_reason,
      });
    }
  };

  const tooltipTitle: JSX.Element = (
    <div className="text-center">
      Необходимо описать причину в комментарий перед отклонением заявки
    </div>
  );

  const modalFooter: JSX.Element = (
    <div className="flex justify-end mt-5">
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      {rejectApplication ? (
        <ButtonCustom
          className={`${css.buttonReject} mr-2`}
          key="cancel"
          size="large"
          type="default"
          danger={rejectApplication}
          text="Отклонить заявку"
          onClick={() => modalOk(false)}
        />
      ) : (
        <Tooltip
          title={tooltipTitle}
          placement="top"
        >
          <div>
            <ButtonCustom
              className={`${css.buttonDisable} mr-2`}
              key="cancel"
              size="large"
              type="default"
              disabled
              text="Отклонить заявку"
            />
          </div>
        </Tooltip>
      )}
      <ButtonCustom
        key="ok"
        size="large"
        text="Согласовать"
        type="primary"
        className={css.buttonOk}
        onClick={() => modalOk(true)}
      />
    </div>
  );

  const ModalApplicationRender: FC<IModalApplicationRender> =
    !isTaskTerminationService ? TariffIncrease : TerminateService;

  return (
    <Modal
      width={580}
      title={modalHeader()}
      open={isModalOpen}
      onCancel={modalCancel}
      footer={modalFooter}
    >
      {!isTaskTerminationService ? (
        <div>
          <div className={css.tariffText}>
            Пожалуйста, проверьте корректность добавленных платных опций и подтвердите проведенную проверку.
          </div>
          <div className={css.tariffText}>
            Если необходимо, внесите изменения в состав платных опций.
            В случае выявления ошибок, опишите причину в комментарии и отклоните заявку.
          </div>
        </div>
      ) : (
        <div className={css.tariffText}>
          Пожалуйста, проверьте корректность выбранной причины прекращения обслуживания.
          Если необходимо, измените причину. При выявлении ошибки, опишите причину
          отклонения в комментарии и отклоните заявку.
        </div>
      )}
      <ModalApplicationRender
        formApplication={formApplication}
        changeOptions={changeOptions}
        setChangeOptions={setChangeOptions}
        setRejectApplication={setRejectApplication}
      />
    </Modal>
  );
};

export default ModalApplication;