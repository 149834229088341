import React, { FC, JSX, useEffect, useState } from "react";
import css from "./StubPage.module.css";
import StubMenu from "./StubPageItem/Menu/StubMenu";
import GeneralInfo from "./StubPageItem/GeneralInfo/GeneralInfo";
import OptionsInfo from "./StubPageItem/OptionsInfo/OptionsInfo";
import DocumentsInfo from "./StubPageItem/DocumentsInfo/DocumentsInfo";
import PriceInfo from "./StubPageItem/PriceInfo/PriceInfo";
import QuestionsInfo from "./StubPageItem/QuestionsInfo/QuestionsInfo";

interface IStubPageProps {}

const StubPage: FC<IStubPageProps> = (): JSX.Element => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 980) {
        setIsActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToAnchor = (id: string): void => {
    const anchor: HTMLElement | null = document.getElementById(id);

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className={css.bodyStyle}>
      <StubMenu
        isActive={isActive}
        setIsActive={setIsActive}
        scrollToAnchor={scrollToAnchor}
      />
      <div className={css.generalInfo}>
        <GeneralInfo />
        <OptionsInfo />
      </div>
      <DocumentsInfo />
      <PriceInfo scrollToAnchor={scrollToAnchor} />
      <QuestionsInfo />
      <div style={{ opacity: "0"}}>.</div>
    </div>
  );
};

export default StubPage;
