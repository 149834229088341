import React, { FC, JSX } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../store/store";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { getActionHistoryFailure } from "../../../../actions/tasks.actions";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow_left_icon.svg";
import css from "../TaskCardReturnBtn/TaskCardReturnBtn.module.css";

interface ITaskCardsReturnBtnProps {
  taskNumber: number;
  taskType: string;
  taskTopic: string;
  drawerTaskAction: string;
}

const TaskCardReturnBtn: FC<ITaskCardsReturnBtnProps> = ({
  taskNumber,
  taskType,
  taskTopic,
  drawerTaskAction,
}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const { pathname: path } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const returnToTasksOrClientCard = (): void => {
    const pathSegments = path.split("/");
    const hasClientUuid = pathSegments.includes("clients");

    if (hasClientUuid) {
      const clientUuid = pathSegments[2];
      navigate(`/clients/${clientUuid}`);
    } else {
      navigate("/tasks");
    }

    dispatch(getActionHistoryFailure());
  };

  const truncateTaskTopic = (): string => {
    const maxLength: number = drawerTaskAction ? 30 : 70;

    if (taskTopic.length > maxLength) {
      return taskTopic.substring(0, maxLength) + "...";
    }

    return taskTopic;
  };

  return (
    <div className="flex items-center">
      <ButtonCustom
        className={css.buttonReturn}
        type="link"
        text=""
        onClick={returnToTasksOrClientCard}
      >
        <ArrowLeft />
      </ButtonCustom>
      <div className="flex">
        <h1 className={css.header}>
          {taskType} №{taskNumber} {taskTopic && `- ${truncateTaskTopic()}`}
        </h1>
      </div>
    </div>
  );
};

export default TaskCardReturnBtn;
