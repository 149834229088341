import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  DatePicker,
  SelectProps,
  UploadProps,
  FormInstance,
  UploadFile,
  Skeleton,
  FormProps,
  notification
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { UploadOutlined } from "@ant-design/icons";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./CreateResume.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocumentIcon } from "../../../../assets/icons/delete_file.svg";
import { AppStateType } from "../../../../reducers/mainReducer";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import { UploadChangeParam } from "antd/es/upload";
import { createNewDocument, deleteUploadFile, updateDocument, uploadFiles } from "../../../../api/document.api";
import {
  DocumentSchemaLabelMapType,
  FilterNamesType,
  ResumesFieldsType,
  SelectOptionsType,
  SkillListType,
} from "app/types";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { documentSchemaLabelsMapper } from "../../../../utils/documentSchemaLabelsMapper";
import { downloadFile, downloadUrl } from "../../../../utils/downloadFile";
import { getResumeFailure, getResumeInfo } from "../../../../actions/alpha/resumes.actions";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import * as DocumentAPI from "../../../../api/document.api";
import { getApplicationInfo } from "../../../../actions/alpha/application.actions";

const operatingModeOptions: SelectProps["options"] = [
  {
    value: "Полный день",
    label: "Полный день"
  },
  {
    value: "Частичная занятость",
    label: "Частичная занятость"
  }
];

interface IUploadedFilesProps {
  file_name: string;
  uuid: string;
}

export interface INavigationProps {
  resumeUuid?: string;
  vacancyUuid?: string;
  isResumeCard?: boolean;
  vacancyStatus?: string;
  contractorUuid?: string;
  applicationStatus?: string;
}

interface ICreateSummaryProps {
}

const CreateResume: FC<ICreateSummaryProps> = (): JSX.Element => {
  const [uploadedFilesData, setUploadedFilesData] = useState<IUploadedFilesProps[] | null>(null);
  const [fileCount, setFileCount] = useState<number>(0);
  const [uploadFilesLoader, setUploadFilesLoader] = useState<boolean>(false);
  const [isResumeValid, setResumeValid] = useState<boolean>(false);
  const [isFormHasValue, setFormHasValue] = useState<boolean>(false);
  const [createDocStatus, setCreateDocStatus] = useState<number>(0);
  const [isResumeUpdated, setResumeUpdated] = useState<boolean>(false);
  const [resumeSkills, setResumeSkills] = useState<SkillListType[]>([]);
  const [isCreateResumeForm, setCreateResumeForm] = useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const dispatch = useDispatch<AppDispatch>();

  const {
    resumeUuid,
    isResumeCard,
    contractorUuid,
  }: INavigationProps = useLocation()?.state ?? {} as INavigationProps;

  const { documentSchema, isDocumentSchemaLoading } =
    useSelector((state: AppStateType) => state.document);

  const resume = useSelector((state: AppStateType) => state.resume.resume);

  const pathname: string = useLocation()?.pathname;

  useEffect(() => {
    const isCreateForm: boolean = pathname?.split("/")?.includes("create_resume");

    setCreateResumeForm(isCreateForm);
  }, [pathname]);

  const resumeLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(documentSchema);

  const resumeNavigation = (): void => {
    isResumeCard
      ? navigate(`/alpha/resume/${resumeUuid}`)
      : navigate("/alpha/resumes");
  };

  useEffect((): void => {
    createAlphaDocStatus(
      createDocStatus,
      createResumeSuccess,
      createResumeError,
    );
  }, [createDocStatus, resumeLabel]);

  useEffect((): void => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setResumeValid(true))
      .catch(() => setResumeValid(false));

    if (values) {
      Object.values(values)?.some(value => {
        if (Array.isArray(value)) {
          return !!value?.length;
        } else {
          return !!value;
        }
      })
        ? setFormHasValue(true)
        : setFormHasValue(false);
    }
  }, [form, values, isDocumentSchemaLoading]);

  useEffect(() => {
    dispatch(getDocumentSchema("cv-schema"));
  }, []);

  const counterUpload = (fileCountClarification: number): boolean =>
    fileCountClarification === 1|| fileCountClarification > 1;

  const uploadFile = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoader(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesData((prevData: IUploadedFilesProps[] | null) => {
        const safePrevData: IUploadedFilesProps[] = prevData || [];
        let newData: IUploadedFilesProps[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;

        response.data.forEach((file: IUploadedFilesProps): void => {
          if (!newData.some((existingFile: IUploadedFilesProps): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData.length > 1) {
          newData = newData.slice(0, 1);
          newFileCount = 1;
        }

        setFileCount(newFileCount);

        return newData;
      });

      if (response.status === 200) {
        setUploadFilesLoader(false);
      }

    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoader(false);
    }

  };

  const uploadResumeFileProps: UploadProps = {
    id: "resume_file",
    name: "resume_file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: ".pdf",
    onChange: (info: UploadChangeParam): boolean => {
      if (info.fileList.length > 1) {
        info.fileList.splice(1);
      }

      const allFilesUploading: boolean =
        info.fileList.every((file: UploadFile<unknown>): boolean => file.status === "uploading");

      if (allFilesUploading) {
        const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
          return !!file;
        });

        const files: File[] = filterFile.map((file: UploadFile<unknown>) => file.originFileObj!) as File[];

        if (files.length > 0) {
          uploadFile(files);
        }

      }
      info.fileList.length = 0;
      return false;
    },
    showUploadList: false,
  };

  const handleRemove = async (e: React.MouseEvent<HTMLElement>, fileRemove: IUploadedFilesProps,): Promise<void> => {
    e.preventDefault();

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesData((prevFiles: IUploadedFilesProps[] | null): IUploadedFilesProps[] | null => {
        if (prevFiles) {
          const newFiles: IUploadedFilesProps[] =
            prevFiles.filter((file: IUploadedFilesProps): boolean => file.uuid !== fileRemove.uuid);
          setFileCount(newFiles.length);
          return newFiles.length > 0 ? newFiles : null;
        }
        setFileCount(0);
        return [];
      });
      form.resetFields(["resume_file"]);
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const uploadFilesList: JSX.Element[] | null = uploadedFilesData?.length ? (
    uploadedFilesData.map((file: IUploadedFilesProps, index: number) => (
      <div key={index} className={css.documentsUpload}>
        <div className="flex items-center pl-[2px]">
          <AttachFileIcon className="flex-shrink-0"/>
          <a className={css.fileName}>
            {file.file_name}
          </a>
        </div>
        <div
          className={css.deleteIcon}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleRemove(e, file)}
        >
          <DeleteDocumentIcon className="flex-shrink-0"/>
        </div>
      </div>
    ))) : null;

  const onFinish: FormProps<FormInstance>["onFinish"] = async (): Promise<void> => {
    const newResumeData: FilterNamesType = {
      first_name: form?.getFieldValue("name"),
      second_name: form?.getFieldValue("second_name"),
      last_name: form?.getFieldValue("surname"),
      birth_date: form?.getFieldValue("birth_date")?.format("YYYY-MM-DD"),
      skill: form?.getFieldValue("skills"),
      working_hours: form?.getFieldValue("operating_mode") || null,
      cv_file: uploadedFilesData?.[0]?.uuid,
    };

    try {
      if (resumeUuid) {
        const { status } = await updateDocument("cv-schema", resumeUuid, newResumeData);
        setCreateDocStatus(status);
        setResumeUpdated(true);
      } else {
        const { status } = await createNewDocument("cv-schema", newResumeData);
        setCreateDocStatus(status);
      }
    } catch (error: any) {
      setCreateDocStatus(error.response.status);
      console.error("Create resume error:", error.response.data.message);
    }
  };

  const createResumeSuccess= (): void => {
    notification.success({
      message: `Резюме успешно ${isResumeUpdated ? "изменено" : "создано"}`,
    });

    setCreateDocStatus(0);
    dispatch(getResumeFailure());

    contractorUuid
      ? navigate(`/alpha/contractor/${contractorUuid}`)
      : resumeNavigation();
  };

  const createResumeError = (): void => {
    notification.error({
      message: `"Ошибка ${isResumeUpdated ? "редактирования" : "создания"} резюме`,
    });

    setCreateDocStatus(0);
  };

  const closeResumeForm = () => {
    contractorUuid
      ? navigate(`/alpha/contractor/${contractorUuid}`)
      : resumeNavigation();

    dispatch(getResumeFailure());
  };

  useEffect(() => {
    DocumentAPI.getDocumentList("skills-directory")
    .then((response) => setResumeSkills(response.data.results))
    .catch((err) => console.error("Error", err));
  }, []);

  const skillsOptions: SelectOptionsType[] = resumeSkills?.map(({ fields }: SkillListType) => {
    return {
      key: fields?.uuid,
      value: fields?.uuid,
      label: fields?.skill_name,
    };
  }) ?? [];

  return (resume || isCreateResumeForm ? (
      <div className={css.container}>
        <h1 className={css.createResumeHeader}>
          Создание нового резюме
        </h1>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          validateTrigger="onChange"
        >
          <div className={css.createResumeParamsBlock}>
            <h2 className={css.createResumeParamsHeader}>
              Параметры резюме
            </h2>
            <div className="flex justify-between mb-[20px]">
              <Form.Item
                className="w-[32%] mr-[20px] mb-0"
                id="surname"
                name="surname"
                label={resumeLabel?.last_name?.label}
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите фамилию"
                  }
                ]}
                initialValue={resume?.fields?.last_name ?? null}
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="Введите фамилию"
                />
              </Form.Item>
              <Form.Item
                className="w-[32%] mr-[20px] mb-0"
                id="name"
                name="name"
                label={resumeLabel?.first_name?.label}
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите имя"
                  }
                ]}
                initialValue={resume?.fields?.first_name ?? null}
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="Введите имя"
                />
              </Form.Item>
              <Form.Item
                className="w-[32%] mb-0"
                id="second_name"
                name="second_name"
                label={resumeLabel?.second_name?.label}
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите отчество"
                  }
                ]}
                initialValue={resume?.fields?.second_name ?? null}
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="Введите отчетсво"
                />
              </Form.Item>
            </div>
            <Form.Item
              className="w-full mb-[20px]"
              id="birth_date"
              name="birth_date"
              label="Дата рождения"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите дату рождения"
                }
              ]}
              initialValue={resume?.fields?.birth_date ?? null}
            >
              <DatePicker
                className="w-full"
                size="large"
                value={null}
                format="DD.MM.YYYY"
                allowClear
                inputReadOnly={false}
                placeholder="Дата рождения"
                onChange={(date: Dayjs | null): void =>
                  date === null
                  ? form.setFieldValue("birth_date", null)
                  : form.setFieldValue("birth_date", date)
                }
                disabledDate={(current: Dayjs) => {
                  return dayjs().isBefore(current.add(16, "year"));
                }}
                defaultPickerValue={dayjs().subtract(16, "year")}
              />
            </Form.Item>
            <Form.Item
              className="w-full mb-[20px]"
              id="skills"
              name="skills"
              label="Скиллы"
              rules={[
                { required: true, message: "Пожалуйста, выберите скиллы" }
              ]}
              initialValue={resume?.fields?.skills ?? null}
            >
              <Select
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                placeholder="Выберите скиллы"
                className="w-full"
                suffixIcon={null}
                options={skillsOptions}
              />
            </Form.Item>
            <Form.Item
              className="w-[32%] mb-[20px]"
              id="operating_mode"
              name="operating_mode"
              label={resumeLabel?.working_hours?.label}
              initialValue={resume?.fields?.working_hours ?? null}
            >
              <Select
                size="large"
                placeholder="Выберите режим работы"
                allowClear
                onChange={(value) => {
                  form.setFieldValue("operating_mode", value);
                }}
                filterOption={false}
                options={operatingModeOptions}
              />
            </Form.Item>
            <Form.Item
              className="w-[32%] mb-0"
              id="resume_file"
              name="resume_file"
              label="Файл резюме"
              rules={[
                  { required: true, message: "Пожалуйста, прикрепите файл резюме" }
              ]}
              initialValue={resume?.fields?.cv_file ?? null}
            >
              <Upload
                {...uploadResumeFileProps}
                disabled={counterUpload(fileCount)}
              >
                <div className="flex items-center">
                  <Button
                    className="mr-[12px]"
                    icon={<UploadOutlined />}
                    size="large"
                    disabled={counterUpload(fileCount)}
                  >
                    Загрузить
                  </Button>
                  <span className={css.uploadFileTypeDescr}>
                    Формат: PDF
                  </span>
                </div>
              </Upload>
            </Form.Item>
            {resume?.fields?.cv_file && (
              <a
                className="inline-block mt-[5px] text-blue-500"
                onClick={() => downloadFile("cv_file", downloadUrl(resume?.fields?.cv_file))}
              >
                Скачать файл
              </a>
            )}
            <div className={`${css.fileListing} grid justify-items-start items-center`}>
              {uploadFilesLoader ? (
                <Skeleton
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    width: "100%"
                  }}
                />
              ) : uploadFilesList}
            </div>
          </div>
          <div className={css.createResumeActionBlock}>
            <ButtonCustom
              className="mr-[8px]"
              type="default"
              text={resumeUuid ? "Назад" : "Отменить"}
              size="large"
              ghost
              onClick={closeResumeForm}
            />
            {!resumeUuid && (
              <ButtonCustom
                className="mr-[8px]"
                type="default"
                text="Сохранить как черновик"
                size="large"
                ghost
                disabled={!isFormHasValue}
              />
            )}
            <ButtonCustom
              className="mr-[8px]"
              type="primary"
              text={resumeUuid ? "Сохранить" : "Создать резюме"}
              size="large"
              htmlType="submit"
              disabled={!isResumeValid}
            />
          </div>
        </Form>
      </div>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default CreateResume;
