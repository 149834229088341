import React, { JSX } from "react";
import css from "../components/Tasks/Tasks.module.css";
import { Link } from "react-router-dom";
import TagCustom from ".././components/ui-kit/TagCustom/TagCustom";
import { TagCustomColorType, TasksListingDataType } from "app/types";
import { statusVariant } from "./catalog";

export const statusIcon = (text: string, record: TasksListingDataType, clientUuid?: string): JSX.Element | string => {
  const taskLink: string = clientUuid
    ? `/clients/${clientUuid}/task/${record?.fields?.uuid}`
    : `/task/${record?.fields?.uuid}`;
  const status: { color: TagCustomColorType; text: string } = statusVariant[text];
  
  return status ? (
    <Link className={css.tableCellStatus} to={taskLink}>
      <TagCustom color={status?.color} text={status?.text} />
    </Link>
  ) : "";
};
