import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_NUMBER_SUCCESS,
  GET_NOTIFICATIONS_NUMBER_FAILURE,
} from "../constants/actionTypes";
import { NotificationsActionsType } from "../actions/notifications.actions";
import { NotificationsDataType, NumberOfNotificationsType } from "app/types";

type InitialNotificationsType = {
  error: any;
  notifications: NotificationsDataType[];
  notificationsNumber: NumberOfNotificationsType | null;
  isNotificationsLoading: boolean;
  notificationsReadStatus: any;
  totalDocs: number;
};

const initialState: InitialNotificationsType = {
  error: null,
  notifications: [],
  notificationsNumber: null,
  isNotificationsLoading: false, //при получении данных c API вернуть на true
  notificationsReadStatus: null,
  totalDocs: 0,
};

const notifications = (
  state: InitialNotificationsType = initialState,
  action: NotificationsActionsType
): InitialNotificationsType => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications.notifications,
        totalDocs: action.payload.notifications.total_docs,
        error: null,
        isNotificationsLoading: false,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: [],
        totalDocs: 0,
        error: action.payload.error,
        isNotificationsLoading: false,
      };
    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsReadStatus: action.payload.notificationsReadStatus,
        error: null,
        isNotificationsLoading: false,
      };
    case READ_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notificationsReadStatus: null,
        error: action.payload.error,
        isNotificationsLoading: false,
      };
    case GET_NOTIFICATIONS_NUMBER_SUCCESS:
      return {
        ...state,
        notificationsNumber: action.payload.notificationsNumber,
        error: null,
        isNotificationsLoading: false,
      };
    case GET_NOTIFICATIONS_NUMBER_FAILURE:
      return {
        ...state,
        notificationsNumber: null,
        error: action.payload.error,
        isNotificationsLoading: false,
      };
    default:
      return state;
  }
};

export default notifications;
