import { SchemaFieldsType, SchemaType } from "app/types";
import { FormInstance } from "antd";

export const isDocFieldRequired = (
  docSchema: SchemaType | null,
  fieldName: string,
  docForm?: FormInstance,
): "" | "error" | "warning" | undefined => {
  const currentField: SchemaFieldsType[] | undefined = docSchema?.fields
  ?.filter(({ name }: { name: string }) => name === fieldName);

  const isRequiredFiled: "" | "error" = !!currentField?.length && currentField?.[0]?.required_for_finalize
    ? "error"
    : "";

  return !docForm?.getFieldValue(fieldName)
    ? isRequiredFiled
    : "";
};
