import React, { FC, JSX } from "react";
import { ReactComponent as SearchProfileDocument } from "../../../assets/icons/search-square.svg";
import css from "./ZeroClientProfileDocument.module.css";

interface IZeroClientProfileDocumentProps {
  variant: string;
}

const ZeroClientProfileDocument: FC<IZeroClientProfileDocumentProps> = ({variant}): JSX.Element => {

  const textVariant = (text: string): string => {
    switch (text) {
      case "Организация":
        return "данные об организации";
      case "Выписка":
        return "данные о выписках";
      case "Патент":
        return "данные о патенте";
      case "УСН":
        return "данные о подтверждении о переходе на УСН";
      case "Паспорт руководителя":
        return "данные о паспорте руководителя";
      case "Решение учредителя":
        return "данные о решении учредителя по формированию уставного капитала и назначения руководителя";
      case "СНИЛС директора":
        return "данные о СНИЛС генерального директора";
      case "Паспорт сотрудника":
        return "данные о паспорте сотрудника";
      case "СНИЛС сотрудника":
        return "данные о СНИЛС сотрудника";
      case "ИНН сотрудника":
        return "данные о ИНН сотрудника";
      case "Договор сотрудника":
        return "данные о договоре сотрудника";
      case "Миграционная карта сотрудника":
        return "данные о миграционной карте или патенте сотрудника";
      case "НДФЛ сотрудника":
        return "документы для вычета НДФЛ сотрудника";
      case "Банковские реквизиты сотрудника":
        return "данные о банковских реквизитах сотрудника";
      default:
        return "";
    }
  };

  return (
    <div className={css.container}>
      <SearchProfileDocument className="mr-[12px]"/>
      <div>
        <div className={css.textHeader}>Нет данных</div>
        <div className={css.textBody}>{`Мы не получили ${textVariant(variant)}`}</div>
      </div>
    </div>
  );
};

export default ZeroClientProfileDocument;
