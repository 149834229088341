import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { VacancyFormValuesType, VacancyListType } from "app/types";
import * as DocumentAPI from "../../../../api/document.api";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import Aspirant from "./CreateVacancyItem/Aspirant/Aspirant";
import Employer from "./CreateVacancyItem/Employer/Employer";
import Parameters from "./CreateVacancyItem/Parameters/Parameters";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  FormProps,
  FormInstance,
  notification,
} from "antd";
import css from "./CreateVacancy.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getVacancyData, getVacancyFailure } from "../../../../actions/alpha/vacancy.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import { INavigationProps } from "../../Resume/CreateResume/CreateResume";
import { createNewDocument, updateDocument } from "../../../../api/document.api";

interface ICreateVacancyDataProps {
}

const CreateVacancy: FC<ICreateVacancyDataProps> = (): JSX.Element => {
  const [isVacancySubmittable, setVacancySubmittable] = useState<boolean>(false);
  const [createVacancyStatus, setCreateVacancyStatus] = useState<number>(0);
  const [isVacancyFormHasValue, setVacancyFormHasValue] = useState<boolean>(false);
  const [vacancyUuid, setVacancyUuid] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const params: string = useLocation()?.pathname;

  useEffect(() => {
    const vacancyUuid: string = params?.split("/")?.[3];
    setVacancyUuid(vacancyUuid);

    if (vacancyUuid) dispatch(getVacancyData(vacancyUuid));
  }, [params]);

  const vacancyData: VacancyListType | null = useSelector((state: AppStateType) => state.vacancy.vacancyData);

  const navigate: NavigateFunction = useNavigate();

  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  useEffect((): void => {
    createAlphaDocStatus(
      createVacancyStatus,
      createVacancySuccess,
      createVacancyError,
    );
  }, [createVacancyStatus]);

  const createVacancySuccess = (): void => {
    notification.success({
      message: "Вакансия успешно создан",
    });

    dispatch(getVacancyFailure());
    setCreateVacancyStatus(0);
    navigate("/alpha/vacancies");
  };

  const createVacancyError = (): void => {
    notification.error({
      message: "Ошибка создания вакансии",
    });

    setCreateVacancyStatus(0);
  };

  const {
    vacancyStatus
  }: INavigationProps = useLocation()?.state ?? {} as INavigationProps;

  const onCloseForm = (): void => {
    form.resetFields();
    vacancyUuid
      ? navigate(-1)
      : navigate("/alpha/vacancies", {state: vacancyStatus});
  };

  useEffect((): void => {
    form
    .validateFields({ validateOnly: true })
    .then(() => setVacancySubmittable(true))
    .catch(() => setVacancySubmittable(false));

    if (values) {
      Object.values(values)?.some((value) => !!value)
        ? setVacancyFormHasValue(true)
        : setVacancyFormHasValue(false);
    }
  }, [form, values]);

  const onCreateVacancyFinish: FormProps<FormInstance>["onFinish"] = async (values: FormInstance): Promise<void> => {
    const filtered: VacancyFormValuesType = Object.entries(values)
    .filter(([_, value]): boolean =>
      typeof value === "string" && value.trim() !== "" || Array.isArray(value) && !!value?.length)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value
    }), {}) as VacancyFormValuesType;

    try {
      if (vacancyUuid) {
        const { status } = await updateDocument("vacancy", vacancyUuid, filtered);
        setCreateVacancyStatus(status);
      } else {
        const { status } = await DocumentAPI.createNewDocument("vacancy", filtered);
        setCreateVacancyStatus(status);
      }
    } catch (error: any) {
      setCreateVacancyStatus(error.response.status);
      console.error("Create vacancy error:", error.response.data.message);
    }
  };

  const clearDateIfUnlimited = (isUnlimited: boolean): void => {
    if (isUnlimited) form.setFieldValue("end_date", null);
  };

  const isVacancyPublished: boolean = vacancyData?.fields?.vacancy_status === "ACTIVE";
  const createOrSaveVacancyText: string = !vacancyData ? "Создать" : "Сохранить";

  return (
    <div className={css.container}>
      <h1 className={css.createVacancyHeader}>
        Создание новой вакансии
      </h1>
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onCreateVacancyFinish}
        >
          <Parameters
            vacancyData={vacancyData}
            clearDateIfUnlimited={clearDateIfUnlimited}
          />
          <Aspirant vacancyData={vacancyData} />
          <Employer vacancyData={vacancyData} />
          <div className={`${css.containerBlock} flex justify-end p-5 mb-3 bg-white border-bottom-gray`}>
            <ButtonCustom
              className={`${css.buttonBack} mr-2`}
              key="cancel"
              size="large"
              type="default"
              text={!vacancyData ? "Отменить" : "Назад"}
              onClick={onCloseForm}
            />
            {!vacancyData && (
              <ButtonCustom
                className={`${css.buttonBack} mr-2`}
                key="save"
                size="large"
                type="default"
                text="Сохранить как черновик"
                disabled={!isVacancyFormHasValue}
              />
            )}
            <ButtonCustom
              key="ok"
              size="large"
              text={!isVacancyPublished
                ? `${createOrSaveVacancyText} и опубликовать вакансию`
                : "Опубликовать вакансию"
              }
              type="primary"
              htmlType="submit"
              className={css.buttonOk}
              disabled={!isVacancySubmittable}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateVacancy;
