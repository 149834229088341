import axios from "../utils/axios";
import {
    ClientBanksDataParamsType,
    CreateCommentType,
    CreateLabourRegulationType,
    DocumentClientFieldsUpdateType,
    FilterClientsType,
    getCommentsParamsType,
    RegistrationContactDetailsType,
    TypicalRequestToClientType
} from "app/types";

const API_CLIENT: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_CLIENT;

//ручка получения схемы клиента
export const getSchemaClient = async (): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/schemas/client");
};

//ручка получения листинга клиентов и фильтрация клиентов
export const getListingClients = async (params: null | FilterClientsType = null): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/d/client", params);
};

//ручка получения данных клиента по uuid документа
export const getDocumentClient = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/client/uuid/${uuid}`);
};

//ручка на обновление сущности клиента
export const updateDocumentClient = async(
  uuid?: string,
  body?: DocumentClientFieldsUpdateType | null
): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/client/uuid/${uuid}`, body);
};

//ручка получения данных тарифа клиента по uuid
export const getClientTariff = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/billing/tariffs/v1/client-tariff/${uuid}`);
};

//ручка получения данных информации о банках клиента
export const getClientBanksData = async(
  uuid?: string,
  params: ClientBanksDataParamsType | null = null
): Promise<any> => {
  return axios.GET(url, `api/fin/bank_statements/v1/banks/${uuid}`, params);
};

//ручка отправки обращения к клиенту
export const sendRequestToClient = async (
  body?: TypicalRequestToClientType | null,
  isAppeal?: boolean,
): Promise<any> => {
  const requestVariant: string = isAppeal ? "outgoing-request" : "outgoing";

  return axios.POST(url, `api/or/internal/v1/${requestVariant}/create`, body);
};

//ручка отправки обращения к клиенту (платные консультации)
export const sendRequestToClientPaidConsultations = async (body?: TypicalRequestToClientType | null): Promise<any> => {
  return axios.POST(url, "api/or/internal/v1/chargeable_service/create", body);
};

//ручка получения схемы "Положения об оплате труда"
export const getLabourRegulationSchema = async (): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/schemas/labour-remuneration-regulations");
};

//ручка на создание документа "Положения об оплате труда"
export const creatClientLabourRegulation = async (body?: CreateLabourRegulationType | null): Promise<any> => {
  return axios.POST(url, "api/mdm/documents/v1/d/labour-remuneration-regulations/1", body);
};

//ручка на получение документа "Положение об оплате труда"
export const getClientLabourRegulation = async (uuid: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/labour-remuneration-regulations/uuid/${uuid}`);
};

//ручка на обновление сущности документа "Положение об оплате труда"
export const updateClientLabourRegulation = async (
  uuid: string,
  body?: CreateLabourRegulationType | null
): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/labour-remuneration-regulations/uuid/${uuid}`, body);
};

//ручка регистрации факта просмотра контактных данных в карточке клиента
export const viewContactDetails = async (body?: RegistrationContactDetailsType | null): Promise<any> => {
  return axios.POST(url, "api/mdm/documents/v1/viewed_contact_data_log", body);
};

//ручка получения комментариев
export const getComments = async (params: null | getCommentsParamsType = null): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/d/client-comment", params);
};

//ручка создания комментария
export const createComments = async (body: CreateCommentType | null): Promise<any> => {
  return axios.POST(url, "api/mdm/documents/v1/d/client-comment/", body);
};

//ручка создания заявка на прекращение обслуживания (создание задачи -> платные консультации)
export const sendRequestToTerminateService = async (body?: TypicalRequestToClientType | null): Promise<any> => {
  return axios.POST(url, "api/or/internal/v1/termination-of-service/create", body);
};

//ручка создания заявки на повышение тарифа
export const sendRequestToTariffIncrease = async (body?: TypicalRequestToClientType | null): Promise<any> => {
  return axios.POST(url, "api/or/internal/v1/update-of-tariff/create", body);
};
