import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { Checkbox, Form, Input, Table } from "antd";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search_icon.svg";
import css from "./Tasks.module.css";
import { PaginationCustom } from "../../../../ui-kit/PaginationCustom/PaginationCustom";
import { ColumnsType } from "antd/es/table";
import { NavigateFunction, useNavigate, Link } from "react-router-dom";
import {
  DocumentClientType,
  FilterClientsType,
  FilterTasksType,
  TasksListingDataType,
  TypicalRequestToClientResponseType
} from "app/types";
import {
  getDocumentTaskFailure,
  getTaskPageNumber,
  getTaskPageSize,
  getTasksData,
  setTaskListingParams,
} from "../../../../../actions/tasks.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { GotoTaskCard } from "../../../../../utils/GotoTaskCard";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import ModalContent from "./ModalContents/ModalContent";
import ZeroClientTasks from "./ZeroClientTasks/ZeroClientTasks";
import { statusIcon } from "../../../../../utils/statusIcon";
import * as jose from "jose";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ZeroSearch from "../../../ZeroSearch/ZeroSearch";
import { filterParamsToArray } from "../../../../../utils/filterParamsToArray";
import { useTableSorter } from "../../../../../utils/handleSorterTable";
import { roleResolver } from "../../../../../utils/roleResolver";
import DraftWarning from "../../../../../utils/DraftWarning/DraftWarning";
import { taskStatus, taskType } from "../../../../../utils/catalog";
import ModalCreatAppeal from "./ModalCreatAppeal/ModalCreatAppeal";
import { sendRequestStatusToClientClear } from "../../../../../actions/client.actions";

interface ITasksProps {
  clientInfo: DocumentClientType | null;
}

type FormPropsType = {
  taskName: string | null,
}

enum ListingPage {
  firstPage = 1,
}

const Tasks: FC<ITasksProps> = ({clientInfo}): JSX.Element => {
  const [form] = Form.useForm();
  const values: FormPropsType = Form.useWatch([], form);
  const { taskName } = values || {};
  
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isModalContentOpen, setIsModalContentOpen] = useState<boolean>(false);
  const [isModalAppealOpen, setIsModalAppealOpen] = useState<boolean>(false);
  
  const {
    tasksData,
    tasksPage,
    tasksPageSize,
    totalDocs,
    tasksParams
  } = useSelector((state: AppStateType) => state.tasks);

  // логика пагинации страницы
  const [currentPage, setCurrentPage] = useState<number>(tasksPage);
  const [pageSize, setPageSize] = useState<number>(tasksPageSize);

  useEffect(() => {
    dispatch(getDocumentTaskFailure());
  }, []);

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getTaskPageNumber(newPage));
    setParamsData({
      ...paramsData,
      page: newPage,
    });
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    dispatch(getTaskPageSize(newPageSize));
    setCurrentPage(ListingPage.firstPage);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    setParamsData({
      ...paramsData,
      page: ListingPage.firstPage,
      page_size: newPageSize,
    });
  };

  const requestToClient: TypicalRequestToClientResponseType | null =
    useSelector((state: AppStateType) => state.client.requestToClient);

  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const [paramsData, setParamsData] = useState<FilterTasksType | null>(tasksParams
    ? tasksParams
    : {
      "client.uuid": clientInfo?.fields?.uuid,
      page_size: tasksPageSize,
      page: tasksPage,
      order_by: "-number",
      }
  );
  const [selectedTaskStatuses, setSelectedTaskStatuses] = useState<string[]>(
    tasksParams?.status_task
      ? filterParamsToArray(tasksParams?.status_task as string)
      : []);
  const [selectedTaskTypes, setSelectedTaskTypes] = useState<string[]>(
    tasksParams?.task_type_lookup
      ? filterParamsToArray(tasksParams.task_type_lookup as string)
      : []);
  const [isLoadingData, setLoadingData] = useState<boolean>(true);

  useEffect(() => {
    if (taskName?.trim() === "") {
      setParamsData((prev: FilterTasksType | null) => {
        if (prev && prev.clients !== null) {
          const { clients, ...rest } = prev;
          dispatch(setTaskListingParams({...rest}));
          return {...rest};
        } else {
          dispatch(setTaskListingParams(prev));
          return prev;
        }
      });
    }

    const timeout: NodeJS.Timeout = setTimeout(():void => {
      const hasInputValue: boolean = taskName?.trim() !== ""
        && typeof taskName === "string"
        && (!paramsData?.clients || paramsData?.clients !== taskName);
      
      if (hasInputValue) {
        setParamsData((prev: FilterTasksType | null) => {
          const updatedParams = {
            ...prev,
            clients: taskName?.trim()
          };
          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [taskName]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue("taskName", e.target.value);

    if (e.target.value) {
      setTimeout((): void => {
        setCurrentPage(ListingPage.firstPage);
        dispatch(getTaskPageNumber(ListingPage.firstPage));
      }, 2000);
    } else {
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
    }
  };

  const onTaskStatusFilterChange = (e: CheckboxChangeEvent, taskStatus: string): void => {
    const removeStatusFilter: string[] =
      selectedTaskStatuses?.filter((filter: string): boolean => filter !== taskStatus);
    setSelectedTaskStatuses(e.target.checked ? [...selectedTaskStatuses, taskStatus] : removeStatusFilter);
  };

  const isTaskStatusFilterChecked = (taskStatus: string): boolean => {
    return selectedTaskStatuses?.includes(taskStatus);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const controller: AbortController = new AbortController();

    if (requestToClient) {
      timer = setTimeout((): void => {
        setLoadingData(true);
        dispatch(getTasksData(paramsData, controller.signal))
          .finally(() => {
            setLoadingData(false);
          });
        dispatch(setTaskListingParams(paramsData));
        dispatch(sendRequestStatusToClientClear(0));
      }, 1500);
    } else {
      setLoadingData(true);
      dispatch(getTasksData(paramsData, controller.signal))
        .finally(() => {
          setLoadingData(false);
        });
    }

    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [dispatch, paramsData, requestToClient]);

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setParamsData);

  const renderTaskType = (record: TasksListingDataType, clientUuid?: string): JSX.Element => {
    const {
      uuid,
      task_type,
      topic,
      support_topic
    } = record?.fields || {};
    
    return (
      <Link
        className={css.tableCell}
        to={clientUuid ? `/clients/${clientUuid}/task/${uuid}` : `/task/${uuid}`}
      >
        {topic || support_topic ? (
          <div className={css.taskTopic}>
            <span>
              {task_type?.[0]?.label}
            </span>
            <span>
              {support_topic ?? topic}
            </span>
          </div>
        ) : (
          <span>
            {record?.fields?.task_type?.[0]?.label}
          </span>
        )}
      </Link>
    );
  };

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const renderDeadline = (text: string, record: TasksListingDataType, clientUuid?: string): JSX.Element => {
    const [year, month, day]: string[] = text.slice(0, 10).split("-");
    const deadline: string = `${day}.${month}.${year}`;

    return (
      <Link
        className={`${css.tableCell} flex flex-col`}
        to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
      >
        <span className="gray-color">до {deadline}</span>
      </Link>
    );
  };

  const filterTaskStatus = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          {taskStatus.map(({status, label}) => (
            <Checkbox
              key={status}
              className="table-checkbox"
              onChange={(e: CheckboxChangeEvent) => onTaskStatusFilterChange(e, status)}
              checked={isTaskStatusFilterChecked(status)}
            >
              {label}
            </Checkbox>
          ))}
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={clearTaskStatusFilters}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={() => {
              confirm();
              applyTaskStatusFilters();
            }}
          />
        </div>
      </div>
    );
  };

  const applyTaskStatusFilters = (): void => {
    setParamsData((prevParamsData: FilterClientsType | null) => {
      const updateParams: FilterClientsType = {
        ...prevParamsData,
        status_task: `[${selectedTaskStatuses}]`,
        page: ListingPage.firstPage,
      };

      dispatch(setTaskListingParams(updateParams));
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
      return updateParams;
    });
  };

  const clearTaskStatusFilters = (): void => {
    setSelectedTaskStatuses([]);
    setParamsData((prevParamsData: FilterClientsType | null) => {
      const updateParams: FilterClientsType = {
        ...prevParamsData,
        status_task: null,
        page: ListingPage.firstPage,
      };

      dispatch(setTaskListingParams(updateParams));
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
      return updateParams;
    });
  };

  const onTaskTypeFilterChange = (e: CheckboxChangeEvent, taskType: string): void => {
    const removeTaskTypeFilter: string[] =
      selectedTaskTypes?.filter((filter: string): boolean => filter !== taskType);
    setSelectedTaskTypes(e.target.checked ? [...selectedTaskTypes, taskType] : removeTaskTypeFilter);
  };

  const isTaskTypeFilterChecked = (taskType: string): boolean => {
    return selectedTaskTypes?.includes(taskType);
  };

  const filterTaskType = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          {taskType.map(({status, label}) => (
            <Checkbox
              key={status}
              className="table-checkbox"
              onChange={(e: CheckboxChangeEvent) => onTaskTypeFilterChange(e, status)}
              checked={isTaskTypeFilterChecked(status)}
            >
              {label}
            </Checkbox>
          ))}
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={clearTaskTypeFilters}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={() => {
              confirm();
              applyTaskTypeFilters();
            }}
          />
        </div>
      </div>
    );
  };

  const applyTaskTypeFilters = (): void => {
    setParamsData((prevParamsData: FilterClientsType | null) => {
      const updateParams: FilterClientsType = {
        ...prevParamsData,
        task_type_lookup:  selectedTaskTypes?.length ? `[${selectedTaskTypes}]` : null,
        page: ListingPage.firstPage,
      };

      dispatch(setTaskListingParams(updateParams));
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
      return updateParams;
    });
  };

  const clearTaskTypeFilters = (): void => {
    setSelectedTaskTypes([]);
    setParamsData((prevParamsData: FilterClientsType | null) => {
      const updateParams: FilterClientsType = {
        ...prevParamsData,
        task_type_lookup:  null,
        page: ListingPage.firstPage,
      };

      dispatch(setTaskListingParams(updateParams));
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
      return updateParams;
    });
  };

  const columns: ColumnsType<TasksListingDataType> = [
    {
      title: tableTitle("№"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "number"],
      key: "№",
      width: "4.4%",
      align: "center",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "number"),
      }),
      render: (_, record: TasksListingDataType) => GotoTaskCard(record, clientInfo?.fields?.uuid),
    },
    {
      title: tableTitle("Задача"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "task_type"],
      key: "Тип задач",
      width: "74.3%",
      className: css.tableHeader,
      filtered: !!selectedTaskTypes?.length,
      filterDropdown: ({ confirm }) => filterTaskType(confirm),
      onFilterDropdownOpenChange: (visible: boolean): void => {
        if (!visible) {
          applyTaskTypeFilters();
        }
      },
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "task_type.label"),
      }),
      render: (_: string, record: TasksListingDataType) => renderTaskType(record, clientInfo?.fields?.uuid),
    },
    {
      title: tableTitle("Статус"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "status_task"],
      key: "Статус",
      width: "12.1%",
      className: css.tableHeader,
      filtered: !!selectedTaskStatuses?.length,
      filterDropdown: ({ confirm }) => filterTaskStatus(confirm),
      onFilterDropdownOpenChange: (visible: boolean): void => {
        if (!visible) {
          applyTaskStatusFilters();
        }
      },
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "status_task"),
      }),
      render: (text: string, record: TasksListingDataType) => statusIcon(text, record, clientInfo?.fields?.uuid),
    },
    {
      title: tableTitle("Срок"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "deadline"],
      key: "Срок",
      width: "9.2%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "deadline"),
      }),
      render: (text: string, record: TasksListingDataType) =>
        text ? renderDeadline(text, record, clientInfo?.fields?.uuid) : "",
    },
  ];

  const showContentModal = (): void => {
    setIsModalContentOpen(true);
  };
  
  const showAppealModal = (): void => {
    setIsModalAppealOpen(true);
  };
  
  const accountantRole: boolean = roleResolver(decodedToken).isAccountant;
  const adminRole: boolean = roleResolver(decodedToken).isAdmin;
  const isTechSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const assistantRole: boolean = roleResolver(decodedToken).isAssistant;
  const isDraftClient: boolean = clientInfo?.fields?.status_client === "DRAFT";
  const hasAccessTaskCreate: boolean =
    (accountantRole || adminRole || assistantRole || isTechSupport) && !isDraftClient;
  const hasAccessAppealCreate: boolean = (isContactCenter || adminRole || isTechSupport) && !isDraftClient;

  const zeroTasks = (): JSX.Element => {
    const hasFilter: boolean = !!paramsData?.clients
      || !!paramsData?.task_type_lookup
      || !!paramsData?.status_task;
    
    return (
      <>
        {!isLoadingData && hasFilter && <ZeroSearch />}
        {!isLoadingData && !hasFilter && <ZeroClientTasks />}
      </>
    );
  };
  
  const initialValues: FormPropsType = {
    taskName: paramsData?.clients ?? null,
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
    >
      <DraftWarning />
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          Задачи связанные с клиентом
        </h1>
        {hasAccessTaskCreate && (
          <div className={`${css.actions} mb-3`}>
            <div className="mr-12">
              <div className={css.actionsHead}>
                Создать задачу
              </div>
              <div className={css.actionsText}>
                Для получения необходимой информации от клиента или предоставления ему данных,
                создайте задачу и заполните необходимую информацию
              </div>
            </div>
            <ButtonCustom
              type="primary"
              id="createTask"
              className={css.button}
              text="Создать исходящую задачу"
              onClick={showContentModal}
            />
          </div>
        )}
        {hasAccessAppealCreate && (
          <div className={`${css.actions} mb-3`}>
            <div className="mr-12">
              <div className={css.actionsHead}>
                Создать обращение от клиента
              </div>
              <div className={css.actionsText}>
                Вы можете создать задачу «Обращение» для исполнения ответственным бухгалтером
              </div>
            </div>
            <ButtonCustom
              type="primary"
              id="createAppeal"
              className={css.button}
              text="Создать обращение"
              onClick={showAppealModal}
            />
          </div>
        )}
        <Form.Item name="taskName" noStyle>
          <Input
            id="taskName"
            className={css.input}
            allowClear
            suffix={<SearchIcon className={css.searchIcon} />}
            placeholder="Найти по названию задачи"
            onChange={handleInputChange}
          />
        </Form.Item>
        <div>
          <Table
            className={css.table}
            columns={columns}
            loading={isLoadingData}
            dataSource={tasksData?.length ? tasksData : []}
            pagination={false}
            locale={{ emptyText: zeroTasks() }}
          />
          {tasksData?.length ? (
            <PaginationCustom
              className={css.paginationShowTotal}
              total={totalDocs ? totalDocs : 0}
              defaultPageSize={10}
              currentPage={currentPage}
              pageSize={pageSize}
              defaultCurrent={1}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          ) : (
            <div className={css.noTasks}/>
          )}
        </div>
        {isModalContentOpen && (
          <ModalContent
            isModalOpen={isModalContentOpen}
            setIsModalOpen={setIsModalContentOpen}
          />
        )}
        <ModalCreatAppeal
          isModalOpen={isModalAppealOpen}
          setIsModalOpen={setIsModalAppealOpen}
        />
      </div>
    </Form>
  );
};

export default Tasks;
