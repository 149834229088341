import React, { Dispatch, FC, JSX, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { renderAlphaDocStatus } from "../../../../../utils/renderAlphaDocStatus";
import {
  SchemaType,
  VacancyType,
  VacancyListType,
  AlphaListParamsType,
  DocumentSchemaLabelMapType,
} from "app/types";
import { documentSchemaLabelsMapper } from "../../../../../utils/documentSchemaLabelsMapper";
import css from "./AboutVacancy.module.css";
import { getDocumentSchema } from "../../../../../actions/document.actions";
import { getVacancyData } from "../../../../../actions/alpha/vacancy.actions";
import { AppDispatch } from "../../../../../store/store";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { getApplicationsList } from "../../../../../actions/alpha/application.actions";

interface IAboutVacancy {
  vacancyData: VacancyListType | null
  setVacancyUuid: Dispatch<SetStateAction<string>>;
}

const AboutVacancy: FC<IAboutVacancy> = ({
  vacancyData,
  setVacancyUuid,
}): JSX.Element => {

  const documentSchema: SchemaType | null =
    useSelector((state: AppStateType) => state.document.documentSchema);

  const vacancyCardLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(documentSchema);

  const dispatch = useDispatch<AppDispatch>();

  const params: string = useLocation()?.pathname;

  useEffect(() => {
    const vacancyUuid: string = params?.split("/")?.[3];

    const fetchVacancyApplicationParams: AlphaListParamsType | null = {
      page: 1,
      page_size: 5,
      "vacancy.uuid": vacancyUuid ? vacancyUuid : null,
    };

    setVacancyUuid(vacancyUuid);

    dispatch(getVacancyData(vacancyUuid));
    dispatch(getApplicationsList(fetchVacancyApplicationParams));
  }, [params]);

  useEffect(() => {
    dispatch(getDocumentSchema("vacancy"));
  }, []);

  const {
    end_date,
    division,
    participants,
    vacancy_name,
    project_name,
    published_at,
    working_hours,
    document_owner,
    working_address,
    financing_source,
    occupancy_format,
    vacancy_description,
    occupancy_legal_form,
  } = vacancyData?.fields as VacancyType ?? {};

  const renderCardBlock = (value: string | null, slug: string): "" | null | JSX.Element => {
    return value && (
      <li className="textBlock">
        <span className="flex-none gray-color field-label">
          {vacancyCardLabel?.[slug]?.label}
        </span>
        <span className="flex-grow text-sm p-0">
          {value}
        </span>
      </li>
    );
  };

  return (
    <div>
      <div className={`${css.containerBlock} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          О вакансии
        </h1>
        <ul>
          {vacancyData?.fields?.vacancy_status && (
            <li className="textBlock">
              <span className="flex-none gray-color field-label">
                {vacancyCardLabel?.vacancy_status?.label}
              </span>
              <span className="flex-grow text-sm p-0">
                <div>
                  {renderAlphaDocStatus(vacancyData?.fields?.vacancy_status ?? "")}
                </div>
              </span>
            </li>
          )}
          {renderCardBlock(vacancy_name, "vacancy_name")}
          {renderCardBlock(vacancy_description, "vacancy_description")}
          {renderCardBlock(occupancy_format, "occupancy_format")}
          {renderCardBlock(occupancy_legal_form?.[0]?.label ?? "", "occupancy_legal_form")}
          {renderCardBlock(division?.[0]?.label ?? "", "division")}
          {renderCardBlock(document_owner?.[0]?.label ?? "", "document_owner")}
          {renderCardBlock(financing_source, "financing_source")}
          {renderCardBlock(participants?.[0]?.label ?? "", "participants")}
          {renderCardBlock(published_at, "published_at")}
          {/*Рисуем блоки даты без функции, пока не завезут схему на бэк*/}
          <li className="textBlock">
            <span className="flex-none gray-color field-label">
              Дата создания
            </span>
            <span className="flex-grow text-sm p-0">
              {dayjs(vacancyData?.created_at).isValid() && dayjs(vacancyData?.created_at)?.format("DD.MM.YYYY")}
            </span>
          </li>
          <li className="textBlock">
            <span className="flex-none gray-color field-label">
              Дата обновления
            </span>
            <span className="flex-grow text-sm p-0">
              {dayjs(vacancyData?.updated_at).isValid() && dayjs(vacancyData?.created_at)?.format("DD.MM.YYYY")}
            </span>
          </li>
          {renderCardBlock(end_date, "end_date")}
        </ul>
      </div>
      <div className={`${css.containerBlock} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-[20px]">
          Требования к соискателю
        </h1>
        <ul>
          {!!vacancyData?.fields?.vacancy_status && (
            <li className="textBlock">
              <span className="flex-none gray-color field-label">
                {vacancyCardLabel?.skills?.label}
              </span>
              <span className="flex gap-[10px]">
                {vacancyData?.fields?.skills?.map(({ uuid, skill_name }) => (
                  <div key={uuid} className={`${css.skillsText} text-[14px]`}>
                    {skill_name}
                  </div>
                ))}
              </span>
            </li>
          )}
          {renderCardBlock(working_hours, "working_hours")}
          {renderCardBlock(working_address, "working_address")}
          {/* Комментируем пока поле не добавят в схему на бэке */}
          {/*<li className="textBlock">*/}
          {/*  <span className="flex-none gray-color field-label">*/}
          {/*    Желаемый срок выхода на работу*/}
          {/*  </span>*/}
          {/*  <span className="flex-grow text-[14px]">*/}
          {/*    01.01.2024 - 07.01.2024*/}
          {/*  </span>*/}
          {/*</li>*/}
        </ul>
      </div>
      <div className={`${css.containerBlock} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-[20px]">
          Наниматель
        </h1>
        <ul>
          {renderCardBlock(project_name, "project_name")}
          {renderCardBlock(end_date, "end_date")}
          {/* Комментируем пока поле не добавят в схему на бэке */}
          {/*<li className="textBlock">*/}
          {/*  <span className="flex-none gray-color field-label">*/}
          {/*    Нанимающий менеджер*/}
          {/*  </span>*/}
          {/*  <span className="flex-grow text-[14px]">*/}
          {/*    Орлов Евгений Юрьевич*/}
          {/*  </span>*/}
          {/*</li>*/}
        </ul>
      </div>
    </div>
  );
};

export default AboutVacancy;
