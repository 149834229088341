import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../../reducers/mainReducer";
import {
  GET_CONTRACTORS_LIST_SUCCESS,
  GET_CONTRACTORS_LIST_FAILURE,
  GET_CONTRACTOR_SUCCESS,
  GET_CONTRACTOR_FAILURE,
} from "../../constants/actionTypes";
import {
  ContractorsDocType,
  ContractorsListType,
  AlphaListParamsType,
} from "app/types";
import * as ContractorsAPI from "../../api/alpha/contractors.api";

export type ContractorsActionsTypes =
  | GetContractorsListSuccessType
  | GetContractorsListFailureType
  | GetContractorSuccessType
  | GetContractorFailureType
  ;

// Логика получения листинга контрагентов
export type GetContractorsListSuccessType = {
  type: typeof GET_CONTRACTORS_LIST_SUCCESS;
  payload: {
    contractorsList: ContractorsDocType;
  };
};

const getContractorsListSuccess = (contractorsList: ContractorsDocType): GetContractorsListSuccessType => ({
  type: GET_CONTRACTORS_LIST_SUCCESS,
  payload: {
    contractorsList
  }
});

export type GetContractorsListFailureType = {
  type: typeof GET_CONTRACTORS_LIST_FAILURE;
  payload: {
    error: any;
  };
};

const getContractorsListFailure = (error: any): GetContractorsListFailureType => ({
  type: GET_CONTRACTORS_LIST_FAILURE,
  payload: {
    error
  }
});

export const getContractorsList = (params?: AlphaListParamsType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ContractorsActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ContractorsAPI.getContractors(params);

    dispatch(getContractorsListSuccess(response.data));
  } catch (error: any) {
    dispatch(getContractorsListFailure(error.message));
  }
};

// Логика получения контрагента
export type GetContractorSuccessType = {
  type: typeof GET_CONTRACTOR_SUCCESS;
  payload: {
    contractorData: ContractorsListType;
  };
};

const getContractorSuccess = (contractorData: ContractorsListType): GetContractorSuccessType => ({
  type: GET_CONTRACTOR_SUCCESS,
  payload: {
    contractorData
  }
});

export type GetContractorFailureType = {
  type: typeof GET_CONTRACTOR_FAILURE;
  payload: {
    error: any;
  };
};

const getContractorFailure = (error: any): GetContractorFailureType => ({
  type: GET_CONTRACTOR_FAILURE,
  payload: {
    error
  }
});

export const getContractorData = (contractorUuid?: string | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ContractorsActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ContractorsAPI.getContractor(contractorUuid);

    dispatch(getContractorSuccess(response.data));
  } catch (error: any) {
    dispatch(getContractorFailure(error.message));
  }
};
