import React from "react";
import { message } from "antd";
import css from "./MassageVarian.module.css";
import { ReactComponent as Error } from "../../assets/icons/warning_circle_icon.svg";

export const messageSuccess = (text: string): void => {
  message.open({
    content: (
      <div className={css.messageText}>
        {text}
      </div>
    ),
    type:"success",
  });
};

export const messageError = (text: string): void => {
  message.open({
    content: (
      <div className="flex items-center">
        <Error className="flex-shrink-0 mr-[4px]" />
        <div className={css.messageText}>
          {text}
        </div>
      </div>
    ),
    icon: false,
  });
};