import React, { FC, JSX, useEffect } from "react";
import { Tabs, TabsProps } from "antd";
import css from "./Profile.module.css";
import ProfilePersonalInformation from "./ProfilePersonalInformation/ProfilePersonalInformation";
import ProfileClientsList from "./ProfileClientsList/ProfileClientsList";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { fetchUserInfo, getUserTokenDecryptionData } from "../../actions/account.actions";
import LoadingCustom from "../ui-kit/LoadingCustom/LoadingCustom";
import { AppDispatch } from "../../store/store";
import { roleResolver } from "../../utils/roleResolver";
import ProfileAbsenteesList from "./ProfileAbsenteesList/ProfileAbsenteesList";
import { useLocation } from "react-router-dom";

interface IProfileProps {
}

const host: string | undefined = process.env.REACT_APP_API_TASKS_URL;

const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : host;

enum ProfileTabs {
  PersonalInformation = "1",
  Clients = "2",
  Absences = "3",
}

const Profile: FC<IProfileProps> = (): JSX.Element => {
  const location = useLocation();
  const state = location.state;

  const dispatch = useDispatch<AppDispatch>();

  const { decodedToken, isFetching } = useSelector((state: AppStateType) => state.account);

  useEffect((): void => {
    dispatch(fetchUserInfo());
    dispatch(getUserTokenDecryptionData());
  }, []);

  const techSupportRole: boolean = roleResolver(decodedToken).isTechSupport;
  
  const fullUserName: string = `
    ${decodedToken?.last_name || ""}
    ${decodedToken?.first_name || ""}
    ${decodedToken?.second_name || ""}`;

  const itemsTabs: TabsProps["items"] = [
    {
      key: ProfileTabs.PersonalInformation,
      label: "Персональные данные",
      children: <ProfilePersonalInformation />,
    },
    {
      key: ProfileTabs.Clients,
      label: techSupportRole ? "Обслуживаемые клиенты" : null,
      children: techSupportRole ? <ProfileClientsList/> : null,
    },
    {
      key: ProfileTabs.Absences,
      label: "Отсутствия",
      children: <ProfileAbsenteesList/>,
    },
  ];

  return (!isFetching ? (
      <div className={css.container}>
        <div className="flex justify-between">
          <h1 className={css.containerHeader}>
            {fullUserName}
          </h1>
          {techSupportRole && (
            <ButtonCustom
              size="large"
              text="Администрирование"
              type="default"
              className={css.toAdmin}
              onClick={() => window.location.replace(`${url}/admin`)}
            />
          )}
        </div>
        <Tabs
          defaultActiveKey={state?.isAbsentCard ? ProfileTabs.Absences : ProfileTabs.PersonalInformation}
          items={itemsTabs}
        />
      </div>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default Profile;
