import React, { JSX } from "react";
import { TasksListingDataType } from "app/types";
import { Tooltip } from "antd";
import css from "../components/Tasks/Tasks.module.css";
import { ReactComponent as BellReadIcon } from "../assets/icons/bell_default_v2.svg";
import { ReactComponent as BellUnReadIcon } from "../assets/icons/bell_message_v2.svg";
import { ReactComponent as RedBallIcon } from "../assets/icons/message_point.svg";
import { Link } from "react-router-dom";

const contentTooltip = (text: string): JSX.Element => (
  <div className={css.tooltip}>
    {text}
  </div>
);

export const statusBellIcon = (record: TasksListingDataType): JSX.Element => {
  const isReadTask: boolean = record?.notification ? !!record?.notification?.viewed_at : true;

  return (
    <Link
      className={css.tableCellStatus}
      to={`/task/${record?.fields?.uuid}`}
    >
      <div className="flex items-center">
        <div className={`${!isReadTask ? css.bellUnRead : {}} relative cursor-pointer`}>
          <Tooltip
            placement="top"
            title={contentTooltip(isReadTask ? "Нет обновлений" : "Есть обновления")}
            trigger="hover"
            arrow={{ pointAtCenter: true }}
          >
            {isReadTask ? (
              <BellReadIcon className="flex-shrink-0 mr-2" />
            ) : (
              <BellUnReadIcon className="flex-shrink-0 mr-2" />
            )}
          </Tooltip>
          {!isReadTask && <RedBallIcon className={css.redBallIcon} />}
        </div>
      </div>
    </Link>
  );
};
