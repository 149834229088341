import React, { FC, JSX, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { Form, Input, Spin } from "antd";
import css from "./LoginForm.module.css";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as Success } from "../../assets/icons/check_circle_icon_green.svg";
import { decodeToken, fetchUserInfo, getUsersListingData } from "../../actions/account.actions";
import {AppDispatch} from "../../store/store";

interface IConfirmNewPasswordFormProps {
}

const ConfirmNewPasswordForm: FC<IConfirmNewPasswordFormProps> = (): JSX.Element => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  //не можем получить данные т.к не авторизированы
  // const {userData} = useSelector((state: AppStateType) => state.account);
  //
  // console.log(userData);
  //
  // useEffect((): void => {
  //   dispatch(getUsersListingData());
  // }, []);

  //моковые данные, как будет подключен бэк и email логику поменять
  const [confirmPassword, setConfirmPassword] = useState<boolean>(true);

  //моковая переменная
  const [confirm, setConfirm] = useState<boolean>(true);

  // const { confirmNewPasswordStatus } = useSelector((state: AppStateType) => state.account);

  const navigate: NavigateFunction = useNavigate();

  // useEffect((): void => {
  //   navigate("/login");
  // }, [navigate]);

  const saveNewPassword = (): void => {
  };

  const cancelReset = (): void => {
    window.location.replace("login");
  };

  const requestNewLink = (): void => {
  };

  return (
    // <Spin
    //   spinning={loading}
    //   size="large"
    //   className="mt-96"
    // >
    //   {confirmNewPasswordStatus === 204 && (
    <>
    {confirm && (
        <div className={css.container}>
          <h1 className={`text-center font-bold mb-[12px] ${css.header}`}>
            Личный кабинет бухгалтера
          </h1>
          <span className={css.resetText}>
            Восстановление пароля
          </span>
          {confirmPassword ? (
            <Form
              name="normal_login"
              className={`flex flex-col ${css.containerReset}`}
              initialValues={{remember: true}}
              onFinish={saveNewPassword}
            >
              <Form.Item
                name="password"
                className="mb-[20px]"
                rules={[
                  {required: true, message: "Пожалуйста, введите корректный пароль!"},
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      if (value.length < 8) {
                        return Promise.reject(new Error("Пароль должен быть длиной не менее 8 символов!"));
                      }

                      if (!/[A-ZА-ЯЁ]/.test(value) || !/[a-zа-яё]/.test(value) || !/[0-9]/.test(value)) {
                        return Promise.reject(new Error("Пароль должен содержать цифры, заглавные и строчные буквы"));
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
                validateTrigger="onBlur"
              >
                <Input.Password
                  className="p-[8px] rounded-[8px]"
                  size="large"
                  placeholder="Введите новый пароль"
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                className="mb-[20px]"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, подтвердите пароль!"
                  },
                  ({getFieldValue}) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error("Введенные вами пароли не совпадают!"));
                    }
                  })
                ]}
              >
                <Input.Password
                  className="p-[8px] rounded-[8px]"
                  size="large"
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Повторите новый пароль"
                />
              </Form.Item>
              <Form.Item
                shouldUpdate
                className="mb-0 text-center"
              >
                <ButtonCustom
                  type="primary"
                  htmlType="submit"
                  text="Подтвердить"
                  onClick={() => setConfirmPassword(false)}
                  className={`${css.button} w-full`}
                />
              </Form.Item>
            </Form>
          ) : (
            <>
              <div className={`flex justify-between ${css.actions} mb-[20px] p-[12px]`}>
                <Success className="flex flex-shrink-0 mr-[10px]"/>
                <div className="inline-block mb-1 text-sm">
                  Пароль успешно изменен, теперь вы можете войти в свой аккаунт используя новый пароль.
                </div>
              </div>
              <ButtonCustom
                type="primary"
                htmlType="submit"
                text="Войти"
                onClick={cancelReset}
                className={`${css.button} w-full`}
              />
            </>
          )}
        </div>
      )}
    </>
      // {confirmNewPasswordStatus === 400 && (
      //   <span className={css.confirmPasswordMessage}>
      //     Ваша ссылка устарела. Запросите новую ссылку на восстановление пароля.
      //     <span
      //       className="pl-1 text-blue-frosty-sky underline cursor-pointer"
      //       onClick={requestNewLink}
      //     >
      //       Восстановить пароль
      //     </span>
      //   </span>
      // )}
    // </Spin>
  );
};

export default ConfirmNewPasswordForm;
