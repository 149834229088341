import { TasksListingDataType } from "app/types";
import css from "../components/Tasks/Tasks.module.css";
import React, { JSX } from "react";
import { Link } from "react-router-dom";
import { reformatName } from "./reformatName";

export const RenderTaskResponsibleReference = (
  _: string,
  record: TasksListingDataType,
) => {
  const tableName = (): string | JSX.Element => {
    const fullName: string[] =
     reformatName(record?.fields?.responsible_reference?.[0]?.label ?? "").trim().split(" ");
    
    const [lastName, firstName, secondName] = fullName;
    
    if (!lastName || !firstName || !secondName) {
      return <div className="flex items-center justify-center">-</div>;
    }
    
    return `${lastName} ${firstName.charAt(0)}. ${secondName.charAt(0)}.`;
  };
  
  return (
    <Link
      className={css.tableCell}
      to={`/task/${record?.fields?.uuid}`}
    >
      {tableName()}
    </Link>
  );
};
