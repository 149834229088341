import React, { FC, JSX, useEffect, useState } from "react";
import { Collapse, CollapseProps, ConfigProvider } from "antd";
import css from "./TaskFilterForm.module.css";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow_down_icon.svg";

interface ITaskFilterFormProps {
  headerText: string,
  renderFilter: JSX.Element,
  isModalOpen: boolean,
}

const TaskFilterForm: FC<ITaskFilterFormProps> = ({
  headerText,
  renderFilter,
  isModalOpen
}): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string[] | string>([headerText]);
  
  useEffect(() => {
    if (!isModalOpen) {
      setActiveKey([headerText]);
    }
  }, [isModalOpen]);
  
  const handleCollapseChange = (key: string[] | string): void => {
    setActiveKey(key);
  };
  
  const collapseLabel = (text: string): JSX.Element => (
    <div className={css.collapseContainer}>
      <div className={css.collapseLabel}>
        {text}
      </div>
      <div className={`${css.collapseIcon} ${activeKey.length ? css.activeIcon : ""}`}>
        <ArrowIcon />
      </div>
    </div>
  );
  
  const getItems: (panelStyle: React.CSSProperties) => CollapseProps["items"] = (panelStyle) => [
    {
      key: headerText,
      label: collapseLabel(headerText),
      children: renderFilter,
      style: panelStyle,
      showArrow: false,
    },
  ];
  
  const panelStyle: React.CSSProperties = {
    background: "#ffffff",
    borderRadius: 0,
    border: "none",
    transition: "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out",
    overflow: "hidden",
  };
  
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: 0,
            contentPadding: 0,
          },
        }
      }}
    >
      <Collapse
        bordered={false}
        expandIconPosition="end"
        activeKey={activeKey}
        className={css.collapseStyle}
        items={getItems(panelStyle)}
        onChange={handleCollapseChange}
      />
    </ConfigProvider>
  );
};

export default TaskFilterForm;