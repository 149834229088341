import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../../reducers/mainReducer";
import {
  GET_CONTRACTS_LIST_SUCCESS,
  GET_CONTRACTS_LIST_FAILURE,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
} from "../../constants/actionTypes";
import {
  ContractsDocType,
  ContractsListType,
  AlphaListParamsType,
} from "app/types";
import * as ContractsAPI from "../../api/alpha/contracts.api";

export type ContractsActionsTypes =
  | GetContractsListSuccessType
  | GetContractsListFailureType
  | GetContractSuccessType
  | GetContractFailureType
  ;

// Логика получения листинга контрагентов
export type GetContractsListSuccessType = {
  type: typeof GET_CONTRACTS_LIST_SUCCESS;
  payload: {
    contractsList: ContractsDocType;
  };
};

const getContractsListSuccess = (contractsList: ContractsDocType): GetContractsListSuccessType => ({
  type: GET_CONTRACTS_LIST_SUCCESS,
  payload: {
    contractsList
  }
});

export type GetContractsListFailureType = {
  type: typeof GET_CONTRACTS_LIST_FAILURE;
  payload: {
    error: any;
  };
};

const getContractsListFailure = (error: any): GetContractsListFailureType => ({
  type: GET_CONTRACTS_LIST_FAILURE,
  payload: {
    error
  }
});

export const getContractsList = (params?: AlphaListParamsType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ContractsActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ContractsAPI.getContracts(params);

    dispatch(getContractsListSuccess(response.data));
  } catch (error: any) {
    dispatch(getContractsListFailure(error.message));
  }
};

// Логика получения контрагента
export type GetContractSuccessType = {
  type: typeof GET_CONTRACT_SUCCESS;
  payload: {
    contractData: ContractsListType;
  };
};

const getContractSuccess = (contractData: ContractsListType): GetContractSuccessType => ({
  type: GET_CONTRACT_SUCCESS,
  payload: {
    contractData
  }
});

export type GetContractFailureType = {
  type: typeof GET_CONTRACT_FAILURE;
  payload: {
    error: any;
  };
};

export const getContractFailure = (error?: any): GetContractFailureType => ({
  type: GET_CONTRACT_FAILURE,
  payload: {
    error
  }
});

export const getContractData = (contractUuid?: string | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ContractsActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ContractsAPI.getContract(contractUuid);

    dispatch(getContractSuccess(response.data));
  } catch (error: any) {
    dispatch(getContractFailure(error.message));
  }
};
