export const roleList: Record<string, string> = {
  "contact-center-role": "Сотрудник Контакт Центра",
  "accountant-role": "Бухгалтер",
  "assistant-role": "assistant-role",
  "realm-admin": "Администратор",
  "admin-role": "Администратор",
  "quality-control-role": "Контроль качества",
  "supervisor-role": "Супервайзер",
  "tech-support-role": "Техническая поддержка",
  "alpha-contractor-role": "Заказчик",
};

export const performersRole = (text: string[]): string =>
  text.map((role: string) => roleList[role]).join(", ");
