import React, {
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Input,
  Radio,
  Select,
  Tooltip,
  Divider,
  DatePicker,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getDocumentSchema, getEmployeeDocumentData } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import dayjs, { Dayjs } from "dayjs";
import { DocumentClientProfileType, UpdateDocumentFieldType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { StepContext, TaskQuestionnaireCardSteps } from "../../ClientProfileCard";
import { onlyOneDirector } from "../../../../utils/onlyOneDirector";
import { isDocFieldRequired } from "../../../../utils/isDocFieldRequired";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormPassportProps {
  passportForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isEmployee?: boolean;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  currentDocumentData?: UpdateDocumentFieldType | null;
}

const ClientProfileCardImageViewerFormPassport: FC<IClientProfileCardImageViewerFormPassportProps> = ({
  passportForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  isEmployee,
  currentDocumentData
}) => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const { passportDoc, passportDocSchema } =
    useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const currentStep: number | null = useContext(StepContext);

  const isCeoDetailCurrentStep: boolean = currentStep === TaskQuestionnaireCardSteps.ManagerDetailsStep;

  useEffect(() => {
    if (!!docApproveStatus && isDocApprovalDisabled || isContactCenter) {
      setDocVerified(true);
    } else {
      setDocVerified(false);
    }
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    const documentStatus: string = passportDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = passportDoc?.fields?.files
    ?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, passportDoc]);

  const isAllValuesValid: boolean = Object.values(currentDocumentData ?? {}).every(value =>
    value !== null && value !== undefined
  );

  useEffect(() => {
    isDocRequiredValuesFilled(passportDocSchema, passportForm, setDocApprovalDisabled);

    onFormValuesChange();
  }, [passportForm, passportDocSchema, isAllValuesValid]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("passport-schema"));
      dispatch(getEmployeeDocumentData("passport-schema", documentUuid));
    }
  }, [dispatch, documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = passportDoc?.fields?.decline_reason ?? "";

      if (docMigrationDeclineReason) {
        passportForm.setFieldValue("declineReason", docMigrationDeclineReason);
      } else {
        passportForm.setFieldValue(
          "declineReason",
          "Необходимо приложить верный документ (приложен не тот документ)"
        );
      }
    }

    onFormValuesChange();
  }, [docApproveStatus, passportDoc, passportForm]);

  useEffect(() => {
    const docPassportSex: string = passportDoc?.fields?.sex ?? "";
    const docPassportName: string = passportDoc?.fields?.first_name ?? "";
    const docPassportSeries: string = passportDoc?.fields?.series_number ?? "";
    const docPassportNumber: string = passportDoc?.fields?.passport_number ?? "";
    const docPassportStatus: string = passportDoc?.fields?.document_status ?? "";
    const docPassportIssuedBy: string = passportDoc?.fields?.issued_by ?? "";
    const docPassportLastName: string = passportDoc?.fields?.last_name ?? "";
    const docPassportBirthDate: Dayjs = dayjs(passportDoc?.fields?.date_of_birth);
    const docPassportIssuedDate: Dayjs = dayjs(passportDoc?.fields?.issued_date);
    const docPassportSecondName: string = passportDoc?.fields?.second_name ?? "";
    const docPassportCitizenship: string = passportDoc?.fields?.citizenship ?? "";
    const docPassportDivisionCode: string = passportDoc?.fields?.subdivision_code ?? "";
    const docPassportDeclineReason: string = passportDoc?.fields?.decline_reason ?? "";
    const shouldUpdateDocField: boolean = !!docPassportSeries
      || !!docPassportSex
      || !!docPassportName
      || !!docPassportNumber
      || !!docPassportStatus
      || !!docPassportIssuedBy
      || !!docPassportLastName
      || !!docPassportBirthDate
      || !!docPassportIssuedDate
      || !!docPassportSecondName
      || !!docPassportCitizenship
      || !!docPassportDivisionCode;

    if (shouldUpdateDocField) {
      handlePassportSeriesChange(docPassportSeries);
      handlePassportNumberOnlyChange(docPassportNumber, "passport_number");
      handlePassportDivisionCodeChange(docPassportDivisionCode);
      onApproveChange(docPassportStatus);

      passportForm.setFieldValue("sex", docPassportSex || null);
      passportForm.setFieldValue("last_name", docPassportLastName);
      passportForm.setFieldValue("issued_by", docPassportIssuedBy);
      passportForm.setFieldValue("first_name", docPassportName);
      passportForm.setFieldValue("second_name", docPassportSecondName);
      passportForm.setFieldValue("isApproved", docPassportStatus);
      passportForm.setFieldValue("citizenship", docPassportCitizenship);
      docPassportBirthDate.isValid()
        ? passportForm.setFieldValue("date_of_birth", docPassportBirthDate)
        : passportForm.setFieldValue("date_of_birth", null);
      docPassportIssuedDate.isValid()
        ? passportForm.setFieldValue("issued_date", docPassportIssuedDate)
        : passportForm.setFieldValue("issued_date", null);
    }

    if (docPassportDeclineReason) {
      passportForm.setFieldValue("declineReason", docPassportDeclineReason);
    }
  }, [passportDoc]);

  const handlePassportSeriesChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,4})/);
    const formattedSeries: string = matches
      ? matches[1]
      : "";

    if (formattedSeries?.length === 4 || formattedSeries?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    passportForm.setFieldValue("series_number", formattedSeries);
  };

  const handlePassportDivisionCodeChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    let inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/\D/g, "")
      : event;

    if (inputValue.length > 3) {
      inputValue = inputValue.substring(0, 3) + "-" + inputValue.substring(3, 6);
    }

    if (inputValue?.length === 7 || inputValue?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    passportForm.setFieldValue("subdivision_code", inputValue);
  };

  const handlePassportLettersOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ): void => {
    const inputValue: string = event.target.value.replace(/[^A-Za-zА-Яа-яЁё]/g, "");

    passportForm.setFieldValue(fieldName, inputValue);
  };

  const handlePassportNumberOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement> | string,
    fieldName: string,
  ): void => {
    const inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,6})/);
    const formattedNumber: string = matches
      ? matches[1]
      : "";

    if (formattedNumber?.length === 6 || formattedNumber?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    passportForm.setFieldValue(fieldName, formattedNumber);
  };

  const managerDetailStep: boolean | undefined =
    isCeoDetailCurrentStep ? onlyOneDirector(documentClientProfile) : undefined;

  useEffect(() => {
    if (isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile) && documentClientProfile) {
      const firstName: string | undefined = documentClientProfile?.fields?.passport_document?.[0].first_name;
      const secondName: string | undefined = documentClientProfile?.fields?.passport_document?.[0].second_name;
      const lastName: string | undefined = documentClientProfile?.fields?.passport_document?.[0].last_name;

      passportForm.setFieldValue("first_name", firstName);
      passportForm.setFieldValue("second_name", secondName);
      passportForm.setFieldValue("last_name", lastName);
    }
  }, [passportForm]);

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isPassportFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;
  const disableFullName: boolean | undefined = isPassportFormDisabled || managerDetailStep;

  return (
    <div className="flex flex-col h-90vh">
      <h2 className="mb-2 text-xl font-bold">
        {`Паспортные данные ${isEmployee ? "сотрудника" : "руководителя"}`}
      </h2>
      {!isPassportFormDisabled && (
        <p className="mb-4 gray-color w-full">
          После проверки правильности документа подтвердите его согласованность и заполните соответствующие данные.
        </p>
      )}
      <Form
        form={passportForm}
        name="passport-form"
        onClick={onFormValuesChange}
        onChange={onFormValuesChange}
        validateTrigger={validateTriggersArr}
      >
        <div className="flex justify-between">
          <Form.Item
            name="last_name"
            rules={[
              {
                required: isFormItemRequiredRule("last_name", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              size="large"
              readOnly={disableFullName}
              value={
              isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile)
                ? documentClientProfile?.fields?.passport_document?.[0]?.last_name
                : ""}
              placeholder="Фамилия"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "last_name")}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "last_name",
                passportForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            name="first_name"
            className="pl-2"
            rules={[
              {
                required: isFormItemRequiredRule("first_name", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              size="large"
              readOnly={disableFullName}
              value={
                isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile)
                  ? documentClientProfile?.fields?.passport_document?.[0]?.first_name
                  : ""}
              placeholder="Имя"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "first_name")}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "first_name",
                passportForm
              ) : ""}
            />
          </Form.Item>
        </div>
        <div className="flex justify-between">
          <Form.Item
            name="second_name"
            rules={[
              {
                required: isFormItemRequiredRule("second_name", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              className="w-full"
              size="large"
              readOnly={disableFullName}
              value={
                isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile)
                  ? documentClientProfile?.fields?.passport_document?.[0]?.second_name
                  : ""}
              placeholder="Отчество"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "second_name")}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "second_name",
                passportForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            rootClassName="w-1/2"
            name="date_of_birth"
            className="pl-2"
            rules={[
              {
                required: isFormItemRequiredRule("date_of_birth", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <DatePicker
              size="large"
              className="w-full ml-1.5"
              style={{
                padding: "6.35px",
              }}
              value={undefined}
              format="DD.MM.YYYY"
              allowClear={!docApproveStatus}
              inputReadOnly={false}
              placeholder="Дата рождения"
              onChange={(date: Dayjs | null): void =>
                date === null
                  ? passportForm.setFieldValue("date_of_birth", null)
                  : passportForm.setFieldValue("date_of_birth", date)
              }
              disabledDate={(current: Dayjs) => {
                return dayjs().isBefore(current.add(16, "year"));
              }}
              defaultPickerValue={dayjs().subtract(16, "year")}
              disabled={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "date_of_birth",
                passportForm
              ) : ""}
            />
          </Form.Item>
        </div>
        <div className="flex justify-between">
          <Form.Item
            name="sex"
            rootClassName="w-full"
            rules={[
              {
                required: isFormItemRequiredRule("sex", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Select
              id="sex"
              size="large"
              allowClear={!docApproveStatus}
              placeholder="Пол"
              disabled={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "sex",
                passportForm
              ) : ""}
              options={[
                { value: "Мужской", label: "Мужской" },
                { value: "Женский", label: "Женский" },
              ]}
            />
          </Form.Item>
        </div>
        <div className="flex justify-between">
          <Form.Item
            name="series_number"
            rules={[
              {
                max: 5,
                message: "Значение должно быть 4 цифры.",
              },
              {
                min: 4,
                message: "Значение должно быть 4 цифры.",
              },
              {
                required: isFormItemRequiredRule("series_number", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              min={4}
              max={4}
              size="large"
              placeholder="Серия"
              onChange={handlePassportSeriesChange}
              readOnly={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "series_number",
                passportForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            name="passport_number"
            className="ml-2"
            rules={[
              {
                max: 7,
                message: "Значение должно быть 6 цифры.",
              },
              {
                min: 6,
                message: "Значение должно быть 6 цифры.",
              },
              {
                required: isFormItemRequiredRule("passport_number", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              min={6}
              max={6}
              size="large"
              placeholder="Номер паспорта"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handlePassportNumberOnlyChange(e, "passport_number")}
              readOnly={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "passport_number",
                passportForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            name="issued_date"
            className="w-1/2 ml-2"
            rules={[
              {
                required: isFormItemRequiredRule("issued_date", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <DatePicker
              size="large"
              className="w-full"
              style={{
                padding: "6.35px",
              }}
              format="DD.MM.YYYY"
              allowClear={!docApproveStatus}
              inputReadOnly
              placeholder="Дата выдачи"
              onChange={(date: Dayjs | null): void =>
                date === null
                  ? passportForm.setFieldValue("issued_date", null)
                  : passportForm.setFieldValue("issued_date", date)
              }
              disabledDate={(current: Dayjs) => {
                return dayjs().isBefore(current);
              }}
              disabled={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "issued_date",
                passportForm
              ) : ""}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="issued_by"
          rules={[
            {
              required: isFormItemRequiredRule("issued_by", passportDocSchema),
              message: "",
            }
          ]}
          validateTrigger={validateTriggersArr}
        >
          <Input
            size="large"
            placeholder="Кем выдан"
            readOnly={isPassportFormDisabled}
            status={!isPassportFormDisabled ? isDocFieldRequired(
              passportDocSchema,
              "issued_by",
              passportForm
            ) : ""}
          />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item
            name="subdivision_code"
            rules={[
              {
                max: 8,
                message: "Значение должно быть 6 цифр.",
              },
              {
                min: 7,
                message: "Значение должно быть 6 цифр.",
              },
              {
                required: isFormItemRequiredRule("subdivision_code", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              size="large"
              placeholder="Код подразделения"
              onChange={handlePassportDivisionCodeChange}
              readOnly={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "subdivision_code",
                passportForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            name="citizenship"
            className="pl-2"
            rules={[
              {
                required: isFormItemRequiredRule("citizenship", passportDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              size="large"
              placeholder="Гражданство"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "citizenship")}
              readOnly={isPassportFormDisabled}
              status={!isPassportFormDisabled ? isDocFieldRequired(
                passportDocSchema,
                "citizenship",
                passportForm
              ) : ""}
            />
          </Form.Item>
        </div>
        {!isPassportFormDisabled && (
          <>
            <Divider className="mt-1" />
            <div className="flex justify-between">
              <Tooltip
                title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              >
                <Form.Item name="isApproved">
                  <Radio.Group
                    onChange={onApproveChange}
                    value={docApproveStatus}
                    disabled={isDocApprovalDisabled}
                  >
                    <Radio value="APPROVED">Согласовать</Radio>
                    <Radio value="DECLINED">Отклонить</Radio>
                  </Radio.Group>
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {docApproveStatus === "DECLINED" && (
                <Form.Item
                  name="declineReason"
                  initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                >
                  <Select
                    placeholder="Причина"
                    size="large"
                    options={[
                      {
                        value: "Необходимо приложить верный документ (приложен не тот документ)",
                        label: "Необходимо приложить верный документ (приложен не тот документ)",
                      },
                      {
                        value: "Истек срок действия документа",
                        label: "Истек срок действия документа",
                      },
                      {
                        value: "Плохое качество документа, нечитаемый скан",
                        label: "Плохое качество документа, нечитаемый скан",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
      </Form>
      <div className="text-right mt-auto">
        <Divider className="mt-0" />
        <ButtonCustom
          id="close"
          className={`${css.buttonBack} mr-3`}
          type="default"
          onClick={closeForm}
          text={isPassportFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isPassportFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={() => {
              setCanSaveDoc(true);
              dispatch(getUnAgreedDocumentType(true));
            }}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormPassport;
