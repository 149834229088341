import React, {
  FC,
  JSX,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { AppStateType } from "../../reducers/mainReducer";
import css from "./Notifications.module.css";
import { ReactComponent as CloseIcon } from "../../assets/icons/close_modal.svg";
import { message, Tabs, TabsProps } from "antd";
import {
  NotificationsParamsType,
  NotificationsDataType,
  NumberOfNotificationsType
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import {
  getNotificationsData,
  getNotificationsNumber,
  notificationsMarkRead
} from "../../actions/notifications.actions";
import { NavigateFunction, useNavigate } from "react-router-dom";
import useSound from "use-sound";
import notificationSfx from "../../sounds/notificationSound.mp3";
import dayjs from "dayjs";
import NotificationsItems from "./NotificationsItems/NotificationsItems";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as Error } from "../../assets/icons/warning_circle_icon.svg";
import { allNotificationsRead } from "../../api/notifications.api";
import { clearTasksNotification } from "../../actions/tasks.actions";

interface INotificationsProps {
  collapsed: boolean;
  notificationVisible: boolean;
  setNotificationVisible: React.Dispatch<SetStateAction<boolean>>;
  clientListingDefaultValue: () => void;
}

enum NotificationsPage {
  firstPage = 1,
}

const Notifications: FC<INotificationsProps> = ({
 collapsed,
 notificationVisible,
 setNotificationVisible,
 clientListingDefaultValue
}): JSX.Element => {
  const [play] = useSound(notificationSfx);
  const [messageApi, contextHolder] = message.useMessage();
  const [params, setParams] = useState<NotificationsParamsType | null>({
    entity_type: "all",
    page: 1,
    page_size: 20
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notificationsData, setNotificationsData] = useState<NotificationsDataType[]>([]);
  const [updateListing, setUpdateListing] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  //получение массива уведомлений
  const {
    error,
    notifications,
    notificationsNumber,
    isNotificationsLoading,
    totalDocs
  } = useSelector((state: AppStateType) => state.notifications);

  //Логика дополнения списка уведомлений
  const updateNotificationsListing = (): void => {
    setNotificationsData((prevData: NotificationsDataType[]): NotificationsDataType[] => {
      if(params?.page === NotificationsPage.firstPage) {
        return notifications;
      }

      const newData: NotificationsDataType[] = notifications.filter(
        (item: NotificationsDataType) =>
          !prevData.some((prevItem: NotificationsDataType): boolean => prevItem.uuid === item.uuid)
      );

      return [...prevData, ...newData];
    });
  };

  useEffect(() => {
    dispatch(getNotificationsData(params));

    const interval: NodeJS.Timeout = setInterval((): void => {
      dispatch(getNotificationsData(params));
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (notificationVisible) {
      const fetchData = async (): Promise<void> => {
        try {
          await dispatch(getNotificationsData(params));
          setIsLoading(true);
          setUpdateListing(false);
        } catch (error) {
          setIsLoading(true);
          setUpdateListing(false);
        }
      };

      fetchData();
    }
  }, [params, notificationVisible]);

  useEffect(() => {
    updateNotificationsListing();
  }, [notifications]);

  const handleParamsChange = (entity_type: string | null, page: number): void => {
    setParams((prev: NotificationsParamsType | null) => {
      const newParams: NotificationsParamsType = {
        ...prev,
        page,
        ...(entity_type !== null ? { entity_type } : {})
      };

      return newParams;
    });
  };

  useEffect(() => {
    if (fetching && notificationVisible) {
      setFetching(false);
      if (totalDocs > notificationsData?.length) {
        setUpdateListing(true);
        handleParamsChange(null, (params?.page ?? 0) + 1);
      }
    }
  }, [fetching, notificationVisible]);

  useEffect(() => {
    if (notificationVisible && (error !== null)) {
      messageError();
    }
  }, [error, notificationVisible]);

  useEffect(() => {
    if (notifications?.length) {
      const lastNotification: string | null = localStorage.getItem("lastNotification");
      
      if (!lastNotification) {
        localStorage.setItem("lastNotification", notifications[0].created_at);
      }
      
      if (lastNotification && dayjs(lastNotification).isBefore(notifications[0].created_at)) {
        play();
        dispatch(getNotificationsNumber());
        localStorage.setItem("lastNotification", notifications[0].created_at);
      }
    }
  }, [play, notifications]);

  //установить дефолтный key у таба
  const [assignDefaultTab, setAssignDefaultTab] = useState<boolean>(true);

  useEffect(() => {
    const defaultTabKey: string = Object.keys(notificationsNumber ?? {})[0];

    if (defaultTabKey && assignDefaultTab) {
      setActiveKey(defaultTabKey);
      handleParamsChange(defaultTabKey, 1);
      setAssignDefaultTab(false);
    }

    if (defaultTabKey && !notificationVisible) {
      setActiveKey(defaultTabKey);
      handleParamsChange(defaultTabKey, 1);
    }
  }, [notificationsNumber, notificationVisible]);

  const [activeKey, setActiveKey] = useState<string>("");

  const handleTabChange = (key: string): void => {
    setActiveKey(key);
    setIsLoading(false);
    handleParamsChange(key, 1);
  };

  const markNotificationAsRead = (notification: NotificationsDataType): void => {
    navigate(notification?.link_to);
    setNotificationVisible(false);
    dispatch(notificationsMarkRead(notification.uuid))
      .then(() => {
        dispatch(getNotificationsNumber());
      });
  };

  const messageError = (): void => {
    messageApi.open({
      content: (
        <div className="flex items-center">
          <Error className="flex-shrink-0 mr-[4px]"/>
          <div className={css.messageText}>
            При обработке запроса произошла ошибка. Пожалуйста, повторите позже
          </div>
        </div>
      ),
      icon: false,
    });
  };

  const markAllNotificationAsRead = async (): Promise<void> => {
    try {
      const response = await allNotificationsRead();

      if (response.status === 200) {
        dispatch(getNotificationsData(params));
        dispatch(getNotificationsNumber());
        dispatch(clearTasksNotification());
      }
    } catch {
      messageError();
    }
  };

  const openClientCard = (notification: NotificationsDataType): void => {
    clientListingDefaultValue();

    navigate(notification?.link_to_client);
    setNotificationVisible(false);
  };

  const filterNotifications: NumberOfNotificationsType = Object.fromEntries(
    Object.entries(notificationsNumber ?? {}).filter(
      ([, value]): boolean => value.facet_type === "notifications_list"
    )
  );

  const renderViewedAll: boolean = filterNotifications
    ? Object.keys(filterNotifications)?.some((key: string): boolean =>
      filterNotifications[key]?.notifications_amount > 0)
    : false;

  //данные верхнего меню страницы
  const itemsTabs: TabsProps["items"] = Object.keys(filterNotifications)?.map((key: string) => {
    const isNotificationAmount: boolean =
      !!notificationsNumber && notificationsNumber[key]?.notifications_amount > 0;

    return {
      key,
      label: (
        <div className="flex items-center">
          <div className="mr-1">{notificationsNumber ? notificationsNumber[key]?.label : ""}</div>
          {isNotificationAmount && (
            <div
              className={css.counterLabel}
              style={activeKey === key ? { backgroundColor: "#EDF1FF" } : { backgroundColor: "#F0F1F3" }}
            >
              {notificationsNumber ? notificationsNumber[key]?.notifications_amount : ""}
            </div>
          )}
        </div>
      ),
      children: (
        <NotificationsItems
          key={key}
          isNotificationsLoading={isNotificationsLoading}
          notifications={notificationsData}
          markNotificationAsRead={markNotificationAsRead}
          openClientCard={openClientCard}
          areAllViewed={!renderViewedAll}
          notificationVisible={notificationVisible}
          isLoading={isLoading}
          activeKey={activeKey}
          variant={notificationsNumber ? notificationsNumber[key]?.label : ""}
          updateListing={updateListing}
          setFetching={setFetching}
        />
      )
    };
  });

  const notificationStyle: string = notificationVisible
    ? (collapsed ? css.containerCollapsed : css.container)
    : (collapsed ? css.hiddenCollapsed : css.hidden);

  return (
    <>
      <div className={notificationStyle}>
        {contextHolder}
        <ul className={css.header}>
          <li className="flex justify-between mb-2">
            <span className={css.headerTitle}>
              Уведомления
            </span>
            <span
              className="hover:cursor-pointer"
              onClick={() => setNotificationVisible(false)}
            >
              <CloseIcon/>
            </span>
          </li>
        </ul>
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          items={itemsTabs}
          onChange={handleTabChange}
          className="pl-[24px] pr-[24px]"
        />
        {renderViewedAll && (
          <div className={css.notificationViewedAll}>
            <ButtonCustom
              text="Отметить все как прочитанные"
              type="primary"
              ghost
              className={css.buttonViewedAll}
              onClick={markAllNotificationAsRead}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
