export const createAlphaDocStatus = (
  status: number | null,
  success: () => void,
  failure: () => void,
): void => {
  switch (status) {
    case 200:
    case 204:
      success();
      break;
    case 400:
    case 403:
    case 404:
    case 500:
      failure();
      break;
    default:
      break;
  }
};
