import React, { FC, JSX } from "react";
import css from "./ZeroTasks.module.css";
import { ReactComponent as TaskDefault } from "../../../assets/icons/empty-tasks.svg";

interface IZeroTasksProps {
  activeKey: string;
}

const ZeroTasks: FC<IZeroTasksProps> = ({ activeKey }): JSX.Element => {

  const headerText = ( activeKey: string ): string => {
    switch ( activeKey ){
      case "1":
        return "Новых задач нет";
      case "2":
        return "Задачи в работе отсутствуют";
      case "3":
        return "Выполненных задач пока нет";
      case "4":
        return "Отклоненных задач нет";
      default:
        return "Задачи отсутствуют";
    }
  };

    const bodyText = ( activeKey: string ): string => {
        switch ( activeKey ){
            case "1":
                return "Здесь будут отображены ваши новые задачи";
            case "2":
                return "Здесь будут отображены задачи, которые вы взяли в работу";
            case "3":
                return "Здесь будут отображены задачи, которые были успешно выполнены";
            case "4":
                return "Здесь будут отображены задачи, которые были отклонены или отменены";
            default:
                return "Здесь будут отображены все ваши задачи, включая новые, в работе, выполненные и отклоненные";
        }
    };

  return (
    <div className={css.container}>
      <div className={css.label}>
        <div className={css.icon}><TaskDefault/></div>
        <div>
          <div className={css.textHeader}>{headerText(activeKey)}</div>
          <div className={css.textBody}>{bodyText(activeKey)}</div>
        </div>
      </div>
    </div>
  );
};

export default ZeroTasks;
