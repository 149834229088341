import { UserListingType, DocumentTaskType } from "app/types";

export const performersFilter = (
  responsibleUserList: UserListingType[],
  taskInfo: DocumentTaskType | null | undefined,
  responsibleUuid: string | null | undefined
): UserListingType[] => {
  return responsibleUserList.filter((user: UserListingType): boolean => {
    const isTaskTypeAppeal: boolean = taskInfo?.fields?.task_type?.[0]?.label === "Обращение";
    const positions: string[] | undefined = user?.roles;
    const isDraftClient: boolean = !!taskInfo?.fields?.client?.[0]?.status_client
      && taskInfo?.fields?.client?.[0]?.status_client === "DRAFT";
    
    if (user?.id === responsibleUuid) {
      return false;
    }
    
    if (positions?.length) {
      return positions.some(
        (position: string): boolean => {
          if (isDraftClient) {
            return isTaskTypeAppeal
              ? ["assistant-role", "contact-center-role"].includes(position)
              : ["assistant-role"].includes(position);
          } else {
            return isTaskTypeAppeal
              ? ["accountant-role", "assistant-role", "contact-center-role"].includes(position)
              : ["accountant-role", "assistant-role"].includes(position);
          }
        }
      );
    }
    
    return false;
  });
};