import React, { FC, JSX } from "react";
import css from "./GeneralInfo.module.css";
import homePicture from "../../../../assets/icons/BK_home.svg";
import { FloatButton } from "antd";
import { ReactComponent as UpIcon } from "../../../../assets/icons/float_button_icon.svg";

interface IGeneralInfoProps {
}

const GeneralInfo: FC<IGeneralInfoProps> = (): JSX.Element => {
  return (
    <>
      <div className={css.generalInfoStyle} id="home">
        <div className={css.generalStyle}>
          <div className="flex flex-col justify-center items-center w-1/2">
            <div className={css.generalTextLabel}>
              Бизнес Конвейер
            </div>
            <div className={css.underLine}></div>
            <div className={css.generalTextInfo}>
              Программа для ЭВМ &quot;Бизнес Конвейер&quot; предоставляется клиентам в виде
              установочного дистрибутива
              либо облачной услуги и предназначена для визуального управления бизнес-процессами (Low-Code),
              информационного обмена между смежными системами, автоматизации создания объектов,
              справочников и т.д.
            </div>
          </div>
          <div className={css.imgInfo}>
            <div
              className={css.homePicture}
              style={{ backgroundImage: `url(${homePicture})` }}
            />
          </div>
        </div>
      </div>
      <FloatButton.BackTop
        className={css.floatButton}
        icon={<UpIcon />}
      />
    </>
  );
};

export default GeneralInfo;