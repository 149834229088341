import {
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  GET_USER_ROLE_LIST_SUCCESS,
  GET_USER_ROLE_LIST_FAILURE,
  GET_USER_ROLE_SUCCESS,
  GET_USER_ROLE_FAILURE,
  GET_USER_FULL_INFO_FAILURE,
  GET_USER_FULL_INFO_SUCCESS,
  UPDATE_USER_FULL_INFO_SUCCESS,
  UPDATE_USER_FULL_INFO_FAILURE,
  GET_USER_TOKEN_DECRYPTION_SUCCESS,
  GET_USER_TOKEN_DECRYPTION_FAILURE,
  GET_USERS_LISTING_SUCCESS,
  GET_USERS_LISTING_FAILURE,
  DECODE_TOKEN,
  GET_PERFORMERS_LISTING_SUCCESS,
  GET_PERFORMERS_LISTING_FAILURE,
  GET_CLIENTS_SERVED_SUCCESS,
  GET_CLIENTS_SERVED_FAILURE,
  GET_CLIENTS_SERVED_PAGE_SIZE,
  GET_CLIENTS_SERVED_PAGE_NUMBER,
} from "../constants/actionTypes";
import { AccountActionsTypes } from "../actions/account.actions";
import {
  UserInfoType,
  RoleListType,
  UserFullInfoType,
  TokenDecryptionType,
  UserListingType,
  ProfileClientsResultsType
} from "app/types";
import * as jose from "jose";

const token: string | null = window?.localStorage.getItem("authToken");
const refreshToken: string | null = window?.localStorage.getItem("refreshToken");

export type InitialAccountStateType = {
  error: string | null;
  userData: UserInfoType | null;
  userFullData: UserFullInfoType | null;
  isLoading: boolean;
  userUpdatedData: UserFullInfoType | null;
  authToken: string | null;
  refreshToken: string | null;
  confirmNewPasswordStatus: number,
  isFetching: boolean;
  userRoleList: RoleListType[];
  userRoleInfo: UserListingType[];
  userTokenDecryption: TokenDecryptionType | null;
  usersListing: UserListingType[];
  usersListingPageSize: number;
  usersListingTotalDocs: number;
  usersListingCurrentPage: number;
  decodedToken: jose.JWTPayload | null;
  performersListing: UserListingType[];
  performersDownload: boolean;
  clientsServedData: null | ProfileClientsResultsType[];
  clientsServedPageSize: number;
  clientsServedPageNumber: number;
  clientsServedTotalDocs: number | null;
  isClientsLoading: boolean;
};

const initialState: InitialAccountStateType = {
  error: null,
  userData: null,
  userFullData: null,
  isLoading: true,
  userUpdatedData: null,
  authToken: token,
  refreshToken: refreshToken,
  confirmNewPasswordStatus: 0,
  isFetching: true,
  userRoleList: [],
  userRoleInfo: [],
  userTokenDecryption: null,
  usersListing: [],
  decodedToken: null,
  performersListing: [],
  performersDownload: false,
  clientsServedData: null,
  clientsServedPageSize: 10,
  clientsServedPageNumber: 1,
  clientsServedTotalDocs: null,
  isClientsLoading: true,
  usersListingPageSize: 5,
  usersListingTotalDocs: 1,
  usersListingCurrentPage: 1,
};

const account = (
  state: InitialAccountStateType = initialState,
  action: AccountActionsTypes
): InitialAccountStateType => {
  switch (action.type) {
    // Логика деавторизации
    case LOGOUT:
      return {
        ...state,
        authToken: null,
        refreshToken: null,
        error: null
      };
    // Логика авторизации
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: null,
        refreshToken: null,
        error: action.payload.error
      };
    // Логика обновления токена
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authToken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: null
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: null,
        refreshToken: null,
        error: action.payload.error
      };
    // Логика получения информации об авторизованном пользователе
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userData: action.payload.userInfo,
        error: null
      };
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        userData: null,
        error: action.payload.error
      };
    // Логика получения информации о доступных ролях пользователей
    case GET_USER_ROLE_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userRoleList: action.payload.userRoleList,
        error: null
      };
    case GET_USER_ROLE_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        userRoleList: [],
        error: action.payload.error
      };
    // Логика получения информации о конкретной роли пользователя
    case GET_USER_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userRoleInfo: action.payload.userRoleInfo,
        error: null,
      };
    case GET_USER_ROLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        userRoleInfo: [],
        error: action.payload.error
      };
    // Логика получения подробной информации об авторизованном пользователе по uuid
    case GET_USER_FULL_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        userFullData: action.payload.userFullInfo,
        error: null
      };
    case GET_USER_FULL_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        userFullData: null,
        error: action.payload.error
      };
    // Логика обновления подробной информации об авторизованном пользователе по uuid
    case UPDATE_USER_FULL_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userUpdatedData: action.payload.userUpdatedInfo,
        error: null
      };
    case UPDATE_USER_FULL_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        userUpdatedData: null,
        error: action.payload.error
      };
    // Логика получения ключа шифрования токена
    case GET_USER_TOKEN_DECRYPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userTokenDecryption: action.payload.userTokenDecryption,
        error: null
      };
    case GET_USER_TOKEN_DECRYPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        userTokenDecryption: null,
        error: action.payload.error
      };
    //Логика получения листинга пользователей.
    case GET_USERS_LISTING_SUCCESS:
      return {
        ...state,
        usersListing: action.payload.usersListing.results,
        usersListingPageSize: action.payload.usersListing.page_size,
        usersListingCurrentPage: action.payload.usersListing.page,
        usersListingTotalDocs: action.payload.usersListing.total_docs,
        error: null
      };
    case GET_USERS_LISTING_FAILURE:
      return {
        ...state,
        usersListing: [],
        usersListingPageSize: 5,
        usersListingCurrentPage: 1,
        usersListingTotalDocs: 1,
        error: action.payload.error,
      };
    // Логика расшифровки токена авторизованного пользователя
    case DECODE_TOKEN:
      return {
        ...state,
        isFetching: false,
        decodedToken: action.payload.decodedToken,
        error: null,
      };
    //Логика получения листинга исполнителей
    case GET_PERFORMERS_LISTING_SUCCESS:
      return {
        ...state,
        performersListing: action.payload.performersListing,
        performersDownload: true,
        error: null,
      };
    case GET_PERFORMERS_LISTING_FAILURE:
      return {
        ...state,
        performersListing: [],
        performersDownload: true,
        error: action.payload.error,
      };
    //Логика получения листинга обслуживаемых клиентов
    case GET_CLIENTS_SERVED_SUCCESS:
      return {
        ...state,
        clientsServedData: action.payload.clientsServedListing.results,
        clientsServedTotalDocs: action.payload.clientsServedListing.total_docs,
        error: null,
        isClientsLoading: false,
      };
    case GET_CLIENTS_SERVED_FAILURE:
      return {
        ...state,
        clientsServedData: null,
        clientsServedTotalDocs: null,
        error: action.payload.error,
        isClientsLoading: false,
      };
    case GET_CLIENTS_SERVED_PAGE_SIZE:
      return {
        ...state,
        clientsServedPageSize: action.payload.pageSize,
      };
    case GET_CLIENTS_SERVED_PAGE_NUMBER:
      return {
        ...state,
        clientsServedPageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
};

export default account;
