import React, {
  JSX,
  FC,
  useState,
  ChangeEvent,
  useEffect
} from "react";
import {
  Form,
  Input,
  InputNumber,
  Popover
} from "antd";
import { ButtonCustom } from "../../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./TariffIncrease.module.css";
import { IModalApplicationRender } from "../../../../../../../Tasks/TaskCards/ModalApplication/ModalApplication";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../../../reducers/mainReducer";
import { getConsultationsNumber } from "../../../../../../../../utils/clientConsultations";
import { AppDispatch } from "../../../../../../../../store/store";
import { setPopoverTariffType } from "../../../../../../../../actions/client.actions";
import { DocumentSchemaLabelMapType } from "app/types";
import { documentSchemaLabelsMapper } from "../../../../../../../../utils/documentSchemaLabelsMapper";

const TariffIncrease: FC<IModalApplicationRender> = ({
  formContent,
  formApplication,
  changeOptions,
  setChangeOptions,
  setRejectApplication
}): JSX.Element => {
  const { TextArea } = Input;

  const {
    clientTariffData,
    schemaClient,
    showClientTariff,
    documentClient
  } = useSelector((state: AppStateType) => state.client);

  const tariffClientLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClient);

  const dispatch = useDispatch<AppDispatch>();

  const commentValue: string = formContent
    ? formContent.getFieldValue("textTariff")
    : formApplication?.getFieldValue("textTariff");
  const commentCount: number = formContent
    ? formContent.getFieldValue("textTariff")?.length || 0
    : formApplication?.getFieldValue("textTariff")?.length || 0;

  useEffect(() => {
    if (formContent) {
      formContent.setFieldsValue({
        paidConsultation: 0,
        accountingRestoration: 0,
        addingEmployees: 0,
        addAgencyAgreements: 0,
        textTariff: "",
      });
    }
  }, [formContent, formApplication]);

  const tariffContentData = (label: string, value: string | number | undefined): JSX.Element => {
    return (
      <li className="flex items-center gap-1">
        <span className={css.cardItemLabel}>
          {label}:
        </span>
        <span className={css.cardItemValue}>
          {value ?? "-"}
        </span>
      </li>
    );
  };

  const tariffContent: JSX.Element = (
    <div>
      <ul className={css.tariffContent}>
        {tariffContentData(tariffClientLabel?.number_of_consultations?.label, getConsultationsNumber(clientTariffData))}
        {tariffContentData(
          tariffClientLabel?.accounting_restore_months?.label,
          `${clientTariffData?.account_recovery_months ?? "-"} мес.`
        )}
        {tariffContentData(
          tariffClientLabel?.number_of_employees?.label,
          clientTariffData?.number_of_employees
        )}
        {tariffContentData(
          tariffClientLabel?.agency_agreements_qty?.label,
          clientTariffData?.agency_contracts_qty
        )}
        {tariffContentData(
          tariffClientLabel?.opf?.label,
          documentClient?.fields?.opf
        )}
        {tariffContentData(
          tariffClientLabel?.tax_system?.label,
          documentClient?.fields?.tax_system
        )}
      </ul>
      <div className="flex items-end flex-col">
        <ButtonCustom
          type="primary"
          text="Закрыть"
          className="flex mt-1"
          onClick={() => dispatch(setPopoverTariffType(false))}
        />
      </div>
    </div>
  );

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    if (setRejectApplication) {
      setRejectApplication(!!charsCount);
    }

    return formContent
      ? formContent.setFieldValue("textTariff", value)
      : formApplication?.setFieldValue("textTariff", value);
  };

  const checkField = (fieldsName: string): boolean => formContent
    ? formContent.getFieldValue(fieldsName) > 0
    : formApplication?.getFieldValue(fieldsName) > 0;

  const defaultFieldValue = (fieldsName: string): void => formContent
    ? formContent.setFieldValue(fieldsName, 0)
    : formApplication?.setFieldValue(fieldsName, 0);

  const formField = ( name: string, label: string ): JSX.Element => {
    const inputValue: number | null = formContent
      ? formContent.getFieldValue(name)
      : formApplication?.getFieldValue(name);

    const setFieldValue = (name: string, value: number | null): void => formContent
      ? formContent.setFieldValue(name, value)
      : formApplication?.setFieldValue(name, value);

    return (
      <Form.Item className="mb-5" name={name} id={name}>
        <div className="flex w-full justify-between items-center">
          <div>{label}</div>
          {checkField(name) && (
            <ButtonCustom
              type="link"
              text="Сбросить"
              onClick={() => defaultFieldValue(name)}
            />
          )}
        </div>
        <InputNumber
          id={name}
          className="w-full"
          size="large"
          min={0}
          value={inputValue}
          onChange={(value: number | null): void => setFieldValue(name, value)}
          changeOnWheel
        />
      </Form.Item>
    );
  };

  const formFieldsRender = (): JSX.Element => (
    <>
      {formField("paidConsultation", "Платная консультация")}
      {formField("accountingRestoration", "Восстановление бухгалтерского учета")}
      {formField("addingEmployees", "Добавление сотрудников")}
      {formField("addAgencyAgreements", "Добавление агентских договоров")}
    </>
  );

  const tariffOptionRender = (name: string, label: string): JSX.Element => {
    return (
      <li className="textBlockTariff">
        <span className="flex-none gray-color field-label">
          {label}
        </span>
        <span className="flex-grow text-[14px]">
          {formApplication?.getFieldValue(name)}
        </span>
      </li>
    );
  };

  return (
    <div>
      <div className="flex items-center mb-5">
        <div className={css.tariffTitle}>
          Текущий тариф клиента:
        </div>
        <Popover
          content={tariffContent}
          title={clientTariffData?.name ?? "Тариф"}
          trigger="click"
          open={showClientTariff}
        >
          <ButtonCustom
            type="default"
            text="Показать"
            className={css.tariffButton}
            onClick={() => dispatch(setPopoverTariffType(true))}
          />
        </Popover>
      </div>
      {formApplication && (
        <div>
          <div className={`${css.tariffTitle} mb-3`}>
            Добавочный состав опций тарифа:
          </div>
          {changeOptions && (
            <>
              <div>
                <ul>
                  {tariffOptionRender("paidConsultation", "Платная консультация")}
                  {tariffOptionRender("accountingRestoration", "Восстановление бухгалтерского учета")}
                  {tariffOptionRender("addingEmployees", "Добавление сотрудников")}
                  {tariffOptionRender("addAgencyAgreements", "Добавление агентских договоров")}
                </ul>
              </div>
              <ButtonCustom
                className={css.changeOptions}
                type="link"
                text="Изменить опции"
                onClick={(): void => { if (setChangeOptions) setChangeOptions(false); }}
              />
            </>
          )}
        </div>
      )}
      <div>
        {formContent && (
          <div className={css.tariffText}>
            Укажите количество добавочных платных опций, если это применимо.<br />
            В противном случае оставьте нулевое значение опции.
          </div>
        )}
        {formContent ? (<div>{formFieldsRender()}</div>) : (!changeOptions && formFieldsRender())}
        <div className={css.tariffText}>
          Вы также можете добавить служебный комментарий. Этот комментарий не будет виден клиенту.
        </div>
        <Form.Item
          className="mb-5"
          name="textTariff"
        >
          <TextArea
            id="textTariff"
            rows={2}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваше сообщение"
            value={commentValue}
            onChange={handleTextAreaChange}
          />
          <div className="text-xs font-normal leading-100 text-gray-700 text-right">
            {commentCount}/2000 символов
          </div>
        </Form.Item>
      </div>
    </div>
  );
};

export default TariffIncrease;