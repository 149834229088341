import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../../reducers/mainReducer";
import { ButtonCustom } from "../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./ContractCard.module.css";
import { DocumentSchemaLabelMapType, SchemaType } from "app/types";
import dayjs from "dayjs";
import { translateContractType } from "../../../../../../../utils/translateContractType";
import TagCustom from "../../../../../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument
  from "../../../../../../ClientProfileCard/ZeroClientProfileDocument/ZeroClientProfileDocument";
import { documentSchemaLabelsMapper } from "../../../../../../../utils/documentSchemaLabelsMapper";

interface IContractCardProps {
  showImageViewer: (docType: string, uuid: string) => void;
  contractUuid: string;
  contractDocType: string;
}

const ContractCard: FC<IContractCardProps> = ({
  showImageViewer,
  contractUuid,
  contractDocType
}): JSX.Element => {
  const [isConfirmed, setConfirmed] = useState<boolean>(false);

  const schemaEmployee: SchemaType | null = useSelector((state: AppStateType) => state.employee.schemaEmployee);

  const {
    contractDoc,
    contractDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (contractDoc?.fields?.document_status === "APPROVED") {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  }, [contractDoc]);

  const contractCardDocsLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaEmployee);
  const contractCardInfoLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(contractDocSchema);

  const renderConfirmationContract = (): JSX.Element => {
    return isConfirmed ? (
      <TagCustom color="success" text="Согласовано"/>
    ) : (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderCause = (): JSX.Element => {
    return (
      <div className={`${css.cause} mt-5 mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          Необходимо приложить верный документ (приложен не тот документ)
        </div>
      </div>
    );
  };

  const renderContractCardField = (label: string, value: string | number): JSX.Element => (
    <li className="textBlockWidth">
      <span className="inline-block gray-color text-sm">
        {label}
      </span>
      <span className="flex-grow text-sm">
        {value}
      </span>
    </li>
  );

  return (
    <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
      <h1 className="header-text mb-5">
        {contractCardDocsLabels?.employee_contract?.label ?? "Договор"}
      </h1>
      {contractDoc?.fields ? (
        <>
          <ul>
            {!!contractDoc?.fields?.contract_type &&
              renderContractCardField(
                contractCardInfoLabels?.contract_type?.label,
                translateContractType(contractDoc?.fields?.contract_type)
              )
            }
            {!!contractDoc?.fields?.contract_date &&
              renderContractCardField(
                contractCardInfoLabels?.contract_date?.label,
                dayjs(contractDoc?.fields?.contract_date).format("DD.MM.YYYY")
              )
            }
            {!!contractDoc?.fields?.expiration_date &&
              renderContractCardField(
                contractCardInfoLabels?.expiration_date?.label,
                dayjs(contractDoc?.fields?.expiration_date).format("DD.MM.YYYY")
              )
            }
            {!!contractDoc?.fields?.position && (
              renderContractCardField(
                contractCardInfoLabels?.position?.label,
                contractDoc?.fields?.position
              )
            )}
            {!!contractDoc?.fields?.rate &&
              renderContractCardField(
                contractCardInfoLabels?.rate?.label,
                contractDoc?.fields?.rate
              )
            }
            {!!contractDoc?.fields?.salary &&
              renderContractCardField(
                contractCardInfoLabels?.salary?.label,
                contractDoc?.fields?.salary
              )
            }
            {!!contractDoc?.fields?.allowances &&
              renderContractCardField(
                contractCardInfoLabels?.allowances?.label,
                contractDoc?.fields?.allowances
              )
            }
            {!!contractDoc?.fields?.payment_methods &&
              renderContractCardField(
                contractCardInfoLabels?.payment_methods?.label,
                contractDoc?.fields?.payment_methods
              )
            }
            {!!contractDoc?.fields?.workplace &&
              renderContractCardField(
                contractCardInfoLabels?.workplace?.label,
                contractDoc?.fields?.workplace
              )
            }
            {!!contractDoc?.fields?.payment_type &&
              renderContractCardField(
                contractCardInfoLabels?.payment_type?.label,
                contractDoc?.fields?.payment_type
              )
            }
          </ul>
          <span className="checkFiles">
            <div className="flex items-center">
              <ButtonCustom
                className="p-[7px 12px] mr-2"
                type="primary"
                ghost
                text="Посмотреть файлы"
                onClick={() => showImageViewer(
                  contractDocType,
                  contractUuid
                )}
              />
              {!!contractDoc?.fields?.document_status && renderConfirmationContract()}
            </div>
            {!!contractDoc?.fields?.decline_reason && (isConfirmed ? null : renderCause())}
          </span>
        </>
      ) : (
        <ZeroClientProfileDocument variant="Договор сотрудника" />
      )}
    </div>
  );
};

export default ContractCard;
