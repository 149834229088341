import React, { FC, SetStateAction, useEffect, useState } from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Input,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocFieldRequired } from "../../../../utils/isDocFieldRequired";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormBankDetailsProps {
  bankDetailsForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
}

const ClientProfileCardImageViewerFormBankDetails: FC<IClientProfileCardImageViewerFormBankDetailsProps> = ({
  bankDetailsForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
}) => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const { bankDoc, bankDocSchema } =
    useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    if (!!docApproveStatus && isDocApprovalDisabled || isContactCenter) {
      setDocVerified(true);
    } else {
      setDocVerified(false);
    }
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(bankDocSchema, bankDetailsForm, setDocApprovalDisabled);
  }, [bankDetailsForm, bankDocSchema, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = bankDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = bankDoc?.fields?.files
    ?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("bank-schema"));
      dispatch(getEmployeeDocumentData("bank-schema", documentUuid));
    }
  }, [dispatch, documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = bankDoc?.fields?.decline_reason ?? "";

      if (docMigrationDeclineReason) {
        bankDetailsForm.setFieldValue("declineReason", docMigrationDeclineReason);
      } else {
        bankDetailsForm.setFieldValue(
          "declineReason",
          "Необходимо приложить верный документ (приложен не тот документ)"
        );
      }
    }

    onFormValuesChange();
  }, [docApproveStatus, bankDoc, bankDetailsForm]);

  useEffect(() => {
    const docBik: string = bankDoc?.fields?.bic ?? "";
    const docBankAccountNumber: string = bankDoc?.fields?.bank_account_number ?? "";
    const docCorrespondentAccount: string = bankDoc?.fields?.correspondent_account ?? "";
    const docBankStatus: string = bankDoc?.fields?.document_status ?? "";
    const docBankDeclineReason: string = bankDoc?.fields?.decline_reason ?? "";
    const shouldUpdateDocField: boolean = !!docBik
      || !!docBankStatus
      || !!docBankAccountNumber
      || !!docCorrespondentAccount;

    if (shouldUpdateDocField) {
      handleBikChange(docBik);
      handleAccountNumberChange(docBankAccountNumber);
      handleCorrespondentAccountChange(docCorrespondentAccount);
      onApproveChange(docBankStatus);

      bankDetailsForm.setFieldValue("isApproved", docBankStatus);
    }

    if (docBankDeclineReason) {
      bankDetailsForm.setFieldValue("declineReason", docBankDeclineReason);
    }
  }, [bankDoc, bankDetailsForm]);

  const handleBikChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,9})/);
    const formattedBik: string = matches ? matches[1] : "";

    if (formattedBik?.length === 9 || formattedBik?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    bankDetailsForm.setFieldValue("bic", formattedBik);
  };

  const handleAccountNumberChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,20})/);
    const formattedAccountNumber: string = matches ? matches[1] : "";

    if (formattedAccountNumber?.length === 20 || formattedAccountNumber?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    bankDetailsForm.setFieldValue("bank_account_number", formattedAccountNumber);
  };

  const handleCorrespondentAccountChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,20})/);
    const formattedCorrespondentAccount: string = matches ? matches[1] : "";

    if (formattedCorrespondentAccount?.length === 20 || formattedCorrespondentAccount?.length === 0) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }

    bankDetailsForm.setFieldValue("correspondent_account", formattedCorrespondentAccount);
  };

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isBankFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  return (
    <div className="flex flex-col h-90vh">
      <h2 className="mb-2 text-xl font-bold">
        Банковские реквизиты
      </h2>
      {!isBankFormDisabled && (
        <p className="mb-4 gray-color w-full">
          После проверки правильности документа подтвердите его согласованность и заполните соответствующие данные.
        </p>
      )}
      <Form
        form={bankDetailsForm}
        name="bank-details-form"
        onChange={onFormValuesChange}
        onClick={onFormValuesChange}
        validateTrigger={validateTriggersArr}
      >
        <div className="flex flex-col">
          <Form.Item
            name="bic"
            className="mb-5"
            rules={[
              {
                min: 9,
                message: "Значение должно быть 9 цифр.",
              },
              {
                max: 10,
                message: "Значение должно быть 9 цифр.",
              },
              {
                required: isFormItemRequiredRule("bic", bankDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              id="bic"
              size="large"
              placeholder="БИК банка"
              onChange={handleBikChange}
              readOnly={isBankFormDisabled}
              status={!isBankFormDisabled ? isDocFieldRequired(
                bankDocSchema,
                "bic",
                bankDetailsForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            name="bank_account_number"
            className="mb-5"
            rules={[
              {
                min: 20,
                message: "Значение должно быть 20 цифр.",
              },
              {
                max: 21,
                message: "Значение должно быть 20 цифр.",
              },
              {
                required: isFormItemRequiredRule("bank_account_number", bankDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              id="bank_account_number"
              size="large"
              placeholder="Номер счета получателя"
              onChange={handleAccountNumberChange}
              readOnly={isBankFormDisabled}
              status={!isBankFormDisabled ? isDocFieldRequired(
                bankDocSchema,
                "bank_account_number",
                bankDetailsForm
              ) : ""}
            />
          </Form.Item>
          <Form.Item
            name="correspondent_account"
            rules={[
              {
                min: 20,
                message: "Значение должно быть 20 цифр.",
              },
              {
                max: 21,
                message: "Значение должно быть 20 цифр.",
              },
              {
                required: isFormItemRequiredRule("correspondent_account", bankDocSchema),
                message: "",
              }
            ]}
            validateTrigger={validateTriggersArr}
          >
            <Input
              id="correspondent_account"
              size="large"
              placeholder="Корреспондентский счет"
              onChange={handleCorrespondentAccountChange}
              readOnly={isBankFormDisabled}
              status={!isBankFormDisabled ? isDocFieldRequired(
                bankDocSchema,
                "correspondent_account",
                bankDetailsForm
              ) : ""}
            />
          </Form.Item>
        </div>
        {!isBankFormDisabled && (
          <>
            <Divider className="mt-1"/>
            <div className="flex justify-between">
              <Tooltip
                title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              >
                <Form.Item name="isApproved">
                  <Radio.Group
                    onChange={onApproveChange}
                    value={docApproveStatus}
                    disabled={isDocApprovalDisabled || isContactCenter}
                  >
                    <Radio value="APPROVED">Согласовать</Radio>
                    <Radio value="DECLINED">Отклонить</Radio>
                  </Radio.Group>
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {docApproveStatus === "DECLINED" && (
                <Form.Item
                  name="declineReason"
                  initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                >
                  <Select
                    placeholder="Причина"
                    size="large"
                    options={[
                      {
                        value: "Необходимо приложить верный документ (приложен не тот документ)",
                        label: "Необходимо приложить верный документ (приложен не тот документ)",
                      },
                      {
                        value: "Плохое качество документа, нечитаемый скан",
                        label: "Плохое качество документа, нечитаемый скан",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
      </Form>
      <div className="text-right mt-auto">
        <Divider className="mt-0"/>
        <ButtonCustom
          id="close"
          className={`${css.buttonBack} mr-3`}
          type="default"
          onClick={closeForm}
          text={isBankFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isBankFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={() => {
              setCanSaveDoc(true);
              dispatch(getUnAgreedDocumentType(true));
            }}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormBankDetails;
