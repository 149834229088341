import React, { FC, JSX, useEffect, useState } from "react";
import { UserFullInfoType } from "app/types";
import { handlePhoneNumberChange } from "../../../../utils/phoneNumberMask";
import css from "./PerformerPersonalInformation.module.css";
import ListItem from "../../../ui-kit/RenderListItem/ListItem";
import TagCustom from "../../../ui-kit/TagCustom/TagCustom";
import { fetchSelectedUser } from "../../../../api/account.api";
import { roleList } from "../../../../utils/performersRole";

interface IPerformerPersonalInformationProps {
  personalInfo: UserFullInfoType | null;
}

const PerformerPersonalInformation: FC<IPerformerPersonalInformationProps> = ({
  personalInfo
}): JSX.Element => {
  const [rolesListing, setRolesListing] = useState<string[]>([]);
  
  useEffect(() => {
    const getUserInfo = async (): Promise<void> => {
      try{
        const response: any = await fetchSelectedUser(personalInfo?.id);
        setRolesListing(response?.data?.roles);
      } catch (err) {
        console.error("Get user info error:", err);
      }
    };

    if (personalInfo?.id) {
      getUserInfo();
    }
  }, [personalInfo]);
  
  const uniqueRoles: string[] = Array.from(new Set(rolesListing.map((role: string) => roleList[role])));

  return (
    <div className="mb-4">
      <div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">
        <h1 className={css.userLabel}>
          Роли и права
        </h1>
        {uniqueRoles?.map((role: string, index: number) => (
          <TagCustom key={index} color="customDraft" text={role} />
        ))}
      </div>
      <div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">
        <div className="flex justify-between items-center mb-5">
          <h1 className="header-text">
            Персональные данные
          </h1>
        </div>
        <ul>
          <ListItem value={personalInfo?.attributes?.last_name ?? "-"} label="Фамилия" />
          <ListItem value={personalInfo?.attributes?.first_name ?? "-"} label="Имя" />
          <ListItem value={personalInfo?.attributes?.second_name ?? "-"} label="Отчество" />
          <ListItem
            value={personalInfo?.attributes?.phone
              ? handlePhoneNumberChange(personalInfo?.attributes?.phone.toString() ?? "-")
              : "-"}
            label="Телефон"
          />
          <ListItem value={personalInfo?.email ?? "-"} label="Электронная почта" />
        </ul>
      </div>
    </div>
  );
};

export default PerformerPersonalInformation;
