export const getMonthWord = (monthsDiff: number | undefined | string): string => {
  const wrongMonthsDiffData: boolean = monthsDiff === undefined
    || typeof monthsDiff === "string"
    || monthsDiff === null;
  
  if (wrongMonthsDiffData) {
    return "";
  } else {
    return `${monthsDiff} мес.`;
  }
};