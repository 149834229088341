import React, { FC, JSX, SetStateAction, useEffect, useState } from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";
import { onlyOneDirector } from "../../../../utils/onlyOneDirector";

interface IClientProfileCardImageViewerFormSolutionProps {
  decisionForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
}

const ClientProfileCardImageViewerFormDecision: FC<IClientProfileCardImageViewerFormSolutionProps> = ({
  decisionForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
}) => {
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const {
    decisionDoc,
    decisionDocSchema,
    passportCompanyDoc
  } = useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    isDocRequiredValuesFilled(decisionDocSchema, decisionForm, setDocApprovalDisabled);
  }, [decisionForm, decisionDocSchema, onFormValuesChange]);

  useEffect(() => {
    if (!!docApproveStatus && isDocApprovalDisabled || isContactCenter) {
      setDocVerified(true);
    } else {
      setDocVerified(false);
    }
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    const documentStatus: string = decisionDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = decisionDoc?.fields?.files
    ?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, decisionDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("founders-decision-schema"));
      dispatch(getEmployeeDocumentData("founders-decision-schema", documentUuid));
    }
  }, [dispatch, isNotAgreedDocs, documentUuid]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = decisionDoc?.fields?.decline_reason ?? "";

      if (docMigrationDeclineReason) {
        decisionForm.setFieldValue("declineReason", docMigrationDeclineReason);
      } else {
        decisionForm.setFieldValue(
          "declineReason",
          "Необходимо приложить верный документ (приложен не тот документ)"
        );
      }
    }

    onFormValuesChange();
  }, [docApproveStatus, decisionDoc, decisionForm]);

  useEffect(() => {
    const docUsnStatus: string = decisionDoc?.fields?.document_status ?? "";
    const docUsnDeclineReason: string = decisionDoc?.fields?.decline_reason ?? "";

    if (docUsnStatus) {
      decisionForm.setFieldValue("isApproved", docUsnStatus);
    }

    if (docUsnDeclineReason) {
      decisionForm.setFieldValue("declineReason", docUsnDeclineReason);
    }
  }, [decisionDoc, decisionForm]);

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isDecisionFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const directorLastName = (): JSX.Element | null => {
    const lastName: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.last_name
      : passportCompanyDoc?.fields?.last_name;

    if (!lastName) {
      return null;
    }

    return (
      <li className="flex mb-[8px]">
        <span className="document-field">
          Фамилия
        </span>
        <span className="text-[14px]">
          {lastName}
        </span>
      </li>
    );
  };

  const directorFirstName = (): JSX.Element | null => {
    const firstName: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.first_name
      : passportCompanyDoc?.fields?.first_name;

    if (!firstName) {
      return null;
    }

    return (
      <li className="flex mb-[8px]">
        <span className="document-field">
          Имя
        </span>
        <span className="text-[14px]">
          {firstName}
        </span>
      </li>
    );
  };

  const directorSecondName = (): JSX.Element | null => {
    const secondName: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.second_name
      : passportCompanyDoc?.fields?.second_name;

    if (!secondName) {
      return null;
    }

    return (
      <li className="flex mb-[8px]">
        <span className="document-field">
          Отчество
        </span>
        <span className="text-[14px]">
          {secondName}
        </span>
      </li>
    );
  };

  return (
    <div className="flex flex-col h-90vh">
      <h2 className="mb-2 text-xl font-bold">
        Решение учредителя по формированию уставного капитала и назначения руководителя
      </h2>
      {!isDecisionFormDisabled && (
        <p className="mb-4 gray-color w-full">
          После проверки правильности документа подтвердите его согласованность.
        </p>
      )}
      <h3 className="mb-4 font-bold">
        Данные генерального директора организации
      </h3>
      <div className={css.informationCEO}>
        <ul>
          {directorLastName()}
          {directorFirstName()}
          {directorSecondName()}
        </ul>
      </div>
      <Form
        form={decisionForm}
        name="decision-form"
        onChange={onFormValuesChange}
        onClick={onFormValuesChange}
        validateTrigger={validateTriggersArr}
      >
        {!isDecisionFormDisabled && (
          <>
            <Divider className="mt-1"/>
            <div className="flex justify-between">
              <Tooltip
                title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              >
                <Form.Item name="isApproved">
                  <Radio.Group
                    onChange={onApproveChange}
                    value={docApproveStatus}
                    disabled={isDocApprovalDisabled}
                  >
                    <Radio value="APPROVED">Согласовать</Radio>
                    <Radio value="DECLINED">Отклонить</Radio>
                  </Radio.Group>
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {docApproveStatus === "DECLINED" && (
                <Form.Item
                  name="declineReason"
                  initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                >
                  <Select
                    placeholder="Причина"
                    size="large"
                    options={[
                      {
                        value: "Необходимо приложить верный документ (приложен не тот документ)",
                        label: "Необходимо приложить верный документ (приложен не тот документ)"
                      },
                      {
                        value: "Необходимо приложить полный документ (не все страницы)",
                        label: "Необходимо приложить полный документ (не все страницы)",
                      },
                      {
                        value: "Истек срок действия документа",
                        label: "Истек срок действия документа",
                      },
                      {
                        value: "Плохое качество документа, нечитаемый скан",
                        label: "Плохое качество документа, нечитаемый скан",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
      </Form>
      <div className="text-right mt-auto">
        <Divider className="mt-0"/>
        <ButtonCustom
          id="close"
          className={`${css.buttonBack} mr-3`}
          type="default"
          onClick={closeForm}
          text={isDecisionFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isDecisionFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={() => {
              setCanSaveDoc(true);
              dispatch(getUnAgreedDocumentType(true));
            }
            }
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormDecision;
