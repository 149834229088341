import React, { FC, JSX } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import {
  TaskStepsType,
  AbstractDocType,
} from "app/types";
import { ReactComponent as FirstStep } from "../../../../../assets/icons/step_first.svg";
import { ReactComponent as FirstStepActive } from "../../../../../assets/icons/step_first_active.svg";
import { ReactComponent as SecondStep } from "../../../../../assets/icons/step_second.svg";
import { ReactComponent as SecondStepActive } from "../../../../../assets/icons/step_second_active.svg";
import { ReactComponent as ThirdStep } from "../../../../../assets/icons/step_third.svg";
import { ReactComponent as ThirdStepActive } from "../../../../../assets/icons/step_third_active.svg";
import { ReactComponent as FourthStep } from "../../../../../assets/icons/step_fourth.svg";
import { ReactComponent as FourthStepActive } from "../../../../../assets/icons/step_fourth_active.svg";
import { ReactComponent as FifthStep } from "../../../../../assets/icons/step_fifth.svg";
import { ReactComponent as FifthStepActive } from "../../../../../assets/icons/step_fifth_active.svg";
import { ReactComponent as SixthStep } from "../../../../../assets/icons/step_sixth.svg";
import { ReactComponent as SixthStepActive } from "../../../../../assets/icons/step_sixth_active.svg";
import { ReactComponent as ErrorIcon } from "../../../../../assets/icons/status_error_v2.svg";
import { ReactComponent as FinishedStep } from "../../../../../assets/icons/status-checked.svg";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import ActionHistory from "../../ActionHistory/ActionHistory";
import ZeroHistory from "../ZeroHistory/ZeroHistory";
import { Card, Spin, Steps } from "antd";
import { NavigateFunction, useNavigate } from "react-router-dom";
import css from "./TaskCardHistoryStepBlock.module.css";

enum StatusSteps {
  First = 0,
  Second = 1,
  Third = 2,
  Four = 3,
  Five = 4,
  Six = 5,
  Seven = 6,
}

interface ITaskCardHistoryStepBlockProps {
  taskUuid: string;
  currentStep: number | undefined;
  isCheckAccess: boolean;
  refTaskContent: React.RefObject<HTMLDivElement>
  isAdministrator?: boolean;
  setNavBarHidden: React.Dispatch<React.SetStateAction<boolean>>;
  clientProfileIp: AbstractDocType[] | undefined;
  clientProfileOoo: AbstractDocType[] | undefined;
  drawerTaskAction: string;
  isConfirmModalOpen: boolean;
  actionHistoryHeight: number;
  isHistoryStepsBlockLoading: boolean;
}

const TaskCardHistoryStepBlock: FC<ITaskCardHistoryStepBlockProps> = ({
  taskUuid,
  currentStep,
  isCheckAccess,
  refTaskContent,
  isAdministrator,
  setNavBarHidden,
  clientProfileIp,
  clientProfileOoo,
  drawerTaskAction,
  isConfirmModalOpen,
  actionHistoryHeight,
  isHistoryStepsBlockLoading,
}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const {
    statusTask,
    actionHistory,
    actionHistoryStatus,
  } = useSelector((state: AppStateType) => state.tasks);

  const haveActionHistory: boolean = actionHistory ? actionHistory?.results?.length > 0 : false;
  const isAdministratorHistory: boolean = !!isAdministrator && !!actionHistoryStatus;
  const isFinalSteps: boolean = currentStep === StatusSteps.Five || currentStep === StatusSteps.Six;
  const actionSelectedStyle: string | boolean = drawerTaskAction && !isConfirmModalOpen && "mt-3";
  const historyStepBlockWidth: string | false = !drawerTaskAction && css.historyStepBlock;

  const handleButtonClick = (type: "open" | "finalize"): void => {
    const correctClientProfile: string = clientProfileOoo?.length
      ? clientProfileOoo[0]?.uuid
      : clientProfileIp?.[0]?.uuid as string;

    setNavBarHidden(true);

    if (type === "open") {
      navigate(`/task/${taskUuid}/questionnaire/${correctClientProfile}/`);
    } else if (type === "finalize") {
      navigate(`/task/${taskUuid}/questionnaire/${correctClientProfile}/validation`);
    }
  };

  const getStepIcon = (
    status: string | undefined,
    Step: JSX.Element,
    StepActive: JSX.Element
  ): JSX.Element => {
    switch (status) {
      case "in_progress":
        return StepActive;
      case "passed":
        return <FinishedStep />;
      case "error":
        return <ErrorIcon />;
      default:
        return Step;
    }
  };

  const fifthStepIcon = (statusOne?: string, statusTwo?: string, statusThree?: string): JSX.Element => {
    const statusError = [statusOne, statusTwo, statusThree].includes("error");
    const statusInProgress = [statusOne, statusTwo, statusThree].includes("in_progress");
    const statusPassedOrSkipped = statusOne === "passed" && statusTwo === "skipped" && statusThree === "passed";

    if (statusInProgress) return <FifthStepActive />;
    if (statusPassedOrSkipped) return <FinishedStep />;
    if (statusError) return <ErrorIcon />;

    return <FifthStep />;
  };

  const fifthStepError: boolean = [StatusSteps.Four, StatusSteps.Five, StatusSteps.Six]
    .some((index: number) => statusTask?.statuses?.[index]?.status === "error");

  const renderQuestionnaireStep = (
    stepIcon: JSX.Element,
    statusLabel: string,
    status?: string
  ): TaskStepsType => ({
    icon: stepIcon,
    title: <span className={css.taskStepDescr}>{statusLabel}</span>,
    status: status === "error" ? "error" : undefined,
  });

  const getStatuses = (index: number): string => statusTask?.statuses?.[index]?.status as string;

  const questionnaireSteps: TaskStepsType[] = [
    renderQuestionnaireStep(
      getStepIcon(getStatuses(StatusSteps.First), <FirstStep />, <FirstStepActive />),
      statusTask?.statuses?.[StatusSteps.First]?.label ?? ""
    ),
    renderQuestionnaireStep(
      getStepIcon(getStatuses(StatusSteps.Second), <SecondStep />, <SecondStepActive />),
      statusTask?.statuses?.[StatusSteps.Second]?.label ?? ""
    ),
    renderQuestionnaireStep(
      getStepIcon(getStatuses(StatusSteps.Third), <ThirdStep />, <ThirdStepActive />),
      statusTask?.statuses?.[StatusSteps.Third]?.label ?? ""
    ),
    renderQuestionnaireStep(
      getStepIcon(getStatuses(StatusSteps.Four), <FourthStep />, <FourthStepActive />),
      statusTask?.statuses?.[StatusSteps.Four]?.label ?? ""
    ),
    {
      icon: fifthStepIcon(
        getStatuses(StatusSteps.Five),
        getStatuses(StatusSteps.Six),
        getStatuses(StatusSteps.Seven)
      ),
      title: fifthStepError ? (
        <div className="flex flex-col mt-2">
        <span className={css.taskStepDescr}>
          {statusTask?.statuses?.[StatusSteps.Five]?.label ?? ""}
        </span>
          <span className={css.fifthStepError}>
          Ошибка создания карточки. Пожалуйста, проверьте внесение данных.
        </span>
        </div>
      ) : (
        <span className={css.taskStepDescr}>
        {statusTask?.statuses?.[StatusSteps.Five]?.label ?? ""}
      </span>
      ),
      status: fifthStepError ? "error" : undefined,
    },
    renderQuestionnaireStep(
      getStepIcon(getStatuses(Number(statusTask?.statuses?.length) - 1), <SixthStep />, <SixthStepActive />),
      statusTask?.statuses?.[statusTask?.statuses?.length - 1]?.label ?? ""
    ),
  ];

  const renderActionHistory = (): JSX.Element => {
    return haveActionHistory ? (
      <ActionHistory
        actionHistory={actionHistory ?? null}
        heightHistory={actionHistoryHeight}
        widthHistory={refTaskContent?.current?.clientWidth}
      />
    ) : <ZeroHistory heightHistory={actionHistoryHeight} />;
  };

  const renderStagesChecking = (): JSX.Element => {
    return (
      <div className={css.stepsHeader}>
        <div className="text-base font-normal leading-5 mb-4">
          Этапы проверки анкеты
        </div>
        <Steps
          className="mb-5"
          direction="vertical"
          current={isFinalSteps ? StatusSteps.Four : currentStep}
          items={questionnaireSteps}
        />
        <div key="openForm" className="flex justify-start">
          <ButtonCustom
            id="openButton"
            className={css.openFormBtn}
            type="primary"
            text="Открыть анкету"
            htmlType="button"
            onClick={() => handleButtonClick("open")}
            disabled={!isCheckAccess || isHistoryStepsBlockLoading}
          />
          {fifthStepError && (
            <ButtonCustom
              id="checkButton"
              type="primary"
              danger
              className={isCheckAccess ? css.btnError1C : css.btnBase}
              text="Проверить"
              onClick={() => handleButtonClick("finalize")}
              disabled={!isCheckAccess || isHistoryStepsBlockLoading}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Card className={`${historyStepBlockWidth} ${actionSelectedStyle}`}>
      <Spin
        spinning={isHistoryStepsBlockLoading}
        className="min-h-full"
        style={isAdministrator ? { height: actionHistoryHeight } : {}}
      >
        <span className={css.historyHeader}>
          {isAdministrator ? "История действий" : "Проверка анкеты"}
        </span>
        {isAdministratorHistory && renderActionHistory()}
        {!isAdministrator && renderStagesChecking()}
      </Spin>
    </Card>
  );
};

export default TaskCardHistoryStepBlock;
