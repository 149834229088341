import React, {
  FC,
  JSX,
  Key,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import {
  Form,
  Select,
  GetProps,
  FormProps,
  DatePicker,
  SelectProps,
  FormInstance,
  notification,
} from "antd";
import {
  UsersListingFilterType,
  AllUsersListingType,
  AttributesTypeProps,
  SelectOptionsType,
  AbsenteesFormType,
  AbsenteesListType,
  IJWTPayloadProps,
  UserListingType,
  AbsenteesType,
  AbsentType,
  SubstitutesType
} from "app/types";
import { AxiosResponse } from "axios";
import { createNewDocument, updateDocument } from "../../../../api/document.api";
import { getUsersListing } from "../../../../api/account.api";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import qs from "qs";
import dayjs, { Dayjs } from "dayjs";
import debounce from "debounce";
import * as DocumentAPI from "../../../../api/document.api";
import css from "../../../Alpha/Vacancy/CreateVacancy/CreateVacancy.module.css";
import isBetween from "dayjs/plugin/isBetween";
import { useLocation } from "react-router-dom";
import { roleResolver } from "../../../../utils/roleResolver";
dayjs.extend(isBetween);

interface IProfileCreateOrEditAbsentFormProps {
  absentUuid?: React.Key | null;
  absenteesList: AbsenteesListType | null;
  isAbsentEdited?: boolean;
  closeAbsentForm: () => void;
  setAbsenteesKey: Dispatch<SetStateAction<Key | null>>
  createAbsentStatus: number | null;
  setCreateAbsentStatus: Dispatch<SetStateAction<number | null>>;
  setCreateOrUpdateModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedUserRoles?: string[] | null;
}

const absenceReasons: SelectProps["options"] = [
  {
    value: "Отпуск",
    label: "Отпуск",
  },
  {
    value: "Больничный",
    label: "Больничный",
  },
  {
    value: "Прочее",
    label: "Прочее",
  },
];

const debouncedGetUsersListing: debounce.DebouncedFunction<(
  params: string,
  onSuccess: (value: AxiosResponse<AllUsersListingType>) => void,
  onError: any,
) => void> = debounce((params, onSuccess, onError): void => {
    getUsersListing(params)
    .then(onSuccess)
    .catch(onError);
}, 1000);

const { RangePicker } = DatePicker;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const ProfileCreateOrEditAbsentForm: FC<IProfileCreateOrEditAbsentFormProps> = ({
  absentUuid,
  absenteesList,
  closeAbsentForm,
  isAbsentEdited,
  setAbsenteesKey,
  createAbsentStatus,
  setCreateAbsentStatus,
  setCreateOrUpdateModalOpen,
  selectedUserRoles
}): JSX.Element | boolean => {
  const [isAbsentLoaded, setIsAbsentLoaded] = useState<boolean>(false);
  const [absentUser, setAbsentUser] = useState<AbsenteesType | null>(null);
  const [isFormHasValues, setFormHasValues] = useState<boolean>(false);
  const [replacementSelectionUser, setReplacementSelectionUser] = useState<UserListingType[]>([]);
  const [searchSelectionUser, setSearchSelectionUser] = useState<UserListingType[]>([]);
  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string | null>(null);
  const [hasSearch, setHasSearch] = useState<boolean>(false);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [getUserErrMessage, setGetUserErrMessage] = useState<string>("");
  const [replacementDatesList, setReplacementDatesList] = useState<[string, string][]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [form]: [FormInstance<AbsenteesFormType>] = Form.useForm();
  const values: AbsenteesFormType | null = Form.useWatch([], form) ?? null;
  const path: string = useLocation().pathname;
  const isPerformers: boolean = path.includes("performers");
  const performerUuid: string | undefined = path.split("/").pop();

  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const {
    isAdmin,
    isTechSupport
  } = roleResolver(decodedToken);
  const isAvailable: boolean = (isAdmin || isTechSupport) && isPerformers;

  useEffect(() => {
    const replacementDates: [string, string][] = absenteesList?.results?.map((absent: AbsenteesType) => {
      return [
        absent?.fields?.replacement_start_date ?? "",
        absent?.fields?.replacement_end_date ?? "",
      ];
    }) ?? [];

    setReplacementDatesList(replacementDates);
  }, [absenteesList]);

  useEffect((): void => {
    createAlphaDocStatus(
      createAbsentStatus,
      createAbsentSuccess,
      createAbsentError,
    );
  }, [createAbsentStatus]);

  useEffect((): void => {
    if (values) {
      const hasNoValue: boolean = Object.values(values)
        .some((value: string | string[]): boolean => typeof value === "string" ? !value : !value?.length);

      setFormHasValues(hasNoValue);
    }
  }, [values]);

  useEffect(() => {
    setIsUsersLoading(true);

    const params: UsersListingFilterType = {
      page_size: 100,
      roles: selectedUserRoles?.length ? selectedUserRoles : decodedToken?.resource_access?.bc?.roles,
      ...(searchEmployeeValue ? { name: searchEmployeeValue } : null),
      ...(values?.replacement_date ? {
        available_from: dayjs(values.replacement_date?.[0]).format("YYYY-MM-DD"),
        available_to: dayjs(values.replacement_date?.[1]).format("YYYY-MM-DD"),
      } : null),
    };

    const queryString: string = qs.stringify(params, { arrayFormat: "repeat" });

    debouncedGetUsersListing(queryString,
      (response: any) => {
        setIsUsersLoading(false);
        setReplacementSelectionUser(response.data.results as UserListingType[]);
        
        if (isAbsentEdited) {
          setSearchSelectionUser(response.data.results as UserListingType[]);
        }
      },
      (err: any) => {
        setIsUsersLoading(false);
        console.error("Get users error:", err);
      },
    );

    return (): void => debouncedGetUsersListing.clear();
  }, [searchEmployeeValue, values?.replacement_date]);

  useEffect(() => {
    if (absentUuid) {
      setIsAbsentLoaded(true);

      DocumentAPI.getEmployeeDocument("absence-substitution-schema", String(absentUuid))
      .then((response) => {
        setAbsentUser(response.data);
      })
      .catch((err) => {
        console.error("Get absence-substitution error:", err);
      })
      .finally(() => setIsAbsentLoaded(false));
    }
  }, [absentUuid]);

  const formatLabel = (attributes: AttributesTypeProps): string => {
    const { position, last_name, first_name, second_name } = attributes || {};

    return [
      position,
      last_name,
      first_name,
      second_name,
    ]
    .filter(Boolean)
    .join(" ");
  };

  const selectUserOptions = (value: UserListingType[]):SelectOptionsType[] => {
    return value
      ?.filter(({ id }: UserListingType): boolean =>
        (isAvailable || id !== decodedToken?.sub) && (!isPerformers || id !== performerUuid))
      ?.map(({ id, attributes }: UserListingType) => {
        return {
          key: id,
          value: id,
          label: formatLabel(attributes),
        };
      }) ?? [];
  };

  const replacementSelectionUserOptions: SelectOptionsType[] = selectUserOptions(replacementSelectionUser);
  const searchSelectionUserOptions: SelectOptionsType[] = selectUserOptions(searchSelectionUser);
  
  const substitutesData: SubstitutesType[] =
    absentUser?.fields?.substitutes?.map(({ uuid, label }: AbsentType) => {
      const [ firstName, secondName, lastName] = label.split(" ");
      return {
        key: uuid,
        value: uuid,
        label: `${lastName} ${firstName} ${secondName}`,
      };
    }) ?? [];
  
  useEffect(() => {
    if (absentUser) {
      form.setFieldValue("substitutes" as any, substitutesData);
    }
  }, [absentUser]);

  useEffect(() => {
    if (!hasSearch) {
      const validValues: (string | number)[] =
        searchSelectionUserOptions.map((option: SelectOptionsType) => option.value);
      const filteredSubstitutesData: SubstitutesType[] =
        substitutesData.filter((substitute: SubstitutesType) => validValues.includes(substitute.value));

      form.setFieldValue("substitutes" as any, filteredSubstitutesData);
      setHasSearch(false);
    }
  }, [searchSelectionUser]);
  
  useEffect(() => {
    setSearchEmployeeValue(null);
  }, [values?.substitutes]);
  
  useEffect(() => {
    if (!absentUser?.fields?.substitutes) {
      form.setFieldValue("substitutes" as any, null);
    }
  }, [values?.replacement_date]);
  
  const isSubstitutesType = (item: any): item is SubstitutesType => {
    return item && typeof item === "object" && "value" in item;
  };

  const onCreatAbsentFinish: FormProps["onFinish"] = async (values: AbsenteesFormType): Promise<void> => {
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    
    const rawSubstitutes: Array<string | SubstitutesType> =
      form.getFieldValue("substitutes" as any) as Array<string | SubstitutesType>;
    
    const substitutesData: string[] = rawSubstitutes.map((item: string | SubstitutesType): string => {
      return isSubstitutesType(item) ? item.value : item;
    });
    
    const newAbsentDoc: AbsenteesFormType = {
      ...values,
      substitutes: substitutesData,
      strategy_type: absentUuid ? "update" : "create",
      replacement_end_date: dayjs(values.replacement_date?.[1]).utc(true).format(),
      replacement_start_date: dayjs(values.replacement_date?.[0]).utc(true).format(),
      ...(isPerformers ? { absent: performerUuid } : null),
    };

    try {
      if (absentUuid) {
        const { status } = await updateDocument(
          "absence-substitution-schema",
          absentUuid?.toString() ?? "",
          newAbsentDoc
        );

        setCreateAbsentStatus(status);
      } else {
        const { status } = await createNewDocument("absence-substitution-schema", newAbsentDoc);

        setCreateAbsentStatus(status);
      }
    } catch (error: any) {
      setGetUserErrMessage(error.response.data?.error);
      setCreateAbsentStatus(error.response.status);

      console.error("Create absent error:", error.response.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  const createAbsentSuccess = (): void => {
    notification.success({
      message: "Замещающий сотрудник назначен",
    });

    setAbsentUser(null);
    setAbsenteesKey(null);
    setCreateAbsentStatus(null);
    setCreateOrUpdateModalOpen(false);
  };

  const createAbsentError = (): void => {
    notification.error({
      message: createAbsentStatus === 500
        ? "Ошибка назначения замещающего сотрудника"
        : getUserErrMessage,
    });

    setGetUserErrMessage("");
    setCreateAbsentStatus(null);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current: dayjs.Dayjs): boolean => {
    // Запрещаем выбрать дату в прошлом или сегодняшнюю дату
    if (current && (current.isBefore(dayjs().startOf("day")) || current.isSame(dayjs(), "day"))) {
      return true;
    }

    //запрещаем выбрать даты уже созданных отсутствий, при редактировании отсутствия его даты можно выбрать повторно,
    //либо выбрать другие доступные
    return replacementDatesList.some(([start, end]): boolean => {
      if (absentUser) {
        // Проверяем, не пересекается ли диапазон с периодом absentUser
        const absentStart: Dayjs = dayjs(absentUser?.fields?.replacement_start_date);
        const absentEnd: Dayjs = dayjs(absentUser?.fields?.replacement_end_date);
        
        if (
          dayjs(start).isBetween(absentStart, absentEnd, null, "[]") ||
          dayjs(end).isBetween(absentStart, absentEnd, null, "[]")
        ) {
          return false;
        }
      }
      
      // Проверяем, находится ли текущая дата внутри диапазона
      return current.isBetween(dayjs(start), dayjs(end), null, "[]");
    });
  };

  const isFormLoaded: boolean = !isAbsentLoaded && (!isAbsentEdited || !!absentUser);

  const handleSearchEmployees = (searchValue: string): void => {
    setSearchEmployeeValue(searchValue?.trim() || null);
    setHasSearch(!!searchValue);
  };

  const initialUserReplacementDates: dayjs.Dayjs[] | null = absentUser
    ? [dayjs(absentUser?.fields?.replacement_start_date), dayjs(absentUser?.fields?.replacement_end_date)]
    : null;

  return (
    isFormLoaded ? (
      <Form
        form={form}
        name="createOrUpdateAbsent"
        layout="vertical"
        onFinish={onCreatAbsentFinish}
      >
        <Form.Item
          className="w-full mb-4"
          id="replacement_date"
          name="replacement_date"
          label="Период отсутствия"
          initialValue={initialUserReplacementDates}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите даты отсутствия",
            },
          ]}
        >
          <RangePicker
            className="w-full"
            format="DD.MM.YYYY"
            size="large"
            allowClear
            placeholder={["Дата начала", "Дата окончания"]}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          className="w-full mb-4"
          id="substitution_reason"
          name="substitution_reason"
          label="Причина отсутствия"
          initialValue={absentUser?.fields?.substitution_reason}
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите причину отсутствия",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Выберите причину"
            allowClear
            filterOption={false}
            options={absenceReasons}
          />
        </Form.Item>
        <Form.Item
          className="w-full mb-4"
          id="substitutes"
          name="substitutes"
          label="Замещающие сотрудники на время отсутствия"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите замещающих сотрудников",
            },
          ]}
        >
          <Select
            placeholder="Найдите и выберите сотрудников по ФИО"
            options={replacementSelectionUserOptions}
            searchValue={searchEmployeeValue as string}
            showSearch
            allowClear
            size="large"
            mode="multiple"
            filterOption={false}
            loading={isUsersLoading}
            onSearch={handleSearchEmployees}
          />
        </Form.Item>
        <div className="text-right">
          <ButtonCustom
            className={`${css.buttonBack} mr-2`}
            key="cancel"
            size="large"
            type="default"
            text={!absentUuid ? "Отменить" : "Назад"}
            onClick={closeAbsentForm}
          />
          <ButtonCustom
            key="ok"
            size="large"
            text={!absentUuid ? "Подтвердить" : "Сохранить"}
            type="primary"
            htmlType="submit"
            className={css.buttonOk}
            disabled={isFormHasValues || isUsersLoading}
          />
        </div>
      </Form>
    ) : <LoadingCustom className="m-48"/>
  );
};

export default ProfileCreateOrEditAbsentForm;
