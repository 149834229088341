import React, { FC, JSX, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import type { TabsProps } from "antd";
import css from "./Reports.module.css";
import ReportCreation from "./ReportCreation/ReportCreation";
import ReportsListing from "./ReportsListing/ReportsListing";
import { AppStateType } from "../../reducers/mainReducer";
import * as jose from "jose";
import { roleResolver } from "../../utils/roleResolver";
import { setReportsCreationParams } from "../../actions/reports.action";
import { AppDispatch } from "../../store/store";

interface IReportsProps {}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

enum ReportPages {
  createPage = "/reports",
  historyPage = "/reports/history",
}

const Reports: FC<IReportsProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [activeTab, setActiveTab] = useState<string>(ReportPages.createPage);

  const { pathname } = useLocation();
  
  useEffect(() => {
    return () => {
      dispatch(setReportsCreationParams(null));
    };
  }, []);

  useEffect(() => {
    if (pathname.includes(ReportPages.historyPage)) setActiveTab(ReportPages.historyPage);
  }, [pathname]);

  const navigate: NavigateFunction = useNavigate();

  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    const qualityControl:boolean = roleResolver(decodedToken).isQualityControl;
    const techSupport: boolean = roleResolver(decodedToken).isTechSupport;
    const isAvailable: boolean = qualityControl || techSupport;

    if (!isAvailable && decodedToken) {
      navigate("/tasks");
    }
  }, [decodedToken]);

  const reportTabs: TabsProps["items"] = [
    {
      key: ReportPages.createPage,
      label: "Создание отчета",
      children: <ReportCreation />,
      destroyInactiveTabPane: true,
    },
    {
      key: ReportPages.historyPage,
      label: "История отчетов",
      children: <ReportsListing />,
      destroyInactiveTabPane: true,
    },
  ];

  const handleTabChange = (tabKey: string): void => {
    navigate(`${tabKey}`);
    setActiveTab(tabKey);
  };

  return (
    <div className={`${css.container}`}>
      <h1 className={css.containerHeader}>Отчеты</h1>
      <Tabs
        items={reportTabs}
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onChange={handleTabChange}
        destroyInactiveTabPane={true}
      />
    </div>
  );
};

export default Reports;
