import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../../reducers/mainReducer";
import {
  GET_VACANCY_LIST_SUCCESS,
  GET_VACANCY_LIST_FAILURE,
  GET_VACANCY_SUCCESS,
  GET_VACANCY_FAILURE,
} from "../../constants/actionTypes";
import {
  AlphaListParamsType,
  VacancyListType,
  VacancyDocType,
} from "app/types";
import * as VacancyAPI from "../../api/alpha/vacancy.api";

export type VacancyActionsTypes =
  | GetVacancyListSuccessType
  | GetVacancyListFailureType
  | GetVacancySuccessType
  | GetVacancyFailureType
  ;

// Логика получения списка вакансий
export type GetVacancyListSuccessType = {
  type: typeof GET_VACANCY_LIST_SUCCESS;
  payload: {
    vacanciesList: VacancyDocType;
  };
};

const getVacancyListSuccess = (vacanciesList: VacancyDocType): GetVacancyListSuccessType => ({
  type: GET_VACANCY_LIST_SUCCESS,
  payload: {
    vacanciesList
  }
});

export type GetVacancyListFailureType = {
  type: typeof GET_VACANCY_LIST_FAILURE;
  payload: {
    error: any;
  };
};

const getVacancyListFailure = (error: any): GetVacancyListFailureType => ({
  type: GET_VACANCY_LIST_FAILURE,
  payload: {
    error
  }
});

export const getVacanciesList = (params?: AlphaListParamsType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    VacancyActionsTypes
  > => async (dispatch) => {
  try {
    const response = await VacancyAPI.getVacancies(params);

    dispatch(getVacancyListSuccess(response.data));
  } catch (error: any) {
    dispatch(getVacancyListFailure(error.message));
  }
};

// Логика получения контрагента
export type GetVacancySuccessType = {
  type: typeof GET_VACANCY_SUCCESS;
  payload: {
    vacancyData: VacancyListType;
  };
};

const getVacancySuccess = (vacancyData: VacancyListType): GetVacancySuccessType => ({
  type: GET_VACANCY_SUCCESS,
  payload: {
    vacancyData
  }
});

export type GetVacancyFailureType = {
  type: typeof GET_VACANCY_FAILURE;
  payload: {
    error: any;
  };
};

export const getVacancyFailure = (error?: any): GetVacancyFailureType => ({
  type: GET_VACANCY_FAILURE,
  payload: {
    error
  }
});

export const getVacancyData = (vacancyUuid?: string | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    VacancyActionsTypes
  > => async (dispatch) => {
  try {
    const response = await VacancyAPI.getVacancy(vacancyUuid);

    dispatch(getVacancySuccess(response.data));
  } catch (error: any) {
    dispatch(getVacancyFailure(error.message));
  }
};
