import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import ProfileCreateOrEditAbsentModal from "./ProfileCreateOrEditAbsentModal/ProfileCreateOrEditAbsentModal";
import ProfileAbsenteesTable from "./ProfileAbsenteesTable/ProfileAbsenteesTable";
import css from "./ProfileAbsenteesList.module.css";
import { AbsenteesListType } from "app/types";
import { fetchSelectedUser } from "../../../api/account.api";

interface IProfileAbsenteesListProps {
  performerUuid?: string;
}

const ProfileAbsenteesList: FC<IProfileAbsenteesListProps> = ({
  performerUuid
}): JSX.Element => {
  const [absenteesKey, setAbsenteesKey] = useState<React.Key | null>(null);
  const [absenteesList, setAbsenteesList] = useState<AbsenteesListType | null>(null);
  const [isAbsentEdited, setAbsentEdited] = useState<boolean>(false);
  const [createAbsentStatus, setCreateAbsentStatus] = useState<number | null>(0);
  const [createOrUpdateModalOpen, setCreateOrUpdateModalOpen] = useState<boolean>(false);
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>([]);

  const openCreateOrUpdateModal = (): void => {
    setAbsentEdited(false);
    setAbsenteesKey(null);
    setCreateOrUpdateModalOpen(true);
  };
  
  useEffect(() => {
    const getUserInfo = async (): Promise<void> => {
      try{
        const response: any = await fetchSelectedUser(performerUuid);
        setSelectedUserRoles(response?.data?.roles);
      } catch (err) {
        console.error("Get user info error:", err);
      }
    };
    
    if (performerUuid) {
      getUserInfo();
    }
  }, []);
  
  const performersData: string | null = performerUuid ?? null;

  return (
    <div className="p-5 bg-white rounded-xl">
      <div className="flex justify-between items-center mb-4">
        <h2 className={css.absenteesHeader}>
          Отсутствия
        </h2>
        <ButtonCustom
          size="large"
          type="primary"
          text="Добавить отсутствие"
          onClick={openCreateOrUpdateModal}
        />
      </div>
      <ProfileAbsenteesTable
        absenteesKey={absenteesKey}
        absenteesList={absenteesList}
        performersUuid={performersData}
        setAbsenteesKey={setAbsenteesKey}
        setAbsentEdited={setAbsentEdited}
        setAbsenteesList={setAbsenteesList}
        createAbsentStatus={createAbsentStatus}
        setCreateOrUpdateModalOpen={setCreateOrUpdateModalOpen}
      />
      {createOrUpdateModalOpen && (
        <ProfileCreateOrEditAbsentModal
          absentUuid={absenteesKey}
          absenteesList={absenteesList}
          isAbsentEdited={isAbsentEdited}
          setAbsenteesKey={setAbsenteesKey}
          createAbsentStatus={createAbsentStatus}
          setCreateAbsentStatus={setCreateAbsentStatus}
          createOrUpdateModalOpen={createOrUpdateModalOpen}
          setCreateOrUpdateModalOpen={setCreateOrUpdateModalOpen}
          selectedUserRoles={selectedUserRoles}
        />
      )}
    </div>
  );
};

export default ProfileAbsenteesList;
