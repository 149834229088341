import React from "react";
import { Link, NavigateFunction } from "react-router-dom";
import { TasksListingDataType } from "app/types";
import css from "../components/Tasks/Tasks.module.css";
import { ShortFormatTooltipText } from "./ShortFormatTooltipText";

const maxVisibleTextLength: number = 58;

export const RenderTaskCardClientType = (
  _: string,
  record: TasksListingDataType,
  _navigate: NavigateFunction,
) => {
  const correctClientLabel: string = record?.fields?.client?.[0]?.short_name
  ? record?.fields?.client?.[0]?.short_name
  : record?.fields?.client?.[0]?.label;

  const correctClientProfileShortName: string = record?.fields?.client_profile_ooo?.length
    ? record?.fields?.client_profile_ooo?.[0]?.short_name
    : record?.fields?.client_profile_ip?.[0]?.short_name;

  const correctClientProfileLabel: string = record?.fields?.client_profile_ooo?.length
    ? record?.fields?.client_profile_ooo?.[0]?.label
    : record?.fields?.client_profile_ip?.[0]?.label;

  const correctClientProfileTaxSystem: string | undefined = record?.fields?.client_profile_ooo?.length
    ? record?.fields?.client_profile_ooo?.[0]?.tax_system
    : record?.fields?.client_profile_ip?.[0]?.tax_system;

  const correctTaskName: string = correctClientLabel || correctClientProfileShortName || correctClientProfileLabel;

  return (
    <div className="flex flex-col">
      <span className="text-[12px]">
        {correctClientProfileTaxSystem}
      </span>
      <Link
        className={css.tableCell}
        to={`/task/${record?.fields?.uuid}`}
      >
        <ShortFormatTooltipText
          text={correctTaskName}
          endSlicePoint={maxVisibleTextLength}
        />
      </Link>
    </div>
  );
};
