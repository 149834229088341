import React, {
  FC,
  JSX,
  useEffect,
} from "react";
import { AppDispatch } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import {
  SchemaType,
  ContractType,
  DocumentSchemaLabelMapType,
} from "app/types";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { getContractData, getContractFailure } from "../../../../actions/alpha/contracts.actions";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { documentSchemaLabelsMapper } from "../../../../utils/documentSchemaLabelsMapper";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ListItem from "../../../ui-kit/RenderListItem/ListItem";
import css from "./ContractCard.module.css";

interface IContractCardProps {
}

const ContractCard: FC<IContractCardProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const path: string = useLocation().pathname;

  useEffect(() => {
    //Получаем uuid договора
    const contractUuid: string = path?.split("/")?.slice(-1)?.toString();

    dispatch(getContractData(contractUuid));
    dispatch(getDocumentSchema("contract-schema"));
  }, [path]);

  const {
    uuid,
    duration,
    contractee,
    contractor,
    contract_sum,
    contract_number,
    contractual_date,
    contractor_owner,
    contractee_owner,
  } = useSelector((state: AppStateType) => state.contracts.contractData?.fields) as ContractType ?? {};

  const documentSchema: SchemaType | null =
    useSelector((state: AppStateType) => state.document.documentSchema);

  const contractCardLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(documentSchema);

  const backToContractCard = (): void => {
    navigate("/alpha/contracts");
    dispatch(getContractFailure());
  };

  return (uuid ? (
    <div className={css.container}>
      <ButtonCustom
        className={css.returnBtn}
        type="link"
        text="Назад"
        onClick={backToContractCard}
      >
        <ArrowLeftOutlined/>
      </ButtonCustom>
      <h1 className={css.contractCardHeader}>
        Договор №{contract_number}
      </h1>
      <div className={css.contractParamsBlock}>
        <div className="flex justify-between">
          <h2 className={css.contractParamsHeader}>
            О договоре
          </h2>
          <ButtonCustom
            type="link"
            text="Редактировать"
            onClick={() => navigate(`/alpha/update_contract/${uuid}`, { state: { isContractCard: true } })}
          />
        </div>
        <ul>
          <ListItem value={contract_number} label={contractCardLabel?.["contract_number"]?.label} />
          <ListItem value={contractual_date} label="Дата договора" type="date"/>
          <ListItem value={contract_number} label="Срок действия договора" duration={duration} type="expiration"/>
          <ListItem value={contractor?.[0]?.label} label={contractCardLabel?.["contractor"]?.label} />
          <ListItem value={contractee?.[0]?.label} label={contractCardLabel?.["contractee"]?.label} />
          <ListItem value={contract_sum} label={contractCardLabel?.["contract_sum"]?.label} type="sum" />
          <ListItem value={contractor_owner} label={contractCardLabel?.["contractor_owner"]?.label} />
          <ListItem value={contractee_owner} label={contractCardLabel?.["contractee_owner"]?.label} />
        </ul>
      </div>
    </div>
  ) : (
    <div className="text-center mt-96">
      <LoadingCustom />
    </div>
  ));
};

export default ContractCard;
