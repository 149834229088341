import { SchemaFieldsType, SchemaType } from "app/types";

export const isFormItemRequiredRule = (
  fieldName: string,
  docSchema: SchemaType | null
): boolean => {
  const currentField: SchemaFieldsType[] | undefined = docSchema?.fields
  ?.filter(({ name }: { name: string }) => name === fieldName);

  return !!currentField?.length && currentField?.[0]?.required_for_finalize;
};
