export enum TaskTypes {
  AdvancePaymentCalculating = 203,
  EmployeeSalaryTask = 204,
  OperationsMarking = 303,
  KudirRequest = 304,
  DeclarationSTS = 305,
  PropertyDeclaration = 306,
  DeclarationNDFL = 307,
  SimplifiedDeclaration = 308,
  DeclarationVAT = 309,
  IncomeTaxDeclaration = 310,
  NotificationOfTaxes = 312,
  ChargeOff = 403,
}

const taskTypeMappings: { [key: string]: number } = {
  "Списание": TaskTypes.ChargeOff,
  "Запрос КУДиР": TaskTypes.KudirRequest,
  "Разметка операций": TaskTypes.OperationsMarking,
  "Формирование декларации НДС": TaskTypes.DeclarationVAT,
  "Формирование декларации УСН": TaskTypes.DeclarationSTS,
  "Списание платной консультации": TaskTypes.ChargeOff,
  "Формирование декларации по имуществу ООО": TaskTypes.PropertyDeclaration,
  "Формирование единой упрощённой декларации": TaskTypes.SimplifiedDeclaration,
  "Формирование декларации по налогу на прибыль": TaskTypes.IncomeTaxDeclaration,
  "Формирование декларации 3-НДФЛ для ИП на ОСНО": TaskTypes.DeclarationNDFL,
  "Задача по расчету заработной платы сотрудника": TaskTypes.EmployeeSalaryTask,
  "Формирование уведомления об исчисленных налогах": TaskTypes.NotificationOfTaxes,
  "Задача по расчету авансового платежа по сотруднику": TaskTypes.AdvancePaymentCalculating,
};

export const taskTypeCode = (text: string): number => {
  return taskTypeMappings[text];
};
