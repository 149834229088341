import React, { FC, JSX } from "react";
import MigrationDoc from "./MigrationDoc/MigrationDoc";
import IncomeDoc from "./IncomeDoc/IncomeDoc";
import { DocumentEmployeeType } from "app/types";

interface IDocumentsCardProps {
  showImageViewer: (docType: string, uuid: string) => void;
  docType: string;
  migrationDocUuid: string;
  incomeDocUuid: string;
}

const DocumentsCard: FC<IDocumentsCardProps> = ({
  showImageViewer,
  docType,
  migrationDocUuid,
  incomeDocUuid
}): JSX.Element => {

  return (
    <div className="flex justify-between">
      <MigrationDoc
        showImageViewer={showImageViewer}
        docType={docType}
        migrationDocUuid={migrationDocUuid}
      />
      <IncomeDoc
        showImageViewer={showImageViewer}
        incomeDocUuid={incomeDocUuid}
      />
    </div>
  );
};

export default DocumentsCard;
