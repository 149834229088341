import React, { Dispatch, SetStateAction, useState, useRef } from "react";
import {
  ClientBanksDataParamsType,
  FilterClientsType,
  FilterEmployeeType,
  FilterTasksType,
  ReportsListingFilterType
} from "app/types";

type ParamsDataType =
  FilterTasksType
  | FilterClientsType
  | FilterEmployeeType
  | ClientBanksDataParamsType
  | ReportsListingFilterType
  | null;

type NoneSorterDataType = Exclude<ParamsDataType, null>;

export const useTableSorter = <T extends ParamsDataType>(
  defaultSorter: string | null,
  setParamsData: Dispatch<SetStateAction<T>>,
  setSortValue?: Dispatch<SetStateAction<string | null>>
) => {
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
  const prevText: React.MutableRefObject<string> = useRef<string>("");

  const sortValueData = (value: string | null): void | null => {
    if (setSortValue) {
      setSortValue(value);
    }
  };

  const handleSorter = (e: React.MouseEvent<HTMLElement>, text: string): void => {
    e.preventDefault();
    const isTextChanged: boolean = prevText.current !== text;
    const resetSortOrder: boolean = isTextChanged && sortOrder !== null;
    const defaultValue: "ascend" | null = sortOrder === "descend" ? null : "ascend";
    const workingValue: "ascend" | "descend" | null = sortOrder === "ascend" ? "descend" : defaultValue;
    const newSortOrder: "ascend" | "descend" | null = resetSortOrder ? "ascend" : workingValue;

    if (resetSortOrder || sortOrder === null) {
      prevText.current = text;
    }

    setParamsData((prevParamsData: T) => {
      let updateParams: T;

      if (newSortOrder === null) {
        if (defaultSorter === null) {
          const { order_by, ...rest } = prevParamsData as NoneSorterDataType;
          updateParams = rest as T;
          sortValueData(null);
        } else {
          updateParams = {
            ...prevParamsData,
            order_by: defaultSorter,
          };
          sortValueData(defaultSorter);
        }

      } else {
        updateParams = {
          ...prevParamsData,
          order_by: newSortOrder === "ascend" ? text : `-${text}`,
        };
        sortValueData(newSortOrder === "ascend" ? text : `-${text}`);
      }

      return updateParams;
    });

    setSortOrder(newSortOrder);
  };

  return [handleSorter] as const;
};
