const ACCOUNTANT_ROLES: string[] = ["accountant-role", "assistant-role"];

const doesRoleExist = (roles: string[], targetRoles: string[]): boolean => targetRoles
  .some((role: string) => roles.includes(role));

export const reassignedUserRole = (responsibleRoles: string[]): string => {
  const defaultRole: string = " Бухгалтеру ";
  const assignedRole: string = " сотруднику Контакт центра ";

  return !!responsibleRoles.length && doesRoleExist(responsibleRoles, ACCOUNTANT_ROLES)
    ? assignedRole
    : defaultRole;
};
