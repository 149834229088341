import React, {
  FC,
  JSX,
  Dispatch,
  SetStateAction,
} from "react";
import { UploadedFilesPropsType } from "app/types";
import DrawerDragAndDrop from "../../DrawerDragAndDrop/DrawerDragAndDrop";

interface IUploadedFilesListBlockProps {
  uploadedFilesList: JSX.Element[] | null;
  setDragAndDropFiles: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
  dragAndDropFilesCount: number;
  clarificationHasFiles: boolean;
  setDragAndDropFilesCount: Dispatch<SetStateAction<number>>;
}

const UploadedFilesListBlock: FC<IUploadedFilesListBlockProps> = ({
  uploadedFilesList,
  setDragAndDropFiles,
  dragAndDropFilesCount,
  clarificationHasFiles,
  setDragAndDropFilesCount,
}): JSX.Element | boolean => {

  return clarificationHasFiles && (
    <DrawerDragAndDrop
      uploadedFilesList={uploadedFilesList}
      setDragAndDropFiles={setDragAndDropFiles}
      dragAndDropFilesCount={dragAndDropFilesCount}
      setDragAndDropFilesCount={setDragAndDropFilesCount}
    />
  );
};

export default UploadedFilesListBlock;
