import React, { FC, JSX, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { errorFinalizeOneC } from "../../../../api/tasks.api";
import { notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import css from "./FinalizeTaskOneC.module.css";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/warning_circle_icon.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning_blue_icon.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/check_circle_icon_green.svg";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import { DocumentTaskType } from "app/types";

interface IFinalizeTaskOneC {
}

const FinalizeTaskOneC: FC<IFinalizeTaskOneC> = (): JSX.Element => {

  const navigate: NavigateFunction = useNavigate();

  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);

  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  const notificationsLoaderContent = (): JSX.Element => {
    return (
      <div className="flex">
        <LoadingCustom className="mr-[16px]" fontSize={20}/>
        <div>
          <div className={`${css.notificationsHeader} mb-[8px]`}>
            Обработка данных
          </div>
          <div className={css.notificationsBody}>
            Данные в процессе обработки, пожалуйста подождите.
          </div>
        </div>
      </div>
    );
  };

  const notificationLoader = (): void => {
    notification.open({
      key: "loader",
      message: null,
      description: notificationsLoaderContent(),
      style: {backgroundColor: "#FFF",
        borderRadius: "8px",
        padding: "16px 24px",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.12)"
      },
      closeIcon: <CloseOutlined className="text-black"/>,
    });
  };

  const notificationsErrorContent = (): JSX.Element => {
    return (
      <div className="flex">
        <ErrorIcon className="flex-shrink-0 w-[24px] h-[24px] mr-[16px]"/>
        <div>
          <div className={`${css.notificationsHeader} mb-[8px]`}>
            Ошибка добавления данных в 1С
          </div>
          <div className={css.notificationsBody}>
            В процессе обработки данных произошла ошибка, пожалуйста проверьте ввод данных в 1С
          </div>
        </div>
      </div>
    );
  };

  const notificationError = (): void => {
    notification.open({
      message: null,
      description: notificationsErrorContent(),
      style: {backgroundColor: "#FFF",
        borderRadius: "8px",
        padding: "16px 24px",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.12)"
      },
      closeIcon: <CloseOutlined className="text-black"/>,
    });
  };

  const notificationsSuccessContent = (): JSX.Element => {
    return (
      <div className="flex">
        <SuccessIcon className="flex-shrink-0 w-[24px] h-[24px] mr-[16px]"/>
        <div className={css.notificationsHeader}>
          Данные успешно обработаны
        </div>
      </div>
    );
  };

  const notificationSuccess = (): void => {
    notification.open({
      message: null,
      description: notificationsSuccessContent(),
      style: {backgroundColor: "#FFF",
        borderRadius: "8px",
        padding: "16px 24px",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.12)"
      },
      closeIcon: <CloseOutlined className="text-black"/>,
    });
  };

  const finalizeTaskOneC = async (): Promise<void> => {
    setButtonDisable(true);
    notificationLoader();
    try {
      const response = await errorFinalizeOneC(documentTask?.fields?.uuid);
      if (response.status === 200) {
        notification.destroy("loader");
        notificationSuccess();
        setButtonDisable(false);
      } else {
        notification.destroy("loader");
        notificationError();
        setButtonDisable(false);
      }
    } catch (error) {
      notification.destroy("loader");
      notificationError();
      setButtonDisable(false);
    }
  };

  return (
    <div className={css.actions}>
      <div className="flex items-center">
        <WarningIcon className="flex flex-shrink-0 mr-2 w-[24px] h-[24px]"/>
        <div className={css.actionsHead}>
          Пожалуйста, подтвердите, что все данные организации были добавлены в 1С
        </div>
      </div>
        <ButtonCustom
          type="primary"
          className={css.button}
          text="Подтвердить ввод данных в 1С"
          onClick={finalizeTaskOneC}
          disabled={buttonDisable}
        />
    </div>
  );
};

export default FinalizeTaskOneC;
