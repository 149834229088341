import { TasksListingDataType } from "app/types";
import css from "../components/Tasks/Tasks.module.css";
import React from "react";
import { Link } from "react-router-dom";

export const GotoTaskCard = (
  record: TasksListingDataType,
  clientUuid?: string,
) => {
  return (
    <Link
      className={css.tableCell}
      to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
    >
      {record?.fields?.number}
    </Link>
  );
};
