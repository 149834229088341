import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
} from "react";
import { FormInstance } from "antd";
import { taskClientsType } from "../../../../utils/catalog";
import FiltersComponent from "../FiltersComponent";

interface IClientsTypeFiltersProps {
  form: FormInstance,
  isModalOpen: boolean,
  selectedOPFType: string[],
  setSelectedOPFType: Dispatch<SetStateAction<string[]>>
}

const ClientsTypeFilters: FC<IClientsTypeFiltersProps> = ({
  form,
  isModalOpen,
  selectedOPFType,
  setSelectedOPFType
}): JSX.Element => {
  return (
    <FiltersComponent
      key="typeOPF"
      form={form}
      isModalOpen={isModalOpen}
      selectedItems={selectedOPFType}
      setSelectedItems={setSelectedOPFType}
      taskTypes={taskClientsType}
      formFieldName="typeOPF"
      headerText="По типу клиента (ОПФ)"
    />
  );
};

export default ClientsTypeFilters;