import React, { FC, JSX } from "react";
import css from "./CardLabel.module.css";

interface ICardLabelProps {
  title: string;
}

const CardLabel: FC<ICardLabelProps> = ({ title }): JSX.Element => {
  return (
    <h2 className={css.titleCards}>
      {title}
    </h2>
  );
};

export default CardLabel;