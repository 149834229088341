import { combineReducers } from "redux";
import tasks from "./tasks.reducer";
import client from "./client.reducer";
import account from "./account.reducer";
import document from "./document.reducer";
import employee from "./employee.reducer";
import clientProfile from "./clientProfile.reducer";
import notifications from "./notifications.reducer";
import employeeProfile from "./employeeProfile.reducer";
import reports from "./reports.reducer";
//Alpha reducers imports
import contractors from "./alpha/contractors.reducer";
import resume from "./alpha/resume.reducer";
import vacancy from "./alpha/vacancy.reducer";
import application from "./alpha/application.reducer";
import contracts from "./alpha/contracts.reducer";

const mainReducer = combineReducers({
  tasks,
  client,
  account,
  document,
  employee,
  clientProfile,
  notifications,
  employeeProfile,
  reports,
  //Alpha reducers
  contractors,
  resume,
  vacancy,
  application,
  contracts,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
