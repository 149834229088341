import axios from "../../utils/axios";
import { AlphaListParamsType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_TASKS_URL;

//ручка получения списка заявок
export const getApplications = async (params?: AlphaListParamsType | null): Promise<any> => {
  return axios.GET(API_BASE, "api/mdm/documents/v1/d/supplier-request", params);
};

//ручка получения заявки
export const getApplication = async (applicationUuid?: string | null): Promise<any> => {
  return axios.GET(API_BASE, `api/mdm/documents/v1/d/supplier-request/uuid/${applicationUuid}`);
};

//TODO ручка на бэке поменяется, придется в дальнейшем переписать
//TODO сейчас ручка перезаписывает сущность заявки, что неправильно
//ручка отправки действия по заявке
export const sendActionApplication = async (
  applicationUuid?: string | null,
  body?: AlphaListParamsType | null
): Promise<any> => {
  return axios.POST(API_BASE, `api/mdm/documents/v1/d/supplier-request/uuid/${applicationUuid}`, body);
};
