import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import { AbstractDocType, DocumentClientProfileType, DocumentTaskType } from "app/types";
import css from "./ClientProfileCardEmployees.module.css";
import { TaskQuestionnaireCardSteps } from "../ClientProfileCard";
import { ReactComponent as SortIcons } from "../../../assets/icons/sort_icons.svg";
import { fetchDocumentClientProfile, fetchNotAgreedDocumentsProfile } from "../../../actions/clientProfile.actions";
import { AppDispatch } from "../../../store/store";
import TagCustom from "../../ui-kit/TagCustom/TagCustom";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import ZeroEmployees from "../../ClientsList/ClientCard/ClientCardItem/Employees/ZeroEmployees/ZeroEmployees";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { getDocumentFailure } from "../../../actions/document.actions";
import {
  getDocumentEmployeeProfileFailure,
  getSchemaEmployeeProfileFailure
} from "../../../actions/employeeProfile.actions";

interface ITaskQuestionnaireEmployeesProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setEmployeeCard: Dispatch<SetStateAction<boolean>>;
  isIndividualEntrepreneur: boolean;
  clientProfileSlug: string;
}

const ClientProfileCardEmployees: FC<ITaskQuestionnaireEmployeesProps> = ({
  setCurrentStep,
  setEmployeeCard,
  isIndividualEntrepreneur,
  clientProfileSlug,
}): JSX.Element => {
  const [statusCheck, setStatusCheck] = useState<boolean>(true);
  const [clientEmployeeField, setClientEmployeeField] = useState<AbstractDocType[] | undefined>([]);
  const [sortDirection, setSortDirection] = useState<"asc" | "des">("asc");

  const dispatch = useDispatch<AppDispatch>();

  const params: string = useLocation()?.pathname;

  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);

  const validationCompleted: boolean = documentTask?.fields?.status_task === "FIN_TRUE"
    || documentTask?.fields?.status_task === "1C_SYNC"
    || documentTask?.fields?.status_task === "1C_SUCCESS";

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  useEffect(() => {
    if (documentClientProfile?.fields?.employees_profiles?.length) {
      setClientEmployeeField(documentClientProfile?.fields?.employees_profiles);
    } else {
      setClientEmployeeField(documentClientProfile?.fields?.employees);
    }

    dispatch(getDocumentFailure());
    dispatch(getSchemaEmployeeProfileFailure());
    dispatch(getDocumentEmployeeProfileFailure());
  }, [documentClientProfile]);

  const renderStatusTag = (status: string | undefined, record: AbstractDocType): JSX.Element => {
    switch (status) {
      case "APPROVED":
        return (
          <div
            id="toEmployeeCard"
            className="cursor-pointer"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => toCard(e, record)}
          >
            <TagCustom color="success" text="Проверено"/>
          </div>
        );
      case "VALIDATION":
        return (
          <div
            id="toEmployeeCard"
            className="cursor-pointer"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => toCard(e, record)}
          >
            <TagCustom color="warning" text="На проверке"/>
          </div>
        );
      case "DECLINED":
        return (
          <div
            id="toEmployeeCard"
            className="cursor-pointer"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => toCard(e, record)}
          >
            <TagCustom color="error" text="Отклонено"/>
          </div>
        );
      default:
        return (
          <div
            id="toEmployeeCard"
            className="cursor-pointer"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => toCard(e, record)}
          >
            <TagCustom color="warning" text="На проверке"/>
          </div>
        );
    }
  };

  const pageChange = (stepChange: number): void => {
    setCurrentStep(stepChange);
    window.scrollTo(0, 0);
  };

  const navigate: NavigateFunction = useNavigate();

  const toCard = (e: React.MouseEvent<HTMLElement>, employee: AbstractDocType): void => {
    const taskId: string = params?.split("/")[2];
    const clientProfileId: string = params?.split("/")[4];

    e.preventDefault();
    setEmployeeCard(true);
    navigate(`/task/${taskId}/questionnaire/${clientProfileId}/employee=${employee?.uuid}`,
      {state: {employee}});
  };

  useEffect(() => {
    dispatch(fetchNotAgreedDocumentsProfile(documentTask?.fields?.uuid ?? ""));
    setTimeout(() =>
      dispatch(fetchDocumentClientProfile(clientProfileSlug, documentClientProfile?.fields?.uuid ?? "")), 300);
  }, [dispatch, clientProfileSlug, documentTask]);

  const renderEmployees = (record: AbstractDocType): JSX.Element => {
    return (
      <div
        id="toEmployeeCard"
        className={css.tableCell}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => toCard(e, record)}
      >
        {record.full_name}
      </div>
    );
  };

  const handleSorterEmployee = async (e: React.MouseEvent<HTMLElement>, text: string): Promise<void | null> => {
    e.preventDefault();

    if (!clientEmployeeField) {
      return null;
    }

    const direction: "asc" | "des" = sortDirection === "asc" ? "des" : "asc";

    const sortedEmployees: AbstractDocType[] = [...clientEmployeeField].sort((
      a: AbstractDocType,
      b: AbstractDocType
    ): number => {
      let result;

      switch (text) {
        case "name":
          result = a.full_name?.localeCompare(b.full_name ?? "") ?? 0;
          break;
        case "status":
          result = a.employee_status?.localeCompare(b.employee_status ?? "") ?? 0;
          break;
        default:
          result = 0;
      }

      return direction === "asc" ? result : -result;
    });

    setSortDirection(direction);
    setClientEmployeeField(sortedEmployees);
  };

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="flex justify-between items-center cursor-pointer">
        <div>
          {text}
        </div>
        <span className="block">
          <SortIcons/>
        </span>
      </div>
    );
  };

  //шапка таблицы
  const columns: ColumnsType<AbstractDocType> = [
    {
      title: tableTitle("Сотрудник"),
      dataIndex: "full_name",
      width: "50%",
      key: "сотрудник",
      align: "left",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterEmployee(e, "name"),
      }),
      render: (_: string, record: AbstractDocType) => renderEmployees(record),
    },
    {
      title: tableTitle("Статус"),
      dataIndex: "employee_status",
      width: "50%",
      key: "статус",
      align: "left",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterEmployee(e, "status"),
      }),
      render: (_: string, record: AbstractDocType) => renderStatusTag(record.employee_status, record),
    }
  ];

  // логика пагинации страницы
  const pageSizeOption: number[] = [5, 10, 20];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(pageSizeOption[1]);

  const startIndex: number = (currentPage - 1) * pageSize;
  const endIndex: number = startIndex + pageSize;

  const filterClientEmployeeField: AbstractDocType[] = clientEmployeeField
    ? clientEmployeeField?.slice(startIndex, endIndex)
    : [];

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  //логика работы лоудера
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (documentClientProfile) {
      setLoader(true);
    }
  }, [documentClientProfile]);

  return (loader ? (
      <>
        <div className={`${css.container} p-7 mb-3 bg-white`}>
          <h1 className={`${css.headerText} mb-3`}>
            Список сотрудников
          </h1>
          <div className="inline-block mb-8 gray-color text-sm">
            Выберите сотрудника для проверки
          </div>
          <div className="mb-7">
            {clientEmployeeField?.length ? (
              <>
                <Table
                  columns={columns}
                  dataSource={filterClientEmployeeField}
                  pagination={false}
                />
                <PaginationCustom
                  className={css.paginationShowTotal}
                  total={clientEmployeeField?.length}
                  showTotal={(total: number, range: number[]): string =>
                    `${range[0]}-${range[1]} из ${total}`
                  }
                  currentPage={currentPage}
                  defaultPageSize={10}
                  pageSize={pageSize}
                  defaultCurrent={1}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              </>
            ) : (
              <ZeroEmployees variant="clientProfile"/>
            )}
          </div>
        </div>
        <div className={`${css.container} p-[18px] mb-3 bg-white border-bottom-gray text-end`}>
          <ButtonCustom
            id="stepBack"
            className={css.buttonBack}
            key="cancel"
            size="large"
            type="default"
            text="Назад"
            onClick={() => pageChange(!isIndividualEntrepreneur
              ? TaskQuestionnaireCardSteps.ManagerDetailsStep
              : TaskQuestionnaireCardSteps.OrganisationStep
            )}
          />
          {!validationCompleted ? (
            <ButtonCustom
              id="stepForward"
              className={`${css.buttonOk} ml-4`}
              disabled={!statusCheck}
              type="primary"
              text="Продолжить"
              size="large"
              onClick={() => pageChange(TaskQuestionnaireCardSteps.NotAgreedStep)}
            />
          ) : null
          }
        </div>
      </>
    ) : (
      <div className={`${css.container} p-12 bg-white`}>
        <LoadingCustom className="w-full items-center mt-[22vh] mb-[22vh]"/>
      </div>
    )
  );
};

export default ClientProfileCardEmployees;
