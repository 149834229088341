export const renderDate = (text: string | null): string => {
  if (text === null) {
    return "";
  }

  const [year, month, day]: string[] = text.slice(0, 10).split("-");
  const reformYear: string = year.slice(2, 4);

  return `${day}.${month}.${reformYear}`;
};

export const renderFullDate = (dateFrom: string | null, dateTo: string | null): string => {
  if (dateFrom === null || dateTo === null) {
    return "";
  }

  const [yearFrom, monthFrom, dayFrom]: string[] = dateFrom.slice(0, 10).split("-");
  const [yearTo, monthTo, dayTo]: string[] = dateTo.slice(0, 10).split("-");

  return `${dayFrom}.${monthFrom}.${yearFrom} - ${dayTo}.${monthTo}.${yearTo}`;
};
