import React, { FC, JSX } from "react";
import css from "./OptionsInfo.module.css";
import optionOne from "../../../../assets/icons/BK_option_p1.png";
import optionSix from "../../../../assets/icons/BK_option_p6.png";

interface IOptionsInfoProps {
}

const optionTwo: string = "https://www.smbconnect.ru/new-landing/images/tild3663-6233-4437-b864-" +
    "376434313861__icon_1.png";
const optionThree: string = "https://www.smbconnect.ru/new-landing/images/tild6564-3539-4035-b463-3566373962" +
    "62__icon_2.png";
const optionFour: string = "https://www.smbconnect.ru/new-landing/images/tild3663-3638-4561-b636-613538353" +
    "532__icon_3.png";
const optionFive: string = "https://www.smbconnect.ru/new-landing/images/tild6564-3666-4866-a534-6162303" +
    "83530__icon_4.png";

const OptionsInfo: FC<IOptionsInfoProps> = (): JSX.Element => {
  const renderOption = (imgSrc: string, label: string): JSX.Element => (
    <div className="flex flex-col justify-center items-center w-[33%] mb-20">
      <img
        src={imgSrc}
        className={css.imgStyle}
        alt={label}
      />
      <div className={css.optionsText}>
        {label}
      </div>
    </div>
  );
  
  return (
    <div className="max-w-[1200px] mx-auto" id="functions">
      <div className={css.functionality}>
        Основные функциональные возможности
        программы для ЭВМ &quot;Бизнес Конвейер&quot;
      </div>
      <ul className={css.optionsStyle}>
        {renderOption(optionOne, "Создание и модификация бизнес-процессов")}
        {renderOption(optionTwo, "Отображение доступных к исполнению бизнес-процессов")}
        {renderOption(optionThree, "Мониторинг исполняемых бизнес-процессов")}
        {renderOption(optionFour, "Уведомление внешних систем об изменениях и событиях в бизнес-процессах")}
        {renderOption(optionFive, "Управление пользователями и доступом к функционалу")}
        {renderOption(optionSix, "Управление бизнес-справочниками")}
      </ul>
    </div>
  );
};

export default OptionsInfo;