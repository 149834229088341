import React, { FC, JSX, useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import PerformerPersonalInformation from "./PerformerPersonalInformation/PerformerPersonalInformation";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { UserFullInfoType } from "app/types";
import * as AccountAPI from "../../../api/account.api";
import css from "./PerformerCard.module.css";
import { getUsersListingData } from "../../../actions/account.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import ProfileAbsenteesList from "../../Profile/ProfileAbsenteesList/ProfileAbsenteesList";

interface IPerformerCardProps {
  isAbsentCard?: boolean;
}

enum PerformerTabs {
  PersonalInformation = "1",
  Absences = "2",
}

const PerformerCard: FC<IPerformerCardProps> = ({
  isAbsentCard
}): JSX.Element => {
  const [absentUuid, setAbsentUuid] = useState<string>("");
  const [absentUser, setAbsentUser] = useState<UserFullInfoType | null>(null);
  const [activeKey, setActiveKey] = useState<string>(PerformerTabs.PersonalInformation);

  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const hasPerformers: boolean = location.pathname.includes("performers");
  
  const fullUserName: string = `
    ${absentUser?.attributes?.last_name || ""}
    ${absentUser?.attributes?.first_name || ""}
    ${absentUser?.attributes?.second_name || ""}`;

  useEffect(() => {
    setAbsentUuid(location.pathname?.split("/")?.slice(-1)?.toString());
  }, [location]);

  useEffect(() => {
    if (absentUuid) {
      AccountAPI.getUserFullInfo(absentUuid)
      .then((response) => {
        setAbsentUser(response.data);
        setActiveKey(PerformerTabs.PersonalInformation);
      })
      .catch((err) => console.error("Get absent user error:", err));
    }
  }, [absentUuid]);

  const itemsTabs: TabsProps["items"] = [
    {
      key: PerformerTabs.PersonalInformation,
      label: "Персональные данные",
      children: <PerformerPersonalInformation personalInfo={absentUser}/>,
    },
    {
      key: PerformerTabs.Absences,
      label: "Отсутствия",
      children: <ProfileAbsenteesList performerUuid={absentUuid} />,
    },
  ];
  
  const correctNavigate = (): void => {
    hasPerformers ? navigate("/performers") : navigate("/profile", {state: {isAbsentCard}});
  };
  
  const handleTabChange = (key: string): void => {
    setActiveKey(key);
  };

  return (
    <div className={css.container}>
      <div>
        <ButtonCustom
          className={css.buttonReturn}
          type="link"
          text="Назад"
          onClick={correctNavigate}
        >
          <ArrowLeftOutlined/>
        </ButtonCustom>
      </div>
      <h1 className={css.containerHeader}>
        {fullUserName}
      </h1>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeKey}
        onChange={handleTabChange}
        items={itemsTabs}
      />
    </div>
  );
};

export default PerformerCard;
