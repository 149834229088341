import React, {
  FC,
  JSX,
  Dispatch,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { UploadedFilesPropsType} from "app/types";
import { finalizeClientTask } from "../../../../actions/document.actions";
import { getTaskFile1CFailure, getTaskUuid1CFailure } from "../../../../actions/tasks.actions";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { confirmModalDescr } from "./utils/confirmModalDescriptions";
import { Divider, Modal } from "antd";
import css from "./TaskCardConfirmModal.module.css";

interface ITaskCardConfirmModalProps {
  taskUuid: string;
  confirmTaskAction: string;
  isConfirmModalOpen: boolean;
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  setDrawerTaskAction: Dispatch<SetStateAction<string>>;
  setDragAndDropFiles?: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
  setConfirmTaskAction?: Dispatch<SetStateAction<string>>;
  setDragAndDropFilesCount?: Dispatch<SetStateAction<number>>;
  setAutomaticProcessingActive: Dispatch<SetStateAction<boolean>>;
}

enum ActionType {
  CloseModal = "closeModal",
  CloseTask = "closeTask",
}

const TaskCardConfirmModal: FC<ITaskCardConfirmModalProps> = ({
  taskUuid,
  confirmTaskAction = ActionType.CloseTask,
  isConfirmModalOpen,
  setConfirmModalOpen,
  setDrawerTaskAction,
  setDragAndDropFiles,
  setConfirmTaskAction,
  setDragAndDropFilesCount,
  setAutomaticProcessingActive,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const confirmModalHeader: JSX.Element = (
    <div className={css.modalTitle}>
      Подтверждение действия
    </div>
  );

  const resetModalState = (): void => {
    setDrawerTaskAction("");
    setConfirmModalOpen(false);
    setDragAndDropFiles && setDragAndDropFiles([]);
    setConfirmTaskAction && setConfirmTaskAction("");
    setDragAndDropFilesCount && setDragAndDropFilesCount(0);
    setAutomaticProcessingActive(false);
    dispatch(getTaskUuid1CFailure(0));
    dispatch(getTaskFile1CFailure(0));
  };

  const closeConfirmModal = (): void => {
    if (confirmTaskAction === ActionType.CloseModal) {
      setConfirmModalOpen(false);
    } else {
      resetModalState();
    }
  };

  const approveConfirmModal = (): void => {
    if (confirmTaskAction === ActionType.CloseTask) dispatch(finalizeClientTask(taskUuid ?? ""));

    resetModalState();
  };

  const confirmModalFooter: JSX.Element = (
    <>
      <Divider className="m-0"/>
      <ButtonCustom
        className={`${css.buttonBack} mt-4 mr-2`}
        key="cancel"
        size="large"
        type="default"
        ghost
        text="Отменить"
        onClick={closeConfirmModal}
      />
      <ButtonCustom
        key="approve"
        size="large"
        type="primary"
        text="Подтвердить"
        onClick={approveConfirmModal}
      />
    </>
  );

  return (
    <Modal
      title={confirmModalHeader}
      open={isConfirmModalOpen}
      onOk={approveConfirmModal}
      onCancel={closeConfirmModal}
      footer={confirmModalFooter}
      width="580px"
    >
      <p className={css.modalText}>{confirmModalDescr(confirmTaskAction)}</p>
    </Modal>
  );
};

export default TaskCardConfirmModal;
