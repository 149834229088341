import React, { FC, JSX, useEffect, useState } from "react";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "../DocumentsCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { getDocumentSchema, getEmployeeDocumentData } from "../../../../../../actions/document.actions";
import { AppDispatch } from "../../../../../../store/store";
import TagCustom from "../../../../../ui-kit/TagCustom/TagCustom";
import { DocumentEmployeeProfileType, UpdateDocumentType } from "app/types";
import ZeroClientProfileDocument from "../../../../ZeroClientProfileDocument/ZeroClientProfileDocument";

interface IMigrationDocProps {
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  docUuid: string;
  docType: string;
  hasMigrationOrPatentDoc: boolean;
}

const MigrationDoc: FC<IMigrationDocProps> = ({
  documentUuid,
  showImageViewer,
  docUuid,
  docType,
  hasMigrationOrPatentDoc,
}): JSX.Element => {
  const [isDocConfirmed, setDocConfirmed] = useState<boolean>(false);
  const [schemaName, setSchemaName] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const migrationDoc: UpdateDocumentType | null = useSelector((state: AppStateType) => state.document.migrationDoc);

  useEffect(() => {
    if (migrationDoc?.fields?.document_status === "APPROVED") {
      setDocConfirmed(true);
    } else {
      setDocConfirmed(false);
    }
  }, [migrationDoc]);

  const renderAgreed = (): JSX.Element => {
    return (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderMigrationDeclineReason = (): JSX.Element => {
    return (
      <div className={`${css.cause} mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {migrationDoc?.fields?.decline_reason}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (schemaName) {
      dispatch(getEmployeeDocumentData(schemaName, documentUuid));
    }
  }, [dispatch, schemaName]);

  const openImageViewer = (text: string): void => {
    setSchemaName(() => {
      dispatch(getDocumentSchema(text));
      return text;
    });
  };

  return (
    <>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          {docType === "migration-card-schema" ? "Миграционная карта" : "Патент"}
        </h1>
        {migrationDoc?.fields ? (
          <span className="mb-2">
            {hasMigrationOrPatentDoc && (
              <div className="flex items-center mb-5">
                <ButtonCustom
                  id="checkEmployeeMigration"
                  className="w-36 mr-2"
                  type="primary"
                  ghost
                  text="Проверить файлы"
                  onClick={() => {
                    showImageViewer(docType, docUuid);
                    openImageViewer(docType);
                  }
                  }
                />
                {!!migrationDoc?.fields?.document_status && (isDocConfirmed ? renderAgreed() : renderNotAgreed())}
              </div>
            )}
            {!!migrationDoc?.fields?.decline_reason && (isDocConfirmed ? null : renderMigrationDeclineReason())}
          </span>
        ) : (
          <ZeroClientProfileDocument variant="Миграционная карта сотрудника" />
        )}
      </div>
    </>
  );
};

export default MigrationDoc;
