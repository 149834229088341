export const renderActionDrawerDescr = (taskAction: string): string => {
  switch (taskAction) {
    case "Отправить на уточнение":
    case "Обработать задачу вручную":
      return `Перед отправкой ответа клиенту опишите требования для уточнения данных  в произвольной форме. 
              Ваш комментарий увидит клиент.`;
    case "Обработать задачу автоматически":
      return "Выберите тип задачи и период";
    default:
      return "";
  }
};
