import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_SCHEMA_TASK_FAILURE,
  GET_SCHEMA_TASK_SUCCESS,
  GET_TASKS_LISTING_FAILURE,
  GET_TASKS_LISTING_SUCCESS,
  CREATE_NEW_TASK_SUCCESS,
  CREATE_NEW_TASK_FAILURE,
  GET_DOCUMENT_TASK_SUCCESS,
  GET_DOCUMENT_TASK_FAILURE,
  GET_STATUS_TASK_SUCCESS,
  GET_STATUS_TASK_FAILURE,
  GET_TASKS_PAGE_SIZE,
  GET_TASKS_PAGE_NUMBER,
  SEND_TYPICAL_TASK_DECISION_SUCCESS,
  SEND_TYPICAL_TASK_DECISION_FAILURE,
  GET_ACTION_HISTORY_SUCCESS,
  GET_ACTION_HISTORY_FAILURE,
  GET_CLIENT_TASK_LISTING_SUCCESS,
  GET_CLIENT_TASK_LISTING_FAILURE,
  GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS,
  GET_PROCESS_FILE_AUTOMATICALLY_FAILURE,
  TASK_LISTING_SORTER_DATA,
  TASK_LISTING_FILTER_DATA,
  TASK_LISTING_TAG_KEY,
  TASK_LISTING_PARAMS_DATA,
  TASK_LISTING_LOADING_DEFAULT,
  GET_UUID_TASK_1C_SUCCESS,
  GET_UUID_TASK_1C_FAILURE,
  GET_TASK_FILE_1C_SUCCESS,
  GET_TASK_FILE_1C_FAILURE,
  GET_TASK_STEPS_INFO_SUCCESS,
  GET_TASK_STEPS_INFO_FAILURE,
  REPEAT_TASK_STEP_SUCCESS,
  REPEAT_TASK_STEP_FAILURE,
  GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS,
  GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE,
  CLEAR_TASKS_NOTIFICATION,
} from "../constants/actionTypes";
import {
  NewTaskType,
  NewTaskDataType,
  TasksListingType,
  DocumentTaskType,
  SchemaType,
  FilterTasksType,
  TaskNotificationStatusType,
  TypicalTaskDecisionResponseType,
  TypicalTaskDecisionType,
  ActionHistoryType,
  ActionHistoryParamsType,
  Uuid1CType,
  ParamsGetUuid1CType,
  GetFile1CType,
  ParamsGetFile1CType,
  TaskExecutionWrapper,
  RepeatStepDataType,
  MarkupTask1CDataType
} from "app/types";
import * as TasksAPI from "../api/tasks.api";

export type TaskActionsTypes =
  | GetSchemaTaskSuccessType
  | GetSchemaTaskFailureType
  | GetTaskListingSuccessType
  | GetTaskListingFailureType
  | CreateNewTaskSuccessType
  | CreateNewTaskFailureType
  | GetDocumentTaskSuccessType
  | GetDocumentTaskFailureType
  | GetStatusTaskSuccessType
  | GetStatusTaskFailureType
  | GetTaskPageSizeType
  | GetTaskPageNumberType
  | SendTypicalTaskDecisionSuccessType
  | SendTypicalTaskDecisionFailureType
  | GetActionHistorySuccessType
  | GetActionHistoryFailureType
  | GetClientTasksListingSuccessType
  | GetClientTaskListingFailureType
  | GetProcessAutomaticallyFileSuccessType
  | GetProcessAutomaticallyFileFailureType
  | SetTaskListingSorterData
  | SetTaskListingFilterData
  | SetTaskListingParamsData
  | SetTaskListingTagKey
  | SetTaskListingLoadingDefault
  | GetTaskUuid1CSuccessType
  | GetTaskUuid1CFailureType
  | GetTaskFile1CSuccessType
  | GetTaskFile1CFailureType
  | GetTaskStepsInfoSuccessType
  | GetTaskStepsInfoFailureType
  | RepeatTaskStepSuccessType
  | RepeatTaskStepFailureType
  | GetOperationMarkupFilesSuccessType
  | GetOperationMarkupFilesFailureType
  | ClearTasksNotification;

export type ClearTasksNotification = {
  type: typeof CLEAR_TASKS_NOTIFICATION;
  payload: {
    notification: null;
  };
};

export const clearTasksNotification = (notification = null): ClearTasksNotification => ({
  type: CLEAR_TASKS_NOTIFICATION,
  payload: {
    notification
  }
});

// Логика получения схемы задачи
export type GetSchemaTaskSuccessType = {
  type: typeof GET_SCHEMA_TASK_SUCCESS;
  payload: {
    schemaTask: SchemaType;
  };
};

const getSchemaTaskSuccess = (schemaTask: SchemaType): GetSchemaTaskSuccessType => ({
  type: GET_SCHEMA_TASK_SUCCESS,
  payload: {
    schemaTask
  }
});

export type GetSchemaTaskFailureType = {
  type: typeof GET_SCHEMA_TASK_FAILURE;
  payload: {
    error: any;
  };
};

export const getSchemaTaskFailure = (error?: any): GetSchemaTaskFailureType => ({
  type: GET_SCHEMA_TASK_FAILURE,
  payload: {
    error
  }
});

export const fetchSchemaTask = (): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  TaskActionsTypes
> => async (dispatch) => {
  try {
    const response = await TasksAPI.getSchemaTask();

    dispatch(getSchemaTaskSuccess(response.data));
  } catch (error: any) {
    dispatch(getSchemaTaskFailure(error.message));
  }
};

// Логика получения листинга задач
export type GetTaskListingSuccessType = {
  type: typeof GET_TASKS_LISTING_SUCCESS;
  payload: {
    tasksListing: TasksListingType;
  };
};

const getTaskListingSuccess = (tasksListing: TasksListingType): GetTaskListingSuccessType => ({
  type: GET_TASKS_LISTING_SUCCESS,
  payload: {
    tasksListing
  }
});

export type GetTaskListingFailureType = {
  type: typeof GET_TASKS_LISTING_FAILURE;
  payload: {
    error: any;
  };
};

export const getTaskListingFailure = (error?: any): GetTaskListingFailureType => ({
  type: GET_TASKS_LISTING_FAILURE,
  payload: {
    error
  }
});

//+пагинация листинга задач
export type GetTaskPageSizeType = {
  type: typeof GET_TASKS_PAGE_SIZE;
  payload: {
    size: number;
  };
};

export const getTaskPageSize = (size: number): GetTaskPageSizeType => ({
  type: GET_TASKS_PAGE_SIZE,
  payload: {
    size
  }
});

export type GetTaskPageNumberType = {
  type: typeof GET_TASKS_PAGE_NUMBER,
  payload: {
    page: number;
  };
};

export const getTaskPageNumber = (page: number): GetTaskPageNumberType => ({
  type: GET_TASKS_PAGE_NUMBER,
  payload: {
    page
  }
});

export const getTasksData = (params: null | FilterTasksType = null, signal?: AbortSignal):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  TaskActionsTypes
> => async (dispatch) => {
  try {
    const response = await TasksAPI.getTasks(params, signal);

    dispatch(getTaskListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getTaskListingFailure(error.message));
  }
};

//Логика сортировки листинга задач
export type SetTaskListingSorterData = {
  type: typeof TASK_LISTING_SORTER_DATA;
  payload: {
    tasksSorter: string | null;
  };
};

export const setTaskListingSorter = (tasksSorter: string | null): SetTaskListingSorterData => ({
  type: TASK_LISTING_SORTER_DATA,
  payload: {
    tasksSorter
  }
});

//Логика фильтрации листинга задач
export type SetTaskListingFilterData = {
  type: typeof TASK_LISTING_FILTER_DATA;
  payload: {
    tasksFilter: FilterTasksType | null;
  };
};

export const setTaskListingFilter = (tasksFilter: FilterTasksType | null): SetTaskListingFilterData => ({
  type: TASK_LISTING_FILTER_DATA,
  payload: {
    tasksFilter
  }
});

export type SetTaskListingParamsData = {
  type: typeof TASK_LISTING_PARAMS_DATA;
  payload: {
    tasksParams: FilterTasksType | null;
  };
};

export const setTaskListingParams = (tasksParams: FilterTasksType | null): SetTaskListingParamsData => ({
  type: TASK_LISTING_PARAMS_DATA,
  payload: {
    tasksParams
  }
});

export type SetTaskListingTagKey = {
  type: typeof TASK_LISTING_TAG_KEY;
  payload: {
    tasksTagKey: string;
  };
};

export const setTaskListingTagKey = (tasksTagKey: string): SetTaskListingTagKey => ({
  type: TASK_LISTING_TAG_KEY,
  payload: {
    tasksTagKey
  }
});

export type SetTaskListingLoadingDefault = {
  type: typeof TASK_LISTING_LOADING_DEFAULT;
  payload: {
    loadingDefault: boolean;
  };
};

export const setTaskListingLoadingDefault = (loadingDefault: boolean): SetTaskListingLoadingDefault => ({
  type: TASK_LISTING_LOADING_DEFAULT,
  payload: {
    loadingDefault
  }
});

// Логика создания новой задачи
export type CreateNewTaskSuccessType = {
  type: typeof CREATE_NEW_TASK_SUCCESS;
  payload: {
    newTask: NewTaskType;
  };
};

const createNewTaskSuccess = (newTask: NewTaskType): CreateNewTaskSuccessType => ({
  type: CREATE_NEW_TASK_SUCCESS,
  payload: {
    newTask
  }
});

export type CreateNewTaskFailureType = {
  type: typeof CREATE_NEW_TASK_FAILURE;
  payload: {
    error: any;
  };
};

const createNewTaskFailure = (error: any): CreateNewTaskFailureType => ({
  type: CREATE_NEW_TASK_FAILURE,
  payload: {
    error
  }
});

export const createNewTask = (body: NewTaskDataType):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.createTask(body);

    dispatch(createNewTaskSuccess(response.data));
  } catch (error: any) {
    dispatch(createNewTaskFailure(error.message));
  }
};

//логика получения данных задачи по uuid документа
export type GetDocumentTaskSuccessType = {
  type: typeof GET_DOCUMENT_TASK_SUCCESS;
  payload: {
    documentTask: DocumentTaskType;
    getDocumentTaskStatus: number;
  };
};

const getDocumentTaskSuccess = (documentTask: DocumentTaskType, getDocumentTaskStatus: number)
  : GetDocumentTaskSuccessType => ({
  type: GET_DOCUMENT_TASK_SUCCESS,
  payload: {
    documentTask,
    getDocumentTaskStatus,
  }
});

export type GetDocumentTaskFailureType = {
  type: typeof GET_DOCUMENT_TASK_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentTaskFailure = (error?: any): GetDocumentTaskFailureType => ({
  type: GET_DOCUMENT_TASK_FAILURE,
  payload: {
    error
  }
});

export const fetchDocumentTask = (uuid?: string):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  TaskActionsTypes
> => async (dispatch) => {
  try {
    const response = await TasksAPI.getDocumentTask(uuid);
    dispatch(getDocumentTaskSuccess(response.data, response.status));
  } catch (error: any) {
    dispatch(getDocumentTaskFailure(error.message));
  }
};

//Логика получения статусной модели с актуальными статусами
export type GetStatusTaskSuccessType = {
  type: typeof GET_STATUS_TASK_SUCCESS;
  payload: {
    statusTask: TaskNotificationStatusType;
    statusTaskNumber: number;
  };
};

const getStatusTaskSuccess = (
  statusTask: TaskNotificationStatusType,
  statusTaskNumber: number
): GetStatusTaskSuccessType => ({
  type: GET_STATUS_TASK_SUCCESS,
  payload: {
    statusTask,
    statusTaskNumber,
  }
});

export type GetStatusTaskFailureType = {
  type: typeof GET_STATUS_TASK_FAILURE;
  payload: {
    statusTaskNumber: number;
    error?: any;
  };
};

export const getStatusTaskFailure = (statusTaskNumber: number, error?: any): GetStatusTaskFailureType => ({
  type: GET_STATUS_TASK_FAILURE,
  payload: {
    statusTaskNumber,
    error,
  }
});

export const getStatusTask = (uuid?: string): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  TaskActionsTypes
> => async (dispatch) => {
  try {
    const response = await TasksAPI.getTaskNotificationStatus(uuid);

    dispatch(getStatusTaskSuccess(response.data, response.status));
  } catch (error: any) {
    dispatch(getStatusTaskFailure(error.response.status, error.message));
  }
};

//Логика отправки данных Принятия решения по типовой задаче
export type SendTypicalTaskDecisionSuccessType = {
  type: typeof SEND_TYPICAL_TASK_DECISION_SUCCESS;
  payload: {
    decisionTypeStatus: number;
    decisionTypicalTask: TypicalTaskDecisionResponseType;
  };
};

const sendTypicalTaskDecisionSuccess = (
  decisionTypeStatus: number,
  decisionTypicalTask: TypicalTaskDecisionResponseType
): SendTypicalTaskDecisionSuccessType => ({
  type: SEND_TYPICAL_TASK_DECISION_SUCCESS,
  payload: {
    decisionTypeStatus,
    decisionTypicalTask
  }
});

export type SendTypicalTaskDecisionFailureType = {
  type: typeof SEND_TYPICAL_TASK_DECISION_FAILURE;
  payload: {
    decisionTypeStatus: number,
    error: any;
  };
};

export const sendTypicalTaskDecisionFailure = (
  decisionTypeStatus: number,
  error?: any
): SendTypicalTaskDecisionFailureType => ({
  type: SEND_TYPICAL_TASK_DECISION_FAILURE,
  payload: {
    decisionTypeStatus,
    error
  }
});

export const sendTypicalTaskDecision = (schema_version: number, body?: TypicalTaskDecisionType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.sendTaskDecision(schema_version, body);
    dispatch(sendTypicalTaskDecisionSuccess(response.status, response.data));
  } catch (error: any) {
    dispatch(sendTypicalTaskDecisionFailure(error.response.status, error.message));
  }
};

//Логика получения 'история действия' в типовой задаче
export type GetActionHistorySuccessType = {
  type: typeof GET_ACTION_HISTORY_SUCCESS;
  payload: {
    actionHistory: ActionHistoryType;
    actionHistoryStatus: number;
  };
};

const getActionHistorySuccess = (
  actionHistory: ActionHistoryType,
  actionHistoryStatus: number,
): GetActionHistorySuccessType => ({
  type: GET_ACTION_HISTORY_SUCCESS,
  payload: {
    actionHistory,
    actionHistoryStatus,
  }
});

export type GetActionHistoryFailureType = {
  type: typeof GET_ACTION_HISTORY_FAILURE;
  payload: {
    error: any;
    actionHistoryStatus: number;
  };
};

export const getActionHistoryFailure = (error?: any, actionHistoryStatus: number = 0): GetActionHistoryFailureType => ({
  type: GET_ACTION_HISTORY_FAILURE,
  payload: {
    error,
    actionHistoryStatus,
  }
});

export const getActionHistoryListing = (params: ActionHistoryParamsType | null = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.getActionHistory(params);

    dispatch(getActionHistorySuccess(response.data, response.status));
  } catch (error: any) {
    dispatch(getActionHistoryFailure(error.message, error.response.status));
  }
};

//Логика получения списка "Родительской задачи" в модальном окне раздела Клиента
export type GetClientTasksListingSuccessType = {
  type: typeof GET_CLIENT_TASK_LISTING_SUCCESS;
  payload: {
    clientTaskListing: TasksListingType;
  };
};

const getClientTaskListingSuccess = (clientTaskListing: TasksListingType): GetClientTasksListingSuccessType => ({
  type: GET_CLIENT_TASK_LISTING_SUCCESS,
  payload: {
    clientTaskListing
  }
});

export type GetClientTaskListingFailureType = {
  type: typeof GET_CLIENT_TASK_LISTING_FAILURE;
  payload: {
    error: any;
  };
};

const getClientTaskListingFailure = (error: any): GetClientTaskListingFailureType => ({
  type: GET_CLIENT_TASK_LISTING_FAILURE,
  payload: {
    error
  }
});

export const getClientTasksData = (params: null | FilterTasksType = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.getTasks(params);

    dispatch(getClientTaskListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getClientTaskListingFailure(error.message));
  }
};

//Логика получения документа из 1С при обработке задачи Автоматически
export type GetProcessAutomaticallyFileSuccessType = {
  type: typeof GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS;
  payload: {
    file1CDownloadStatus: number,
  };
};

export const getProcessAutomaticallyFileSuccess = (
  file1CDownloadStatus: number,
): GetProcessAutomaticallyFileSuccessType => ({
  type: GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS,
  payload: {
    file1CDownloadStatus,
  }
});

export type GetProcessAutomaticallyFileFailureType = {
  type: typeof GET_PROCESS_FILE_AUTOMATICALLY_FAILURE;
  payload: {
    file1CDownloadStatus: number,
    error: any;
  };
};

export const getProcessAutomaticallyFileFailure = (
  file1CDownloadStatus: number,
  error?: any
): GetProcessAutomaticallyFileFailureType => ({
  type: GET_PROCESS_FILE_AUTOMATICALLY_FAILURE,
  payload: {
    file1CDownloadStatus,
    error
  }
});

export const getProcessTaskAutomaticallyFile = (task_uuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.getProcessTaskAutomaticallyFileData(task_uuid);

    dispatch(getProcessAutomaticallyFileSuccess(response.status));
  } catch (error: any) {
    dispatch(getProcessAutomaticallyFileFailure(error.response.status, error.message));
  }
};

//Логика получения документа из 1С при обработке задачи Автоматически для Разметки операции
export type GetOperationMarkupFilesSuccessType = {
  type: typeof GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS;
  payload: {
    parsingResult: MarkupTask1CDataType;
    parsingResultStatus: number;
  };
};

export const getOperationMarkupFilesSuccess = (
  parsingResult: MarkupTask1CDataType,
  parsingResultStatus: number
): GetOperationMarkupFilesSuccessType => ({
  type: GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS,
  payload: {
    parsingResult,
    parsingResultStatus
  }
});

export type GetOperationMarkupFilesFailureType = {
  type: typeof GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE;
  payload: {
    error: any;
    parsingResultStatus: number;
  };
};

export const getOperationMarkupFilesFailure = (
  parsingResultStatus: number,
  error?: any,
): GetOperationMarkupFilesFailureType => ({
  type: GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE,
  payload: {
    parsingResultStatus,
    error
  }
});

export const getOperationMarkupFiles = (task_uuid: string | undefined):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.fetchMarkupTaskFiles(task_uuid);

    dispatch(getOperationMarkupFilesSuccess(response.data, response.status));
  } catch (error: any) {
    dispatch(getOperationMarkupFilesFailure(error.response.status, error.message));
  }
};

//логика получения uuid задачи из 1С
export type GetTaskUuid1CSuccessType = {
  type: typeof GET_UUID_TASK_1C_SUCCESS;
  payload: {
    taskFile1CUuidStatus: number,
    taskFile1CUuid: Uuid1CType,
  };
};

export const getTaskUuid1CSuccess = (
  taskFile1CUuidStatus: number,
  taskFile1CUuid: Uuid1CType
): GetTaskUuid1CSuccessType => ({
  type: GET_UUID_TASK_1C_SUCCESS,
  payload: {
    taskFile1CUuidStatus,
    taskFile1CUuid
  }
});

export type GetTaskUuid1CFailureType = {
  type: typeof GET_UUID_TASK_1C_FAILURE;
  payload: {
    taskFile1CUuidStatus: number,
    error: any;
  };
};

export const getTaskUuid1CFailure = (
  taskFile1CUuidStatus: number,
  error?: any
): GetTaskUuid1CFailureType => ({
  type: GET_UUID_TASK_1C_FAILURE,
  payload: {
    taskFile1CUuidStatus,
    error
  }
});

export const getTaskUuid1C = (body: ParamsGetUuid1CType):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.getTaskUuid1CData(body);

    dispatch(getTaskUuid1CSuccess(response.status, response.data));
  } catch (error: any) {
    dispatch(getTaskUuid1CFailure(error.response.status, error.message));
  }
};

//логика получения файла из 1С после получения uuid
export type GetTaskFile1CSuccessType = {
  type: typeof GET_TASK_FILE_1C_SUCCESS;
  payload: {
    taskFile1CStatus: number,
    taskFile1CData: GetFile1CType,
  };
};

export const getTaskFile1CSuccess = (
  taskFile1CStatus: number,
  taskFile1CData: GetFile1CType
): GetTaskFile1CSuccessType => ({
  type: GET_TASK_FILE_1C_SUCCESS,
  payload: {
    taskFile1CStatus,
    taskFile1CData
  }
});

export type GetTaskFile1CFailureType = {
  type: typeof GET_TASK_FILE_1C_FAILURE;
  payload: {
    taskFile1CStatus: number,
    error: any;
  };
};

export const getTaskFile1CFailure = (
  taskFile1CStatus: number,
  error?: any
): GetTaskFile1CFailureType => ({
  type: GET_TASK_FILE_1C_FAILURE,
  payload: {
    taskFile1CStatus,
    error
  }
});

export const getTaskFileC = (body: ParamsGetFile1CType):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.getTaskFile1C(body);

    dispatch(getTaskFile1CSuccess(response.status, response.data));
  } catch (error: any) {
    dispatch(getTaskFile1CFailure(error.response.status, error.message));
  }
};

//логика получения файла из 1С после получения uuid
export type GetTaskStepsInfoSuccessType = {
  type: typeof GET_TASK_STEPS_INFO_SUCCESS;
  payload: {
    stepsTechInfo: TaskExecutionWrapper,
  };
};

export const getTaskStepsInfoSuccess = (stepsTechInfo: TaskExecutionWrapper): GetTaskStepsInfoSuccessType => ({
  type: GET_TASK_STEPS_INFO_SUCCESS,
  payload: {
    stepsTechInfo,
  }
});

export type GetTaskStepsInfoFailureType = {
  type: typeof GET_TASK_STEPS_INFO_FAILURE;
  payload: {
    error: any;
  };
};

export const getTaskStepsInfoFailure = (error?: any): GetTaskStepsInfoFailureType => ({
  type: GET_TASK_STEPS_INFO_FAILURE,
  payload: {
    error
  }
});

export const getTaskStepsTechData = (executionUuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.getTaskStepsInfo(executionUuid);

    dispatch(getTaskStepsInfoSuccess(response.data));
  } catch (error: any) {
    dispatch(getTaskStepsInfoFailure(error.message));
  }
};

//логика перезапуска шага задачи
export type RepeatTaskStepSuccessType = {
  type: typeof REPEAT_TASK_STEP_SUCCESS;
  payload: {
    repeatStepStatus: number,
  };
};

export const repeatTaskStepSuccess = (repeatStepStatus: number): RepeatTaskStepSuccessType => ({
  type: REPEAT_TASK_STEP_SUCCESS,
  payload: {
    repeatStepStatus,
  }
});

export type RepeatTaskStepFailureType = {
  type: typeof REPEAT_TASK_STEP_FAILURE;
  payload: {
    status: number;
    error: any;
  };
};

export const repeatTaskStepFailure = (status: number = 0, error?: any): RepeatTaskStepFailureType => ({
  type: REPEAT_TASK_STEP_FAILURE,
  payload: {
    status,
    error,
  }
});

export const repeatTaskExecutionStep = (body: RepeatStepDataType):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    TaskActionsTypes
  > => async (dispatch) => {
  try {
    const response = await TasksAPI.repeatExecutionStep(body);

    dispatch(repeatTaskStepSuccess(response.status));
  } catch (error: any) {
    dispatch(repeatTaskStepFailure(error.response.status, error.message));
  }
};
