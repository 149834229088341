import axios from "../../utils/axios";
import { AlphaListParamsType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_TASKS_URL;

//ручка получения листинга контрагентов
export const getContracts = async (params?: AlphaListParamsType | null): Promise<any> => {
  return axios.GET(API_BASE, "api/mdm/documents/v1/d/contract-schema", params);
};

//ручка получения контрагента
export const getContract = async (contractUuid?: string | null): Promise<any> => {
  return axios.GET(API_BASE, `api/mdm/documents/v1/d/contract-schema/uuid/${contractUuid}`);
};
