interface ItemsProps {
  value: string,
  label: string,
}

export const itemsTypeClient:ItemsProps[] = [
  {
    value: "Юридическое лицо",
    label: "Юридическое лицо",
  },
  {
    value: "Индивидуальный предприниматель",
    label: "Индивидуальный предприниматель",
  },
];

export const itemsTypeOPF:ItemsProps[] = [
  {
    value: "ИП",
    label: "ИП",
  },
  {
    value: "ООО",
    label: "ООО",
  },
];

export const itemsTaxSystem:ItemsProps[] = [
  {
    value: "УСН Доходы",
    label: "УСН Доходы",
  },
  {
    value: "УСН Доходы минус расходы",
    label: "УСН Доходы минус расходы",
  },
  {
    value: "ОСНО",
    label: "ОСНО",
  },
];

export const itemsPeriod: ItemsProps[] = [
  {
    value: "6 месяцев",
    label: "6 месяцев",
  },
  {
    value: "12 месяцев",
    label: "12 месяцев",
  },
];

export const itemsStatus: ItemsProps[] = [
  {
    value: "Активный",
    label: "Активный",
  },
  {
    value: "Не активный",
    label: "Не активный",
  },
];
