import React, { FC, JSX } from "react";
import { ReactComponent as HistoryZero } from "../../../../../assets/icons/zero_history_v2.svg";
import css from "./ZeroHistory.module.css";

interface IZeroHistoryProps {
  heightHistory: number;
  isApplication?: boolean;
}

const ZeroHistory: FC<IZeroHistoryProps> = ({ heightHistory, isApplication }):JSX.Element => {
  const zeroHistoryHeight: { height: string } = {height: `${heightHistory - 12}px`};
  const taskOrApplication: string = isApplication ? "заявке" : "задаче";

  return (
    <div
      className={css.container}
      style={zeroHistoryHeight}
    >
      <div className={css.label}>
        <div className={css.icon}><HistoryZero/></div>
        <div>
          <div className={css.textHeader}>История пуста</div>
          <div className={css.textBody}>
            Здесь будет отображена история действий <br/> по {taskOrApplication}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZeroHistory;
