import React, {
  Dispatch,
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import css from "./CheckOneCEmployees.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { Input, Table } from "antd";
import {
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppDispatch } from "../../../../../store/store";
import { AbstractDocType, DocumentClientProfileType } from "app/types";
import { ColumnsType } from "antd/es/table";
import { PaginationCustom } from "../../../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search_icon.svg";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { getDocumentFailure } from "../../../../../actions/document.actions";
import {
  getDocumentEmployeeProfileFailure,
  getSchemaEmployeeProfileFailure
} from "../../../../../actions/employeeProfile.actions";
import ZeroSearch from "../../../../ClientsList/ZeroSearch/ZeroSearch";
import ZeroEmployees from "../../../../ClientsList/ClientCard/ClientCardItem/Employees/ZeroEmployees/ZeroEmployees";

enum ListingPage {
  firstPage = 1,
}

interface ICheckOneCEmployeesProps {
  setEmployeeCard: Dispatch<React.SetStateAction<boolean>>;
}

const CheckOneCEmployees: FC<ICheckOneCEmployeesProps> = ({
  setEmployeeCard
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const location: string = useLocation()?.pathname;

  const [clientEmployeeField, setClientEmployeeField] = useState<AbstractDocType[] | undefined>([]);
  const [sortDirection, setSortDirection] = useState<"asc" | "des">("asc");
  const [filteredEmployeeField, setFilteredEmployeeField] = useState<AbstractDocType[] | undefined>([]);

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  useEffect(() => {
    if (documentClientProfile?.fields?.employees_profiles?.length) {
      setClientEmployeeField(documentClientProfile?.fields?.employees_profiles);
    } else {
      setClientEmployeeField(documentClientProfile?.fields?.employees);
    }

    dispatch(getDocumentFailure());
    dispatch(getSchemaEmployeeProfileFailure());
    dispatch(getDocumentEmployeeProfileFailure());
  }, [documentClientProfile]);

  const handleSorterEmployee = async (e: React.MouseEvent<HTMLElement>, text: string): Promise<void | null> => {
    e.preventDefault();

    if (!clientEmployeeField) {
      return null;
    }

    const direction: "asc" | "des" = sortDirection === "asc" ? "des" : "asc";

    const sortedEmployees: AbstractDocType[] = [...clientEmployeeField].sort((
      a: AbstractDocType,
      b: AbstractDocType
    ): number => {
      let result;

      switch (text) {
        case "name":
          result = a.full_name?.localeCompare(b.full_name ?? "") ?? 0;
          break;
        default:
          result = 0;
      }

      return direction === "asc" ? result : -result;
    });

    setSortDirection(direction);
    setClientEmployeeField(sortedEmployees);
  };

  const [inputValue, setInputValue] = useState<string>("");
  const [searchFailure, setSearchFailure] = useState<boolean>(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (inputValue === "") {
      setFilteredEmployeeField(clientEmployeeField);
      setSearchFailure(false);
    } else {

      timeout = setTimeout((): void => {
        const filtered: AbstractDocType[] | undefined = clientEmployeeField?.filter((employee: AbstractDocType) =>
          employee?.full_name?.toLowerCase().includes(inputValue.toLowerCase())
        );

        if (filtered?.length) {
          setFilteredEmployeeField(filtered);
          setSearchFailure(false);
        } else {
          setFilteredEmployeeField([]);
          setSearchFailure(true);
        }

      }, 2000);

    }
    return () => clearTimeout(timeout);

  }, [inputValue, clientEmployeeField]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);

    if (e.target.value) {
      setTimeout((): void => {
        setCurrentPage(ListingPage.firstPage);
      }, 2000);
    } else {
      setCurrentPage(ListingPage.firstPage);
    }
  };

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const navigate: NavigateFunction = useNavigate();

  const toCard = (e: React.MouseEvent<HTMLElement>, employee: AbstractDocType): void => {
    const taskId: string = location?.split("/")[2];
    const clientProfileId: string = location?.split("/")[4];

    e.preventDefault();
    setEmployeeCard(true);
    navigate(`/task/${taskId}/questionnaire/${clientProfileId}/validation/employee=${employee?.uuid}`,
      {state: {employee}});
  };

  //шапка таблицы
  const columns: ColumnsType<AbstractDocType> = [
    {
      title: tableTitle("Сотрудник"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["full_name"],
      key: "employee",
      width: "85.5%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterEmployee(e, "name"),
      }),
      render: (_text: string, record: AbstractDocType) => {
        return (
          <div className={css.columnText}>
            {record?.full_name}
          </div>
        );
      },
    },
    {
      key: "error",
      width: "14.5%",
      className: css.tableHeader,
      render: (_text: string, record: AbstractDocType) => (
        <div className="flex justify-between items-center">
          <ButtonCustom
            type="link"
            text="К данным сотрудника"
            onClick={(e: React.MouseEvent<HTMLElement>) => toCard(e, record)}
          />
        </div>
      ),
    },
  ];

  // логика пагинации страницы
  const pageSizeOption: number[] = [5, 10, 20];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(pageSizeOption[1]);

  const startIndex: number = (currentPage - 1) * pageSize;
  const endIndex: number = startIndex + pageSize;

  const filterClientEmployeeField: AbstractDocType[] = (filteredEmployeeField && !!filteredEmployeeField.length)
    ? filteredEmployeeField.slice(startIndex, endIndex)
    : clientEmployeeField
      ? clientEmployeeField.slice(startIndex, endIndex)
      : [];

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const zeroEmployees = (): JSX.Element => (
    inputValue ? <ZeroSearch/> : <ZeroEmployees variant="clientProfile"/>
  );

  const emptyDescription = (): JSX.Element => {
    return zeroEmployees();
  };

  return (
    <div className={css.listing}>
      <Input
        className={css.input}
        suffix={<SearchIcon className={css.searchIcon}/>}
        placeholder="ФИО сотрудника"
        value={inputValue}
        onChange={handleInputChange}
      />
      <div>
        <Table
          className={css.table}
          columns={columns}
          dataSource={!searchFailure ? filterClientEmployeeField : []}
          pagination={false}
          locale={{emptyText: emptyDescription()}}
        />
        {clientEmployeeField?.length && !searchFailure ? (
          <PaginationCustom
            className={css.paginationShowTotal}
            total={clientEmployeeField?.length}
            showTotal={(total: number, range: number[]): string =>
              `${range[0] || "1"}-${range[1] || "1"} из ${total}`
            }
            defaultPageSize={10}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultCurrent={1}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        ) : (
          <div className={css.noEmployees}/>
        )}
      </div>
    </div>
  );
};

export default CheckOneCEmployees;
