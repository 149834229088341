import React, { FC, JSX } from "react";

interface IAppealsProps {}

const Appeals: FC<IAppealsProps> = (): JSX.Element => {

  return (
    <div className="mt-10">
      <h1 className="text-center text-xl animate-bounce">
        Appeals page
      </h1>
    </div>
  );
};

export default Appeals;
