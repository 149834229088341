import React, { FC, JSX } from "react";
import css from "./ZeroSearch.module.css";
import { ReactComponent as SearchZero } from "../../../assets/icons/search-employee.svg";

interface IZeroSearchProps {
  dataFilter?: boolean
}

const ZeroSearch: FC<IZeroSearchProps> = ({ dataFilter = false }): JSX.Element => {
  return (
    <div className={dataFilter ? css.filterContainer : css.container}>
      <div className={dataFilter ? css.filterLabel : css.label}>
        <div className={dataFilter ? css.filterIcon : css.icon}>
          <SearchZero style={{width: dataFilter ? "32px" : "", height: dataFilter ? "32px" : ""}}/>
        </div>
        <div>
          <div className={dataFilter ? css.filterHeader : css.textHeader}>Ничего не найдено</div>
          <div className={dataFilter ? css.filterBody : css.textBody}>
            Проверьте правильность параметров поиска или фильтров
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZeroSearch;
