import React, {
  FC,
  JSX,
  ChangeEvent,
} from "react";
import { TypicalTaskDecisionType, DocumentTaskType } from "app/types";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { Form, Input, FormInstance } from "antd";
import { reassignedUserRole } from "../../../../utils/reassignedUserRole";
import css from "../TaskCardActionDrawer.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";

interface IReassignTaskProps {
  form: FormInstance,
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType, isReassignTask?: boolean) => void;
  onDrawerClose: () => void;
  taskActionComment: string;
  handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  remainingCharsComment: number;
}

const isReassignTask: boolean = true;

const { TextArea } = Input;

const ReassignTask: FC<IReassignTaskProps> = ({
  form,
  taskUuid,
  onFinish,
  onDrawerClose,
  taskActionComment,
  handleTextAreaChange,
  remainingCharsComment,
}): JSX.Element => {
  const documentTask: DocumentTaskType | null =
    useSelector((state: AppStateType) => state.tasks.documentTask);
  
  const {
    responsible_reference,
    temporary_responsible_reference,
  } = documentTask?.fields ?? {};
  
  const responsibleReferenceRoles: string[] = !temporary_responsible_reference
    ? (responsible_reference?.[0]?.roles ?? [])
    : (temporary_responsible_reference?.[0]?.roles ?? []);

  const reassignTaskBody: TypicalTaskDecisionType = {
    task: taskUuid,
    task_decision_type: "reassign_task",
    comment: taskActionComment,
  };

  const onReassignTaskFinish = (): void => {
    onFinish(reassignTaskBody, isReassignTask);
  };

  return (
    <>
      <p className={css.actionDrawerDescr}>
        Вы можете переназначить это обращение
        {reassignedUserRole(responsibleReferenceRoles)}
        и по желанию оставить комментарий
      </p>
      <Form
        className="m-0"
        name="reassignTask"
        layout="vertical"
        form={form}
        onFinish={onReassignTaskFinish}
      >
        <Form.Item
          className="mb-4"
          name="reassignComment"
          label="Комментарий"
        >
          <TextArea
            id="reassignComment"
            rows={25}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваш комментарий"
            onChange={handleTextAreaChange}
          />
          <div className="text-xs font-normal leading-100 counter-gray-color text-right">
            {remainingCharsComment}/2000
          </div>
        </Form.Item>
        <Form.Item className="text-right">
          <ButtonCustom
            className="mr-2"
            size="large"
            type="default"
            ghost
            text="Отменить"
            onClick={onDrawerClose}
          />
          <ButtonCustom
            size="large"
            type="primary"
            text="Подтвердить"
            htmlType="submit"
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default ReassignTask;
