export const checkServiceStatus = (
  date: string | undefined
): "customError" | "customWarning" | "customSuccess" | "customDraft" | null => {
  if (date) {
    const expirationDate: Date = new Date(date);
    const today: Date = new Date();
    const daysUntilExpired: number = Math.floor((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

    if (daysUntilExpired < 30) {
      return "customError";
    } else if (daysUntilExpired === 30) {
      return "customDraft";
    } if (daysUntilExpired <= 60) {
      return "customWarning";
    } else {
      return "customSuccess";
    }
  }

  return null;
};
