import React, { FC, JSX, useEffect } from "react";
import css from "./ClientsListing.module.css";
import { Button, Form, FormInstance, Input, Table } from "antd";
import { FilterClientsType, FilterTasksType } from "app/types";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search_icon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter_icon.svg";
import ZeroSearch from "../ZeroSearch/ZeroSearch";
import ZeroClients from "../ZeroClients/ZeroClients";
import { setClientsListingParams } from "../../../actions/client.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { AppStateType } from "../../../reducers/mainReducer";
import { ClientsListingType, FormPropsType } from "../ClientsType/ClientsType";
import ClientListingTags from "./ClientListingItem/ClientListingTags";

interface IClientsListingProps {
  form: FormInstance;
  clientsListingProps: ClientsListingType;
}

const ClientsListing: FC<IClientsListingProps> = ({
  form,
  clientsListingProps
}): JSX.Element => {
  const values: FormPropsType = Form.useWatch([], form);
  const { clientsName } = values || {};

  const dispatch = useDispatch<AppDispatch>();

  const {
    paramsData,
    setParamsData,
    filterData,
    columns,
    isLoadingData,
    currentPage,
    pageSize,
    selectedClientStatus,
    FilterNames,
    handleInputChange,
    deleteResponsibleFilterData,
    deleteFilterData,
    showModal,
    clearFilterData,
    handlePageChange,
    handlePageSizeChange
  } = clientsListingProps;

  const {
    clientsData,
    totalDocs,
    clientsParams
  } = useSelector((state: AppStateType) => state.client);

  useEffect(() => {
    if (clientsName?.trim() === "") {
      setParamsData((prev: FilterClientsType | null) => {
        if (prev && prev.client_employees !== null) {
          const { client_employees, ...rest } = prev;
          dispatch(setClientsListingParams({ ...rest }));
          
          return { ...rest };
        } else {
          dispatch(setClientsListingParams(prev));
          
          return prev;
        }
      });
    }

    const timeout: NodeJS.Timeout = setTimeout((): void => {
      const hasInputValue: boolean = clientsName?.trim() !== ""
        && typeof clientsName === "string"
        && (!clientsParams?.client_employees || clientsParams?.client_employees !== clientsName);

      if (hasInputValue) {
        setParamsData((prev: FilterTasksType | null) => {
          const updatedParams = {
            ...prev,
            client_employees: clientsName?.trim()
          };
          dispatch(setClientsListingParams(updatedParams));
          
          return updatedParams;
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [clientsName]);

  const fullName: string = clientsParams?.client_employees ?? "";

  const zeroClients = (name: string): JSX.Element => {
    const hasFilter: boolean = !!name
      || (!!paramsData?.pub_customer_status && !!selectedClientStatus.length)
      || !!filterData;
    
    return (
      <>
        {!isLoadingData && hasFilter && <ZeroSearch />}
        {!isLoadingData && !hasFilter && <ZeroClients />}
      </>
    );
  };

  return (
    <>
      {filterData && Object.values(filterData).find(value => value) ? (
        <div className={`${css.blockInputData}`}>
          <div className="flex mb-3">
            <Form.Item name="clientsName" noStyle>
              <Input
                id="clientsName"
                className={css.inputData}
                allowClear
                suffix={<SearchIcon className={css.searchIcon} />}
                placeholder="Найти по наименованию клиента"
                onChange={handleInputChange}
              />
            </Form.Item>
            <Button
              className={css.button}
              onClick={showModal}
            >
              <FilterIcon className="pr-1" />
              Фильтры
            </Button>
          </div>
          <ClientListingTags
            filterData={filterData}
            deleteResponsibleFilterData={deleteResponsibleFilterData}
            deleteFilterData={deleteFilterData}
            FilterNames={FilterNames}
            clearFilterData={clearFilterData}
          />
        </div>
      ) : (
        <div className={`${css.blockInput} mb-3`}>
          <Form.Item name="clientsName" noStyle>
            <Input
              id="clientsName"
              className={css.input}
              allowClear
              suffix={<SearchIcon className={css.searchIcon} />}
              placeholder="Найти по наименованию клиента"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Button
            className={css.button}
            onClick={showModal}
          >
            <FilterIcon className="pr-1" />
            Фильтры
          </Button>
        </div>
      )}
      <div>
        <Table
          columns={columns}
          loading={isLoadingData}
          dataSource={clientsData?.length ? clientsData : []}
          pagination={false}
          locale={{ emptyText: zeroClients(fullName) }}
        />
        {clientsData?.length ? (
          <PaginationCustom
            total={totalDocs ?? 0}
            defaultPageSize={pageSize}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultCurrent={currentPage}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
          ) : (
            <div className={css.noClients} />
          )
        }
      </div>
    </>
  );
};

export default ClientsListing;
