import React, { FC, JSX } from "react";
import css from "./StubMenu.module.css";
import { ButtonCustom } from "../../../../components/ui-kit/ButtonCustom/ButtonCustom";
import logoPicture from "../../../../assets/icons/BK_logo.png";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface IStubMenuProps {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToAnchor: (id: string) => void;
}

const StubMenu: FC<IStubMenuProps> = ({
  isActive,
  setIsActive,
  scrollToAnchor
}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  
  const toggleMenu = (): void => {
    setIsActive(!isActive);
  };
  
  const renderMenuItem = (text: string, label: string): JSX.Element => (
    <li>
      <span
        className={css.textMenu}
        onClick={(): void => scrollToAnchor(text)}
      >
        {label}
      </span>
    </li>
  );

  const navigateToLogin = (): void => {
    navigate("/login");
  };
  
  const renderMenu = (): JSX.Element => (
    <>
      <label className={isActive ? "mb-6" : ""}>
        <img
          src={logoPicture}
          className={css.logoStyle}
          alt="Company"
        />
      </label>
      <ul className={`flex gap-[30px] ${isActive ? "flex-col items-center mb-6" : ""}`}>
        {renderMenuItem("home", "Главная")}
        {renderMenuItem("functions", "Функции")}
        {renderMenuItem("documents", "Документы")}
        {renderMenuItem("price", "Стоимость")}
        {renderMenuItem("questions", "Вопросы")}
      </ul>
      <div className={`flex gap-3 ${isActive ? "mb-6" : ""}`}>
        <div
          className={`${css.commonButtonStyles} ${css.buttonEnterStyle}`}
          onClick={navigateToLogin}
        >
          Вход
        </div>
        <div
          onClick={(): void => scrollToAnchor("questions")}
          className={`${css.commonButtonStyles} ${css.buttonRegistrationStyle}`}
        >
          Регистрация
        </div>
      </div>
    </>
  );
  
  return (
    <>
      <div className={css.menu}>
        {renderMenu()}
      </div>
      <div className={css.menuBurger}>
        <ButtonCustom
          type="text"
          text=""
          className={`${css.menuBurgerButton} ${css.commonBurgerButtonStyles}`}
          onClick={toggleMenu}
        >
          <div className={`${css.menuBurgerButtonStyle} ${css.commonBurgerButtonStyles} ${isActive ? css.active : ""}`}>
            <span className={`${css.burgerLine} ${isActive ? css.topLine : ""}`} />
            <span className={`${css.burgerLine} ${isActive ? css.middleLine : ""}`} />
            <span className={`${css.burgerLine} ${isActive ? css.bottomLine : ""}`} />
          </div>
        </ButtonCustom>
      </div>
      {isActive && (
        <div className="flex flex-col justify-center items-center">
          {renderMenu()}
        </div>
      )}
    </>
  );
};

export default StubMenu;