import React, { FC, JSX } from "react";
import css from "./NotificationsCounter.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import { NumberOfNotificationsType } from "app/types";

interface INotificationCounterProps {
  collapsed: boolean;
}

const NotificationsCounter: FC<INotificationCounterProps> = (): JSX.Element | null => {
  //получение фасетов
  const notificationsNumber: NumberOfNotificationsType | null =
    useSelector((state: AppStateType) => state.notifications?.notificationsNumber);

  const unreadNotifications = (data: NumberOfNotificationsType | null): number => {
    if (!data) return 0;

    return data?.all.notifications_amount;
  };

   if (unreadNotifications(notificationsNumber)) {
     return (
       <div className={css.icon}>
         {unreadNotifications(notificationsNumber) > 99 ? (
           <>
             <div>
               99
             </div>
             <div className="pb-0.5">
               +
             </div>
           </>
         ) : (
           <div>
             {unreadNotifications(notificationsNumber)}
           </div>
         )}
       </div>
       );
   }
   else {
     return null;
   }
};

export default NotificationsCounter;
