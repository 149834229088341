import React, {
  ChangeEvent,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  DatePicker,
  Form,
  Modal,
  Select,
  Upload,
  Input,
  UploadProps,
  message,
  Empty,
  UploadFile,
  Tooltip,
  Skeleton
} from "antd";
import css from "./ModelContent.module.css";
import { IContactingToClientProps, topicOfAppeal, ITaskTypeProps, taskType } from "../contactingToClient";
import { Dayjs } from "dayjs";
import { ReactComponent as AttachFileIcon } from "../../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../../../assets/icons/delete_file.svg";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { UploadChangeParam } from "antd/es/upload";
import { deleteUploadFile, uploadFiles } from "../../../../../../api/document.api";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { TasksListingDataType, TypicalRequestToClientType } from "app/types";
import { getClientTasksData } from "../../../../../../actions/tasks.actions";
import { AppDispatch } from "../../../../../../store/store";
import {
  sendPaidConsultationsRequestToClient,
  sendTariffIncreaseRequestToClient,
  sendTerminateServiceRequestToClient,
  sendTypicalRequestToClient,
  setPopoverTariffType
} from "../../../../../../actions/client.actions";
import * as jose from "jose";
import { getConsultationsNumber } from "../../../../../../utils/clientConsultations";
import ZeroSearch from "../../../../ZeroSearch/ZeroSearch";
import { roleResolver } from "../../../../../../utils/roleResolver";
import { validFileFormat } from "../../../../../../utils/ModalUploadItems/validFileFormat";
import TariffIncrease from "./PaidServicesItem/TariffIncrease/TariffIncrease";
import TerminateService from "./PaidServicesItem/TerminateService/TerminateService";
import DraggerForm from "../../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import ModalDraggable from "../../../../../../utils/ModalDraggable/ModalDraggable";
import { handleOut, handleOver } from "../../../../../../utils/ModalDraggable/DraggableSwitch";

interface IModalContentProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

interface IUploadedFilesProps {
  file_name: string;
  uuid: string;
}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

enum TopicKey {
  Lower200 = 200,
  Upper200 = 199,
  Lower300 = 300,
  Upper300 = 399
}

const ModalContent: FC<IModalContentProps> = ({
  isModalOpen,
  setIsModalOpen
}): JSX.Element => {

  //Логика модального окна
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const values = Form.useWatch([], form);

  const dispatch = useDispatch<AppDispatch>();

  const [selectedType, setSelectedType] = useState<string>("");

  const [selectedTask, setSelectedTask] = useState<TasksListingDataType | null>(null);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [selectedActionCode, setSelectedActionCode] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [comment, setComment] = useState<string>("");
  const [remainingChars, setRemainingChars] = useState<number>(0);
  const [uploadedFilesData, setUploadedFilesData] = useState<IUploadedFilesProps[] | null>(null);
  const [fileCount, setFileCount] = useState<number>(0);
  const [uploadFilesLoader, setUploadFilesLoader] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const { documentClient, clientTariffData } = useSelector((state: AppStateType) => state.client);

  //TODO получение данных родительской задачи, код временно закомментирован
  // useEffect(() => {
  //   dispatch(getClientTasksData({"client.uuid": documentClient?.fields?.uuid}));
  // }, [documentClient]);

  const clientTaskListing: TasksListingDataType[] | null =
    useSelector((state: AppStateType) => state.tasks.clientTaskListing);

  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const maxSizeFile: number = 50;

  const hideDeadline: boolean = selectedActionCode !== null && selectedActionCode > TopicKey.Lower200;

  // Временно удаляем обязательность наличия родительской задачи !!selectedTask
  const allFieldsFilled: boolean = !!selectedAction && !!comment;

  const isPaidServices: boolean = selectedType === "Платные услуги";
  const isPaidConsultation: boolean = isPaidServices && selectedAction === "Списание платной консультации";
  const isTariffIncrease: boolean = isPaidServices && selectedAction === "Заявка на повышение тарифа";
  const isTerminateService: boolean = isPaidServices && selectedAction === "Заявка на прекращение обслуживания";

  const modalOk = (): void => {
    const clientData: string | undefined = documentClient?.fields?.uuid;
    const selectedAction: number | null = selectedActionCode ? selectedActionCode : null;
    const parentTask: string | null = selectedTask ? selectedTask?.fields?.uuid : null;
    const dateUntil: string | null | undefined = !isPaidConsultation ? selectedDate?.toISOString() : null;
    const finalDate: string | null | undefined = hideDeadline ? null : dateUntil;
    const taskContent: string | null = !isPaidConsultation ? (comment && comment) : "";
    const terminateReason: string | null = values?.terminateReason ? values?.terminateReason : null;
    const textTerminate: string = values?.textTerminate ?? "";
    const textTariff: string = values?.textTariff ?? "";
    const paidConsultation: number = values?.paidConsultation ?? 0;
    const accountingRestoration: number = values?.accountingRestoration ?? 0;
    const addAgencyAgreements: number = values?.addAgencyAgreements ?? 0;
    const addingEmployees: number = values?.addingEmployees ?? 0;
    const uuids: string[] =
      isPaidConsultation ? [] : uploadedFilesData?.map((file: IUploadedFilesProps) => file.uuid) ?? [];
    const authorTask: string | null = decodedToken?.sub ? decodedToken?.sub : null;

    if (isPaidConsultation) {
      const paidConsultationRequest: TypicalRequestToClientType = {
        client: clientData,
        dynamic_tariff_key: "number_of_consultations",
        category_code: 403,
        parent_task_id: parentTask,
        deadline: finalDate,
        task_content: taskContent ? taskContent : null,
        files: uuids,
      };

      dispatch(sendPaidConsultationsRequestToClient(paidConsultationRequest));
      defaultModalState();
      return;
    }

    if (isTerminateService) {
      const terminateServiceRequest: TypicalRequestToClientType = {
        client: clientData,
        category_code: selectedAction,
        task_reason: terminateReason,
        task_content: textTerminate,
        task_author: authorTask,
        files: [],
      };

      dispatch(sendTerminateServiceRequestToClient(terminateServiceRequest));
      defaultModalState();
      return;
    }

    if (isTariffIncrease) {
      const tariffIncreaseRequest: TypicalRequestToClientType = {
        client: clientData,
        category_code: selectedAction,
        task_content: textTariff,
        task_author: authorTask,
        additional_number_of_employees: addingEmployees,
        additional_number_of_consultations: paidConsultation,
        additional_agency_agreements_qty: addAgencyAgreements,
        additional_accounting_restore_months: accountingRestoration,
        files: [],
      };

      dispatch(sendTariffIncreaseRequestToClient(tariffIncreaseRequest));
      defaultModalState();
      return;
    }

    const clientRequest: TypicalRequestToClientType = {
      client: clientData,
      category_code: selectedAction,
      parent_task_id: parentTask,
      deadline: finalDate,
      task_content: taskContent,
      files: uuids,
    };

    dispatch(sendTypicalRequestToClient(clientRequest));
    defaultModalState();
  };

  const defaultModalState = (): void => {
    setIsModalOpen(false);
    dispatch(setPopoverTariffType(false));
    setSelectedType("");
    setSelectedAction("");
    setSelectedActionCode(null);
    setSelectedTask(null);
    setComment("");
    setRemainingChars(0);
    setUploadedFilesData(null);
    setFileCount(0);
    setSelectedDate(null);
    form.resetFields();
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
    setSelectedType("");
    setSelectedAction("");
    setSelectedActionCode(null);
    setSelectedTask(null);
    setComment("");
    setRemainingChars(0);
    setSelectedDate(null);
    dispatch(setPopoverTariffType(false));
    form.resetFields();

    if (uploadedFilesData) {
      deleteAllUploadFiles(uploadedFilesData);
      setUploadedFilesData(null);
      setFileCount(0);
    }
  };

  const deleteAllUploadFiles = async (uploadedFilesData: IUploadedFilesProps[] | null): Promise<void | null> => {
    if (uploadedFilesData) {
      const uuids: string[] = uploadedFilesData.map((file: IUploadedFilesProps) => file.uuid);
      const params: URLSearchParams = new URLSearchParams();
      uuids.forEach((uuid: string) => params.append("file_uuid", uuid));

      try {
        await deleteUploadFile(params.toString());
      } catch (error) {
        message.error("Файлы не удалены!");
      }
    }
    return null;
  };

  const modalHeader = (text: string): JSX.Element => {
    return (
      <div
        className={css.modalHeader}
        onMouseOver={() => handleOver(disabled, setDisabled)}
        onMouseOut={() => handleOut(setDisabled)}
      >
        {text}
      </div>
    );
  };

  const nonePaidConsultation: boolean = isPaidConsultation && !Number(getConsultationsNumber(clientTariffData));

  const checkFormValues = (values: { [key: string]: any } | undefined | null): boolean => {
    if (!values) return true;

    return !Object.values(values).some((value) => typeof value === "number" && value > 0);
  };

  const terminateReasonValues: string | undefined = form.getFieldValue("terminateReason");

  const renderDefaultButton = (isDisabled: boolean): JSX.Element => (
    <ButtonCustom
      id="buttonOk"
      key="ok"
      size="large"
      text="Подтвердить"
      type="primary"
      className={css.buttonOk}
      disabled={isDisabled}
      onClick={modalOk}
    />
  );
  
  const nonePaidConsultationRender: JSX.Element = (
    <Tooltip
      title="Платные консультации закончились"
      placement="top"
      trigger="hover"
      arrow={{pointAtCenter: true}}
    >
      <span>
        {renderDefaultButton(nonePaidConsultation || (!isPaidConsultation && !allFieldsFilled))}
      </span>
    </Tooltip>
  );

  const tariffIncreaseRender: JSX.Element = (
    checkFormValues(values) ? (
      <Tooltip
        title="Для формирования заявки необходимо внести изменения в состав опций"
        placement="top"
        trigger="hover"
        overlayStyle={{width: "263px", marginBottom: "10px"}}
        overlayInnerStyle={{textAlign: "center"}}
        arrow={{pointAtCenter: true}}
      >
        <span>
          {renderDefaultButton(checkFormValues(values))}
        </span>
      </Tooltip>
    ) : (
      renderDefaultButton(checkFormValues(values))
    )
  );

  const terminateServiceRender: JSX.Element = (
    !terminateReasonValues ? (
      <Tooltip
        title="Для формирования заявки необходимо выбрать причину"
        placement="top"
        trigger="hover"
        overlayStyle={{width: "263px", marginBottom: "10px"}}
        overlayInnerStyle={{textAlign: "center"}}
        arrow={{pointAtCenter: true}}
      >
        <span>
          {renderDefaultButton(!terminateReasonValues)}
        </span>
      </Tooltip>
    ) : (
      renderDefaultButton(!terminateReasonValues)
    )
  );

  const renderConfirmButton = (): JSX.Element => {
    if (isTariffIncrease) {
      return tariffIncreaseRender;
    }

    if (nonePaidConsultation) {
      return nonePaidConsultationRender;
    }

    if (isTerminateService) {
      return terminateServiceRender;
    }

    return renderDefaultButton(nonePaidConsultation || (!isPaidConsultation && !allFieldsFilled));
  };

  const modalFooter: JSX.Element = (
    <div className="mt-5">
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      {renderConfirmButton()}
    </div>
  );

  //Логика подгрузки файлов в модальном окне
  enum totalFiles {
    Total100 = 100,
  }

  enum FileListing {
    Empty = 0,
  }

  const uploadFile = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoader(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesData((prevData: IUploadedFilesProps[] | null) => {
        const safePrevData: IUploadedFilesProps[] = prevData || [];
        let newData: IUploadedFilesProps[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;

        response.data.forEach((file: IUploadedFilesProps): void => {
          if (!newData.some((existingFile: IUploadedFilesProps): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData.length > 100) {
          newData = newData.slice(0, 100);
          newFileCount = 100;
        }

        setFileCount(newFileCount);

        return newData;
      });

      if (response.status === 200) {
        setUploadFilesLoader(false);
      }

    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoader(false);
    }

  };

  const propsFile: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: (info: UploadChangeParam): boolean => {
      if (info.fileList.length > 100) {
        info.fileList.splice(100);
      }

      const allFilesUploading: boolean =
        info.fileList.every((file: UploadFile<unknown>): boolean => file.status === "uploading");

      if (allFilesUploading) {
        const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
          if (!file) return false;

          const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
          return size < maxSizeFile;
        });

        if (filterFile.length < info.fileList.length) {
          message.error("Объем некоторых файлов или файла не должен превышать 50мб.");
        }

        const files: File[] = filterFile.map((file: UploadFile<unknown>) => file.originFileObj!) as File[];

        if (files.length > 0) {
          uploadFile(files);
        }

      }
      info.fileList.length = 0;
      return false;
    },
    showUploadList: false,
  };

  const handleRemove = async (e: React.MouseEvent<HTMLElement>, fileRemove: IUploadedFilesProps,): Promise<void> => {
    e.preventDefault();

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesData((prevFiles: IUploadedFilesProps[] | null): IUploadedFilesProps[] | null => {
        if (prevFiles) {
          const newFiles: IUploadedFilesProps[] =
            prevFiles.filter((file: IUploadedFilesProps): boolean => file.uuid !== fileRemove.uuid);
          setFileCount(newFiles.length);
          return newFiles.length > 0 ? newFiles : null;
        }
        setFileCount(0);
        return [];
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const counterUpload = (fileCountClarification: number): boolean =>
    fileCountClarification === totalFiles.Total100 || fileCountClarification > totalFiles.Total100;

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    setRemainingChars(charsCount);
    setComment(value);
  };

  const showUpload: boolean = fileCount >= totalFiles.Total100;

  const reformatDate = (dateString: string): string => {
    const dateObj: Date = new Date(dateString);

    return dateObj.toLocaleDateString("ru-RU", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    });
  };

  const handleSelectChange = (value: string): void | null => {
    if (clientTaskListing) {
      const selectTask: TasksListingDataType[] =
        clientTaskListing?.filter((task: TasksListingDataType): boolean => task.id === value);
      setSelectedTask(selectTask[0]);
    }

    return null;
  };

  const clearImplementer = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setSelectedTask(null);
    form.resetFields(["parentTask"]);
  };

  const emptySelectTask = (): JSX.Element => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Такой задачи нет"
      />
    );
  };

  const taskName = (text: string): JSX.Element => {
    if (text.length <= 46) {
      return <span className={css.parentTask}>{text}</span>;
    } else if (text.length <= 92) {
      return (
        <>
          <span className={`${css.parentTask} mb-1`}>{text.slice(0, 46)}</span>
          <span className={css.parentTask}>{text.slice(46, 92)}</span>
        </>
      );
    } else {
      return (
        <>
          <span className={`${css.parentTask} mb-1`}>{text.slice(0, 46)}</span>
          <span className={css.parentTask}>{text.slice(46, 89)}...</span>
        </>
      );
    }
  };

  const handleSelectTopic = (
    value: string, option: IContactingToClientProps | IContactingToClientProps[] | undefined
  ): void => {
    if (value) {
      setSelectedAction(value);
      if (!Array.isArray(option) && option?.key) {
        setSelectedActionCode(option.key);
        if (option.key > TopicKey.Lower200) {
          setSelectedDate(null);
          form.resetFields(["responseDate"]);
        }
      }
    } else {
      setSelectedAction("");
      setSelectedActionCode(null);
    }
  };

  const isAccountant: boolean = roleResolver(decodedToken).isAccountant;
  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;
  const isTechSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const hasAccess: boolean = isAccountant || isAdmin || isTechSupport;

  const filteredType: ITaskTypeProps[] =
    taskType?.filter(({ value }: { value: string }): boolean => value !== "Платные услуги");

  const filteredTopic = (selectedType: string): IContactingToClientProps[] => {
    //TODO: Временное решение, потом выпилить строгую проверку на 112 код
    const filterTopicHundredth: IContactingToClientProps[] =
      topicOfAppeal?.filter(({ key }: { key: number }): boolean =>
        key < TopicKey.Lower200 && key !== 112);

    //TODO: Временное решение, потом выпилить строгую проверку на 112 код
    const filterTopicTwoHundredth: IContactingToClientProps[] =
      topicOfAppeal?.filter(({ key }: { key: number }): boolean =>
        key > TopicKey.Upper200 && key < TopicKey.Lower300 || key === 112);

    const filterTopicPaidServices: IContactingToClientProps[] =
      topicOfAppeal?.filter(({ key }: { key: number }): boolean => key > TopicKey.Upper300);

    switch (selectedType) {
      case "Запрос информации у клиента":
        return filterTopicHundredth;
      case "Предоставление информации клиенту":
        return filterTopicTwoHundredth;
      case "Платные услуги":
        return filterTopicPaidServices;
      default:
        return [];
    }
  };

  const uploadFilesList: JSX.Element[] | null = uploadedFilesData?.length ? (
    uploadedFilesData.map((file: IUploadedFilesProps, index: number) => (
      <div key={index} className={css.documentsUpload}>
        <div className="flex items-center pl-[2px]">
          <AttachFileIcon className="flex-shrink-0"/>
          <a className={css.fileName}>
            {file.file_name}
          </a>
        </div>
        <div
          className={css.deleteIcon}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleRemove(e, file)}
        >
          <DeleteDocument className="flex-shrink-0"/>
        </div>
      </div>
    ))) : null;

  const modalContent = (selectedType: string): JSX.Element | null => {
    switch (selectedType) {
      case "Запрос информации у клиента":
      case "Предоставление информации клиенту":
        return (
          <>
            <Form.Item
              name="topic"
              className="mb-[20px]"
              validateStatus={!selectedAction ? "error" : ""}
            >
              <Select
                id="topic"
                placeholder="Выберите тему задачи"
                options={filteredTopic(selectedType)}
                listHeight={196}
                showSearch
                allowClear
                size="large"
                className={`${css.modalDrop}`}
                onChange={handleSelectTopic}
                notFoundContent={<ZeroSearch dataFilter/>}
              />
            </Form.Item>
            {/*Временно скрываем родительскую задачу*/}
            {/*{selectedTask ? (*/}
            {/*  <>*/}
            {/*    <div>*/}
            {/*      <div className="text-base font-medium leading-5 text-black-800 mb-3">*/}
            {/*        Родительская задача*/}
            {/*      </div>*/}
            {/*      <div className="flex justify-between items-center">*/}
            {/*        <li className="flex flex-col">*/}
            {/*          <span className="inline-block gray-color text-[14px]">*/}
            {/*            №{selectedTask?.fields?.number}{" "}от{" "}{reformatDate(selectedTask?.created_at)}*/}
            {/*          </span>*/}
            {/*          <span className={css.parentTask}>*/}
            {/*            {selectedTask?.fields?.task_type?.[0]?.label}*/}
            {/*          </span>*/}
            {/*        </li>*/}
            {/*        <div className="flex">*/}
            {/*          <ButtonCustom*/}
            {/*            className={`${css.buttonSelect} mr-2`}*/}
            {/*            type="primary"*/}
            {/*            ghost*/}
            {/*            text="К задаче"*/}
            {/*            onClick={(e: React.MouseEvent<HTMLElement>): void => {*/}
            {/*              e.preventDefault();*/}
            {/*              window.open(`/task/${selectedTask?.fields?.uuid}`, "_blank");*/}
            {/*            }}*/}
            {/*          />*/}
            {/*          <ButtonCustom*/}
            {/*            className={css.buttonClose}*/}
            {/*            type="primary"*/}
            {/*            ghost*/}
            {/*            text=""*/}
            {/*            onClick={(e: React.MouseEvent<HTMLElement>) => clearImplementer(e)}*/}
            {/*          >*/}
            {/*            <CloseImplementer className="flex-shrink-0"/>*/}
            {/*          </ButtonCustom>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  <Form.Item*/}
            {/*    name="parentTask"*/}
            {/*    className="mb-0"*/}
            {/*    validateStatus={!selectedTask ? "error" : ""}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      id="parentTask"*/}
            {/*      placeholder="Родительская задача"*/}
            {/*      listHeight={270}*/}
            {/*      showSearch*/}
            {/*      allowClear*/}
            {/*      size="large"*/}
            {/*      className={css.modalDrop}*/}
            {/*      filterOption={(input: string, option): boolean => {*/}
            {/*        const taskNumber: string = option?.key;*/}
            {/*        const taskType: string = option?.props.name;*/}
            {/*        return (*/}
            {/*          (taskNumber.indexOf(input) !== -1) ||*/}
            {/*          (taskType.toLowerCase().indexOf(input.toLowerCase()) !== -1)*/}
            {/*        );*/}
            {/*      }}*/}
            {/*      onSelect={handleSelectChange}*/}
            {/*      notFoundContent={emptySelectTask()}*/}
            {/*    >*/}
            {/*      {clientTaskListing?.map((task: TasksListingDataType) => (*/}
            {/*        <Option*/}
            {/*          key={task?.fields?.number}*/}
            {/*          id={task?.fields?.uuid}*/}
            {/*          name={task?.fields?.task_type?.[0]?.label}*/}
            {/*          value={task?.id}*/}
            {/*        >*/}
            {/*          <div className="flex justify-between items-center">*/}
            {/*            <li className="flex flex-col">*/}
            {/*              <span className="inline-block gray-color text-[14px]">*/}
            {/*                №{task?.fields?.number}{" "}от{" "}{reformatDate(task?.created_at)}*/}
            {/*              </span>*/}
            {/*              {taskName(task?.fields?.task_type?.[0]?.label)}*/}
            {/*            </li>*/}
            {/*            <ButtonCustom*/}
            {/*              className={css.buttonSelect}*/}
            {/*              type="primary"*/}
            {/*              ghost*/}
            {/*              text="К задаче"*/}
            {/*              onClick={(e: React.MouseEvent<HTMLElement>): void => {*/}
            {/*                e.preventDefault();*/}
            {/*                window.open(`/task/${task?.fields?.uuid}`, "_blank");*/}
            {/*              }}*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        </Option>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </Form.Item>)*/}
            {/*}*/}
            {!isPaidConsultation ?
              (<>
                {/*Временно скрываем дату ожидаемого ответа*/}
                {/*{!hideDeadline && (*/}
                {/*  <>*/}
                {/*    <div className={css.modalText}>*/}
                {/*      Выберите дату ожидаемого ответа от клиента*/}
                {/*    </div>*/}
                {/*    <Form.Item name="responseDate">*/}
                {/*      <DatePicker*/}
                {/*        id="responseDate"*/}
                {/*        size="large"*/}
                {/*        className="w-full"*/}
                {/*        style={{*/}
                {/*          padding: "6.35px",*/}
                {/*        }}*/}
                {/*        placement="bottomRight"*/}
                {/*        format="до DD.MM.YYYY"*/}
                {/*        allowClear={false}*/}
                {/*        inputReadOnly={false}*/}
                {/*        placeholder="Срок ответа"*/}
                {/*        onChange={(date: Dayjs | null) => setSelectedDate(date)}*/}
                {/*        disabledDate={(current: Dayjs) => {*/}
                {/*          return current && current < dayjs().endOf("day");*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    </Form.Item>*/}
                {/*  </>*/}
                {/*)}*/}
                <div className={css.modalText}>
                  Напишите ваше сообщение и прикрепите файлы, если это необходимо
                </div>
                <div className="mb-[20px]">
                  <Form.Item
                    name="textArea"
                    validateStatus={!comment ? "error" : ""}
                  >
                    <TextArea
                      id="textArea"
                      rows={4}
                      maxLength={2000}
                      className="text-base font-normal leading-5 text-gray-900"
                      placeholder="Ваше сообщение"
                      onChange={handleTextAreaChange}
                    />
                    <div className="text-xs font-normal leading-100 text-gray-700 text-right">
                      {remainingChars}/2000 символов
                    </div>
                  </Form.Item>
                </div>
                {!showUpload ? (
                  <DraggerForm
                    props={{...propsFile}}
                    fileCount={fileCount}
                    counterUpload={counterUpload}
                  />
                ) : null}
                <div className={`${css.fileListing} grid justify-items-start items-center`}>
                  {uploadFilesLoader ? (
                    <Skeleton
                      active
                      title={false}
                      paragraph={{
                        rows: 1,
                        width: "100%"
                      }}
                    />
                    ) : uploadFilesList}
                </div>
              </>
            ) : null}
          </>
        );
      case "Платные услуги":
        return (
          <>
            <Form.Item
              name="topic"
              className="mb-[20px]"
              validateStatus={!selectedAction ? "error" : ""}
            >
              <Select
                id="topic"
                placeholder="Выберите тему задачи"
                options={filteredTopic(selectedType)}
                listHeight={196}
                showSearch
                allowClear
                size="large"
                className={`${css.modalDrop}`}
                onChange={handleSelectTopic}
                notFoundContent={<ZeroSearch dataFilter/>}
              />
            </Form.Item>
            {isTariffIncrease && (
              <TariffIncrease formContent={form}/>
            )}
            {isTerminateService && (
              <TerminateService formContent={form}/>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const handleSelectChangeAction = (value: string): void => {
    setSelectedAction("");
    setSelectedActionCode(null);
    setSelectedType(value);
    setComment("");
    setRemainingChars(0);
    form.resetFields(["topic", "textArea"]);
    setSelectedDate(null);

    if (uploadedFilesData) {
      deleteAllUploadFiles(uploadedFilesData);
      setUploadedFilesData(null);
      setFileCount(0);
    }
  };

  const handleSelectClear = (): void => {
    setSelectedType("");
    setSelectedAction("");
    setSelectedActionCode(null);
    setComment("");
    setRemainingChars(0);
    form.resetFields();
  };

  return (
  <Form form={form}>
    <Modal
      width={580}
      title={modalHeader("Создание задачи")}
      open={isModalOpen}
      onCancel={modalCancel}
      footer={modalFooter}
      modalRender={(modal) => (
        <ModalDraggable disabled={disabled} modal={modal} />
      )}
    >
      <div className={css.modalText}>Выберите тип и тему для задачи</div>
      <Form.Item
        name="taskAction"
        className="mt-0 mb-0"
        validateStatus={!selectedType ? "error" : ""}
      >
        <Select
          id="taskAction"
          placeholder="Выберите тип задачи"
          options={hasAccess ? taskType : filteredType}
          showSearch
          allowClear
          size="large"
          className="mb-[20px]"
          notFoundContent={<ZeroSearch dataFilter/>}
          onChange={handleSelectChangeAction}
          onClear={handleSelectClear}
        />
      </Form.Item>
      {modalContent(selectedType)}
    </Modal>
  </Form>
  );
};

export default ModalContent;
