import React, { FC, JSX, useEffect, useState } from "react";
import css from "./Vacancy.module.css";
import { Form, Tabs, TabsProps } from "antd";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import VacancyListing from "./VacancyListing/VacancyListing";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { INavigationProps } from "../Resume/CreateResume/CreateResume";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { getVacancyFailure } from "../../../actions/alpha/vacancy.actions";

interface IVacancyProps {
}

const Vacancy: FC<IVacancyProps> = (): JSX.Element => {
  const [form] = Form.useForm();

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const [activeKey, setActiveKey] = useState<string>("ACTIVE");

  const handleTabChange = (key: string): void => {
    setActiveKey(key);
  };

  const itemsTabs: TabsProps["items"] = [
    {
      key: "ACTIVE",
      label: "Опубликованные",
      children: <VacancyListing vacancyStatus={activeKey} />,
      destroyInactiveTabPane: true,
    },
    {
      key: "WAIT_DECISION",
      label: "На согласовании",
      children: <VacancyListing vacancyStatus={activeKey} />,
      destroyInactiveTabPane: true,
    },
    {
      key: "ARCHIVE",
      label: "Архив",
      children: <VacancyListing vacancyStatus={activeKey} />,
      destroyInactiveTabPane: true,
    },
    {
      key: "DEACTIVE",
      label: "Отозваны",
      children: <VacancyListing vacancyStatus={activeKey} />,
      destroyInactiveTabPane: true,
    },
    {
      key: "ALL",
      label: "Все вакансии",
      children: <VacancyListing vacancyStatus={null} />,
      destroyInactiveTabPane: true,
    }
  ];

  const navigationProps: INavigationProps = {
    vacancyStatus: activeKey,
  };

  const vacancyStatus: string = useLocation()?.state ?? "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openCreateVacancyForm = (): void => {
    navigate("/alpha/create_vacancy", {state: navigationProps});
    dispatch(getVacancyFailure());
  };

  return (
    <Form form={form}>
      <div className={css.container}>
        <div className="flex justify-between">
          <h1 className={css.containerHeader}>Вакансии</h1>
          <ButtonCustom
            className={css.createVacancy}
            type="primary"
            text="Создать вакансию"
            htmlType="button"
            size="large"
            onClick={openCreateVacancyForm}
          />
        </div>
        <Tabs
          defaultActiveKey={vacancyStatus ?? activeKey}
          items={itemsTabs}
          onChange={handleTabChange}
          destroyInactiveTabPane
        />
      </div>
    </Form>
  );
};

export default Vacancy;
