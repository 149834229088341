import {
  CREATE_REPORT_FAILURE,
  CREATE_REPORT_SUCCESS,
  GET_REPORTS_LISTING_FAILURE,
  GET_REPORTS_LISTING_PAGE_NUMBER,
  GET_REPORTS_LISTING_PAGE_SIZE,
  GET_REPORTS_LISTING_SUCCESS,
  REPORTS_CREATION_PARAMS_DATA,
  REPORTS_LISTING_PARAMS_DATA
} from "../constants/actionTypes";
import { ReportsActionsTypes } from "../actions/reports.action";
import {
  NewReportDataType,
  ReportsListingDataType,
  ReportsListingFilterType,
  ReportsCreationFilterType
} from "app/types";

type InitialReportsCardType = {
  error: any;
  reportsListing: null | ReportsListingDataType[];
  pageSize: number;
  pageNumber: number;
  totalDocs: number | null;
  newReport: null | NewReportDataType;
  createReportStatus: number | undefined;
  reportsListingParams: ReportsListingFilterType | null;
  reportsCreationParams: ReportsCreationFilterType | null;
};

const initialState: InitialReportsCardType = {
  error: null,
  reportsListing: null,
  pageSize: 10,
  pageNumber: 1,
  totalDocs: null,
  newReport: null,
  createReportStatus: 0,
  reportsListingParams: null,
  reportsCreationParams: null,
};

const reports = (
  state: InitialReportsCardType = initialState,
  action: ReportsActionsTypes
): InitialReportsCardType => {
  switch (action.type){
    case GET_REPORTS_LISTING_SUCCESS:
      return {
        ...state,
        reportsListing: action.payload.reportsListing.reports,
        totalDocs: action.payload.reportsListing.total_docs,
        error: null,
      };
    case GET_REPORTS_LISTING_FAILURE:
      return {
        ...state,
        reportsListing: null,
        error: action.payload.error,
      };
    case GET_REPORTS_LISTING_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload.pageSize,
      };
    case GET_REPORTS_LISTING_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        newReport: action.payload.newReport,
        createReportStatus: action.payload.createReportStatus,
      };
    case CREATE_REPORT_FAILURE:
      return {
        ...state,
        newReport: null,
        createReportStatus: action.payload.status,
      };
    case REPORTS_LISTING_PARAMS_DATA:
      return {
        ...state,
        reportsListingParams: action.payload.reportsParams,
      };
    case REPORTS_CREATION_PARAMS_DATA:
      return {
        ...state,
        reportsCreationParams: action.payload.reportsCreationParams,
      };
    default:
      return state;
  }
};

export default reports;
