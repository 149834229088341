import {
  GET_APPLICATION_FAILURE,
  GET_APPLICATION_LIST_FAILURE,
  GET_APPLICATION_LIST_SUCCESS,
  GET_APPLICATION_SUCCESS
} from "../../constants/actionTypes";
import { ApplicationActionsTypes } from "../../actions/alpha/application.actions";
import { ApplicationListType } from "app/types";

type InitialApplicationType = {
  error: any;
  applicationList: ApplicationListType[];
  applicationPage: number;
  applicationTotalDocs: number;
  applicationPageSize: number;
  applicationData: ApplicationListType | null;
}

const initialState: InitialApplicationType = {
  error: null,
  applicationList: [],
  applicationPage: 1,
  applicationTotalDocs: 5,
  applicationPageSize: 5,
  applicationData: null,
};

const application = (
  state: InitialApplicationType = initialState,
  action: ApplicationActionsTypes
): InitialApplicationType => {
  switch (action.type) {
    case GET_APPLICATION_LIST_SUCCESS:
      return {
        ...state,
        applicationPage: action.payload.applicationsList.page,
        applicationList: action.payload.applicationsList.results,
        applicationPageSize: action.payload.applicationsList.page_size,
        applicationTotalDocs: action.payload.applicationsList.total_docs,
        error: null
      };
    case GET_APPLICATION_LIST_FAILURE:
      return {
        ...state,
        applicationPage: 0,
        applicationList: [],
        applicationPageSize: 0,
        applicationTotalDocs: 0,
        error: action.payload.error
      };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        applicationData: action.payload.applicationData,
        error: null
      };
    case GET_APPLICATION_FAILURE:
      return {
        ...state,
        applicationData: null,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default application;
