import React, { Dispatch, FC, JSX, SetStateAction } from "react";
import { Modal } from "antd";
import css from "./DeleteVacancyModal.module.css";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";

interface IDeleteVacancyModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  vacancyName: string | null;
}

const DeleteVacancyModal: FC<IDeleteVacancyModal> = ({
  isModalOpen,
  setIsModalOpen,
  vacancyName,
}): JSX.Element => {

  const deleteVacancyTitle: JSX.Element = (
    <span className={css.deleteVacancyHeader}>
      Подтверждение действия
    </span>
  );

  const onCancelDeleteVacancyModal = (): void => {
    setIsModalOpen(false);
  };

  const onApplyDeleteVacancyModal = (): void => {
    setIsModalOpen(false);
    //в дальнейшем дописать логику как будет готов бэк
  };

  const deleteVacancyModalFooter: JSX.Element = (
    <div key="modalBotton">
      <ButtonCustom
        className="mr-[8px]"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={onCancelDeleteVacancyModal}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={onApplyDeleteVacancyModal}
      />
    </div>
  );

  return (
    <Modal
      title={deleteVacancyTitle}
      open={isModalOpen}
      onCancel={onCancelDeleteVacancyModal}
      centered
      footer={deleteVacancyModalFooter}
    >
      <p className={css.deleteVacancyDescription}>
        Вы действительно хотите удалить вакансию {vacancyName}?
      </p>
    </Modal>
  );
};

export default DeleteVacancyModal;