import React, { FC, JSX } from "react";
import MigrationDoc from "./MigrationDoc/MigrationDoc";
import IncomeDoc from "./IncomeDoc/IncomeDoc";

interface IDocumentsCardProps {
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  docUuid: string;
  docType: string;
  hasMigrationOrPatentDoc: boolean;
  incomeDocUuid: string;
}

const DocumentsCard: FC<IDocumentsCardProps> = ({
  documentUuid,
  showImageViewer,
  docUuid,
  docType,
  hasMigrationOrPatentDoc,
  incomeDocUuid
}): JSX.Element => {

  return (
    <div>
      <MigrationDoc
        documentUuid={documentUuid}
        showImageViewer={showImageViewer}
        docUuid={docUuid}
        docType={docType}
        hasMigrationOrPatentDoc={hasMigrationOrPatentDoc}
      />
      <IncomeDoc
        documentUuid={documentUuid}
        showImageViewer={showImageViewer}
        incomeDocUuid={incomeDocUuid}
      />
    </div>
  );
};

export default DocumentsCard;
