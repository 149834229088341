import React, {
  FC,
  JSX,
  Key,
  useEffect,
  useState
} from "react";
import {
  Button,
  Dropdown,
  MenuProps,
  Table,
  TableColumnsType
} from "antd";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings-icon.svg";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { renderAlphaDocStatus } from "../../../../utils/renderAlphaDocStatus";
import css from "./ResumeListing.module.css";
import { renderSkills } from "../../../../utils/RenderSkills/RenderSkills";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getResumeInfo, getResumesData } from "../../../../actions/alpha/resumes.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { AlphaListParamsType, ResumesResultsType } from "app/types";
import { downloadFile, downloadUrl } from "../../../../utils/downloadFile";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import { roleResolver } from "../../../../utils/roleResolver";
import { JWTPayload } from "jose";

interface IResumeListingProps {
}

const ResumeListing: FC<IResumeListingProps> = (): JSX.Element => {
  const [contractorsListParams, setContractorsListParams] = useState<AlphaListParamsType | null>( {
    page: 1,
    page_size: 5,
  });
  const [deleteResumeKey, setDeleteResumeKey] = useState<Key | null>(null);
  const [deleteResumeName, setDeleteResumeName] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setContractorsListParams);

  const {
    resumeData,
    resumePage,
    resumePageSize,
    resumeTotalDocs,
    isListingLoading,
  } = useSelector((state: AppStateType) => state.resume);

  useEffect(() => {
    dispatch(getResumesData(contractorsListParams));
  }, [contractorsListParams]);

  const handleResumePageChange = (newPage: number): void => {
    setContractorsListParams({
      ...contractorsListParams,
      page: newPage,
    });
  };

  const handleResumePageSizeChange = (newPageSize: number): void => {
    setContractorsListParams({
      ...contractorsListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const deleteResume = (resume: ResumesResultsType): void => {
    setDeleteResumeKey(resume?.id);
    setDeleteResumeName(resume?.fields?.uuid);
  };

  const updateResume = (resume: ResumesResultsType): void => {
    navigate(`/alpha/update_resume/${resume.fields?.uuid}`, {state: { resumeUuid: resume?.fields?.uuid }});
    dispatch(getResumeInfo(resume.fields?.uuid));
  };

  const resumeColumns: TableColumnsType<ResumesResultsType> = [
    {
      title: (
        <div className="table-title">
          ФИО
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "last_name"],
      width: "30%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "last_name"),
      }),
      render: (_: string, record: ResumesResultsType ) => {
        const { last_name, first_name, second_name, uuid } = record?.fields ?? {};

        return (
          <Link to={`/alpha/resume/${uuid}`} className={`${css.tableCell} flex`}>
            {[last_name, first_name, second_name].filter(Boolean).join(" ")}
          </Link>
        );
      },
    },
    {
      title: (
        <div className="table-title">
          Скиллы
        </div>
      ),
      dataIndex: ["fields", "skills"],
      width: "26%",
      // filtered: !!selectedHiringForm?.length,
      // filterDropdown: ({ confirm }) => tableHiringFormFilters(confirm),
      //как будем получать данные и отправлять данные на бэк, раскомментировать и проверить логику
      // onFilterDropdownOpenChange: (visible: boolean): void => {
      //   if (!visible) {
      //     changeHiringFormParams();
      //   }
      // },
      render: (_, record: ResumesResultsType) => renderSkills(record.fields.skills)
    },
    {
      title: (
      <div className="table-title">
        Режим работы
      </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex:  ["fields", "working_hours"],
      width: "15%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "working_hours"),
      }),
      render: (text: string, record: ResumesResultsType) => (
        <Link to={`/alpha/resume/${record?.fields?.uuid}`} className={css.tableCell}>{text}</Link>
      )
    },
    {
      title: (
      <div className="table-title">
        Файл резюме
      </div>
      ),
      dataIndex: ["fields", "cv_file"],
      render: (_: string, record: ResumesResultsType) =>
        <div className={css.tableCell}>
          <a
            className="max-w-[300px] flex blue-color items-center mb-[4px]"
            onClick={() => downloadFile("Резюме", downloadUrl(record?.fields?.cv_file))}
          >
            <AttachFileIcon className="flex-shrink-0 mr-1"/>
            <div className={css.docFile}>Файл.pdf</div>
          </a>
        </div>,
      width: "14%",
    },
    {
      title: (
      <div className="table-title">
        Статус
      </div>
      ),
      dataIndex: ["fields", "cv_status"],
      render: (text: string) => renderAlphaDocStatus(text),
      width: "12%",
    },
    {
      title: "",
      key: "settings",
      width: "3%",
      render: (_, record: ResumesResultsType) => {
        const menuItems: MenuProps["items"] = [
          {
            label: <div>Изменить</div>,
            key: "1",
            onClick: () => updateResume(record),
          },
          {
            label: <div>Удалить</div>,
            key: "2",
            danger: true,
            onClick: () => deleteResume(record),
          },
        ];

        return (
          <Dropdown.Button
            menu={{ items: menuItems}}
            buttonsRender={([_leftButton, _rightButton]) => [
              null,
              <Button
                className={css.settingsBtn}
                key={record?.id}
                type="text"
                icon={<SettingsIcon />}
              />,
            ]}
          />
        );
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    !isListingLoading ? (
      <div className="p-[16px] mb-3">
        <div className="flex justify-between items-center mb-[16px]">
          <h1 className={css.resumeListHeader}>
            Мои резюме
          </h1>
          {isAlphaContractor && (
            <ButtonCustom
              type="primary"
              size="large"
              text="Создать резюме"
              onClick={() => navigate("/alpha/create_resume")}
            />
          )}
        </div>
        <div className="p-[16px] mb-3 bg-white rounded-[8px]">
          <Table
            columns={resumeColumns}
            dataSource={resumeData?.length ? resumeData : []}
            pagination={false}
          />
          <PaginationCustom
            className={css.paginationShowTotal}
            total={resumeTotalDocs}
            showTotal={(total: number, range: number[]): string =>
              `${range[0]}-${range[1]} из ${total}`
            }
            currentPage={resumePage}
            defaultPageSize={10}
            pageSize={resumePageSize}
            defaultCurrent={1}
            handlePageChange={handleResumePageChange}
            handlePageSizeChange={handleResumePageSizeChange}
          />
        </div>
      </div>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default ResumeListing;
