import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
} from "react";
import { FormInstance } from "antd";
import { taskStatus } from "../../../../utils/catalog";
import FiltersComponent from "../FiltersComponent";

interface IStatusFiltersProps {
  form: FormInstance,
  isModalOpen: boolean,
  selectedTaskStatuses: string[],
  setSelectedTaskStatuses: Dispatch<SetStateAction<string[]>>,
}

const StatusFilters: FC<IStatusFiltersProps> = ({
  form,
  isModalOpen,
  selectedTaskStatuses,
  setSelectedTaskStatuses,
}): JSX.Element => {
  return (
    <FiltersComponent
      key="statusTask"
      form={form}
      isModalOpen={isModalOpen}
      selectedItems={selectedTaskStatuses}
      setSelectedItems={setSelectedTaskStatuses}
      taskTypes={taskStatus}
      formFieldName="statusTask"
      headerText="По статусу"
    />
  );
};

export default StatusFilters;