import {
  GET_SCHEMA_CLIENT_PROFILE_SUCCESS,
  GET_SCHEMA_CLIENT_PROFILE_FAILURE,
  GET_DOCUMENT_CLIENT_PROFILE_SUCCESS,
  GET_DOCUMENT_CLIENT_PROFILE_FAILURE,
  GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS,
  GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE,
  GET_UN_AGREED_DOCUMENT,
} from "../constants/actionTypes";
import { ClientProfileActionsTypes } from "../actions/clientProfile.actions";
import { DocumentClientProfileType, DocumentsValidationErrorsDataType, SchemaType } from "app/types";

type InitialClientProfileCardType = {
  error: any;
  schemaClientProfile: null | SchemaType;
  documentClientProfile: null | DocumentClientProfileType;
  notAgreedDocuments: null | DocumentsValidationErrorsDataType;
  isSchemaClientProfileLoading: boolean;
  isDocumentClientProfileLoading: boolean;
  sendRequest: boolean;
};

const initialState: InitialClientProfileCardType = {
  error: null,
  schemaClientProfile: null,
  documentClientProfile: null,
  notAgreedDocuments: null,
  isSchemaClientProfileLoading: true,
  isDocumentClientProfileLoading: true,
  sendRequest: true,
};

const clientProfile = (
  state: InitialClientProfileCardType = initialState,
  action: ClientProfileActionsTypes
): InitialClientProfileCardType => {
  switch (action.type) {
    // Логика получения схемы анкеты
    case GET_SCHEMA_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        schemaClientProfile: action.payload.schemaClientProfile,
        isSchemaClientProfileLoading: false,
        error: null,
      };
    case GET_SCHEMA_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        schemaClientProfile: null,
        isSchemaClientProfileLoading: false,
        error: action.payload.error,
      };
    // Логика получения данных схемы анкеты по uuid документа
    case GET_DOCUMENT_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        documentClientProfile: action.payload.documentClientProfile,
        isDocumentClientProfileLoading: false,
        error: null,
      };
    case GET_DOCUMENT_CLIENT_PROFILE_FAILURE:
      return {
        ...state,
        documentClientProfile: null,
        isDocumentClientProfileLoading: false,
        error: action.payload.error,
      };
    //Логика получения ошибок валидации документов в анкете клиента
    case GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS:
      return {
        ...state,
        notAgreedDocuments: action.payload.notAgreedDocuments,
        error: null,
      };
    case GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE:
      return {
        ...state,
        notAgreedDocuments: null,
        error: action.payload.error,
      };
    case GET_UN_AGREED_DOCUMENT:
      return {
        ...state,
        sendRequest: action.payload.sendRequest,
      };
    default:
      return state;
  }
};

export default clientProfile;
