import React, { FC, JSX } from "react";
import css from "./ZeroAccounts.module.css";
import { ReactComponent as AccountsZero } from "../../../../../../assets/icons/bank_accounts_icon.svg";

interface IZeroAccountsProps {
}

const ZeroAccounts: FC<IZeroAccountsProps> = (): JSX.Element => {
  return (
  <div className={css.container}>
    <div className={css.label}>
      <div className={css.icon}><AccountsZero/></div>
      <div>
        <div className={css.textHeader}>Счетов нет</div>
        <div className={css.textBody}>
          У клиента нет счетов или он не предоставил информацию о них
        </div>
      </div>
    </div>
  </div>
  );
};

export default ZeroAccounts;