import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  Form,
  Input,
  Select,
  FormProps,
  SelectProps,
  FormInstance,
  notification,
} from "antd";
import {
  useNavigate,
  NavigateFunction
} from "react-router-dom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./CreateContractor.module.css";
import * as DocumentAPI from "../../../../api/document.api";
import { ContractorFormValuesType } from "app/types";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";

const operatingModeOptions: SelectProps["options"] = [
  {
    value: "Трудовой договор",
    label: "Трудовой договор",
  },
  {
    value: "ГПХ",
    label: "ГПХ",
  },
];

interface ICreateContractorProps {
}

const CreateContractor: FC<ICreateContractorProps> = (): JSX.Element => {
  const [isSubmittable, setSubmittable] = useState<boolean>(false);
  const [createDocStatus, setCreateDocStatus] = useState<number>(0);

  const navigate: NavigateFunction = useNavigate();

  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  useEffect((): void => {
    createAlphaDocStatus(
      createDocStatus,
      createContractorSuccess,
      createContractorError,
    );
  }, [createDocStatus]);

  useEffect((): void => {
    form
    .validateFields({ validateOnly: true })
    .then(() => setSubmittable(true))
    .catch(() => setSubmittable(false));
  }, [form, values]);

  const onFinish: FormProps<FormInstance>["onFinish"] = async (values: FormInstance): Promise<void> => {
    const filtered = Object.entries(values)
    .filter(([_, value]): boolean => typeof value === "string" && value.trim() !== "")
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value
    }), {}) as ContractorFormValuesType;

    try {
      const { status } = await DocumentAPI.createNewDocument("client-company", filtered);
      setCreateDocStatus(status);
    } catch (error: any) {
      setCreateDocStatus(error.response.status);
      console.error("Create contractor error:", error.response.data.message);
    }
  };

  const createContractorSuccess = (): void => {
    notification.success({
      message: "Контрагент успешно создан",
    });

    setCreateDocStatus(0);
    navigate("/alpha/contractors");
  };

  const createContractorError = (): void => {
    notification.error({
      message: "Ошибка создания контрагента",
    });

    setCreateDocStatus(0);
  };

  const onCloseForm = (): void => {
    form.resetFields();
    navigate("/alpha/contractors");
  };

  return (
    <div className={css.container}>
      <h1 className={css.createContractorHeader}>
        Создание нового контрагента
      </h1>
      <div className={css.createContractorForm}>
        <Form
          name="create_contractor_form"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          validateTrigger="onChange"
        >
          <div className={css.createContractorFormBlock}>
            <h2 className={css.createContractorBlockHeader}>
              Основные параметры
            </h2>
            <Form.Item
              className="w-full mb-[20px]"
              id="name"
              name="name"
              label="Наименование контрагента"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите наименование контрагента",
                }
              ]}
            >
              <Input
                allowClear
                className="w-full"
                size="large"
                placeholder="Введите наименование контрагента"
              />
            </Form.Item>
            <div className="flex justify-between">
              <Form.Item
                className="w-[32%] mr-[20px] mb-0"
                id="inn"
                name="inn"
                label="ИНН"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите ИНН"
                  }
                ]}
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="Введите ИНН"
                />
              </Form.Item>
              <Form.Item
                className="w-[32%] mr-[20px] mb-0"
                id="registered_country"
                name="registered_country"
                label="Страна регистрации"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите страну регистрации"
                  }
                ]}
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="Введите страну регистрации"
                />
              </Form.Item>
              <Form.Item
                className="w-[32%] mb-[0px]"
                id="agreement"
                name="agreement"
                label="Договор"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, выберите договор"
                  }
                ]}
              >
                <Select
                  size="large"
                  placeholder="Выберите договор"
                  allowClear
                  filterOption={false}
                  options={operatingModeOptions}
                />
              </Form.Item>
            </div>
          </div>
          <div className={css.createContractorFormBlock}>
            <h2 className={css.createContractorBlockHeader}>
              Контакты
            </h2>
            <div className="flex mb-0">
              <Form.Item
                className="w-[32%] mr-[30px] mb-0"
                id="phone"
                name="phone"
                label="Номер телефона контрагента"
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="+7 000 000 0000"
                />
              </Form.Item>
              <Form.Item
                className="w-[32%] mb-0"
                id="email"
                name="email"
                label="Электронная почта контрагента"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите электронную почту контрагента",
                  },
                  {
                    type: "email",
                    message: "E-mail адрес введен неправильно",
                  }
                ]}
              >
                <Input
                  allowClear
                  className="w-full"
                  size="large"
                  placeholder="Введите электронную почту контрагента"
                />
              </Form.Item>
            </div>
          </div>
          <div className={css.createContractorFormBlock}>
            <div className={`${css.createContractorActionBlock} text-right`}>
              <ButtonCustom
                className="mr-[8px]"
                type="default"
                text="Отменить"
                size="large"
                ghost
                onClick={onCloseForm}
              />
              <ButtonCustom
                className="mr-[8px]"
                type="primary"
                text="Создать контрагента"
                size="large"
                htmlType="submit"
                disabled={!isSubmittable}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateContractor;
