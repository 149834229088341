import {
  CREATE_NEW_TASK_FAILURE,
  CREATE_NEW_TASK_SUCCESS,
  GET_ACTION_HISTORY_FAILURE,
  GET_ACTION_HISTORY_SUCCESS,
  GET_CLIENT_TASK_LISTING_FAILURE,
  GET_CLIENT_TASK_LISTING_SUCCESS,
  GET_DOCUMENT_TASK_FAILURE,
  GET_DOCUMENT_TASK_SUCCESS,
  GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS,
  GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE,
  GET_PROCESS_FILE_AUTOMATICALLY_FAILURE,
  GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS,
  GET_SCHEMA_TASK_FAILURE,
  GET_SCHEMA_TASK_SUCCESS,
  GET_STATUS_TASK_FAILURE,
  GET_STATUS_TASK_SUCCESS,
  GET_TASK_FILE_1C_FAILURE,
  GET_TASK_FILE_1C_SUCCESS,
  GET_TASK_STEPS_INFO_FAILURE,
  GET_TASK_STEPS_INFO_SUCCESS,
  GET_TASKS_LISTING_FAILURE,
  GET_TASKS_LISTING_SUCCESS,
  GET_TASKS_PAGE_NUMBER,
  GET_TASKS_PAGE_SIZE,
  GET_UUID_TASK_1C_FAILURE,
  GET_UUID_TASK_1C_SUCCESS,
  REPEAT_TASK_STEP_FAILURE,
  REPEAT_TASK_STEP_SUCCESS,
  SEND_TYPICAL_TASK_DECISION_FAILURE,
  SEND_TYPICAL_TASK_DECISION_SUCCESS,
  TASK_LISTING_FILTER_DATA,
  TASK_LISTING_LOADING_DEFAULT,
  TASK_LISTING_PARAMS_DATA,
  TASK_LISTING_SORTER_DATA,
  TASK_LISTING_TAG_KEY,
  CLEAR_TASKS_NOTIFICATION,
} from "../constants/actionTypes";
import { TaskActionsTypes } from "../actions/tasks.actions";
import {
  ActionHistoryType,
  DocumentTaskType,
  FilterTasksType,
  GetFile1CType,
  NewTaskType,
  MarkupTask1CDataType,
  SchemaType,
  TaskExecutionWrapper,
  TaskNotificationStatusType,
  TasksListingDataType,
  TypicalTaskDecisionResponseType,
  UploadedFilesPropsType
} from "app/types";

type InitialTaskCardType = {
  error: any;
  newTask: null | NewTaskType;
  tasksData: null | TasksListingDataType[];
  tasksPageSize: number;
  tasksPage: number;
  tasksSorter: null | string;
  tasksFilter: null | FilterTasksType;
  tasksParams: null | FilterTasksType;
  tasksTagKey: string;
  totalDocs: number | null;
  schemaTaskData: null | SchemaType;
  documentTask: null | DocumentTaskType;
  parsingResult: null | MarkupTask1CDataType;
  parsingResultStatus: number;
  markupTaskFileFrom1C: UploadedFilesPropsType[] | null;
  markupTaskStatusForm1C: string | null;
  statusTask: null | TaskNotificationStatusType;
  isListingLoading: boolean;
  isStatusLoading: boolean;
  decisionTypeStatus: number;
  decisionTypicalTask: TypicalTaskDecisionResponseType | null;
  actionHistory: ActionHistoryType | null;
  isActionHistoryLoading: boolean;
  clientTaskListing: null | TasksListingDataType[];
  getDocumentTaskStatus: number;
  file1CDownloadStatus: number;
  taskFile1CUuid: string | null;
  taskFile1CData: null | GetFile1CType;
  stepsTechInfo: TaskExecutionWrapper | null;
  repeatStepStatus: number;
  actionHistoryStatus: number;
  statusTaskNumber: number;
}

const initialState: InitialTaskCardType = {
  error: null,
  newTask: null,
  tasksData: null,
  tasksPageSize: 10,
  tasksPage: 1,
  tasksSorter: "-number",
  tasksFilter: null,
  tasksParams: null,
  tasksTagKey: "1",
  totalDocs: null,
  schemaTaskData: null,
  documentTask: null,
  parsingResult: null,
  parsingResultStatus: 0,
  markupTaskFileFrom1C: null,
  markupTaskStatusForm1C: null,
  statusTask: null,
  isListingLoading: true,
  isStatusLoading: true,
  decisionTypeStatus: 0,
  decisionTypicalTask: null,
  actionHistory: null,
  isActionHistoryLoading: true,
  clientTaskListing: null,
  getDocumentTaskStatus: 0,
  file1CDownloadStatus: 0,
  taskFile1CUuid: null,
  taskFile1CData: null,
  stepsTechInfo: null,
  repeatStepStatus: 0,
  actionHistoryStatus: 0,
  statusTaskNumber: 0,
};

const tasks = (
  state: InitialTaskCardType = initialState,
  action: TaskActionsTypes
): InitialTaskCardType => {
  switch (action.type){
    case CLEAR_TASKS_NOTIFICATION: {
      const tasksWithoutNotifications: TasksListingDataType[] = state.tasksData
      ?.map((task: TasksListingDataType) => {
        return {
          ...task,
          notification: action.payload.notification
        };
      }) ?? [];

      return {
        ...state,
        tasksData: tasksWithoutNotifications,
      };
    }
    case GET_SCHEMA_TASK_SUCCESS:
      return {
        ...state,
        schemaTaskData: action.payload.schemaTask,
        error: null,
      };
    case GET_SCHEMA_TASK_FAILURE:
      return {
        ...state,
        schemaTaskData: null,
        error: action.payload.error,
      };
    case GET_TASKS_LISTING_SUCCESS:
      return {
        ...state,
        tasksData: action.payload.tasksListing.results,
        totalDocs: action.payload.tasksListing.total_docs,
        error: null,
        isListingLoading: false,
      };
    case GET_TASKS_LISTING_FAILURE:
      return {
        ...state,
        tasksData: null,
        error: action.payload.error,
        isListingLoading: false,
      };
    case GET_TASKS_PAGE_SIZE:
      return {
        ...state,
        tasksPageSize: action.payload.size,
      };
    case GET_TASKS_PAGE_NUMBER:
      return {
        ...state,
        tasksPage: action.payload.page,
      };
    case TASK_LISTING_SORTER_DATA:
      return {
        ...state,
        tasksSorter: action.payload.tasksSorter,
      };
    case TASK_LISTING_FILTER_DATA:
      return {
        ...state,
        tasksFilter: action.payload.tasksFilter,
      };
    case TASK_LISTING_PARAMS_DATA:
      return {
        ...state,
        tasksParams: action.payload.tasksParams,
      };
    case TASK_LISTING_TAG_KEY:
      return {
        ...state,
        tasksTagKey: action.payload.tasksTagKey,
      };
    case TASK_LISTING_LOADING_DEFAULT:
      return {
        ...state,
        isListingLoading: action.payload.loadingDefault,
      };
    case CREATE_NEW_TASK_SUCCESS:
      return {
        ...state,
        newTask: action.payload.newTask,
        error: null,
      };
    case CREATE_NEW_TASK_FAILURE:
      return {
        ...state,
        newTask: null,
        error: action.payload.error
      };
    case GET_DOCUMENT_TASK_SUCCESS:
      return {
        ...state,
        documentTask: action.payload.documentTask,
        error: null,
        getDocumentTaskStatus: action.payload.getDocumentTaskStatus,
      };
    case GET_DOCUMENT_TASK_FAILURE:
      return {
        ...state,
        documentTask: null,
        error: action.payload.error,
        getDocumentTaskStatus: 0,
      };
    case GET_STATUS_TASK_SUCCESS:
      return {
        ...state,
        statusTask: action.payload.statusTask,
        statusTaskNumber: action.payload.statusTaskNumber,
        error: null,
        isStatusLoading: false,
      };
    case GET_STATUS_TASK_FAILURE:
      return {
        ...state,
        statusTask: null,
        statusTaskNumber: action.payload.statusTaskNumber,
        error: action.payload.error,
        isStatusLoading: false,
      };
    case SEND_TYPICAL_TASK_DECISION_SUCCESS:
      return {
        ...state,
        decisionTypeStatus: action.payload.decisionTypeStatus,
        decisionTypicalTask: action.payload.decisionTypicalTask,
        error: null,
      };
    case SEND_TYPICAL_TASK_DECISION_FAILURE:
      return {
        ...state,
        decisionTypeStatus: action.payload.decisionTypeStatus,
        error: action.payload.error,
      };
    case GET_ACTION_HISTORY_SUCCESS:
      return {
        ...state,
        actionHistory: action.payload.actionHistory,
        actionHistoryStatus: action.payload.actionHistoryStatus,
        error: null,
        isActionHistoryLoading: false,
      };
    case GET_ACTION_HISTORY_FAILURE:
      return {
        ...state,
        actionHistory: null,
        actionHistoryStatus: action.payload.actionHistoryStatus,
        error: action.payload.error,
        isActionHistoryLoading: false,
      };
    case GET_CLIENT_TASK_LISTING_SUCCESS:
      return {
        ...state,
        clientTaskListing: action.payload.clientTaskListing.results,
        error: null,
      };
    case GET_CLIENT_TASK_LISTING_FAILURE:
      return {
        ...state,
        clientTaskListing: null,
        error: action.payload.error,
      };
    //Логика получения файла из 1С для обработки задачи Автоматически
    case GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS:
      return {
        ...state,
        file1CDownloadStatus: action.payload.file1CDownloadStatus,
        error: null,
      };
    case GET_PROCESS_FILE_AUTOMATICALLY_FAILURE:
      return {
        ...state,
        file1CDownloadStatus: action.payload.file1CDownloadStatus,
        error: action.payload.error,
      };
    case GET_UUID_TASK_1C_SUCCESS:
      return {
        ...state,
        taskFile1CUuid: action.payload.taskFile1CUuid.guid,
        file1CDownloadStatus: action.payload.taskFile1CUuidStatus,
        error: null,
      };
    case GET_UUID_TASK_1C_FAILURE:
      return {
        ...state,
        taskFile1CUuid: null,
        file1CDownloadStatus: action.payload.taskFile1CUuidStatus,
        error: action.payload.error,
      };
    case GET_TASK_FILE_1C_SUCCESS:
      return {
        ...state,
        taskFile1CData: action.payload.taskFile1CData,
        file1CDownloadStatus: action.payload.taskFile1CStatus,
        error: null,
      };
    case GET_TASK_FILE_1C_FAILURE:
      return {
        ...state,
        taskFile1CData: null,
        file1CDownloadStatus: action.payload.taskFile1CStatus,
        error: action.payload.error,
      };
    case GET_TASK_STEPS_INFO_SUCCESS:
      return {
        ...state,
        stepsTechInfo: action.payload.stepsTechInfo,
        error: null,
      };
    case GET_TASK_STEPS_INFO_FAILURE:
      return {
        ...state,
        stepsTechInfo: null,
        error: action.payload.error,
      };
    case REPEAT_TASK_STEP_SUCCESS:
      return {
        ...state,
        repeatStepStatus: action.payload.repeatStepStatus,
        error: null,
      };
    case REPEAT_TASK_STEP_FAILURE:
      return {
        ...state,
        repeatStepStatus: action.payload.status,
        error: action.payload.error,
      };
    //Логика получения файла из 1С для обработки задачи Автоматически для Разметки операции
    case GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS:
      return {
        ...state,
        markupTaskFileFrom1C: action.payload.parsingResult.fields.statement_response_file,
        markupTaskStatusForm1C: action.payload.parsingResult.fields.parsing_result_status,
        parsingResultStatus: action.payload.parsingResultStatus,
        error: null,
      };
    case GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE:
      return {
        ...state,
        markupTaskFileFrom1C: null,
        markupTaskStatusForm1C: null,
        parsingResultStatus: action.payload.parsingResultStatus,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default tasks;
