import React, {
  FC,
  JSX,
  Key,
  Dispatch,
  SetStateAction,
  useState
} from "react";
import { Modal } from "antd";
import ProfileCreateOrEditAbsentForm from "./ProfileCreateOrEditAbsentForm";
import { AbsenteesListType } from "app/types";
import ModalDraggable from "../../../../utils/ModalDraggable/ModalDraggable";
import { handleOut, handleOver } from "../../../../utils/ModalDraggable/DraggableSwitch";

interface IProfileCreateOrEditAbsentModalProps {
  absentUuid?: Key | null;
  absenteesList: AbsenteesListType | null;
  isAbsentEdited?: boolean;
  setAbsenteesKey: Dispatch<SetStateAction<Key | null>>
  createAbsentStatus: number | null;
  setCreateAbsentStatus: Dispatch<SetStateAction<number | null>>;
  createOrUpdateModalOpen: boolean;
  setCreateOrUpdateModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedUserRoles?: string[] | null;
}

const ProfileCreateOrEditAbsentModal: FC<IProfileCreateOrEditAbsentModalProps> = ({
  absentUuid,
  absenteesList,
  isAbsentEdited,
  setAbsenteesKey,
  createAbsentStatus,
  setCreateAbsentStatus,
  createOrUpdateModalOpen,
  setCreateOrUpdateModalOpen,
  selectedUserRoles
}): JSX.Element => {
  const [disabled, setDisabled] = useState<boolean>(true);
  
  const closeAbsentForm = (): void => {
    setAbsenteesKey(null);
    setCreateOrUpdateModalOpen(false);
  };

  const renderAbsentModalHeader: JSX.Element = (
    <div
      className="block mb-4 cursor-move"
      onMouseOver={() => handleOver(disabled, setDisabled)}
      onMouseOut={() => handleOut(setDisabled)}
    >
      {isAbsentEdited ? "Изменение записи отсутствия" : "Добавление отсутствия"}
    </div>
  );

  const renderAbsentModalDescr = (): JSX.Element => {
    return (
      <span className="inline-block mb-4 text-gray-500">
        {isAbsentEdited
          ? "Измените нужные данные записи отсутствия сотрудника при необходимости и подтвердите изменения."
          : `Выберите даты отсутствия. Это необходимо для переназначения задач на время отсутствия сотрудника. 
             При необходимости вы сможете изменить их позже.`}
      </span>
    );
  };

  return (
    <Modal
      title={renderAbsentModalHeader}
      open={createOrUpdateModalOpen}
      onCancel={closeAbsentForm}
      footer={null}
      modalRender={(modal) => (
        <ModalDraggable disabled={disabled} modal={modal} />
      )}
    >
      {renderAbsentModalDescr()}
      <span className="block mb-4 text-gray-500">
        После окончания срока отсутствия все задачи, поступившие в этот период, останутся на замещающем сотруднике.
      </span>
      <ProfileCreateOrEditAbsentForm
        absentUuid={absentUuid}
        absenteesList={absenteesList}
        isAbsentEdited={isAbsentEdited}
        closeAbsentForm={closeAbsentForm}
        setAbsenteesKey={setAbsenteesKey}
        createAbsentStatus={createAbsentStatus}
        setCreateAbsentStatus={setCreateAbsentStatus}
        setCreateOrUpdateModalOpen={setCreateOrUpdateModalOpen}
        selectedUserRoles={selectedUserRoles}
      />
    </Modal>
  );
};

export default ProfileCreateOrEditAbsentModal;
