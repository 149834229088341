import React, { FC, JSX, useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  FilterProfileClientsType,
  ProfileClientsResultsType,
} from "app/types";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import css from "./ProfileClientsList.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import { AppDispatch } from "../../../store/store";
import {
  getClientsServedData,
  getClientsServedPageNumber,
  getClientsServedPageSize
} from "../../../actions/account.actions";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import ZeroClients from "../../Performers/PerformerCard/PerformerClientsList/ZeroClients/ZeroClients";
import { renderDate } from "../../../utils/renderDate";
import { useTableSorter } from "../../../utils/handleSorterTable";

interface IProfileClientsListProps {
}

const ProfileClientsList: FC<IProfileClientsListProps> = (): JSX.Element => {

  const {
    userData,
    clientsServedPageNumber,
    clientsServedPageSize,
    clientsServedTotalDocs,
    clientsServedData,
    isClientsLoading
  } = useSelector((state: AppStateType) => state.account);

  const [loader, setLoader] = useState<boolean>(true);
  const [paramsData, setParamsData] = useState<FilterProfileClientsType | null>({
    page_size: clientsServedPageSize,
    page: clientsServedPageNumber,
  });
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    return () => {
      dispatch(getClientsServedPageNumber(1));
      dispatch(getClientsServedPageSize(10));
    };
  }, []);

  useEffect(() => {
    if (userData?.sub) {
      setLoadingData(true);
      dispatch(getClientsServedData(userData?.sub, paramsData))
        .finally(() => {
          setLoadingData(false);
        });
      setLoader(false);
    }
  }, [dispatch, paramsData?.page, paramsData?.page_size, paramsData?.order_by]);

  // логика пагинации страницы
  const pageSizeOption: number[] = [5, 10, 15]; //варианты кол-ва отображаемых сущностей в таблице
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(pageSizeOption[1]);

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getClientsServedPageNumber(newPage));
    setParamsData({
      ...paramsData,
      page: newPage,
    });
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    dispatch(getClientsServedPageSize(newPageSize));
    setCurrentPage(1);
    dispatch(getClientsServedPageNumber(1));
    setParamsData({
      ...paramsData,
      page: 1,
      page_size: newPageSize,
    });
  };

  useEffect(() => {
    setPageSize(clientsServedPageSize);
    setCurrentPage(clientsServedPageNumber);
  }, []);

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setParamsData);

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const profileClientsListColumns: ColumnsType<ProfileClientsResultsType> = [
    {
      title: tableTitle("Дата выдачи"),
      dataIndex: "created_at",
      width: "20%",
      className: css.tableHeader,
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "created_at"),
      }),
      render: (text) => (
        <span className={`${css.tableCell} pl-6`}>
          {renderDate(text)}
        </span>
      )
    },
    {
      title: tableTitle("Дата окончания"),
      dataIndex: ["fields", "service_expired"],
      width: "20%",
      className: css.tableHeader,
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "service_expired"),
      }),
      render: (text) => (
        <span className={css.tableCell}>
          {renderDate(text)}
        </span>
      )
    },
    {
      title: tableTitle("Клиент"),
      dataIndex: ["fields", "name"],
      width: "60%",
      className: css.tableHeader,
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "name"),
      }),
      render: (text) => (
        <span className={css.tableCell}>
          {text}
        </span>
      ),
    },
  ];

  return (
    !isClientsLoading && !loader ? (
      clientsServedData?.length ? (
        <div className="bg-white">
          <h3 className="pt-4 pb-4 pr-6 pl-6 text-xl font-medium">
            Машиночитаемые доверенности
          </h3>
          <Table
            bordered={false}
            className="mb-0"
            columns={profileClientsListColumns}
            dataSource={clientsServedData ? clientsServedData : []}
            loading={isLoadingData}
            pagination={false}
            showSorterTooltip={false}
          />
          <div className="mb-10">
            <PaginationCustom
              total={clientsServedTotalDocs ? clientsServedTotalDocs : 0}
              showTotal={(total: number, range: number[]): string =>
                `${range[0]}-${range[1]} из ${total}`
              }
              currentPage={currentPage}
              defaultPageSize={10}
              pageSize={pageSize}
              defaultCurrent={1}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
      ) : ( <ZeroClients/> )
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default ProfileClientsList;
