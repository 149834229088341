import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table, TableColumnsType } from "antd";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { roleResolver } from "../../../../utils/roleResolver";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { JWTPayload } from "jose";
import { AppDispatch } from "../../../../store/store";
import { getContractorsList } from "../../../../actions/alpha/contractors.actions";
import {
  AlphaListParamsType,
  ContractorType
} from "app/types";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import css from "./ContractorsListing.module.css";

interface ContractorDataType {
  key: React.ReactNode;
  inn: string;
  name: string;
}

interface IContractorsListingProps {
}

const ContractorsListing: FC<IContractorsListingProps> = (): JSX.Element => {
  const [contractorsListParams, setContractorsListParams] = useState<AlphaListParamsType | null>( {
    page: 1,
    page_size: 5,
  });

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    contractorsList,
    contractorsPage,
    contractorsPageSize,
    contractorsTotalDocs,
  } = useSelector((state: AppStateType) => state.contractors);

  const isAdministrator: boolean = roleResolver(decodedToken).isAdmin;

  useEffect(() => {
    dispatch(getContractorsList(contractorsListParams));
  }, [contractorsListParams]);

  const handleContractorsPageChange = (newPage: number): void => {
    setContractorsListParams({
      ...contractorsListParams,
      page: newPage,
    });
  };

  const handleContractorsPageSizeChange = (newPageSize: number): void => {
    setContractorsListParams({
      ...contractorsListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const renderTableLink = (text: string, record: ContractorDataType) => (
    <Link
      className={css.tableCell}
      to={`/alpha/contractor/${record?.key}`}
    >
      {text}
    </Link>
  );

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setContractorsListParams);

  // Закомментируем логику индикатора цвета дедлайна
  // Пока поле не добавят в сущность контрагента
  // const checkServiceStatus = (
  //   date: string | undefined
  // ): "customError" | "customWarning" | "customSuccess" | "customDraft" | null => {
  //   if (date) {
  //  const expirationDate: Date = new Date(date);
  //  const today: Date = new Date();
  //  const daysUntilExpired: number = Math.floor((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  //  if (daysUntilExpired < 30) {
  //    return "customError";
  //  } else if (daysUntilExpired === 30) {
  //    return "customDraft";
  //  } if (daysUntilExpired <= 60) {
  //    return "customWarning";
  //  } else {
  //    return "customSuccess";
  //  }
  //   }
  //
  //   return null;
  // };

  const contractorsColumns: TableColumnsType<ContractorDataType> = [
    {
      title: (
        <div className="table-title">
          Организация
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "name",
      width: "60%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "name"),
      }),
      render: (text: string, record: ContractorDataType) => renderTableLink(text, record),
    },
    {
      title: (
        <div className="table-title">
          ИНН
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "inn",
      width: "20%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "inn"),
      }),
      render: (text: string, record: ContractorDataType) => renderTableLink(text, record),
    },
    {
      title: (
        <div className="table-title">
          Срок действия договора
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "contract_time",
      width: "20%",
      // Закомментируем логику отрисовки дедлайна
      // Пока поле не добавят в сущность контрагента
      // render: (_: string, record: ContractorsListType) =>
      //   <div className={`${css.tableCell} flex items-center gap-[12px]`}>
      //     <div>{record?.contract_time}</div>
      //     <TagCustom
      //       color={checkServiceStatus(record?.deadline)}
      //       text={deadlineDays(record?.deadline) as string}
      //     />
      //   </div>
    },
  ];

  const contractorsData: ContractorDataType[] = contractorsList?.map(({ fields }) => {
    const {
      inn,
      uuid,
      name,
    } = fields as ContractorType;

    return {
      key: uuid,
      name: name,
      inn: inn,
    };
  });

  const openCreateContractorForm = (): void => {
    navigate("/alpha/create_contractor");
  };

  return (
    <div className={css.container}>
      <div className="flex justify-between items-center">
        <h1 className={css.containerHeader}>
          Контрагенты
        </h1>
        {isAdministrator && (
          <ButtonCustom
            className="mb-[18px]"
            type="primary"
            size="large"
            text="Добавить контрагента"
            onClick={openCreateContractorForm}
          />
        )}
      </div>
      <div className="p-[16px] mb-3 bg-white rounded-[8px]">
        <Input
          className="w-[100%]"
          placeholder="ФИО контрагента, название организации, ИНН"
          suffix={<SearchOutlined style={{ opacity: .5}} />}
          size="large"
          allowClear
          onChange={(e) => setContractorsListParams(
            {
              ...contractorsListParams,
              search: e.target.value
            }
          )}
        />
      </div>
      <Table
        columns={contractorsColumns}
        dataSource={contractorsData}
        pagination={false}
      />
      <PaginationCustom
        className={css.paginationShowTotal}
        total={contractorsTotalDocs}
        showTotal={(total: number, range: number[]): string =>
          `${range[0]}-${range[1]} из ${total}`
        }
        pageSize={contractorsPageSize}
        currentPage={contractorsPage}
        defaultCurrent={1}
        defaultPageSize={10}
        handlePageChange={handleContractorsPageChange}
        handlePageSizeChange={handleContractorsPageSizeChange}
      />
    </div>
  );
};

export default ContractorsListing;
