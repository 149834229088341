import axios from "../../utils/axios";
import { AlphaListParamsType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_TASKS_URL;

//ручка получения списка вакансий
export const getVacancies = async (params?: AlphaListParamsType | null): Promise<any> => {
  return axios.GET(API_BASE, "api/mdm/documents/v1/d/vacancy", params);
};

//ручка получения вакансии
export const getVacancy = async (vacancyUuid?: string | null): Promise<any> => {
  return axios.GET(API_BASE, `api/mdm/documents/v1/d/vacancy/uuid/${vacancyUuid}`);
};
