import React, { FC, JSX, useEffect, useState } from "react";
import css from "./CheckOneCAccounts.module.css";
import { ClientBanksDataParamsType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { useLocation } from "react-router-dom";
import { AppStateType } from "../../../../../reducers/mainReducer";
import {
  getClientBanksDataInfo,
  getClientBanksPageNumber,
  getClientBanksPageSize,
  getClientBanksSearch,
  getClientBanksSorter
} from "../../../../../actions/client.actions";
import LoadingCustom from "../../../../ui-kit/LoadingCustom/LoadingCustom";
import ZeroSearch from "../../../../ClientsList/ZeroSearch/ZeroSearch";
import { useTableSorter } from "../../../../../utils/handleSorterTable";
import { Input, Table, TableColumnsType } from "antd";
import { PaginationCustom } from "../../../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search_icon.svg";
import ZeroAccounts from "../../../../ClientsList/ClientCard/ClientCardItem/Accounts/ZeroAccounts/ZeroAccounts";

interface AccountsTableDataType {
  key: React.Key | number;
  name: JSX.Element | string | undefined;
  bic: JSX.Element | string | undefined;
  checkingAccount: JSX.Element | string | undefined;
}

enum ListingPage {
  firstPage = 1,
}

interface ICheckOneCAccounts {
}

const CheckOneCAccounts: FC<ICheckOneCAccounts> = (): JSX.Element => {
  const [isAccountsLoading, setAccountsLoading] = useState<boolean>(false);
  const [clientsUuid, setClientsUuid] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const path: string = useLocation().pathname;

  const {
    clientBanksData,
    banksPageNumber,
    banksPageSize,
    banksSorter,
    banksSearch
  } = useSelector((state: AppStateType) => state.client);

  const [accountSearchValue, setAccountSearchValue] = useState<string>(banksSearch);
  const [currentPage, setCurrentPage] = useState<number>(banksPageNumber);
  const [pageSize, setPageSize] = useState<number>(banksPageSize);
  const [paramsData, setParamsData] = useState<ClientBanksDataParamsType | null>({
    page: banksPageNumber,
    size: banksPageSize,
    search: banksSearch,
    order_by: banksSorter,
    lookup_field: "profile_uuid",
  });

  useEffect(() => {
    const clientUuid: string = path?.split("/")?.[4];
    setClientsUuid(clientUuid);
  }, [path]);

  useEffect(() => {
    if (clientsUuid) {
      dispatch(getClientBanksDataInfo(clientsUuid, paramsData));
      dispatch(getClientBanksSorter(paramsData?.order_by ? paramsData?.order_by : "-name"));
    }
  }, [dispatch, clientsUuid, paramsData?.page, paramsData?.order_by,
    paramsData?.search, paramsData?.size, paramsData?.lookup_field]);

  useEffect(() => {
    if (accountSearchValue.trim() === "") {
      setParamsData((prev: ClientBanksDataParamsType | null) => {
        if (prev && prev.search !== null) {
          const {search, ...rest} = prev;

          return {...rest};
        } else {
          return prev;
        }
      });
    }

    const timeout: NodeJS.Timeout = setTimeout(():void => {
      if (accountSearchValue.trim() !== "") {
        setParamsData((prev: ClientBanksDataParamsType| null) => {
          const updatedParams: ClientBanksDataParamsType = {
            ...prev,
            search: accountSearchValue.trim()
          };

          return updatedParams;
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [accountSearchValue, setParamsData]);

  useEffect(() => {
    setAccountsLoading(true);

    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setAccountsLoading(false);
    }, 1000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [accountSearchValue]);

  const emptyDescription = (): JSX.Element => {
    if (isAccountsLoading) {
      return (
        <div className="text-center">
          <LoadingCustom/>
        </div>
      );
    }

    return accountSearchValue ? <ZeroSearch/> : <ZeroAccounts/>;
  };

  const onAccountSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAccountSearchValue(e.target.value);
    setCurrentPage(ListingPage.firstPage);
    dispatch(getClientBanksPageNumber(ListingPage.firstPage));
    dispatch(getClientBanksSearch(e.target.value));
  };

  const accountsTableData: AccountsTableDataType[] = clientBanksData?.map(({
    bic,
    name,
    acc,
  }, index: number) => {
    return {
      key: index,
      name: name,
      bic: bic,
      checkingAccount: acc,
    };
  }) ?? [];

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter("-name", setParamsData);

  const accountsTableColumns: TableColumnsType<AccountsTableDataType> = [
    {
      title: (
        <span className="table-title">
        Наименование банка
      </span>
      ),
      dataIndex: "name",
      sorter: true,
      showSorterTooltip: false,
      width: "35%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "name"),
      }),
    },
    {
      title: (
        <span className="table-title">
        Бик
      </span>
      ),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      sortIcon: (): null => null,
      dataIndex: "bic",
      width: "30%",
    },
    {
      title: (
        <span className="table-title">
        Расчетный счет
      </span>
      ),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      sortIcon: (): null => null,
      dataIndex: "checkingAccount",
      width: "35%",
    }
  ];

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getClientBanksPageNumber(newPage));
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    dispatch(getClientBanksPageSize(newPageSize));
    setCurrentPage(ListingPage.firstPage);
    dispatch(getClientBanksPageNumber(ListingPage.firstPage));
  };

  useEffect(() => {
    setPageSize(banksPageSize);
    setCurrentPage(banksPageNumber);
  }, []);

  useEffect(() => {
    setParamsData((prevParamsData: ClientBanksDataParamsType | null) => {
      const newParams: ClientBanksDataParamsType = {
        ...prevParamsData,
        size: banksPageSize,
        page: banksPageNumber,
      };

      return newParams;
    });
  }, [banksPageSize, banksPageNumber]);

  return (
    <div className={css.accountsContainer}>
      <Input
        className={css.accountsSearchInput}
        suffix={<SearchIcon className={css.accountsSearchIcon} />}
        placeholder="Наименование банка, БИК или расчетный счет"
        value={accountSearchValue}
        onChange={onAccountSearchChange}
      />
      <Table
        columns={accountsTableColumns}
        dataSource={accountsTableData}
        pagination={false}
        locale={{ emptyText:emptyDescription() }}
      />
      {accountsTableData.length ? (
        <PaginationCustom
          className={css.paginationShowTotal}
          currentPage={currentPage}
          defaultPageSize={banksPageSize}
          pageSize={pageSize}
          defaultCurrent={banksPageNumber}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <div className={css.noBanks}/>
      )}
    </div>
  );
};

export default CheckOneCAccounts;
