import React, {
  FC,
  Key,
  JSX,
  useState,
  useEffect,
  ChangeEvent,
} from "react";
import {
  Card,
  Input,
  Table,
  Select,
  DatePicker,
  notification,
  DatePickerProps,
  TableColumnsType,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import {
  UserListingType,
  CreateReportDataType,
  DatePeriodPickerType,
  UsersListingFilterType,
  ClientsListingFieldsType,
  ReportsCreationFilterType,
  ReportsOrganisationsListingFilterType,
} from "app/types";
import { RangePickerProps } from "antd/es/date-picker";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { getClientsData } from "../../../actions/client.actions";
import { AppStateType } from "../../../reducers/mainReducer";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import { getUserRoleInfo, getUserRoleListData, getUsersListingData } from "../../../actions/account.actions";
import {
  createNewReport,
  createReportFailure,
  setReportsCreationParams,
} from "../../../actions/reports.action";
import DeadLine from "../../../utils/DeadLine";
import css from "./ReportCreation.module.css";
import { TableRowSelection } from "antd/es/table/interface";
import dayjs, { Dayjs } from "dayjs";
import EmptyList from "../../ui-kit/EmptyList/EmptyList";
import debounce from "debounce";
import { useTableSorter } from "../../../utils/handleSorterTable";
import { getMonthWord } from "utils/ClientsItems/getMonthWord";

interface IReportCreationProps {
}

interface OrganisationTableDataType {
  key: React.Key;
  organisation: JSX.Element | string;
  period: JSX.Element | string;
  inn: JSX.Element | string;
  region: JSX.Element | string;
}

interface EmployeeTableDataType {
  key: React.Key;
  name: JSX.Element | string;
  role: JSX.Element | string | undefined;
}

const employeeColumns: TableColumnsType<EmployeeTableDataType> = [
  {
    title: (
      <div className="table-title">
        Имя
      </div>
    ),
    dataIndex: "name",
    width: "70%",
  },
  {
    title: (
      <div className="table-title">
        Роль
      </div>
    ),
    dataIndex: "role",
    width: "30%",
  }
];

const ReportCreation: FC<IReportCreationProps> = (): JSX.Element => {
  const {
    reportsCreationParams,
    createReportStatus,
  } = useSelector((state: AppStateType) => state.reports);

  const {
    clientsData,
    totalDocs: organisationTotalDocs,
  } = useSelector((state: AppStateType) => state.client);

  const {
    decodedToken,
    usersListing,
    usersListingCurrentPage,
    usersListingPageSize,
    usersListingTotalDocs,
    userRoleList,
  } = useSelector((state: AppStateType) => state.account);

  const [reportType, setReportType] = useState<string>(reportsCreationParams?.report_type
    ? reportsCreationParams?.report_type
    : "Отчет по задачам"
  );
  const [opfType, setOpfType] = useState<string | null>(
    reportsCreationParams?.organizational_form
      ? reportsCreationParams?.organizational_form
      : null
  );
  const [employeeType, setEmployeeType] = useState<string | null>(
    reportsCreationParams?.selected_users_role
      ? reportsCreationParams?.selected_users_role
      : null
  );
  const [reportPeriod, setReportPeriod] = useState<string>(
    reportsCreationParams?.reporting_period
      ? reportsCreationParams?.reporting_period
      : "Произвольный"
  );
  const [periodPickerType, setPeriodPickerType] = useState<DatePeriodPickerType>("year");
  const [periodPickerPlaceholder, setPeriodPickerPlaceholder] = useState<string>("Выберите год");
  const [searchCompanyValue, setSearchCompanyValue] = useState<string | null>(
    reportsCreationParams?.selected_client_name
      ? reportsCreationParams?.selected_client_name
      : null
  );
  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string | null>(
    reportsCreationParams?.selected_users_name
      ? reportsCreationParams?.selected_users_name
      : null
  );
  const [organisationCurrentPage, setOrganisationCurrentPage] = useState<number>(
    reportsCreationParams?.selected_clients_page
      ? reportsCreationParams?.selected_clients_page
      : 1
  );
  const [organisationPageSize, setOrganisationPageSize] = useState<number>(
    reportsCreationParams?.selected_clients_page_size
      ? reportsCreationParams?.selected_clients_page_size
      : 5
  );
  const [employeesCurrentPage, setEmployeesCurrentPage] = useState<number>(
    reportsCreationParams?.selected_users_page
      ? reportsCreationParams?.selected_users_page
      : 1
  );
  const [employeesPageSize, setEmployeesPageSize] = useState<number>(
    reportsCreationParams?.selected_users_page_size
      ? reportsCreationParams?.selected_users_page_size
      : 5
  );
  const [selectedOrganisations, setSelectedOrganisations] = useState<Key[]>(
    reportsCreationParams?.selected_clients
      ? reportsCreationParams?.selected_clients
      : []
  );
  const [selectedEmployees, setSelectedEmployees] = useState<Key[]>(
    reportsCreationParams?.selected_users
      ? reportsCreationParams?.selected_users
      : []
  );
  const [isGenerateReportDisabled, setGenerateReportDisabled] = useState<boolean>(false);
  const [firstPeriodDate, setFirstPeriodDate] = useState<string | null>(
    reportsCreationParams?.reporting_first_period_date
      ? reportsCreationParams?.reporting_first_period_date
      : null
  );
  const [secondPeriodDate, setSecondPeriodDate] = useState<string | null>(
    reportsCreationParams?.reporting_second_period_date
      ? reportsCreationParams?.reporting_second_period_date
      : null
  );
  const [date, setDate] = useState<DatePickerProps["value"] | RangePickerProps["value"]>(
    reportsCreationParams?.reporting_date
      ? reportsCreationParams?.reporting_date
      : undefined
  );
  const [dateString, setDateString] = useState<string | null>(
    reportsCreationParams?.reporting_date_string
      ? reportsCreationParams?.reporting_date_string
      : null
  );
  const [organizationSorter, setOrganizationsSorter] = useState<ReportsCreationFilterType | null>(null);
  const [generateNewReport, setGenerateNewReport] = useState<boolean>(false);
  const [isEmployeeDebouncing, setEmployeeDebouncing] = useState<boolean>(false);
  const [isOrganisationDebouncing, setOrganisationDebouncing] = useState<boolean>(false);
  const [isLoadingOrganisation, setLoadingOrganisation] = useState<boolean>(false);
  const [isLoadingEmployee, setLoadingEmployee] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const { Option } = Select;

  const { RangePicker } = DatePicker;

  const createReportNotificationSuccess = (): void => {
    notification.success({
      message: "Новый отчет успешно создан",
    });
  };

  const createReportNotificationFailure = (): void => {
    notification.error({
      message: "Ошибка создания отчета",
    });
  };

  useEffect(() => {
    if (createReportStatus === 200) {
      createReportNotificationSuccess();
      dispatch(createReportFailure());
    }

    if (!!createReportStatus && createReportStatus !== 200 && createReportStatus !== 0) {
      createReportNotificationFailure();
      dispatch(createReportFailure());
    }
  }, [createReportStatus]);

  const fetchClientData = (params: ReportsOrganisationsListingFilterType): void => {
    setOrganisationDebouncing(false);
    getClientsData(params);
  };

  const fetchEmployeesData = (params: UsersListingFilterType): void => {
    setEmployeeDebouncing(false);
    getUsersListingData(params);
  };

  // Фильтры для таблицы клиентов
  useEffect(() => {
    setLoadingOrganisation(true);

    const params: ReportsOrganisationsListingFilterType = {
      page: organisationCurrentPage,
      page_size: organisationPageSize,
      opf: opfType,
      client_employees: searchCompanyValue?.trim()?.length ? searchCompanyValue?.trim() : null,
      order_by: organizationSorter?.order_by,
    };

    !isOrganisationDebouncing
      ? dispatch(getClientsData(params))
          .finally(() => {
            setLoadingOrganisation(false);
          })
      : setLoadingOrganisation(true);
        dispatch(debounce(() => fetchClientData(params), 1000));
  }, [
    opfType,
    organisationPageSize,
    organisationCurrentPage,
    isOrganisationDebouncing,
    organizationSorter?.order_by
  ]);

  // Фильтры для таблицы сотрудников
  useEffect(() => {
    setLoadingEmployee(true);

    const params: UsersListingFilterType = {
      name: searchEmployeeValue?.trim()?.length ? searchEmployeeValue?.trim() : null,
      page_size: employeesPageSize,
      page: employeesCurrentPage,
      role: employeeType,
    };

    !isEmployeeDebouncing
      ? dispatch(getUsersListingData(params))
        .finally(() => {
          setLoadingEmployee(false);
        })
      : setLoadingEmployee(true);
        dispatch(debounce(() => fetchEmployeesData(params), 1000));
  }, [dispatch, employeesPageSize, employeesCurrentPage, employeeType, isEmployeeDebouncing]);

  useEffect(() => {
    const newParams: ReportsCreationFilterType = {
      ...reportsCreationParams,
      selected_users: selectedEmployees,
      selected_users_page: employeesCurrentPage,
      selected_users_role: employeeType,
      selected_users_name: searchEmployeeValue,
      selected_users_page_size: employeesPageSize,
      reporting_date: date,
      reporting_period: reportPeriod,
      organizational_form: opfType,
      selected_clients: selectedOrganisations,
      selected_client_name: searchCompanyValue,
      selected_clients_page: organisationCurrentPage,
      selected_clients_page_size: organisationPageSize,
      reporting_date_string: dateString,
      reporting_first_period_date: firstPeriodDate,
      reporting_second_period_date: secondPeriodDate,
      report_type: reportType,
    };

    dispatch(setReportsCreationParams(newParams));
  }, [
    date,
    opfType,
    dateString,
    reportType,
    employeeType,
    reportPeriod,
    firstPeriodDate,
    secondPeriodDate,
    employeesPageSize,
    selectedEmployees,
    searchCompanyValue,
    searchEmployeeValue,
    employeesCurrentPage,
    selectedOrganisations,
    organisationPageSize,
    organisationCurrentPage,
  ]);

  const handleOrganisationPageChange = (newPage: number): void => {
    setOrganisationCurrentPage(newPage);
  };

  const handleOrganisationPageSizeChange = (newPageSize: number): void => {
    setOrganisationPageSize(newPageSize);
    setOrganisationCurrentPage(1);
  };

  const handleEmployeesPageChange = (newPage: number): void => {
    setEmployeesCurrentPage(newPage);
  };

  const handleEmployeesPageSizeChange = (newPageSize: number): void => {
    setEmployeesPageSize(newPageSize);
    setEmployeesCurrentPage(1);
  };

  const handleReportTypeChange = (changedReportType: string): void => {
    setReportType(changedReportType);
  };

  const handleReportPeriodTypeChange = (reportPeriodType: string): void => {
    if (reportPeriodType === "Произвольный") {
      setDate(null);
      setDateString(null);
      setFirstPeriodDate(null);
      setSecondPeriodDate(null);
    }

    setReportPeriod(reportPeriodType);
  };

  const handleReportPeriodDateChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string[] | string
  ): void => {
    setDate(value);

    if (Array.isArray(dateString)) {
      setDateString(null);
      setFirstPeriodDate(dateString?.[0]);
      setSecondPeriodDate(dateString?.[1]);
    } else {
      setDateString(dateString);
      setFirstPeriodDate(null);
      setSecondPeriodDate(null);
    }
  };

  useEffect(() => {
    switch (reportPeriod) {
      case "Год":
        setPeriodPickerType("year");
        setPeriodPickerPlaceholder("Выберите год");
        break;
      case "Месяц":
        setPeriodPickerType("month");
        setPeriodPickerPlaceholder("Выберите месяц");
        break;
      case "Квартал":
        setPeriodPickerType("quarter");
        setPeriodPickerPlaceholder("Выберите квартал");
        break;
      case "Произвольный":
        setPeriodPickerType("date");
        setPeriodPickerPlaceholder("Выберите период");
        break;
    }
  }, [reportPeriod]);

  const handleOPFTypeChange = (changedOPFType: string): void => {
    setOrganisationCurrentPage(1);
    setOpfType(changedOPFType);
  };

  const handleEmployeeTypeChange = (employeeType: string): void => {
    setEmployeesCurrentPage(1);
    setEmployeeType(employeeType);
  };

  const onCompanySearch = (event: ChangeEvent<HTMLInputElement>) => {
    setOrganisationDebouncing(true);
    setOrganisationCurrentPage(1);
    setSearchCompanyValue(event.target.value);
  };

  const onEmployeeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setEmployeeDebouncing(true);
    setEmployeesCurrentPage(1);
    setSearchEmployeeValue(event.target.value);
  };

  // Получаем данные из строк таблицы клиентов
  const onSelectOrganisationChange = (newSelectedRowKeys: Key[]) => {
    setSelectedOrganisations(newSelectedRowKeys);
  };

  const rowOrganisationSelection: TableRowSelection<OrganisationTableDataType> | undefined = {
    selectedRowKeys: selectedOrganisations,
    onChange: onSelectOrganisationChange,
    defaultSelectedRowKeys: selectedOrganisations,
    preserveSelectedRowKeys: true,
  };

  const hasSelectedOrganisations: boolean = selectedOrganisations.length > 0;

  const organisationData: OrganisationTableDataType[] = clientsData
  ?.map(({ fields }: { fields: ClientsListingFieldsType }, index: number) => {
    return {
      key: fields?.uuid,
      organisation: (
        fields?.short_name?.length
          ? fields?.short_name
          : fields?.name
      ),
      period: (
        <div key={index} className="flex">
          <span>
            {getMonthWord(fields?.accounting_restore_months)}
          </span>
          <span className={css.reportTableSubrow}>
            {DeadLine(fields?.service_expired ?? "-", true)}
          </span>
        </div>
      ),
      inn: fields?.inn,
      region: fields?.region ?? "-",
    };
  }) ?? [];
  
  const [handleSorter] = useTableSorter(null, setOrganizationsSorter);
  
  const tableTitle = (text: string): JSX.Element => (
    <div className="table-title">
      {text}
    </div>
  );
  
  const tableHeaderCell = (sortKey: string) => (
  ) => ({ onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, sortKey), });
  
  const organisationColumns: TableColumnsType<OrganisationTableDataType> = [
    {
      title: tableTitle("Организация"),
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: tableHeaderCell("name"),
      dataIndex: "organisation",
      width: "35%"
    },
    {
      title: tableTitle("Срок обслуживания"),
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: tableHeaderCell("client_tariff.service_life_months"),
      dataIndex: "period",
      width: "20%",
    },
    {
      title: tableTitle("ИНН"),
      dataIndex: "inn",
      width: "15%",
    },
    {
      title: tableTitle("Регион обслуживания"),
      dataIndex: "region",
      render: (text: string) => <a>{text}</a>,
      width: "30%",
    }
  ];

  // Получаем данные из строк таблицы сотрудников
  const onSelectEmployeesChange = (newSelectedRowKeys: Key[]) => {
    setSelectedEmployees(newSelectedRowKeys);
  };

  const rowEmployeeSelection: TableRowSelection<EmployeeTableDataType> | undefined = {
    selectedRowKeys: selectedEmployees,
    onChange: onSelectEmployeesChange,
    defaultSelectedRowKeys: selectedEmployees,
    preserveSelectedRowKeys: true,
  };

  useEffect(() => {
    dispatch(getUserRoleListData());
  }, []);

  const usersRoles = {
    "accountant-role": userRoleList?.find(i => i.name === "accountant-role"),
    "contact-center-role": userRoleList?.find(i => i.name === "contact-center-role"),
  };

  useEffect(() => {
    if (employeeType) {
      dispatch(getUserRoleInfo(employeeType));
    }
  }, [employeeType]);

  const hasSelectedEmployees: boolean = selectedEmployees.length > 0;

  const userAccountRole = (roles: string[]): string => {
    if (roles?.includes("accountant-role")) return "Бухгалтер";
    if (roles?.includes("assistant-role")) return "Бухгалтер";
    if (roles?.includes("contact-center-role")) return "Сотрудник контакт-центра";

    return "";
  };

  const employeeData: EmployeeTableDataType[] = usersListing?.map(({
    id,
    roles,
    firstName,
    lastName,
  }: UserListingType) => {
    return {
      key: id,
      name: `${firstName} ${lastName}`,
      role: userAccountRole(roles),
    };
  }) ?? [];

  // Проверка на дизейбл кнопки формирования отчета
  useEffect(() => {
    const hasDataToGenerateReport: boolean = !!firstPeriodDate || !!secondPeriodDate || !!dateString || !!date;

    setGenerateReportDisabled(!hasDataToGenerateReport);
  }, [date, dateString, firstPeriodDate, secondPeriodDate]);

  // Логика создания нового отчета
  useEffect(() => {
    const firstDate: string | null = firstPeriodDate?.split(".")?.reverse()?.join("-") ?? null;
    const secondDate: string | null = secondPeriodDate?.split(".")?.reverse()?.join("-") ?? null;
    const employeesArr: string[] = selectedEmployees?.length
      ? selectedEmployees?.toString()?.split(",")
      : [];
    const organisationsArr: string[] = selectedOrganisations?.length
      ? selectedOrganisations?.toString()?.split(",")
      : [];

    if (generateNewReport) {
      const newReportData: CreateReportDataType = {
        date_end: periodPickerType === "date" ? secondDate : null,
        created_by: decodedToken?.sub,
        date_start: periodPickerType === "date" ? firstDate : null,
        report_type: reportType,
        period_type: periodPickerType === "date" ? null : periodPickerType,
        period_value: dateString ? dateString?.replace("Q", "0") : null,
        client_uuids: organisationsArr,
        responsible_users_uuids: employeesArr,
      };

      dispatch(createNewReport(newReportData));
      setGenerateNewReport(false);
    }
  }, [
    dateString,
    reportType,
    decodedToken,
    firstPeriodDate,
    secondPeriodDate,
    periodPickerType,
    generateNewReport,
    selectedEmployees,
    selectedOrganisations,
  ]);

  const generateReport = () => {
    setGenerateNewReport(true);
  };
  
  const emptyOrganisation = (): JSX.Element => (
    <>
      {!isLoadingOrganisation && !!searchCompanyValue && <EmptyList activeKey="clients" className="h-[35vh]" />}
      {!isLoadingOrganisation && !searchCompanyValue && <EmptyList activeKey="noClients" className="h-[35vh]" />}
    </>
  );
  
  const emptyEmployee = (): JSX.Element => (
    <>
      {!isLoadingEmployee && !!searchEmployeeValue && <EmptyList activeKey="employees" className="h-[35vh]" />}
      {!isLoadingEmployee && !searchEmployeeValue && <EmptyList activeKey="noEmployees" className="h-[35vh]" />}
    </>
  );

  return (
    <div>
      <Card className="mb-3">
        <h2 className={css.createReportHeader}>
          Создание нового отчета
        </h2>
        <span className={css.createReportDescr}>
          Выберите необходимые параметры для формирования отчета
        </span>
        <div className="mb-[25px]">
          <span className={css.reportTypeHeader}>
            Тип отчета
          </span>
          <Select
            className="w-full max-w-[400px]"
            value={reportType}
            placeholder="Выберите тип отчета"
            defaultActiveFirstOption
            onChange={handleReportTypeChange}
            size="large"
          >
            <Option key="Отчет по задачам" value="Отчет по задачам">
              Отчет по задачам
            </Option>
            <Option key="Отчет по клиентам" value="Отчет по клиентам">
              Отчет по клиентам
            </Option>
          </Select>
        </div>
        <div>
          <span className={css.reportTypeHeader}>
            Отчетный период
          </span>
          <Select
            className="mb-3 w-full max-w-[400px]"
            value={reportPeriod}
            placeholder="Выберите период"
            defaultActiveFirstOption
            onChange={handleReportPeriodTypeChange}
            size="large"
          >
            <Option key="Год" value="Год">
              Год
            </Option>
            <Option key="Квартал" value="Квартал">
              Квартал
            </Option>
            <Option key="Месяц" value="Месяц">
              Месяц
            </Option>
            <Option key="Произвольный" value="Произвольный">
              Произвольный
            </Option>
          </Select>
          <div>
            {reportPeriod !== "Произвольный" ? (
              <DatePicker
                className="w-full max-w-[400px]"
                picker={periodPickerType}
                placeholder={periodPickerPlaceholder}
                defaultValue={date}
                onChange={(value, dateString) => handleReportPeriodDateChange(value, dateString)}
                size="large"
                allowClear
                inputReadOnly={false}
                disabledDate={(current: Dayjs) => {
                  return dayjs().isBefore(current);
                }}
              />
            ) : (
              <RangePicker
                className="w-full max-w-[400px]"
                placeholder={["Дата начала", "Дата окончания"]}
                size="large"
                defaultValue={date as any}
                onChange={(value, dateString) => handleReportPeriodDateChange(value, dateString)}
                format="DD.MM.YYYY"
                allowClear
                inputReadOnly={false}
                disabledDate={(current: Dayjs) => {
                  return dayjs().isBefore(current);
                }}
              />
            )}
          </div>
        </div>
      </Card>
      <Card className="mb-3">
        <h2 className={css.createReportHeader}>
          Выбор клиентов и сотрудников
        </h2>
        <span className={css.createReportDescr}>
          Дополнительно вы можете выбрать клиентов и сотрудников
        </span>
        <div className="mb-[25px]">
          <span className={css.reportTypeHeader}>
            Выберите клиентов
          </span>
          <div className="flex items-center justify-between mb-3">
            {hasSelectedOrganisations && (
              <div className={`flex items-center ${hasSelectedOrganisations ? "mr-1.5" : ""}`}>
                <ButtonCustom
                  className="mr-3"
                  text="Сбросить"
                  type="primary"
                  size="large"
                  ghost
                  disabled={!hasSelectedOrganisations}
                  onClick={() => setSelectedOrganisations([])}
                />
                <span className="w-[150px]">
                  Выбрано {selectedOrganisations.length} клиент(ов)
                </span>
              </div>
            )}
            <Select
              className="w-full max-w-[400px] mr-3"
              value={opfType}
              placeholder="Выберите ОПФ"
              defaultActiveFirstOption
              onChange={handleOPFTypeChange}
              size="large"
              onClear={() => setOpfType(null)}
              allowClear
            >
              <Option key="ООО" value="ООО">
                ООО
              </Option>
              <Option key="ИП" value="ИП">
                ИП
              </Option>
            </Select>
            <Input
              className="w-full"
              placeholder="Найти клиентов по названию или ФИО"
              value={searchCompanyValue as string}
              onChange={onCompanySearch}
              suffix={<SearchOutlined />}
              size="large"
              allowClear
            />
          </div>
          <Table
            rowSelection={rowOrganisationSelection}
            columns={organisationColumns}
            dataSource={organisationData}
            loading={isLoadingOrganisation}
            pagination={false}
            locale={{ emptyText: emptyOrganisation() }}
          />
          <PaginationCustom
            className={css.paginationShowTotal}
            total={organisationTotalDocs ? organisationTotalDocs : 0}
            showTotal={(total: number, range: number[]): string =>
              `${range[0]}-${range[1]} из ${total}`
            }
            currentPage={organisationCurrentPage}
            defaultPageSize={10}
            pageSize={organisationPageSize}
            defaultCurrent={1}
            handlePageChange={handleOrganisationPageChange}
            handlePageSizeChange={handleOrganisationPageSizeChange}
          />
        </div>
        <div>
          <span className={css.reportTypeHeader}>
            Выберите сотрудников
          </span>
          <div className="flex items-center justify-between mb-3">
            {hasSelectedEmployees && (
              <div className={`flex items-center ${hasSelectedEmployees ? "mr-2.5" : ""}`}>
                <ButtonCustom
                  className="mr-3"
                  text="Сбросить"
                  type="primary"
                  size="large"
                  ghost
                  disabled={!hasSelectedEmployees}
                  onClick={() => setSelectedEmployees([])}
                />
                <span className="w-[165px]">
                  Выбрано {selectedEmployees.length} сотрудник(ов)
                </span>
              </div>
            )}
            <Select
              className="w-full max-w-[400px] mr-3"
              value={employeeType}
              placeholder="Выберите роль"
              defaultActiveFirstOption
              onChange={handleEmployeeTypeChange}
              size="large"
              onClear={() => setEmployeeType(null)}
              allowClear
            >
              <Option
                key={usersRoles?.["accountant-role"]?.name}
                value={usersRoles?.["accountant-role"]?.name}
              >
                {usersRoles?.["accountant-role"]?.description}
              </Option>
              <Option
                key={usersRoles?.["contact-center-role"]?.name}
                value={usersRoles?.["contact-center-role"]?.name}
              >
                {usersRoles?.["contact-center-role"]?.description}
              </Option>
            </Select>
            <Input
              className="w-full"
              placeholder="Найти сотрудников по ФИО"
              value={searchEmployeeValue as string}
              onChange={onEmployeeSearch}
              suffix={<SearchOutlined />}
              size="large"
              allowClear
            />
          </div>
          <Table
            rowSelection={rowEmployeeSelection}
            columns={employeeColumns}
            dataSource={employeeData}
            loading={isLoadingEmployee}
            pagination={false}
            locale={{ emptyText: emptyEmployee() }}
          />
          <PaginationCustom
            className={css.paginationShowTotal}
            total={usersListingTotalDocs ? usersListingTotalDocs : 0}
            showTotal={(total: number, range: number[]): string =>
              `${range[0]}-${range[1]} из ${total}`
            }
            currentPage={employeesCurrentPage}
            defaultPageSize={usersListingPageSize}
            pageSize={employeesPageSize}
            defaultCurrent={usersListingCurrentPage}
            handlePageChange={handleEmployeesPageChange}
            handlePageSizeChange={handleEmployeesPageSizeChange}
          />
        </div>
      </Card>
      <Card className="flex justify-end">
        <ButtonCustom
          type="primary"
          text="Сформировать отчет"
          onClick={generateReport}
          disabled={isGenerateReportDisabled}
        />
      </Card>
    </div>
  );
};

export default ReportCreation;
