import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/warning_blue_icon.svg";
import { ReactComponent as ErrorIconRed } from "../../../assets/icons/warning_circle_icon.svg";
import { Modal } from "antd";
import css from "./ClientProfileCardNotAgreed.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { finalizeClientTask } from "../../../actions/document.actions";
import { AppStateType } from "../../../reducers/mainReducer";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  AbstractDocType,
  ClientValidationErrorsDataType,
  DocumentsValidationErrorsDataType,
  DocumentTaskType,
  EmployeeSchema,
  EmployeeValidationErrorsDataType,
  StatusDataType,
} from "app/types";
import { TaskQuestionnaireCardSteps } from "../ClientProfileCard";
import {
  fetchDocumentClientProfile,
  fetchNotAgreedDocumentsProfile,
} from "../../../actions/clientProfile.actions";
import NotAgreedItem from "./NotAgreedItem/NotAgreedItem";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { getDocumentTaskFailure } from "../../../actions/tasks.actions";

interface ITaskQuestionnaireNotAgreedProps {
  closeForm: () => void;
  saveFormData: (docUuid: string, data: any) => void
  saveCompanyFormData: (docUuid: string, data: any) => void
  documentType: string;
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isImageViewerOpen: boolean;
  isValidationCompleted: boolean;
  hasNotAgreedSteps: boolean;
  clientProfileSlug: string;
  canUpdateNotAgreedDocs: boolean;
  setCanUpdateNotNotAgreedDocs: Dispatch<SetStateAction<boolean>>;
  setDocumentUuid: Dispatch<SetStateAction<string>>;
  setDocumentType: Dispatch<SetStateAction<string>>;
}

const ClientProfileCardNotAgreed: FC<ITaskQuestionnaireNotAgreedProps> = ({
  closeForm,
  saveFormData,
  saveCompanyFormData,
  documentType,
  documentUuid,
  showImageViewer,
  isImageViewerOpen,
  setCurrentStep,
  isValidationCompleted,
  hasNotAgreedSteps,
  clientProfileSlug,
  canUpdateNotAgreedDocs,
  setCanUpdateNotNotAgreedDocs,
  setDocumentUuid,
  setDocumentType,
}): JSX.Element => {
  type ValidationErrorTypeProps = StatusDataType | EmployeeValidationErrorsDataType | EmployeeSchema | string;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [documentClientProfileEmployees, setDocumentClientProfileEmployees] = useState<AbstractDocType[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);

  const {
    notAgreedDocuments,
    documentClientProfile,
  } = useSelector((state: AppStateType) => state.clientProfile);

  useEffect(() => {
    if (canUpdateNotAgreedDocs) {
      setLoading(false);
      setCanUpdateNotNotAgreedDocs(false);

      setTimeout(() =>
        dispatch(fetchNotAgreedDocumentsProfile(documentTask?.fields?.uuid ?? ""))
          .then(() => setLoading(true)), 300);

      setTimeout(() =>
        dispatch(fetchDocumentClientProfile(clientProfileSlug, documentClientProfile?.fields?.uuid ?? "")), 500);
    }
  }, [documentTask, canUpdateNotAgreedDocs, isLoading]);

  useEffect(() => {
    if (documentClientProfile?.fields?.employees?.length) {
      setDocumentClientProfileEmployees(documentClientProfile?.fields?.employees);
    } else {
      setDocumentClientProfileEmployees(documentClientProfile?.fields?.employees_profiles ?? []);
    }
  }, [documentClientProfile, documentTask, canUpdateNotAgreedDocs, isLoading]);

  const moreEmployees: boolean | null = !!documentClientProfileEmployees && documentClientProfileEmployees?.length > 1;

  //логика фильтрации данных (оставляем только несогласованные либо непроверенные данные)
  const filterApproved = (obj: null | DocumentsValidationErrorsDataType): null | DocumentsValidationErrorsDataType => {
    if (!obj) {
      return null;
    }

    const processEntity = (entity: null | unknown): null | unknown => {

      if (typeof entity === "object" && entity !== null) {
        const typedEntity = entity as Record<string, unknown>;

        const documentStatus: boolean =
          (typedEntity as StatusDataType).status?.document_status === "APPROVED" ||
          (typedEntity as StatusDataType).status?.document_status === null;

        if (documentStatus) {
          return null;
        }

        return Object.fromEntries(
          Object.entries(typedEntity)
            .map(([key, value]: [string, unknown]) => [key, processEntity(value)])
            .filter(([_, value]): boolean => value !== null
              && !(Object.keys(value as object).length === 0 && value?.constructor === Object))
        );
      }

      return entity;
    };

    return processEntity(obj) as DocumentsValidationErrorsDataType | null;
  };

  //проверка если данные пришли.
  const filterNotApprovedDoc: null | DocumentsValidationErrorsDataType =
    notAgreedDocuments ? filterApproved(notAgreedDocuments) : null;

  //логика проверки на наличие несогласованных либо не проверенных документов
  const checkDeclinedStatus = (obj: null | DocumentsValidationErrorsDataType): boolean => {
    if (!obj) {
      return false;
    }

    const hasDeclinedStatus = (entity: unknown): boolean => {
      if (typeof entity === "object" && entity !== null) {
        const typedEntity = entity as Record<string, unknown>;

        for (const value of Object.values(typedEntity)) {
          if (value && typeof value === "object") {
            const declinedDocument: boolean = (value as StatusDataType)?.status?.document_status === "DECLINED";

            if (declinedDocument) {
              return true;
            } else if (hasDeclinedStatus(value)) {
              return true;
            }
          }
        }
      }
      return false;
    };

    return hasDeclinedStatus(obj);
  };

  const checkClientType: ClientValidationErrorsDataType | undefined =
    filterNotApprovedDoc?.["client-profile-ip"]
      ? filterNotApprovedDoc?.["client-profile-ip"]
      : filterNotApprovedDoc?.["client-profile-ooo"];

  const filteredEntries: [string, ValidationErrorTypeProps][] | null =
    checkClientType ? Object.entries(checkClientType!).filter(([key, _value]: [string, ValidationErrorTypeProps]
    ): boolean => key !== "document_uuid") : null;

  const employeeProfile: [string, ValidationErrorTypeProps][] | null =
    filteredEntries ? filteredEntries.filter(([key, _value]: [string, ValidationErrorTypeProps]
    ): boolean => key == "employee-profile") : null;

  const clientProfile: [string, ValidationErrorTypeProps][] | null =
    filteredEntries ? filteredEntries.filter(([key, _value]: [string, ValidationErrorTypeProps]
    ): boolean => key !== "employee-profile") : null;

  const pageChange = (stepChange: number) => {
    setCurrentStep(stepChange);
    window.scrollTo(0, 0);
    setCanUpdateNotNotAgreedDocs(true);
  };

  const modalOk = (): void => {
    setIsModalOpen(false);
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
  };

  const modalFooter: JSX.Element = (
    <>
      <div key="modalBotton" className="border-t border-gray mt-5"/>
      <ButtonCustom
        id="returnСheck"
        className={`${css.buttonBack} mt-4`}
        key="cancel"
        size="large"
        type="default"
        text={"Вернуться к проверке"}
        onClick={modalCancel}
      />
      <ButtonCustom
        id="completeCheck"
        key="ok"
        size="large"
        text={"Завершить проверку"}
        type="primary"
        onClick={modalOk}
      />
    </>
  );

  const renderAgreement = (): JSX.Element => {
    return (
      <>
        Перед завершением задачи, пожалуйста,
        удостоверьтесь в полноте и правильности данных в анкете.
      </>
    );
  };

  const finalizeValidation = (): void => {
    navigate(`/task/${documentTask?.fields?.uuid}`);
    dispatch(finalizeClientTask(documentTask?.fields?.uuid ?? ""));
    dispatch(getDocumentTaskFailure());
  };

  const goToFirstStep = (): void => {
    setCurrentStep(TaskQuestionnaireCardSteps.OrganisationStep);
  };

  //если есть непроверенные доки у одного сотрудника, тогда покажи его
  const hasEmployeeOne = (array: [string, ValidationErrorTypeProps][] | null): boolean => {

    if (array === null) return false;

    for (const [, entity] of array) {
      if (typeof entity === "object" && entity !== null && Object.keys(entity).length > 1) {
        return true;
      }
    }

    return false;
  };

  //если есть непроверенные данные у сотрудников
  const hasMoreEmployee = (value: ValidationErrorTypeProps): boolean => {
    return Object.keys(value as EmployeeSchema).length === 0;
  };

  const clientNotAgreedItems: boolean = !!filterNotApprovedDoc && !!checkClientType && !!clientProfile?.length;

  return (
    <div>
      {checkDeclinedStatus(notAgreedDocuments) ? (
        <>
          <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
            <h1 className={`${css.headerText} mb-2`}>
              Не согласованные пункты анкеты
            </h1>
            <div className={css.textBelow}>
              Если вы уверены в обоснованности отказов, перечисленных ниже, завершите процесс валидации.
              В случае ошибки измените свое решение через окно проверки файла
            </div>
          </div>
          {isLoading ?
            (
              <div className={`${css.notApproved} mb-3 border-bottom-gray`}>
                {clientNotAgreedItems && (
                  <div className={`${css.container} p-5 bg-white border-bottom-gray`}>
                    {clientProfile?.map(([key, value]: [string, ValidationErrorTypeProps], index: number) => {
                      return (
                        value && (
                          <NotAgreedItem
                            key={index}
                            index={index}
                            label={key}
                            closeForm={closeForm}
                            saveCompanyFormData={saveCompanyFormData}
                            documentType={documentType}
                            documentUuid={documentUuid}
                            showImageViewer={showImageViewer}
                            isImageViewerOpen={isImageViewerOpen}
                            unAgreedClause={value as StatusDataType}
                            isClient={true}
                            isValidationCompleted={isValidationCompleted}
                            isCompany
                            isNotAgreedDocs={true}
                            setCanUpdateNotNotAgreedDocs={setCanUpdateNotNotAgreedDocs}
                          />
                        )
                      );
                    })}
                  </div>
                )
                }
                {!moreEmployees ? (
                  hasEmployeeOne(employeeProfile)
                  && employeeProfile?.map(([_key, value]: [string, ValidationErrorTypeProps], index: number) => {
                    return (
                      value && (
                        <div key={index} className={`${css.container} p-5 bg-white border-bottom-gray`}>
                          <div className={`${css.headerTextEmployee} mb-8`}>
                            Сотрудник только один
                          </div>
                          {/*убрать логику фильтрации как только на бэке уберут document_uuid*/}
                          {Object.entries(value)
                            .filter(([key, _value]: [string, ValidationErrorTypeProps]
                              ): boolean => key !== "document_uuid")
                            .map(([key, value]: [string, ValidationErrorTypeProps], index: number
                          ) => {
                            return (
                              <NotAgreedItem
                                key={index}
                                index={index}
                                label={key}
                                closeForm={closeForm}
                                saveFormData={saveFormData}
                                documentType={documentType}
                                documentUuid={documentUuid}
                                showImageViewer={showImageViewer}
                                isImageViewerOpen={isImageViewerOpen}
                                unAgreedClause={value as StatusDataType}
                                isClient={false}
                                isValidationCompleted={isValidationCompleted}
                                isNotAgreedDocs={true}
                                setDocumentUuid={setDocumentUuid}
                                setDocumentType={setDocumentType}
                                setCanUpdateNotNotAgreedDocs={setCanUpdateNotNotAgreedDocs}
                              />
                            );
                          })}
                        </div>
                      )
                    );
                  })
                ) : (
                  employeeProfile?.map(([_key, value]: [string, ValidationErrorTypeProps]) => {
                    return (
                      value && (
                        Object.entries(value)
                          .map(([_key, value]: [string, ValidationErrorTypeProps], index: number) => {
                          return (
                            !hasMoreEmployee(value) && (
                              <div key={index} className={`${css.container} p-5 bg-white border-bottom-gray`}>
                                <div className={`${css.headerTextEmployee} mt-2 mb-8`}>
                                  Сотрудник {index + 1}
                                </div>
                                {Object.entries(value)
                                  .map(([key, value]: [string, ValidationErrorTypeProps], index: number
                                ) => {
                                  return (
                                    <NotAgreedItem
                                      key={index}
                                      index={index}
                                      label={key}
                                      closeForm={closeForm}
                                      saveFormData={saveFormData}
                                      documentType={documentType}
                                      documentUuid={documentUuid}
                                      showImageViewer={showImageViewer}
                                      isImageViewerOpen={isImageViewerOpen}
                                      unAgreedClause={value as StatusDataType}
                                      isClient={false}
                                      isValidationCompleted={isValidationCompleted}
                                      isNotAgreedDocs={true}
                                      setDocumentUuid={setDocumentUuid}
                                      setDocumentType={setDocumentType}
                                      setCanUpdateNotNotAgreedDocs={setCanUpdateNotNotAgreedDocs}
                                    />
                                  );
                                })}
                              </div>
                            ));
                        })
                      )
                    );
                  })
                )}
              </div>
            ) : (
              <div className="text-center mt-10 mb-10">
                <LoadingCustom/>
              </div>
            )
          }
        </>
        ) : null}
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <div className={`flex justify-between ${css.actions} mb-3 p-7`}>
          <div className="mr-12">
            <div className={`${css.actionsHead} mb-2`}>
              <ErrorIcon className="mr-1 flex-shrink-0 w-[20px] h-[20px]"/>
              Требуется действие по анкете
            </div>
            <div className={css.actionsText}>
              Пожалуйста, проверьте корректность и подтвердите
              завершение процесса проверки анкеты клиента и его сотрудников.
            </div>
          </div>
          <ButtonCustom
            id="endValidation"
            type="primary"
            className={css.button}
            text="Завершить валидацию"
            disabled={hasNotAgreedSteps}
            onClick={finalizeValidation}
          />
        </div>
        {hasNotAgreedSteps && (
          <div className={`flex justify-between ${css.actionsError} mb-3 p-7`}>
            <div className="mr-12">
              <div className={`${css.actionsHeadError} mb-2`}>
                <ErrorIconRed className="mr-1 flex-shrink-0 w-[20px] h-[20px]" />
                Пропущен обязательный этап
              </div>
              <div className={css.actionsText}>
                Пожалуйста, вернитесь назад для проверки данных.
              </div>
            </div>
            <ButtonCustom
              id="goToFirstStep"
              type="primary"
              danger={true}
              className={css.buttonErrorOneC}
              text="Вернуться"
              onClick={goToFirstStep}
            />
          </div>
        )}
      </div>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray text-end`}>
        <ButtonCustom
          id="stepBack"
          className={css.buttonBack}
          key="cancel"
          size="large"
          type="default"
          text="Назад"
          onClick={() => pageChange(TaskQuestionnaireCardSteps.EmployeesStep)}
        />
      </div>
      <Modal
        title="Вы уверены?"
        open={isModalOpen}
        onOk={modalOk}
        onCancel={modalCancel}
        footer={modalFooter}
      >
        <p className={css.modalText}>
          {renderAgreement()}
        </p>
      </Modal>
    </div>
  );
};

export default ClientProfileCardNotAgreed;
