import { getDaysWord, getVerbForm } from "./deadlineItems";

export const deadlineDays = (data: string | undefined): string | null => {
  if (data) {
    const targetDate: Date = new Date(data);
    targetDate.setHours(23, 59, 59);
    
    const now: Date = new Date();

    if (targetDate > now) {
      const delta: number = targetDate.getTime() - now.getTime();
      const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
      const remainingDays: number = Math.round(delta / millisecondsPerDay);

      let daysWord: string = getDaysWord(remainingDays);
      const { verbForm, adjustedDays } = getVerbForm(remainingDays);

      const remainingDaysNumber: number = parseInt(remainingDays.toString().slice(-2));

      if (remainingDaysNumber >= 10 && remainingDaysNumber <= 20) {
        daysWord = "дней";
      }

      return `${verbForm} ${adjustedDays} ${daysWord}`;
    } else {
      return "срок истек";
    }
  }

  return null;
};
