export const confirmModalDescr = (taskAction: string): string => {
  const descriptions: { [key: string]: string } = {
    closeTask: "Вы уверены, что хотите завершить задачу? Это действие необратимо.",
    closeModal:
      "Вы уверены, что хотите отменить составление действия по задаче? Все введенные вами данные будут утеряны.",
    increasePriority: "Вы уверены, что хотите повысить приоритет задачи?",
  };

  return descriptions[taskAction];
};
