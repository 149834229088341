import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_NUMBER_SUCCESS,
  GET_NOTIFICATIONS_NUMBER_FAILURE,
} from "../constants/actionTypes";
import * as NotificationsAPI from "../api/notifications.api";
import { NotificationsParamsType, NotificationsType, NumberOfNotificationsType } from "app/types";

export type NotificationsActionsType =
  | GetNotificationsSuccessType
  | GetNotificationsFailureType
  | ReadNotificationsSuccessType
  | ReadNotificationsFailureType
  | GetNotificationsNumberSuccessType
  | GetNotificationsNumberFailureType;

// Логика получения уведомлений
export type GetNotificationsSuccessType = {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: {
    notifications: NotificationsType;
  };
};

const getNotificationsSuccess = (notifications: NotificationsType): GetNotificationsSuccessType => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: {
    notifications
  }
});

export type GetNotificationsFailureType = {
  type: typeof GET_NOTIFICATIONS_FAILURE;
  payload: {
    error: any;
  };
};

const getNotificationsFailure = (error: any): GetNotificationsFailureType => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: {
    error
  }
});

export const getNotificationsData = (params: null | NotificationsParamsType = null):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  NotificationsActionsType
  > => async (dispatch) => {
  try {
    const response = await NotificationsAPI.getNotifications(params);

    dispatch(getNotificationsSuccess(response.data));
  } catch (error: any) {
    dispatch(getNotificationsFailure(error.message));
  }
};

//логика получения кол-ва уведомлений
export type GetNotificationsNumberSuccessType = {
  type: typeof GET_NOTIFICATIONS_NUMBER_SUCCESS;
  payload: {
    notificationsNumber: NumberOfNotificationsType;
  };
};

const getNotificationsNumberSuccess = (
  notificationsNumber: NumberOfNotificationsType
): GetNotificationsNumberSuccessType => ({
  type: GET_NOTIFICATIONS_NUMBER_SUCCESS,
  payload: {
    notificationsNumber
  }
});

export type GetNotificationsNumberFailureType = {
  type: typeof GET_NOTIFICATIONS_NUMBER_FAILURE;
  payload: {
    error: any;
  };
};

const getNotificationsNumberFailure = (error: any): GetNotificationsNumberFailureType => ({
  type: GET_NOTIFICATIONS_NUMBER_FAILURE,
  payload: {
    error
  }
});

export const getNotificationsNumber = ():
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    NotificationsActionsType
  > => async (dispatch) => {
  try {
    const response = await NotificationsAPI.getFacetsOfNotifications();

    dispatch(getNotificationsNumberSuccess(response.data));
  } catch (error: any) {
    dispatch(getNotificationsNumberFailure(error.message));
  }
};

// Логика прочтения уведомлений
export type ReadNotificationsSuccessType = {
  type: typeof READ_NOTIFICATIONS_SUCCESS;
  payload: {
    notificationsReadStatus: any;
  };
};

const readNotificationsSuccess = (notificationsReadStatus: NotificationsType[]): ReadNotificationsSuccessType => ({
  type: READ_NOTIFICATIONS_SUCCESS,
  payload: {
    notificationsReadStatus
  }
});

export type ReadNotificationsFailureType = {
  type: typeof READ_NOTIFICATIONS_FAILURE;
  payload: {
    error: any;
  };
};

const readNotificationsFailure = (error: any): ReadNotificationsFailureType => ({
  type: READ_NOTIFICATIONS_FAILURE,
  payload: {
    error
  }
});

export const notificationsMarkRead = (uuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    NotificationsActionsType
  > => async (dispatch) => {
  try {
    const response = await NotificationsAPI.notificationRead(uuid);

    dispatch(readNotificationsSuccess(response.data));
  } catch (error: any) {
    dispatch(readNotificationsFailure(error.message));
  }
};
