export const myCustomTheme = {
  token: {
    colorPrimary: "#3438E0",
    fontFamily: "HelveticaNeueCyr",
  },
  components: {
    Button: {
      colorBgContainerDisabled: "#67768C26",
    },
    Menu: {
      itemActiveBg: "rgba(243, 244, 246, 0.70)",
      itemSelectedColor: "black",
      itemSelectedBg: "rgba(243, 244, 246, 0.70)",
      itemHeight: 45,
      fontSize: 16,
    },
    Tabs: {
      colorPrimary: "#3438E0",
      algorithm: true,
      itemColor: "#67768C"
    },
    Input: {
      colorBorder: "#67768C66",
      colorPrimaryHover: "#101928",
      colorTextPlaceholder: "#67768C66",
    },
    DatePicker: {
      colorBorder: "#67768C66",
      colorPrimaryHover: "#101928",
      colorTextPlaceholder: "#67768C66",
    },
    Table: {
      headerSortActiveBg: "#F0F1F3",
      headerSortHoverBg: "#F0F1F3",
      rowSelectedBg: "#e9eeff",
      rowSelectedHoverBg: "#edf1ff",
    },
  }
};
