export const applicationStatusList = [
  {
    value: "NEW",
    label: "Новая",
  },
  {
    value: "SIGNED",
    label: "Подписана",
  },
  {
    value: "SENT",
    label: "Отправлена",
  },
  {
    value: "APPROVE",
    label: "Одобрена",
  },
  {
    value: "DEACTIVE",
    label: "Отозвана отправителем",
  },
  {
    value: "DRAFT",
    label: "Черновик",
  },
  {
    value: "ARCHIVE",
    label: "Архив",
  },
  {
    value: "DECLINE",
    label: "Отклонена",
  },
];
