import React, { JSX, FC } from "react";
import { ReactComponent as AttachFileIcon } from "../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../assets/icons/delete_file.svg";
import css from "./UploadFilesListing.module.css";
import { UploadedFilesPropsType } from "app/types";
import { downloadFile, downloadUrl } from "../../downloadFile";

interface IUploadFilesListingProps {
  file: UploadedFilesPropsType;
  handleRemoveFile: (e: React.MouseEvent<HTMLElement>, file: UploadedFilesPropsType) => void;
  markupTaskFileFrom1C?: UploadedFilesPropsType[] | null;
}

const UploadFilesListing: FC<IUploadFilesListingProps> = ({
  file,
  handleRemoveFile,
  markupTaskFileFrom1C
}): JSX.Element => {
  const {
    uuid,
    file_name
  } = file || {};
  
  const renderDeleteFileIcon = (file: UploadedFilesPropsType): JSX.Element | null => {
    const hasDelete: boolean = !markupTaskFileFrom1C?.length || file?.uuid !== markupTaskFileFrom1C?.[0].uuid;
    
    return hasDelete ? (
      <div
        className={css.deleteIcon}
        onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveFile(e, file)}
      >
        <DeleteDocument className="flex-shrink-0" />
      </div>
    ) : null;
  };
  
  return (
    <div key={uuid} className={css.documentsAction}>
      <div className="flex items-center content-between">
        <div className="w-full flex items-center pl-0.5">
          <AttachFileIcon className="flex-shrink-0" />
          <a
            className={css.fileName}
            onClick={() => downloadFile(file_name, downloadUrl(uuid))}
          >
            {file_name}
          </a>
        </div>
        {renderDeleteFileIcon(file)}
      </div>
    </div>
  );
};

export default UploadFilesListing;