import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
} from "antd";
import { operatingMode } from "../../mocks";
import dayjs, { Dayjs } from "dayjs";
import css from "../../CreateVacancy.module.css";
import * as DocumentAPI from "../../../../../../api/document.api";
import { SelectOptionsType, SkillListType, VacancyListType } from "app/types";

interface IAspirantProps{
  vacancyData: VacancyListType | null;
}

const Aspirant: FC<IAspirantProps> = ({ vacancyData }):JSX.Element => {
  const [skills, setSkills] = useState<SkillListType[]>([]);

  useEffect(() => {
    DocumentAPI.getDocumentList("skills-directory")
    .then((response) => setSkills(response.data.results))
    .catch((err) => console.error("Error", err));
  }, []);

  const skillsOptions: SelectOptionsType[] = skills?.map(({ fields }: SkillListType) => {
    return {
      key: fields?.uuid,
      value: fields?.uuid,
      label: fields?.skill_name,
    };
  }) ?? [];

  return (
    <div className={`${css.containerBlock} p-5 mb-3 bg-white border-bottom-gray`}>
      <h1 className={css.createVacancyBlockHeader}>
        Соискатель
      </h1>
      <div className="flex mb-[20px]">
        <Form.Item
          className="m-0 mr-[30px] w-[32%]"
          id="skills"
          name="skills"
          label="Скиллы"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите наименование позиции",
            },
          ]}
          initialValue={vacancyData?.fields?.skills?.length
            ? vacancyData?.fields?.skills
            : null
          }
        >
          <Select
            className="w-full"
            mode="multiple"
            placeholder="Скиллы"
            size="large"
            suffixIcon={null}
            options={skillsOptions}
          />
        </Form.Item>
        <Form.Item
          className="m-0 w-[32%]"
          id="desired_date"
          name="desired_date"
          label="Желаемая дата выхода на работу"
          initialValue={vacancyData?.fields?.desired_date
            ? vacancyData?.fields?.desired_date
            : null
          }
        >
          <DatePicker
            className="w-full"
            id="desiredDate"
            size="large"
            format="DD.MM.YYYY"
            placeholder="Выберите дату"
            disabledDate={(current: Dayjs) => {
              return dayjs().isAfter(current);
            }}
          />
        </Form.Item>
      </div>
      <div className="flex mb-[20px]">
        <Form.Item
          className="m-0 mr-[30px] w-[32%]"
          id="working_hours"
          name="working_hours"
          label="Режим работы"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите режим работы"
            }
          ]}
          initialValue={vacancyData?.fields?.working_hours ?? null}
        >
          <Select
            className="w-full"
            id="operatingMode"
            placeholder="Выберите режим работы"
            options={operatingMode}
            listHeight={196}
            showSearch
            allowClear
            size="large"
          />
        </Form.Item>
        <Form.Item
          className="m-0 w-[32%]"
          id="working_address"
          name="working_address"
          label="Адрес места работы"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите адрес места работы"
            }
          ]}
          initialValue={vacancyData?.fields?.working_address ?? null}
        >
          <Input
            className="w-full"
            size="large"
            allowClear
            placeholder="Введите адрес места работы"
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default Aspirant;
