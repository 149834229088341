import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { AppDispatch } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { Link, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AlphaListParamsType, ApplicationType } from "app/types";
import { getApplicationsList } from "../../../../actions/alpha/application.actions";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { renderAlphaDocStatus } from "../../../../utils/renderAlphaDocStatus";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { Checkbox, Input, Table, TableColumnsType, Tabs, type TabsProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { applicationStatusList } from "./applicationStatusList";
import EmptyList from "../../../ui-kit/EmptyList/EmptyList";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search_icon.svg";
import css from "./ApplicationList.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";
import { INavigationProps } from "../../Resume/CreateResume/CreateResume";

interface IApplicationsData {
  key: React.Key;
  number: string;
  published_at: string;
  vacancy: string;
  contractee: string;
  request_comment: string;
  request_status: string;
}

interface IApplicationsListProps {
  isContractor?: boolean;
  isVacancyCard?: boolean;
  vacancyUuid?: string;
}

const ApplicationsList: FC<IApplicationsListProps> = ({
  isContractor,
  isVacancyCard,
  vacancyUuid,
}): JSX.Element => {
  const applicationStatus: string = useLocation()?.state ?? "";
  const canGetApplicationsInNewStatus: boolean = !applicationStatus && !isContractor && !isVacancyCard;

  const [activeTab, setActiveTab] = useState<string>(canGetApplicationsInNewStatus ? "NEW" : "All");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [applicationListParams, setApplicationListParams] = useState<AlphaListParamsType | null>({
    page: 1,
    page_size: 5,
    request_status: canGetApplicationsInNewStatus ? "NEW" : null,
    "vacancy.uuid": vacancyUuid ? vacancyUuid : null,
  });

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isAlphaAdmin: boolean = roleResolver(decodedToken).isAlphaAdmin;

  const {
    applicationList,
    applicationPage,
    applicationPageSize,
    applicationTotalDocs,
  } = useSelector((state: AppStateType) => state.application);

  useEffect(() => {
    dispatch(getApplicationsList(applicationListParams));
  }, [applicationListParams]);

  useEffect(() => {
    setApplicationListParams({
      ...applicationListParams,
      "vacancy.uuid": vacancyUuid
    });
  }, [isVacancyCard, vacancyUuid]);

  useEffect(() => {
    dispatch(getDocumentSchema("supplier-request"));
  }, []);

  const applicationTabs: TabsProps["items"] = [
    {
      key: "NEW",
      label: "Новые",
      destroyInactiveTabPane: true,
    },
    {
      key: "WAIT_DECISION",
      label: "На согласовании",
      destroyInactiveTabPane: true,
    },
    {
      key: "ARCHIVE",
      label: "Архив",
      destroyInactiveTabPane: true,
    },
    {
      key: "All",
      label: "Все заявки",
      destroyInactiveTabPane: true,
    },
  ];

  const navigationProps: INavigationProps = {
    vacancyUuid,
    applicationStatus: activeTab,
  };

  const handleTabChange = (tabKey: string): void => {
    setActiveTab(tabKey);

    setApplicationListParams({
      ...applicationListParams,
      request_status: tabKey === "All" ? null : tabKey,
    });
  };

  const handleApplicationPageChange = (newPage: number): void => {
    setApplicationListParams({
      ...applicationListParams,
      page: newPage,
    });
  };

  const handleApplicationPageSizeChange = (newPageSize: number): void => {
    setApplicationListParams({
      ...applicationListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const renderTableLink = (text: string, record: IApplicationsData): JSX.Element => (
    <Link
      className={css.tableCell}
      to={`/alpha/application/${record?.key}`}
      state={navigationProps}
    >
      {text}
    </Link>
  );

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setApplicationListParams);

  const onApplicationStatusFilterChange = (e: CheckboxChangeEvent, status: string): void => {
    const removeStatusFilter: string[] =
      selectedStatuses?.filter((selectedStatus: string): boolean => selectedStatus !== status);

    setSelectedStatuses(e.target.checked ? [...selectedStatuses, status] : removeStatusFilter);
  };

  const isApplicationStatusChecked = (status: string): boolean => {
    return selectedStatuses?.includes(status);
  };

  const clearApplicationStatusFilters = (): void => {
    setSelectedStatuses([]);
  };

  const applyApplicationStatusFilter = (): void => {
    setApplicationListParams({
      ...applicationListParams,
      request_status: selectedStatuses?.length ? `[${selectedStatuses}]` : null,
    });
  };

  const tableApplicationStatusFilters = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          {applicationStatusList.map(({ label, value }) => (
            <Checkbox
              key={value}
              className="table-checkbox"
              onChange={(e: CheckboxChangeEvent) => onApplicationStatusFilterChange(e, value)}
              checked={isApplicationStatusChecked(value)}
            >
              {label}
            </Checkbox>
          ))}
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={clearApplicationStatusFilters}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={(): void => {
              confirm();
              applyApplicationStatusFilter();
            }}
          />
        </div>
      </div>
    );
  };

  const applicationColumns: TableColumnsType<IApplicationsData> = [
    {
      title: (
        <div className="table-title">
          №
        </div>
      ),
      sorter: true,
      showSorterTooltip: true,
      dataIndex: "number",
      width: "10%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "number"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Дата и время
          направления
        </div>
      ),
      sorter: true,
      showSorterTooltip: true,
      dataIndex: "published_at",
      width: "10%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "published_at"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Вакансия
        </div>
      ),
      sorter: true,
      showSorterTooltip: true,
      dataIndex: "vacancy",
      width: "20%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "vacancy"),
      }),
      hidden: isVacancyCard,
    },
    {
      title: (
        <div className="table-title">
          Контрагент
        </div>
      ),
      sorter: true,
      showSorterTooltip: true,
      dataIndex: "contractor",
      width: "20%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "contractor"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Комментарий
        </div>
      ),
      sorter: true,
      showSorterTooltip: true,
      dataIndex: "request_comment",
      width: "20%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "request_comment"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Статус
        </div>
      ),
      dataIndex: "request_status",
      sorter: true,
      showSorterTooltip: true,
      render: (text: string) => renderAlphaDocStatus(text),
      width: "20%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "request_status"),
      }),
      filtered: activeTab === "All" ? !!selectedStatuses?.length : false,
      filterDropdown: activeTab === "All" ? ({ confirm }) => tableApplicationStatusFilters(confirm) : null,
    },
  ];

  const applicationData: IApplicationsData[] = applicationList?.map(({ fields }) => {
    const {
      uuid,
      number,
      published_at,
      vacancy,
      contractee,
      request_comment,
      request_status,
    } = fields as ApplicationType;

    return {
      key: uuid,
      number,
      published_at,
      vacancy: vacancy?.[0]?.label,
      contractee: contractee?.[0]?.label,
      request_comment,
      request_status,
    };
  }) ?? [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hideContainerStyle: boolean = !isVacancyCard && !isContractor;

  return (
    <div className={hideContainerStyle ? "container-block" : ""}>
      <div className="flex items-center content-center justify-between mb-3">
        {!isContractor && !isVacancyCard && (
          <>
            <h1 className={css.applicationHeader}>Заявки</h1>
            {!isAlphaAdmin && (
              <ButtonCustom
                className="mr-3"
                text="Создать новую заявку"
                type="primary"
                size="large"
                onClick={() => navigate("/alpha/create_application")}
              />
            )}
          </>
        )}
      </div>
      {!isContractor && !isVacancyCard && (
        <Tabs
          items={applicationTabs}
          defaultActiveKey={applicationStatus ?? activeTab}
          onChange={handleTabChange}
          destroyInactiveTabPane
        />
      )}
      <div className="p-4 mb-3 bg-white rounded-lg">
        {isContractor && (
          <h1 className={css.applicationCardHeader}>
            Заявки
          </h1>
        )}
        {isVacancyCard && (
          <h1 className={css.applicationCardHeader}>
            Заявки по вакансии
          </h1>
        )}
        <Input
          className="mb-3"
          size="large"
          allowClear
          suffix={<SearchIcon style={{ width: "20px", opacity: .7 }} />}
          placeholder="Найти по наименованию вакансии или контрагенту"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setApplicationListParams({
            ...applicationListParams,
            search: e.target.value,
          })}
        />
        <Table
          columns={applicationColumns}
          dataSource={applicationData}
          pagination={false}
          locale={{
            emptyText: <EmptyList activeKey={`applications ${activeTab}`} className="h-[35vh]" />
          }}
        />
        <PaginationCustom
          total={applicationTotalDocs}
          showTotal={(total: number, range: number[]): string =>
            `${range[0]}-${range[1]} из ${total}`
          }
          currentPage={applicationPage}
          defaultPageSize={10}
          pageSize={applicationPageSize}
          defaultCurrent={1}
          handlePageChange={handleApplicationPageChange}
          handlePageSizeChange={handleApplicationPageSizeChange}
        />
      </div>
    </div>
  );
};

export default ApplicationsList;
