export const filterParamsToArray = (params: string): string[] => {
  const isNegated: boolean = !!params?.includes("!");
  const trimmedString: string = params?.trim()?.substring(isNegated ? 2 : 1, params.length - 1) as string;

  if (!trimmedString) {
    return [];
  }

  return trimmedString
    .split(",")
    .map((item: string) => item.trim())
    .filter((item: string): boolean => item !== "")
    .map((item: string): string => isNegated ? `!${item}` : item);
};

export const deleteNoneFilter = (data: string[]) => {
  return data.filter((status: string) => !status.includes("!"));
};