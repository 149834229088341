import React, { FC, JSX, useEffect, useState } from "react";
import css from "./DraftWarning.module.css";
import { ButtonCustom } from "../../components/ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as WarningDraft } from "../../assets/icons/warning_draft_icon.svg";
import { useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { DocumentClientType } from "app/types";

interface IDraftWarningProps {
}

const DraftWarning: FC<IDraftWarningProps> = (): JSX.Element | null => {

  const clientInfo: DocumentClientType | null=
    useSelector((state: AppStateType) => state.client.documentClient);
  const isDraftClient: boolean = clientInfo?.fields?.status_client === "DRAFT";

  const [showBanner, setShowBanner] = useState<boolean>(false);

  const plateClientDraft: string | null = localStorage.getItem("plateClientDraft");

  useEffect(() => {
    if (plateClientDraft !== null) {
      setShowBanner(JSON.parse(plateClientDraft));
    }
  }, [plateClientDraft]);

  const hidePlate = (): void => {
    setShowBanner(false);
    localStorage.setItem("plateClientDraft", JSON.stringify(false));
  };

  const renderBanner: boolean = showBanner && isDraftClient;

  return (renderBanner ? (
    <div className={css.bannerDraft}>
      <div className="flex items-center justify-between gap-2">
        <WarningDraft className="flex flex-shrink-0" />
        <div>
          Карточка клиента создана как черновик.
          Клиент еще не отправил необходимые документы, данные в карточке могут быть не полными.
        </div>
      </div>
      <ButtonCustom
        type="link"
        text="Скрыть"
        onClick={hidePlate}
      />
    </div>
  ) : null);
};

export default DraftWarning;