import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import {
  Card,
  Tabs,
  Table,
  Select,
  Tooltip,
  TabsProps,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import {
  TaskExecutions,
  DocumentTaskType,
  TaskExecutionStep,
  SelectOptionsType,
  RepeatStepDataType,
  TaskExecutionSteps,
  DocumentTaskFieldsType,
  TaskCardTechInfoDataType,
  TaskCardTechInfoValueDataType,
  ExecutionStepsInfoValueDataType,
} from "app/types";
import ReactJson from "react-json-view";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/warning_circle_icon.svg";
import css from "./TaskCardTechInfo.module.css";
import {
  getTaskStepsTechData,
  repeatTaskExecutionStep,
  repeatTaskStepFailure
} from "../../../../actions/tasks.actions";
import { AppDispatch } from "../../../../store/store";

interface TaskCardTechInfoType {
  key: React.ReactNode;
  name: string;
  keyName: string;
  value: TaskCardTechInfoValueDataType;
  children?: TaskCardTechInfoType[] | void | null;
}

interface ExecutionStepsInfoColumns {
  key: React.ReactNode;
  name: string;
  value: ExecutionStepsInfoValueDataType;
  keyName: string;
}

const taskCardTechInfoColumns: ColumnsType<TaskCardTechInfoType> = [
  {
    title: <span className={css.tableHeader}>Наименование</span>,
    dataIndex: "name",
    key: "name",
    width: "33%"
  },
  {
    title: <span className={css.tableHeader}>Ключ</span>,
    dataIndex: "keyName",
    key: "keyName",
    width: "33%"
  },
  {
    title: <span className={css.tableHeader}>Значение</span>,
    dataIndex: "value",
    key: "value",
    width: "33%"
  }
];

const executionStepsInfoColumns: ColumnsType<ExecutionStepsInfoColumns> = [
  {
    title: <span className={css.tableHeader}>Наименование</span>,
    dataIndex: "name",
    key: "name",
    width: "33%"
  },
  {
    title: <span className={css.tableHeader}>Ключ</span>,
    dataIndex: "keyName",
    key: "keyName",
    width: "33%"
  },
  {
    title: <span className={css.tableHeader}>Значение</span>,
    dataIndex: "value",
    key: "value",
    width: "33%"
  }
];

interface ITaskCardTechInfoProps {
}

const defaultStepOption: SelectOptionsType[] = [{
  value: 0,
  label: <div className="flex justify-between items-center">Попытка 1</div>,
}];

const TaskCardTechInfo: FC<ITaskCardTechInfoProps> = (): JSX.Element => {
  const [executionSteps, setExecutionSteps] = useState<TaskExecutionSteps[]>([]);
  const [executionStepsData, setExecutionStepsData] = useState<[string, any][]>([]);
  const [currentExecutionStep, setCurrentExecutionStep] = useState<TaskExecutionSteps | null>(null);
  const [isResultExpanded, setResultExpanded] = useState<boolean>(false);
  const [resultJSON, setResultJSON] = useState<string>("");
  const [isRepeatStepLoading, setRepeatStepLoading] = useState<boolean>(false);
  const [restartStepSelectOptions, setRestartStepSelectOptions] = useState<SelectOptionsType[]>(defaultStepOption);
  const [currentAttempt, setCurrentAttempt] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>("");

  const {
    documentTask,
    stepsTechInfo,
    schemaTaskData,
    repeatStepStatus,
  } = useSelector((state: AppStateType) => state.tasks);

  const isStatusUpdated: boolean = repeatStepStatus !== 0;

  useEffect(() => {
    if (documentTask?.fields?.execution_uuid) {
      dispatch(getTaskStepsTechData(documentTask.fields.execution_uuid));
    }
  }, [documentTask, repeatStepStatus]);

  useEffect(() => {
    const restartStepsAttemptsOptions: SelectOptionsType[] = currentExecutionStep?.execution_steps
      ?.map(({ has_error }, index: number): SelectOptionsType => {

      return {
        value: index,
        label: (
          <div className={`flex justify-between items-center ${has_error ? css.resultError : ""}`}>
            <span>
              Попытка {index + 1}
            </span>
            {has_error && (
              <ErrorIcon />
            )}
          </div>
        ),
      };
    }) ?? [];

    setRestartStepSelectOptions(restartStepsAttemptsOptions);
  }, [currentExecutionStep]);

  useEffect(() => {
    if (!activeTab) setActiveTab(executionSteps?.[0]?.id);

    const currentStep: TaskExecutionSteps = executionSteps
      ?.filter((step: TaskExecutionSteps): boolean => step.id === activeTab)?.[0] ?? [];

    if (currentStep.execution_steps?.length) setCurrentAttempt(currentStep.execution_steps.length - 1);

    setCurrentExecutionStep(currentStep);
  }, [executionSteps, activeTab]);

  const dispatch = useDispatch<AppDispatch>();

  const translateFieldName = (fieldName: string): string => {
    switch (fieldName) {
      case "schema_name":
        return "Наименование схемы";
      case "schema_version":
        return "Версия схемы";
      case "created_by":
        return "Автор схемы";
      case "created_at":
        return "Дата создания";
      case "updated_at":
        return "Дата обновления";
      case "deleted":
        return "Документ удален";
      case "fields":
        return "Поля документа";
      case "id":
        return "Идентификатор";
      case "result":
        return "Информация по шагу";
      default:
        return fieldName;
    }
  };

  const translateStepsFieldName = (fieldName: string): string => {
    switch (fieldName) {
      case "created_at":
        return "Дата создания";
      case "updated_at":
        return "Дата обновления";
      case "result":
        return "Результат выполнения";
      default:
        return fieldName;
    }
  };

  const filteredDocSchemaName = (fieldName: string): string => {
    return schemaTaskData?.fields?.filter(({ name }: { name: string }) => name === fieldName)?.[0]?.label ?? fieldName;
  };

  const taskCardTechInfoChildrenData = (key: string, value: string | number | boolean | DocumentTaskFieldsType) => {
    return key === "fields" ? (
      Object.entries(value).map(([key, value]: TaskCardTechInfoDataType) => {
        return {
          key: key,
          name: filteredDocSchemaName(key),
          keyName: key,
          value: typeof value === "object" && value !== null
            ? <ReactJson theme="grayscale:inverted" src={value} />
            : value
        };
      })
    ) : null;
  };

  const sortedTaskCardTechInfoData: TaskCardTechInfoDataType[] = Object.entries(documentTask as DocumentTaskType)
    ?.sort((
      prevElem: TaskCardTechInfoDataType,
      currElem: TaskCardTechInfoDataType
    ): number => {
      // Поместим элементы с ключом 'fields' в конец массива
      if (prevElem[0] === "fields") {
        return 1;
      }

      if (currElem[0] === "fields") {
        return -1;
      }

      return 0;
  });

  const taskCardTechInfoData: TaskCardTechInfoType[] = sortedTaskCardTechInfoData?.map((
    [key, value]: TaskCardTechInfoDataType
  ) => {
    return {
      key: key,
      name: translateFieldName(key),
      keyName: key,
      value: value,
      children: taskCardTechInfoChildrenData(key, value)
    };
  });

  const showJson = (value: any): void => {
    const resultJSON: string = JSON.stringify(value, null, 2);
    setResultJSON(resultJSON);
    setResultExpanded(true);
  };

  useEffect(() => {
    if (currentExecutionStep?.execution_steps?.[currentAttempt]) {
      const sortedStepsInfoData: [string, any][] =
        Object.entries(currentExecutionStep?.execution_steps?.[currentAttempt] as TaskExecutionStep)?.sort((
          prevStep,
          currStep
        ): number => {
          // Поместим элементы с ключом 'result' в конец массива
          if (prevStep[0] === "result") {
            return 1;
          }

          if (currStep[0] === "result") {
            return -1;
          }

          return 0;
        })?.filter((step): boolean => step[0] !== "has_error");

      const executionStepResult = currentExecutionStep?.execution_steps?.[currentAttempt]?.result;
      const resultJSON: string = JSON.stringify(executionStepResult, null, 2);

      setResultJSON(resultJSON);
      setExecutionStepsData(sortedStepsInfoData);
    }
  }, [currentExecutionStep, currentAttempt]);

  const executionStepsTableData = executionStepsData?.map((
    [key, value]
  ) => {
    const isJsonInformation: boolean = key === "result"
      || key === "meta_data"
      || key === "outgoing_data"
      || key === "incoming_data";

    return {
      key: key,
      name: translateStepsFieldName(key),
      keyName: key,
      value: isJsonInformation ? (
        <ButtonCustom
          onClick={() => showJson(value)}
          className="pl-0"
          type="link"
          text="Показать json"
        />
      ) : value,
    };
  });

  useEffect(() => {
    if (stepsTechInfo) {
      const executions: TaskExecutionSteps[] =
        Object.entries(stepsTechInfo?.executions as TaskExecutions).map(([id, step]) => ({
          id,
          has_error: step.has_error,
          execution_steps: step.execution_steps,
          step_name: step.step_name
        }));

      setExecutionSteps(executions);
    }
  }, [stepsTechInfo]);

  const renderExecutionTabs: TabsProps["items"] = executionSteps?.map(({ id, step_name, has_error}) => {
    const isStepErr: boolean = has_error && id !== currentExecutionStep?.id;

    return {
      key: id,
      label: (
        <div className="flex items-center">
          {has_error ? (
            <>
              <span className={`block mr-[4px] ${isStepErr ? "text-red-500" : ""}`}>
                {step_name}
              </span>
              <Tooltip title="Ошибка выполнения шага">
                <ErrorIcon />
              </Tooltip>
            </>
          ) : (
            step_name
          )}
        </div>
      ),
    };
  });

  const onStepTabChange = (key: string): void => {
    setActiveTab(key);
  };

  const onStepRestart = (): void => {
    if (documentTask && currentExecutionStep) {
      const repeatTaskStepData: RepeatStepDataType = {
        execution_uuid: documentTask?.fields?.execution_uuid,
        process_step_uuid: currentExecutionStep?.id,
      };

      setRepeatStepLoading(true);
      dispatch(repeatTaskExecutionStep(repeatTaskStepData));
    }
  };

  useEffect(() => {
    if (repeatStepStatus === 200) {
      const isRepeatSuccess: boolean = true;

      successNotification(isRepeatSuccess);
      setRepeatStepLoading(false);
      dispatch(repeatTaskStepFailure());
    }

    if (repeatStepStatus !== 200 && isStatusUpdated) {
      repeatStepError();
      setRepeatStepLoading(false);
      dispatch(repeatTaskStepFailure());
    }
  }, [repeatStepStatus]);

  const repeatStepError = (isRepeatSuccess?: boolean): void => {
    notification.error({
      message: isRepeatSuccess ? "Ошибка перезапуска шага" : "Ошибка копирования данных",
    });
  };

  const successNotification = (isRepeatSuccess?: boolean): void => {
    notification.success({
      message: isRepeatSuccess ? "Шаг успешно перезапущен" : "Данные скопированы",
    });
  };

  const onResultCopy = async (): Promise<void> => {
    if (resultJSON) {
      try {
        await navigator.clipboard.writeText(resultJSON);
        successNotification();
      } catch (error) {
        repeatStepError();
        console.error("Ошибка при копировании в буфер обмена:", error);
      }
    }
  };

  const onAttemptChange = (attempt: number): void => {
    setCurrentAttempt(attempt);
  };

  const defaultActiveStepTab: string = currentExecutionStep?.id ?? renderExecutionTabs?.[0]?.id as string;

  return (
    <div>
      <Card className="h-full mb-2.5">
        <h1 className={css.header}>Техническая информация</h1>
        <Table
          columns={taskCardTechInfoColumns}
          dataSource={taskCardTechInfoData}
          pagination={false}
        />
      </Card>
      <Card className="h-full">
        <Tabs
          className="mb-4"
          defaultActiveKey={defaultActiveStepTab}
          items={renderExecutionTabs}
          onChange={onStepTabChange}
        />
        <div className="flex justify-between mb-4">
          <Select
            className="w-full max-w-[200px]"
            defaultValue={currentAttempt}
            value={currentAttempt}
            loading={isStatusUpdated}
            onChange={onAttemptChange}
            options={restartStepSelectOptions}
          />
          <ButtonCustom
            type="primary"
            text="Перезапустить шаг"
            disabled={isRepeatStepLoading}
            onClick={onStepRestart}
          />
        </div>
        <div className="flex justify-between">
          <Table
            className={isResultExpanded ? "mr-5" : "mr-0"}
            style={{ width: !isResultExpanded ? "100%" : "50%" }}
            columns={executionStepsInfoColumns}
            dataSource={executionStepsTableData}
            pagination={false}
          />
          {isResultExpanded && (
            <div
              className={css.resultCard}
              style={{ width: "50%" }}
            >
              <div className={css.resultCardHeaderWrap}>
                <span className={css.resultHeader}>
                  Результат выполнения
                </span>
                <div className="flex justify-between">
                  <ButtonCustom
                    className="mr-2"
                    type="link"
                    text="Скопировать"
                    onClick={onResultCopy}
                  />
                  <ButtonCustom
                    type="link"
                    text="Скрыть"
                    onClick={() => setResultExpanded(false)}
                  />
                </div>
              </div>
              {resultJSON && (
                <div className="h-full overflow-x-auto">
                  <pre>{resultJSON}</pre>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default TaskCardTechInfo;
