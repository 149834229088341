import { ClientTariffDataType } from "app/types";

enum ClientConsultation {
  hasNone = 0,
}

export const getConsultationsNumber = (clientTariffData: ClientTariffDataType | null): number | string => {
  const hasConsultations: boolean = clientTariffData?.number_of_consultations !== undefined;
  const consultations: number = Number(clientTariffData?.number_of_consultations);

  return hasConsultations && consultations > ClientConsultation.hasNone
    ? consultations
    : `${ClientConsultation.hasNone}`;
};
