import axios from "../utils/axios";

const API_DOCUMENT: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_DOCUMENT;

//ручка получения схемы документа
export const getSchemaDocument = async (docSchemaName: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/schemas/${docSchemaName}`);
};

//ручка получения данных документа
export const getEmployeeDocument = async(docSchemaName: string, uuid: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/${docSchemaName}/uuid/${uuid}`);
};

//ручка получения данных документа
export const getCompanyDocument = async(docSchemaName: string, uuid: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/${docSchemaName}/uuid/${uuid}`);
};

//ручка получения файла конкретного документа
export const getDocumentFile = async(file_uuid: string): Promise<any> => {
  return axios.GET(url, `api/mdm/storage/v1/${file_uuid}`);
};

//ручка обновления данных сотрудников организации
export const updateEmployeeDocument = async(docSchemaName: string, uuid: string, data: any): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/${docSchemaName}/uuid/${uuid}`, data);
};

//ручка обновления данных организации
export const updateCompanyDocument = async(docSchemaName: string, uuid: string, data: any): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/${docSchemaName}/uuid/${uuid}`, data);
};

//ручка завершения валидации задачи
export const finalizeTask = async(task_uuid: string): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/finalize_task/${task_uuid}`);
};

//ручка загрузки файлов в модальном окне
export const uploadFiles = async(file: FormData): Promise<any> => {
  return axios.POST_FILE(url, "api/mdm/storage/v1/upload_files", file);
};

//ручка скачивания файлов
export const downLoadFile = async (urlData: string): Promise<any> => {
  return axios.GET_FILE(url, urlData);
};

//ручка удаления файлов в модальном окне
export const deleteUploadFile = async (params?: string | null): Promise<any> => {
  return axios.DELETE(url, `api/mdm/storage/v1/files?${params}`);
};

//ручка получения выписки подозрительных операций
export const getBankStatement = async (statementUuid?: string): Promise<any> => {
  return axios.GET(url, `api/fin/bank_statements/v1/statement/${statementUuid}?suspicious=true`);
};

//ручка получения пакета выписок банка
export const getBankPackagesStatement = async (clientProfileUuid?: string): Promise<any> => {
  return axios.GET(
    url,
    `api/fin/bank_statements/v1/statement/statement/client-pofile-uuid=${clientProfileUuid}?suspicious=true`);
};

//ручка создания динамического документа
export const createNewDocument = async (schemaSlug: string, data: any): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/${schemaSlug}/`, data);
};

//ручка обновления документа
export const updateDocument = async(docSchemaName: string, uuid: string, data: any): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/${docSchemaName}/uuid/${uuid}`, data);
};

//ручка получения данных документа
export const getDocumentList = async(docSchemaName: string, params?: any): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/${docSchemaName}`, params);
};

//ручка удаления документа
export const deleteDocument = async(docSchemaName: string, docUuid: string, body?: any): Promise<any> => {
  return axios.DELETE(url, `api/mdm/documents/v1/d/${docSchemaName}/uuid/${docUuid}`, body);
};
