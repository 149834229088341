import React, {
  ChangeEvent,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { Input, Modal, Space, Form } from "antd";
import css from "./ClientModal.module.css";
import { handlePhoneNumberChange } from "../../../../../../utils/phoneNumberMask";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import {
  createClientLabourRegulationData,
  updateClientData,
  updateClientLabourRegulationData
} from "../../../../../../actions/client.actions";
import {
  AbstractDocType,
  CreateLabourRegulationType,
  DocumentClientFieldsRewriteType,
  DocumentClientFieldsUpdateType,
  DocumentClientType,
} from "app/types";
import { AppDispatch } from "../../../../../../store/store";
import { updateDocumentData } from "../../../../../../actions/document.actions";
import ModalDraggable from "../../../../../../utils/ModalDraggable/ModalDraggable";
import { handleOut, handleOver } from "../../../../../../utils/ModalDraggable/DraggableSwitch";

interface IClientModalProps {
  title: string;
  isModalOpen: boolean;
  clientInfo?: DocumentClientType | null;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
}

interface IFormProps {
  phoneNumber: string,
  email: string,
}

enum PhoneNumber {
  defaultLength = 16,
}

const ClientModal: FC<IClientModalProps> = ({
  title,
  isModalOpen,
  clientInfo,
  setIsModalOpen
}): JSX.Element => {
  const [form] = Form.useForm<IFormProps>();
  const values: IFormProps = Form.useWatch([], form);

  const dispatch = useDispatch<AppDispatch>();

  const [inputSalaryValue, setInputSalaryValue] = useState<string>("");
  const [inputPrepaidValue, setInputPrepaidValue] = useState<string>("");
  const [updateClient, setUpdateClient] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const { phoneNumber, email } = values || {};

  const {
    documentClient,
    clientLabourRegulation
  } = useSelector((state: AppStateType) => state.client);

  const handleInputSalaryChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    setInputSalaryValue(value);
  };

  const checkInputSalaryValue = (): void => {
    const number: number = Number(inputSalaryValue);

    if (!isNaN(number) && number >= 1 && number <= 31) {
      setInputSalaryValue(`${number} числа`);
    } else {
      setInputSalaryValue("");
    }
  };

  const handleInputPrepaidChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    setInputPrepaidValue(value);
  };

  const checkInputPrepaidValue = (): void => {
    const number: number = Number(inputPrepaidValue);

    if (!isNaN(number) && number >= 1 && number <= 31) {
      setInputPrepaidValue(`${number} числа`);
    } else {
      setInputPrepaidValue("");
    }
  };

  const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const formattedNumber: string = handlePhoneNumberChange(value);
    form.setFieldValue("phoneNumber", formattedNumber);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const validEmail: string = value.replace(/[^A-Za-z0-9@._%?&-]/g, "");
    form.setFieldValue("email", validEmail);
  };

  const isEmailValid = (email: string): boolean => {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const modalOk = (): void => {
    //создание документа "Положения об оплате труда"
    if ( title === "Положения об оплате труда" && documentClient?.fields?.uuid) {
      const clientLabelData: CreateLabourRegulationType = {
        client: documentClient?.fields?.uuid,
        salary_accrual_date: parseInt(inputSalaryValue.split(" ")[0]),
        advance_accrual_date: parseInt(inputPrepaidValue.split(" ")[0]),
      };

      if (clientLabourRegulation) {
        const labourUuid: string = documentClient?.fields?.labour_remuneration_regulations[0]?.uuid;
        dispatch(updateClientLabourRegulationData(labourUuid, clientLabelData));
      } else {
        setUpdateClient((): boolean => {
          dispatch(createClientLabourRegulationData(clientLabelData));
          return true;
        });
      }

    }

    //редактирование контактных данных клиента
    if (title === "Контакты клиента" && documentClient?.fields?.uuid) {
      const newContactData: DocumentClientFieldsUpdateType = {
        ...clientInfo?.fields,
        phone: phoneNumber.replace(/(\+7)\s+(\d{3})\s+(\d{3}-\d{2}-\d{2})/, "$1($2)$3"),
        email: email,
      } as DocumentClientFieldsUpdateType;

      dispatch(updateDocumentData("client", clientInfo?.fields?.uuid ?? "", newContactData));
    }

    setIsModalOpen(false);
    setInputSalaryValue("");
    setInputPrepaidValue("");
  };

  const extractUuidValues = (documentClient: DocumentClientType | null): DocumentClientFieldsUpdateType | null => {
    if (documentClient) {
      const fieldsClient: DocumentClientFieldsRewriteType = documentClient.fields;
      const modifiedFields: Record<string, unknown> = {};
      for (const key in fieldsClient) {
        if (Array.isArray(fieldsClient[key])) {
          if ((fieldsClient[key] as string[] | AbstractDocType[]).every((item: unknown) => (typeof item === "string")
            || (typeof item === "object" && ("uuid" in item!)))) {
            modifiedFields[key] = (fieldsClient[key] as string[] | AbstractDocType[]).map((item: unknown): string => {
              return (typeof item === "string") ? item : (item as { uuid: string }).uuid;
            });
          } else {
            modifiedFields[key] = fieldsClient[key];
          }
        } else {
          modifiedFields[key] = fieldsClient[key];
        }
      }
      delete modifiedFields["uuid"];
      return modifiedFields as DocumentClientFieldsUpdateType;
    } else {
      return null;
    }
  };

  const clientUpdateDate: DocumentClientFieldsUpdateType | null = extractUuidValues(documentClient);

  //обновляем сущность клиента
  useEffect(() => {
    if (clientLabourRegulation && clientUpdateDate && documentClient && updateClient) {
      const updateClient: DocumentClientFieldsUpdateType = {
        ...clientUpdateDate,
        labour_remuneration_regulations: [clientLabourRegulation?.fields?.uuid],
      };

      dispatch(updateClientData(documentClient?.fields?.uuid, updateClient));
      setUpdateClient(false);
    }
  }, [clientLabourRegulation]);

  useEffect(() => {
    form.setFieldValue("phoneNumber", clientInfo?.fields?.phone ?? "");
    form.setFieldValue("email", clientInfo?.fields?.email ?? "");
  }, [clientInfo?.fields]);

  const modalCancel = (): void => {
    setIsModalOpen(false);
    setInputSalaryValue("");
    setInputPrepaidValue("");
    form.setFieldValue("phoneNumber", clientInfo?.fields?.phone ?? "");
    form.setFieldValue("email", clientInfo?.fields?.email ?? "");
  };

  const modalHeader = (text: string): JSX.Element => {
    return (
      <div
        className={css.modalHeader}
        onMouseOver={() => handleOver(disabled, setDisabled)}
        onMouseOut={() => handleOut(setDisabled)}
      >
        {text}
      </div>
    );
  };

  const disableButtonOk: boolean = !(phoneNumber?.length === PhoneNumber.defaultLength
    && isEmailValid(email)) && !(inputSalaryValue && inputPrepaidValue);

  const modalFooter: JSX.Element = (
    <>
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        className={css.buttonOk}
        disabled={disableButtonOk}
        onClick={modalOk}
      />
    </>
  );

  const modalContent = (title: string): JSX.Element | null => {
    switch (title) {
      case("Положения об оплате труда"):
        return (
          <>
            <div className={css.modalText}>
              Внесите необходимые данные и подтвердите изменение
            </div>
            <div className="inline-block gray-color text-sm">
              Дата начисления заработной платы
            </div>
            <Space
              direction="vertical"
              size="large"
              className={`${css.spaceStyle} mb-5`}
            >
              <Space.Compact className={css.spaceStyle}>
                <Input
                  className={css.inputStyle}
                  placeholder="Введите число"
                  size="large"
                  onChange={handleInputSalaryChange}
                  value={inputSalaryValue}
                  onBlur={checkInputSalaryValue}
                />
                <Input
                  className={css.inputStyle}
                  value="Ежемесячно"
                  size="large"
                  readOnly={true}
                />
              </Space.Compact>
            </Space>
            <div className="inline-block gray-color text-sm">
              Дата начисления аванса
            </div>
            <Space
              direction="vertical"
              size="large"
              className={`${css.spaceStyle} mb-5`}
            >
              <Space.Compact
                className={css.spaceStyle}
              >
                <Input
                  className={css.inputStyle}
                  placeholder="Введите число"
                  size="large"
                  onChange={handleInputPrepaidChange}
                  value={inputPrepaidValue}
                  onBlur={checkInputPrepaidValue}
                />
                <Input
                  className={css.inputStyle}
                  value="Ежемесячно"
                  size="large"
                  readOnly={true}
                />
              </Space.Compact>
            </Space>
          </>
        );
      case ("Контакты клиента"):
        return (
          <>
            <div className={css.modalText}>
              Внесите необходимые данные и подтвердите изменение
            </div>
            <div className="inline-block gray-color text-sm">
              Номер телефона
            </div>
            <Space
              direction="vertical"
              size="large"
              className={`${css.spaceStyle} mb-5`}
            >
              <Space.Compact className={css.spaceStyle}>
                <Form.Item
                  noStyle
                  id="phoneNumber"
                  name="phoneNumber"
                >
                  <Input
                    placeholder="+7 000 000 00 00"
                    size="large"
                    value={phoneNumber}
                    onChange={handlePhoneNumber}
                  />
                </Form.Item>
              </Space.Compact>
            </Space>
            <div className="inline-block gray-color text-sm">
              Электронная почта
            </div>
            <Space
              direction="vertical"
              size="large"
              className={`${css.spaceStyle} mb-5`}
            >
              <Space.Compact className={css.spaceStyle}>
                <Form.Item
                  noStyle
                  id="email"
                  name="email"
                >
                  <Input
                    placeholder="Введите email"
                    size="large"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Item>
              </Space.Compact>
            </Space>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      title={modalHeader(title)}
      open={isModalOpen}
      onCancel={modalCancel}
      footer={modalFooter}
      modalRender={(modal) => (
        <ModalDraggable disabled={disabled} modal={modal} />
      )}
    >
      <Form form={form}>
        {modalContent(title)}
      </Form>
    </Modal>
  );
};

export default ClientModal;
