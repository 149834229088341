import {
  CLEAR_DOCUMENT_COMPANY,
  CREATE_NEW_DOCUMENT_FAILURE,
  CREATE_NEW_DOCUMENT_SUCCESS,
  FINALIZE_TASK_FAILURE,
  FINALIZE_TASK_SUCCESS,
  GET_BANK_COMPANY_DOCUMENT_FAILURE,
  GET_BANK_COMPANY_DOCUMENT_SUCCESS,
  GET_BANK_DOCUMENT_FAILURE,
  GET_BANK_DOCUMENT_SUCCESS,
  GET_BANK_PACKAGES_STATEMENT_FAILURE,
  GET_BANK_PACKAGES_STATEMENT_SUCCESS,
  GET_BANK_STATEMENT_FAILURE,
  GET_BANK_STATEMENT_SUCCESS,
  GET_CONTRACT_DOCUMENT_FAILURE,
  GET_CONTRACT_DOCUMENT_SUCCESS,
  GET_DECISION_COMPANY_DOCUMENT_FAILURE,
  GET_DECISION_COMPANY_DOCUMENT_SUCCESS,
  GET_DECISION_DOCUMENT_FAILURE,
  GET_DECISION_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_FILE_FAILURE,
  GET_DOCUMENT_FILE_SUCCESS,
  GET_DOCUMENT_LIST_FAILURE,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_INCOME_DOCUMENT_FAILURE,
  GET_INCOME_DOCUMENT_SUCCESS,
  GET_INN_DOCUMENT_FAILURE,
  GET_INN_DOCUMENT_SUCCESS,
  GET_MIGRATION_COMPANY_DOCUMENT_FAILURE,
  GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS,
  GET_MIGRATION_DOCUMENT_FAILURE,
  GET_MIGRATION_DOCUMENT_SUCCESS,
  GET_PASSPORT_COMPANY_DOCUMENT_FAILURE,
  GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS,
  GET_PASSPORT_DOCUMENT_FAILURES,
  GET_PASSPORT_DOCUMENT_SUCCESS,
  GET_SCHEMA_BANK_DOCUMENT_SUCCESS,
  GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS,
  GET_SCHEMA_DECISION_DOCUMENT_SUCCESS,
  GET_SCHEMA_DOCUMENT_FAILURE,
  GET_SCHEMA_DOCUMENT_SUCCESS,
  GET_SCHEMA_INCOME_DOCUMENT_SUCCESS,
  GET_SCHEMA_INN_DOCUMENT_SUCCESS,
  GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS,
  GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS,
  GET_SCHEMA_SNILS_DOCUMENT_SUCCESS,
  GET_SCHEMA_USN_DOCUMENT_SUCCESS,
  GET_SNILS_COMPANY_DOCUMENT_FAILURE,
  GET_SNILS_COMPANY_DOCUMENT_SUCCESS,
  GET_SNILS_DOCUMENT_FAILURE,
  GET_SNILS_DOCUMENT_SUCCESS,
  GET_USN_COMPANY_DOCUMENT_FAILURE,
  GET_USN_COMPANY_DOCUMENT_SUCCESS,
  GET_USN_DOCUMENT_FAILURE,
  GET_USN_DOCUMENT_SUCCESS,
  UPDATE_BANK_COMPANY_DOCUMENT_FAILURE,
  UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_BANK_DOCUMENT_FAILURE,
  UPDATE_BANK_DOCUMENT_SUCCESS,
  UPDATE_CONTRACT_DOCUMENT_FAILURE,
  UPDATE_CONTRACT_DOCUMENT_SUCCESS,
  UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE,
  UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_DECISION_DOCUMENT_FAILURE,
  UPDATE_DECISION_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_INCOME_DOCUMENT_FAILURE,
  UPDATE_INCOME_DOCUMENT_SUCCESS,
  UPDATE_INN_DOCUMENT_FAILURE,
  UPDATE_INN_DOCUMENT_SUCCESS,
  UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE,
  UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_MIGRATION_DOCUMENT_FAILURE,
  UPDATE_MIGRATION_DOCUMENT_SUCCESS,
  UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE,
  UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_PASSPORT_DOCUMENT_FAILURE,
  UPDATE_PASSPORT_DOCUMENT_SUCCESS,
  UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE,
  UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_SNILS_DOCUMENT_FAILURE,
  UPDATE_SNILS_DOCUMENT_SUCCESS,
  UPDATE_USN_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_USN_DOCUMENT_COMPANY_FAILURE,
  UPDATE_USN_DOCUMENT_FAILURE,
  UPDATE_USN_DOCUMENT_SUCCESS
} from "../constants/actionTypes";
import { DocumentActionsTypes } from "../actions/document.actions";
import { BankStatementDataType, SchemaType, UpdateDocumentType } from "app/types";

export type InitialDocumentType = {
  error: any;
  documentData: null | UpdateDocumentType;
  innDoc: null | UpdateDocumentType;
  innDocStatus: number;
  usnDoc: null | UpdateDocumentType;
  usnDocStatus: number;
  bankDoc: null | UpdateDocumentType;
  bankDocStatus: number;
  snilsDoc: null | UpdateDocumentType;
  snilsDocStatus: number;
  incomeDoc: null | UpdateDocumentType;
  incomeDocStatus: number;
  passportDoc: null | UpdateDocumentType;
  passportDocStatus: number;
  decisionDoc: null | UpdateDocumentType;
  decisionDocStatus: number;
  contractDoc: null | UpdateDocumentType;
  contractDocStatus: number;
  migrationDoc: null | UpdateDocumentType;
  migrationDocStatus: number;
  documentSchema: null | SchemaType;
  documentCurrentFileData: string;
  isDocumentSchemaLoading: boolean;
  isDocumentLoading: boolean;
  finalizeTaskData: any;
  finalizeTaskStatus: any;
  innDocSchema: null | SchemaType;
  usnDocSchema: null | SchemaType;
  snilsDocSchema: null | SchemaType;
  bankDocSchema: null | SchemaType;
  incomeDocSchema: null | SchemaType;
  contractDocSchema: null | SchemaType;
  decisionDocSchema: null | SchemaType;
  passportDocSchema: null | SchemaType;
  migrationDocSchema: null | SchemaType;
  bankStatementData: BankStatementDataType[];
  bankPackagesData: BankStatementDataType[];
  usnCompanyDoc: null | UpdateDocumentType;
  bankCompanyDoc: null | UpdateDocumentType;
  snilsCompanyDoc: null | UpdateDocumentType;
  passportCompanyDoc: null | UpdateDocumentType;
  decisionCompanyDoc: null | UpdateDocumentType;
  migrationCompanyDoc: null | UpdateDocumentType;
  newDocument: null | UpdateDocumentType;
  updatedDoc: null | UpdateDocumentType;
  docList: null | UpdateDocumentType;
};

const initialState: InitialDocumentType = {
  error: null,
  documentData: null,
  innDoc: null,
  innDocStatus: 0,
  usnDoc: null,
  usnDocStatus: 0,
  bankDoc: null,
  bankDocStatus: 0,
  snilsDoc: null,
  snilsDocStatus: 0,
  incomeDoc: null,
  incomeDocStatus: 0,
  passportDoc: null,
  passportDocStatus: 0,
  decisionDoc: null,
  decisionDocStatus: 0,
  contractDoc: null,
  contractDocStatus: 0,
  migrationDoc: null,
  migrationDocStatus: 0,
  documentSchema: null,
  documentCurrentFileData: "",
  isDocumentSchemaLoading: true,
  isDocumentLoading: true,
  finalizeTaskData: null,
  finalizeTaskStatus: null,
  innDocSchema: null,
  usnDocSchema: null,
  snilsDocSchema: null,
  bankDocSchema: null,
  incomeDocSchema: null,
  contractDocSchema: null,
  decisionDocSchema: null,
  passportDocSchema: null,
  migrationDocSchema: null,
  bankStatementData: [],
  bankPackagesData: [],
  usnCompanyDoc: null,
  bankCompanyDoc: null,
  snilsCompanyDoc: null,
  passportCompanyDoc: null,
  decisionCompanyDoc: null,
  migrationCompanyDoc: null,
  newDocument: null,
  updatedDoc: null,
  docList: null,
};

const document = (
  state: InitialDocumentType = initialState,
  action: DocumentActionsTypes
): InitialDocumentType => {
  switch (action.type) {
    // Логика получения данных документа сотрудника
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
        documentData: null,
        // при выходе из задачи обнуляем данные ошибок валидации
        usnDoc: null,
        snilsDoc: null,
        passportDoc: null,
        decisionDoc: null,
        bankDoc: null,
        incomeDoc: null,
        migrationDoc: null,
        isDocumentLoading: false,
        innDocStatus: 0,
        snilsDocStatus: 0,
        bankDocStatus: 0,
        passportDocStatus: 0,
        contractDocStatus: 0,
        usnDocStatus: 0,
        incomeDocStatus: 0,
        decisionDocStatus: 0,
        migrationDocStatus: 0,
        error: action.payload.error,
      };
    case CLEAR_DOCUMENT_COMPANY:
      return {
        ...state,
        usnCompanyDoc: null,
        bankCompanyDoc: null,
        snilsCompanyDoc: null,
        passportCompanyDoc: null,
        decisionCompanyDoc: null,
        migrationCompanyDoc: null,
      };
    case GET_INN_DOCUMENT_SUCCESS:
      return {
        ...state,
        innDoc: action.payload.innDoc,
        innDocStatus: action.payload.innDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_INN_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        innDocStatus: action.payload.innDocStatus,
        error: action.payload.error,
      };
    case GET_USN_DOCUMENT_SUCCESS:
      return {
        ...state,
        usnDoc: action.payload.usnDoc,
        usnDocStatus: action.payload.usnDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_USN_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        usnDocStatus: action.payload.usnDocStatus,
        error: action.payload.error,
      };
    case GET_BANK_DOCUMENT_SUCCESS:
      return {
        ...state,
        bankDoc: action.payload.bankDoc,
        bankDocStatus: action.payload.bankDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_BANK_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        bankDocStatus: action.payload.bankDocStatus,
        error: action.payload.error,
      };
    case GET_SNILS_DOCUMENT_SUCCESS:
      return {
        ...state,
        snilsDoc: action.payload.snilsDoc,
        snilsDocStatus: action.payload.snilsDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_SNILS_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        snilsDocStatus: action.payload.snilsDocStatus,
        error: action.payload.error,
      };
    case GET_INCOME_DOCUMENT_SUCCESS:
      return {
        ...state,
        incomeDoc: action.payload.incomeDoc,
        incomeDocStatus: action.payload.incomeDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_INCOME_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        incomeDocStatus: action.payload.incomeDocStatus,
        error: action.payload.error,
      };
    case GET_PASSPORT_DOCUMENT_SUCCESS:
      return {
        ...state,
        passportDoc: action.payload.passportDoc,
        passportDocStatus: action.payload.passportDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_PASSPORT_DOCUMENT_FAILURES:
      return {
        ...state,
        isDocumentLoading: false,
        passportDocStatus: action.payload.passportDocStatus,
        error: action.payload.error,
      };
    case GET_DECISION_DOCUMENT_SUCCESS:
      return {
        ...state,
        decisionDoc: action.payload.decisionDoc,
        decisionDocStatus: action.payload.decisionDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_DECISION_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        decisionDocStatus: action.payload.decisionDocStatus,
        error: action.payload.error,
      };
    case GET_CONTRACT_DOCUMENT_SUCCESS:
      return {
        ...state,
        contractDoc: action.payload.contractDoc,
        contractDocStatus: action.payload.contractDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_CONTRACT_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        contractDocStatus: action.payload.contractDocStatus,
        error: action.payload.error,
      };
    case GET_MIGRATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        migrationDoc: action.payload.migrationDoc,
        migrationDocStatus: action.payload.migrationDocStatus,
        isDocumentLoading: false,
        error: null,
      };
    case GET_MIGRATION_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        migrationDocStatus: action.payload.migrationDocStatus,
        error: action.payload.error,
      };
    // Логика получения данных документа компании
    case GET_USN_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        usnCompanyDoc: action.payload.usnCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case GET_USN_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case GET_BANK_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        bankCompanyDoc: action.payload.bankCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case GET_BANK_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case GET_SNILS_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        snilsCompanyDoc: action.payload.snilsCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case GET_SNILS_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        passportCompanyDoc: action.payload.passportCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case GET_PASSPORT_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case GET_DECISION_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        decisionCompanyDoc: action.payload.decisionCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case GET_DECISION_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        migrationCompanyDoc: action.payload.migrationCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case GET_MIGRATION_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    // Логика получения данных файла документа
    case GET_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
        documentCurrentFileData: action.payload.documentCurrentFileData,
        isDocumentLoading: false,
        error: null,
      };
    case GET_DOCUMENT_FILE_FAILURE:
      return {
        ...state,
        documentCurrentFileData: "",
        isDocumentLoading: false,
        error: action.payload.error,
      };
    // Логика обновления данных документа сотрудника
    case UPDATE_INN_DOCUMENT_SUCCESS:
      return {
        ...state,
        innDoc: action.payload.innDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_INN_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_USN_DOCUMENT_SUCCESS:
      return {
        ...state,
        usnDoc: action.payload.usnDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_USN_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_BANK_DOCUMENT_SUCCESS:
      return {
        ...state,
        bankDoc: action.payload.bankDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_BANK_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_SNILS_DOCUMENT_SUCCESS:
      return {
        ...state,
        snilsDoc: action.payload.snilsDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_SNILS_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_INCOME_DOCUMENT_SUCCESS:
      return {
        ...state,
        incomeDoc: action.payload.incomeDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_INCOME_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_PASSPORT_DOCUMENT_SUCCESS:
      return {
        ...state,
        passportDoc: action.payload.passportDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_PASSPORT_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_DECISION_DOCUMENT_SUCCESS:
      return {
        ...state,
        decisionDoc: action.payload.decisionDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_DECISION_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_CONTRACT_DOCUMENT_SUCCESS:
      return {
        ...state,
        contractDoc: action.payload.contractDoc,
        isDocumentLoading: false,
        error: null,
      };
     case UPDATE_CONTRACT_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
     case UPDATE_MIGRATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        migrationDoc: action.payload.migrationDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_MIGRATION_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    // Логика обновления данных документа компании
    case UPDATE_USN_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        usnCompanyDoc: action.payload.usnCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_USN_DOCUMENT_COMPANY_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        bankCompanyDoc: action.payload.bankCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_BANK_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        snilsCompanyDoc: action.payload.snilsCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        passportCompanyDoc: action.payload.passportCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    case UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        decisionCompanyDoc: action.payload.decisionCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
     case UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        migrationCompanyDoc: action.payload.migrationCompanyDoc,
        isDocumentLoading: false,
        error: null,
      };
    case UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE:
      return {
        ...state,
        isDocumentLoading: false,
        error: action.payload.error,
      };
    // Логика получения данных схемы документа по slug
    case GET_SCHEMA_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentSchema: action.payload.documentSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_DOCUMENT_FAILURE:
      return {
        ...state,
        documentSchema: null,
        isDocumentSchemaLoading: false,
        error: action.payload.error,
      };
    case GET_SCHEMA_INN_DOCUMENT_SUCCESS:
      return {
        ...state,
        innDocSchema: action.payload.innDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_USN_DOCUMENT_SUCCESS:
      return {
        ...state,
        usnDocSchema: action.payload.usnDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_BANK_DOCUMENT_SUCCESS:
      return {
        ...state,
        bankDocSchema: action.payload.bankDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_SNILS_DOCUMENT_SUCCESS:
      return {
        ...state,
        snilsDocSchema: action.payload.snilsDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_INCOME_DOCUMENT_SUCCESS:
      return {
        ...state,
        incomeDocSchema: action.payload.incomeDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_DECISION_DOCUMENT_SUCCESS:
      return {
        ...state,
        decisionDocSchema: action.payload.decisionDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS:
      return {
        ...state,
        passportDocSchema: action.payload.passportDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS:
      return {
        ...state,
        contractDocSchema: action.payload.contractDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    case GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        migrationDocSchema: action.payload.migrationDocSchema,
        isDocumentSchemaLoading: false,
        error: null,
      };
    // Логика финализации задачи
    case FINALIZE_TASK_SUCCESS:
      return {
        ...state,
        finalizeTaskData: action.payload.finalizeTaskData,
        finalizeTaskStatus: action.payload.finalizeTaskStatus,
        error: null,
      };
    case FINALIZE_TASK_FAILURE:
      return {
        ...state,
        finalizeTaskData: null,
        finalizeTaskStatus: null,
        error: action.payload.error,
      };
    // Логика получения выписки подозрительных операций
    case GET_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        bankStatementData: action.payload.bankStatementData,
        error: null,
      };
    case GET_BANK_STATEMENT_FAILURE:
      return {
        ...state,
        bankStatementData: [],
        error: action.payload.error,
      };
    // Логика получения пакета выписок
    case GET_BANK_PACKAGES_STATEMENT_SUCCESS:
      return {
        ...state,
        bankPackagesData: action.payload.bankPackagesData,
        error: null,
      };
    case GET_BANK_PACKAGES_STATEMENT_FAILURE:
      return {
        ...state,
        bankPackagesData: [],
        error: action.payload.error,
      };
    // Логика создания нового динамического документа
    case CREATE_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        newDocument: action.payload.newDocument,
        error: null,
      };
    case CREATE_NEW_DOCUMENT_FAILURE:
      return {
        ...state,
        newDocument: null,
        error: action.payload.error,
      };
    // Логика обновления документа
    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        updatedDoc: action.payload.updatedDoc,
        error: null,
      };
    case UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        updatedDoc: null,
        error: action.payload.error,
      };
    // Логика получения списка документов
    case GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        docList: action.payload.docList,
        error: null,
      };
    case GET_DOCUMENT_LIST_FAILURE:
      return {
        ...state,
        docList: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default document;
