import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../../reducers/mainReducer";
import {
  GET_APPLICATION_LIST_SUCCESS,
  GET_APPLICATION_LIST_FAILURE,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
} from "../../constants/actionTypes";
import {
  AlphaListParamsType,
  ApplicationListType,
  ApplicationDocType,
} from "app/types";
import * as ApplicationAPI from "../../api/alpha/application.api";
import { sendActionApplication } from "../../api/alpha/application.api";

export type ApplicationActionsTypes =
  | GetApplicationListSuccessType
  | GetApplicationListFailureType
  | GetApplicationSuccessType
  | GetApplicationFailureType
  ;

// Логика получения списка вакансий
export type GetApplicationListSuccessType = {
  type: typeof GET_APPLICATION_LIST_SUCCESS;
  payload: {
    applicationsList: ApplicationDocType;
  };
};

const getApplicationListSuccess = (applicationsList: ApplicationDocType): GetApplicationListSuccessType => ({
  type: GET_APPLICATION_LIST_SUCCESS,
  payload: {
    applicationsList
  }
});

export type GetApplicationListFailureType = {
  type: typeof GET_APPLICATION_LIST_FAILURE;
  payload: {
    error: any;
  };
};

const getApplicationListFailure = (error: any): GetApplicationListFailureType => ({
  type: GET_APPLICATION_LIST_FAILURE,
  payload: {
    error
  }
});

export const getApplicationsList = (params?: AlphaListParamsType | null):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ApplicationActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ApplicationAPI.getApplications(params);

    dispatch(getApplicationListSuccess(response.data));
  } catch (error: any) {
    dispatch(getApplicationListFailure(error.message));
  }
};

// Логика получения контрагента
export type GetApplicationSuccessType = {
  type: typeof GET_APPLICATION_SUCCESS;
  payload: {
    applicationData: ApplicationListType;
  };
};

const getApplicationSuccess = (applicationData: ApplicationListType): GetApplicationSuccessType => ({
  type: GET_APPLICATION_SUCCESS,
  payload: {
    applicationData
  }
});

export type GetApplicationFailureType = {
  type: typeof GET_APPLICATION_FAILURE;
  payload: {
    error: any;
  };
};

export const getApplicationFailure = (error?: any): GetApplicationFailureType => ({
  type: GET_APPLICATION_FAILURE,
  payload: {
    error
  }
});

export const getApplicationInfo = (applicationUuid?: string | null):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ApplicationActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ApplicationAPI.getApplication(applicationUuid);

    dispatch(getApplicationSuccess(response.data));
  } catch (error: any) {
    dispatch(getApplicationFailure(error.message));
  }
};

//TODO ручка на бэке поменяется, придется в дальнейшем переписать логику
export const sendApplicationAction = (applicationUuid?: string | null, params?: AlphaListParamsType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ApplicationActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ApplicationAPI.sendActionApplication(applicationUuid, params);

    dispatch(getApplicationSuccess(response.data));
  } catch (error: any) {
    dispatch(getApplicationFailure(error.message));
  }
};
