import React, { FC, JSX, useEffect, useState } from "react";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./PersonalInformation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import {
  getEmployeeDocumentData,
  getDocumentSchema,
} from "../../../../../actions/document.actions";
import { AppDispatch } from "../../../../../store/store";
import dayjs from "dayjs";
import { handleSnilsNumberChange } from "../../../../../utils/snilsNumberMask";
import { handleInnNumberChange } from "../../../../../utils/innNumberMask";
import TagCustom from "../../../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument from "../../../ZeroClientProfileDocument/ZeroClientProfileDocument";
import { renderEmployeeItem } from "../EmployeeCard";

interface IPersonalInformationProps {
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  passportUuid: string;
  snilsUuid: string;
  innUuid: string;
}

const PersonalInformation: FC<IPersonalInformationProps> = ({
  documentUuid,
  showImageViewer,
  passportUuid,
  snilsUuid,
  innUuid
}): JSX.Element => {
  const [isConfirmedPassport, setConfirmedPassport] = useState<boolean>(false);
  const [isConfirmedSnils, setConfirmedSnils] = useState<boolean>(false);
  const [isConfirmedInn, setConfirmedInn] = useState<boolean>(false);
  const [schemaName, setSchemaName] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const {
    schemaEmployeeProfile,
    documentEmployeeProfile,
  } = useSelector((state: AppStateType) => state.employeeProfile);

  const {
    innDoc,
    snilsDoc,
    passportDoc,
    innDocSchema,
    passportDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (innDoc?.fields?.document_status === "APPROVED") {
      setConfirmedInn(true);
    } else {
      setConfirmedInn(false);
    }

    if (snilsDoc?.fields?.document_status === "APPROVED") {
      setConfirmedSnils(true);
    } else {
      setConfirmedSnils(false);
    }

    if (passportDoc?.fields?.document_status === "APPROVED") {
      setConfirmedPassport(true);
    } else {
      setConfirmedPassport(false);
    }
  }, [innDoc, snilsDoc, passportDoc]);

  const personalDocsCardLabels = {
    inn_document: schemaEmployeeProfile?.fields?.find(i => i.name === "inn_document"),
    snils_document: schemaEmployeeProfile?.fields?.find(i => i.name === "snils_document"),
    passport_document: schemaEmployeeProfile?.fields?.find(i => i.name === "passport_document"),
  };

  const personalInfoCardLabels = {
    inn: innDocSchema?.fields?.find(i => i.name === "inn"),
    sex: innDocSchema?.fields?.find(i => i.name === "sex"),
    first_name: passportDocSchema?.fields?.find(i => i.name === "first_name"),
    second_name: passportDocSchema?.fields?.find(i => i.name === "second_name"),
    last_name: passportDocSchema?.fields?.find(i => i.name === "last_name"),
    series_number: passportDocSchema?.fields?.find(i => i.name === "series_number"),
    issued_date: passportDocSchema?.fields?.find(i => i.name === "issued_date"),
    issued_by: passportDocSchema?.fields?.find(i => i.name === "issued_by"),
    date_of_birth: passportDocSchema?.fields?.find(i => i.name === "date_of_birth"),
    citizenship: passportDocSchema?.fields?.find(i => i.name === "citizenship"),
  };

  const renderAgreed = (): JSX.Element => {
    return (
      <TagCustom color="success" text="Согласовано"/>
    );
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderConfirmationPassport = (): JSX.Element => {
    return (
      isConfirmedPassport ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderConfirmationSnils = (): JSX.Element => {
    return (
      isConfirmedSnils ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderConfirmationInn = (): JSX.Element => {
    return (
      isConfirmedInn ? renderAgreed() : renderNotAgreed()
    );
  };

  const renderCause = (cause?: string): JSX.Element => {
    return (
      <div className={`${css.cause} mb-3`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {cause}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (schemaName) {
      dispatch(getEmployeeDocumentData(schemaName, documentUuid));
    }
  }, [dispatch, schemaName]);

  const openImageViewer = (text: string): void => {
    setSchemaName(() => {
      dispatch(getDocumentSchema(text));
      return text;
    });
  };

  return (
    <>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          {personalDocsCardLabels?.passport_document?.label ?? "Паспорт"}
        </h1>
        {passportDoc?.fields ? (
          <>
            <div>
              <ul>
                {!!passportDoc?.fields?.last_name && renderEmployeeItem(
                  personalInfoCardLabels?.last_name?.label,
                  passportDoc?.fields?.last_name
                )}
                {!!passportDoc?.fields?.first_name && renderEmployeeItem(
                  personalInfoCardLabels?.first_name?.label,
                  passportDoc?.fields?.first_name
                )}
                {!!passportDoc?.fields?.second_name && renderEmployeeItem(
                  personalInfoCardLabels?.second_name?.label,
                  passportDoc?.fields?.second_name
                )}
                {!!passportDoc?.fields?.sex && renderEmployeeItem(
                  personalInfoCardLabels?.sex?.label ?? "Пол",
                  passportDoc?.fields?.sex
                )}
                {!!passportDoc?.fields?.series_number && renderEmployeeItem(
                  personalInfoCardLabels?.series_number?.label,
                  `
                    ${passportDoc?.fields?.series_number}
                    ${passportDoc?.fields?.passport_number}
                  `
                )}
                {!!passportDoc?.fields?.issued_date && renderEmployeeItem(
                  personalInfoCardLabels?.issued_date?.label,
                  dayjs(passportDoc?.fields?.issued_date).format("DD.MM.YYYY")
                )}
                {!!passportDoc?.fields?.issued_by && renderEmployeeItem(
                  personalInfoCardLabels?.issued_by?.label,
                  passportDoc?.fields?.issued_by
                )}
                {!!passportDoc?.fields?.date_of_birth && renderEmployeeItem(
                  personalInfoCardLabels?.date_of_birth?.label,
                  dayjs(passportDoc?.fields?.date_of_birth).format("DD.MM.YYYY")
                )}
                {!!passportDoc?.fields?.citizenship && renderEmployeeItem(
                  personalInfoCardLabels?.citizenship?.label,
                  passportDoc?.fields?.citizenship
                )}
              </ul>
            </div>
            <span className="mb-2">
              {!!documentEmployeeProfile?.fields?.passport_document?.length && (
                <div className="flex items-center mb-3">
                  <ButtonCustom
                    id="checkEmployeePassport"
                    className="w-36 mr-2"
                    type="primary"
                    ghost
                    text="Проверить файлы"
                    onClick={() => {
                      showImageViewer(
                        "passport-schema",
                        passportUuid
                      );
                      openImageViewer("passport-schema");
                    }
                    }
                  />
                  {!!passportDoc?.fields?.document_status && renderConfirmationPassport()}
                </div>
              )}
              {!isConfirmedPassport && (
                !passportDoc?.fields?.decline_reason ? null : renderCause(passportDoc?.fields?.decline_reason)
              )}
            </span>
          </>
        ) : (
          <ZeroClientProfileDocument variant="Паспорт сотрудника" />
        )}
      </div>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          {personalDocsCardLabels?.snils_document?.label ?? "СНИЛС"}
        </h1>
        {snilsDoc?.fields ? (
          <>
            <div>
              <ul>
                {!!snilsDoc?.fields?.snils_number && renderEmployeeItem(
                  personalDocsCardLabels?.snils_document?.label,
                  handleSnilsNumberChange(snilsDoc?.fields?.snils_number)
                )}
              </ul>
            </div>
            <span className="mb-2">
              {!!documentEmployeeProfile?.fields?.snils_document?.length && (
                <div className="flex items-center mb-3">
                  <ButtonCustom
                    id="checkEmployeeSnils"
                    className="w-36 mr-2"
                    type="primary"
                    ghost
                    text="Проверить файлы"
                    onClick={() => {
                      showImageViewer(
                        "snils-schema",
                        snilsUuid
                      );
                      openImageViewer("snils-schema");
                    }
                    }
                  />
                  {!!snilsDoc?.fields?.document_status && renderConfirmationSnils()}
                </div>
              )}
              {!isConfirmedSnils && (
                !snilsDoc?.fields?.decline_reason ? null : renderCause(snilsDoc?.fields?.decline_reason)
              )}
            </span>
          </>
        ) : (
          <ZeroClientProfileDocument variant="СНИЛС сотрудника" />
        )}
      </div>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-5">
          {personalDocsCardLabels?.inn_document?.label ?? "ИНН"}
        </h1>
        {innDoc?.fields ? (
          <>
            <div>
              <ul>
                {!!innDoc?.fields?.inn && renderEmployeeItem(
                  personalInfoCardLabels?.inn?.label,
                  handleInnNumberChange(innDoc?.fields?.inn ?? "")
                )}
              </ul>
            </div>
            <span className="mb-2">
              {!!documentEmployeeProfile?.fields?.inn_document?.length && (
                <div className="flex items-center mb-3">
                  <ButtonCustom
                    id="checkEmployeeInn"
                    className="w-36 mr-2"
                    type="primary"
                    ghost
                    text="Проверить файлы"
                    onClick={() => {
                      showImageViewer(
                        "inn-schema",
                        innUuid
                      );
                      openImageViewer("inn-schema");
                    }
                    }
                  />
                  {!!innDoc?.fields?.document_status && renderConfirmationInn()}
                </div>
              )}
              {!isConfirmedInn && (
                !innDoc?.fields?.decline_reason ? null : renderCause(innDoc?.fields?.decline_reason)
              )}
            </span>
          </>
        ) : (
          <ZeroClientProfileDocument variant="ИНН сотрудника" />
        )}
      </div>
    </>
  );
};

export default PersonalInformation;
