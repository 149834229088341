import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { Tabs, TabsProps } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ContractorCardInfo from "./ContractorCardInfo/ContractorCardInfo";
import ContractorCardResume from "./ContractorCardResume/ContractorCardResume";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getContractorData } from "../../../../actions/alpha/contractors.actions";
import { ContractorType } from "app/types";
import { AppStateType } from "../../../../reducers/mainReducer";
import css from "./ContractorCard.module.css";
import ApplicationList from "../../Applications/ApplicationsList/ApplicationList";

interface IContractorCardProps {
}

//TODO: Компонент пока моковый, после готового АПИ будем пересматривать логику и верстку
const ContractorCard: FC<IContractorCardProps> = (): JSX.Element => {
  const [contractorUuid, setContractorUuid] = useState<string>("");
  const [isResumeCreationBlock, setResumeCreationBlock] = useState<boolean>(false);
  const [activeContractorCardTab, setActiveContractorCardTab] = useState<string>("1");
  const [isContractorUpdated, setContractorUpdated] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const path: string = useLocation().pathname;

  const contractorData: ContractorType | null =
    useSelector((state: AppStateType) => state.contractors.contractorData?.fields) ?? null;

  useEffect(() => {
    //Получаем uuid контрагента
    const uuid: string = path?.split("/")?.slice(-1)?.toString();

    setContractorUuid(uuid);
    dispatch(getContractorData(uuid));
  }, [path]);

  useEffect(() => {
    dispatch(getDocumentSchema("client-company"));
  }, []);

  useEffect(() => {
    if (isContractorUpdated) {
      setContractorUpdated(false);
      setTimeout(() => dispatch(getContractorData(contractorUuid)), 500);
    }
  }, [isContractorUpdated]);

  const navigate: NavigateFunction = useNavigate();

  //данные табов в карточке контрагента
  const contractorCardTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "О контрагенте",
      children: (
        <ContractorCardInfo
          contractorUuid={contractorUuid}
          contractorData={contractorData}
          setContractorUpdated={setContractorUpdated}
        />
      ),
      destroyInactiveTabPane: true
    },
    {
      key: "2",
      label: "Заявки",
      children: (
        <ApplicationList isContractor/>
      ),
      destroyInactiveTabPane: true
    },
    {
      key: "3",
      label: "Резюме",
      children: (
        <ContractorCardResume contractorUuid={contractorUuid} />
      ),
      destroyInactiveTabPane: true
    }
  ];

  const handleContractorCardTabChange = (key: string): void => {
    setActiveContractorCardTab(key);
  };

  return (
    <div className={css.container}>
      <ButtonCustom
        className={css.returnBtn}
        type="link"
        text="Назад"
        onClick={() => navigate("/alpha/contractors")}
      >
        <ArrowLeftOutlined />
      </ButtonCustom>
      <h1 className={css.contractorCardHeader}>
        Контрагент {contractorData?.name}
      </h1>
      <Tabs
        className="mb-[18px]"
        defaultActiveKey="1"
        items={contractorCardTabs}
        activeKey={activeContractorCardTab}
        onChange={handleContractorCardTabChange}
      />
    </div>
  );
};

export default ContractorCard;
