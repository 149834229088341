import React, { FC, JSX, useEffect, useState } from "react";
import css from "./VacancyCard.module.css";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow_left_icon.svg";
import { ReactComponent as DropDownMenu } from "../../../../assets/icons/dropdown_icon.svg";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { Dropdown, MenuProps, Tabs, TabsProps } from "antd";
import AboutVacancy from "./AboutVacancy/AboutVacancy";
import { useSelector } from "react-redux";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { INavigationProps } from "../../Resume/CreateResume/CreateResume";
import ApplicationList from "../../Applications/ApplicationsList/ApplicationList";
import { AppStateType } from "../../../../reducers/mainReducer";
import { VacancyListType } from "app/types";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IVacanciesCardProps {
}

const VacancyCard: FC<IVacanciesCardProps> = (): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>("vacancy");
  const [vacancyUuid, setVacancyUuid] = useState<string>("");

  const navigate: NavigateFunction = useNavigate();

  const handleTabChange = (key: string): void => {
    setActiveKey(key);
  };

  const vacancyData: VacancyListType | null = useSelector((state: AppStateType) => state.vacancy.vacancyData);

  const applicationTotalDocs: number= useSelector((state: AppStateType) => state.application.applicationTotalDocs);

  const itemsTabs: TabsProps["items"] = [
    {
      key: "vacancy",
      label: "О вакансии",
      destroyInactiveTabPane: true,
      children: (
        <AboutVacancy
          vacancyData={vacancyData}
          setVacancyUuid={setVacancyUuid}/>
      ),
    },
    {
      key: "applications",
      label: (
        <div className="flex items-center">
          <div className="mr-1">Заявки</div>
          {applicationTotalDocs && (
            <div
              className={css.counterLabel}
              style={activeKey === "applications" ? { backgroundColor: "#EDF1FF" } : { backgroundColor: "#F0F1F3" }}
            >
              {applicationTotalDocs}
            </div>
          )}
        </div>
      ),
      children: (
        <ApplicationList
          isVacancyCard
          vacancyUuid={vacancyUuid}
        />
      )
    },
  ];

  const isVacancyPublished: boolean = vacancyData?.fields?.vacancy_status === "ACTIVE";

  const menuItems: MenuProps["items"] = [
    isVacancyPublished ?
      {
      label: (<div>Снять с публикации</div>),
      key: "1",
      } : {
        label: (<div>Опубликовать</div>),
        key: "2",
      },
    isVacancyPublished
      ? {
        label: (<div>Удалить</div>),
        key: "3",
        danger: true,
      } : null,
  ];

  const {
    vacancyStatus
  }: INavigationProps = useLocation()?.state ?? {} as INavigationProps;

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const { isAlphaContractor, isAlphaAdmin } = roleResolver(decodedToken);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const canUpdateApplication: boolean = isAlphaContractor && activeKey === "applications" || isAlphaAdmin;

  return (
    <div className={css.container}>
      <div className={`${css.cardsHeader} mb-[18px]`}>
        <div className="w-[88%]">
          <ButtonCustom
            className={css.buttonReturn}
            type="link"
            text="Назад"
            onClick={() => navigate("/alpha/vacancies", { state: vacancyStatus })}
          >
            <ArrowLeft/>
          </ButtonCustom>
          <div className="flex">
            <h1 className={css.header}>Вакансия «{vacancyData?.fields?.vacancy_name}»</h1>
          </div>
        </div>
        <div className="flex">
          <ButtonCustom
            className={canUpdateApplication ? css.buttonChange : "hidden"}
            type="primary"
            text={activeKey === "applications" && !isAlphaAdmin ? "Создать заявку" : "Изменить"}
            onClick={(): void => {
              canUpdateApplication
                ? navigate("/alpha/create_application", { state: vacancyUuid })
                : navigate(`/alpha/update_vacancy/${vacancyUuid}`);
            }}
          />
          <div className={css.dropdownButton}>
            <Dropdown
              className="w-full h-full p-[8px]"
              menu={{items: menuItems}}
            >
              <DropDownMenu />
            </Dropdown>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey={activeKey}
        items={itemsTabs}
        onChange={handleTabChange}
      />
    </div>
  );
};

export default VacancyCard;
