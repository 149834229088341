interface ItemsPerformersProps {
  value: string,
  label: string,
}

export const performersRoles:ItemsPerformersProps[] = [
  {
    value: "Бухгалтер",
    label: "Бухгалтер",
  },
  {
    value: "Ассистент",
    label: "Ассистент",
  },
  {
    value: "Сотрудник КЦ",
    label: "Сотрудник КЦ",
  },
];
