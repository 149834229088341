import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import {
  SchemaType,
  ContractorType,
  DocumentSchemaLabelMapType,
} from "app/types";
import { deadlineDays } from "../../../../../utils/deadlineDays";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { documentSchemaLabelsMapper } from "../../../../../utils/documentSchemaLabelsMapper";
import TagCustom from "../../../../ui-kit/TagCustom/TagCustom";
import ContractorCardEditInfoModal from "./ContractorCardEditInfoModal/ContractorCardEditInfoModal";
import dayjs from "dayjs";
import css from "./ContractorCardInfo.module.css";

interface IContractorCardInfoProps {
  contractorUuid: string;
  contractorData: ContractorType | null;
  setContractorUpdated: Dispatch<SetStateAction<boolean>>;
}

//TODO: Компонент пока моковый, после готового АПИ будем пересматривать логику и верстку
const ContractorCardInfo: FC<IContractorCardInfoProps> = ({
  contractorUuid,
  contractorData,
  setContractorUpdated,
}): JSX.Element => {
  const [isContractorContactsEdited, setContractorContactsEdited] = useState<boolean>(false);
  const [isContractorCardEditInfoModalOpen, setContractorCardEditInfoModalOpen] = useState<boolean>(false);

  const documentSchema: SchemaType | null =
    useSelector((state: AppStateType) => state.document.documentSchema);

  const openEditContractorContacts = (): void => {
    setContractorContactsEdited(true);
    setContractorCardEditInfoModalOpen(true);
  };

  const contractorCardLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(documentSchema);

  return (
    <>
      <div className={css.contractorCardInfoBlock}>
        <div className="flex items-center justify-between">
          <h2 className={css.contractorCardAboutHeader}>
            О контрагенте
          </h2>
          <ButtonCustom
            type="link"
            text="Редактировать"
            onClick={() => setContractorCardEditInfoModalOpen(true)}
          />
        </div>
        <ul>
          <li className="flex items-center mb-[8px]">
            <span className={css.contractorCardAboutLabel}>
              {contractorCardLabel?.name?.label}
            </span>
            <span className={css.contractorCardAboutData}>
              {contractorData?.name}
            </span>
          </li>
          <li className="flex items-center mb-[8px]">
            <span className={css.contractorCardAboutLabel}>
               {contractorCardLabel?.inn?.label}
            </span>
            <span className={css.contractorCardAboutData}>
              {contractorData?.inn}
            </span>
          </li>
          <li className="flex items-center">
            <span className={css.contractorCardAboutLabel}>
              {contractorCardLabel?.registered_country?.label}
            </span>
            <span className={css.contractorCardAboutData}>
              {contractorData?.registered_country}
            </span>
          </li>
        </ul>
      </div>
      {/*Временно комментируем моковый блок, пока на бэке не добавят сущность договора*/}
      {/*<div className={css.contractorCardInfoBlock}>*/}
      {/*  <h2 className={css.contractorCardAboutHeader}>*/}
      {/*    Договор*/}
      {/*  </h2>*/}
      {/*  <ul>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Статус договора*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        <TagCustom*/}
      {/*          text="Активный"*/}
      {/*          color="customSuccess"*/}
      {/*        />*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        № договора*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        AB-4231124/23*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Дата подписания договора*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        {dayjs().format("DD.MM.YYYY")}*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Срок действия договора*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        <span className="inline-block mr-[12px]">*/}
      {/*          до {dayjs().format("DD.MM.YYYY")}*/}
      {/*        </span>*/}
      {/*        <TagCustom*/}
      {/*          color="customSkills"*/}
      {/*          text={deadlineDays(dayjs().format("DD.MM.YYYY")) as string}*/}
      {/*        />*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Заказчик*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        ООО “Компания”*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Исполнитель*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        ООО “Компания”*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-center mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Сумма договора*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        123456 ₽*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-start mb-[8px]">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Владелец договора на стороне заказчика*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        Юзер*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*    <li className="flex items-start">*/}
      {/*      <span className={css.contractorCardAboutLabel}>*/}
      {/*        Владелец договора на стороне исполнителя*/}
      {/*      </span>*/}
      {/*      <span className={css.contractorCardAboutData}>*/}
      {/*        Юзер*/}
      {/*      </span>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</div>*/}
      <div className={css.contractorCardInfoBlock}>
        <div className="flex items-center justify-between">
          <h2 className={css.contractorCardAboutHeader}>
            Контакты контрагента
          </h2>
          <ButtonCustom
            type="link"
            text="Редактировать"
            onClick={openEditContractorContacts}
          />
        </div>
        <ul>
          <li className="flex items-center mb-[8px]">
            <span className={css.contractorCardAboutLabel}>
              {contractorCardLabel?.phone?.label}
            </span>
            <span className={css.contractorCardAboutData}>
              {contractorData?.phone}
            </span>
          </li>
          <li className="flex items-center">
            <span className={css.contractorCardAboutLabel}>
              {contractorCardLabel?.email?.label}
            </span>
            <span className={css.contractorCardAboutData}>
              {contractorData?.email}
            </span>
          </li>
        </ul>
      </div>
      {isContractorCardEditInfoModalOpen && (
        <ContractorCardEditInfoModal
          contractorUuid={contractorUuid}
          contractorData={contractorData}
          setContractorUpdated={setContractorUpdated}
          isContractorContactsEdited={isContractorContactsEdited}
          setContractorContactsEdited={setContractorContactsEdited}
          isContractorCardEditInfoModalOpen={isContractorCardEditInfoModalOpen}
          setContractorCardEditInfoModalOpen={setContractorCardEditInfoModalOpen}
        />
      )}
    </>
  );
};

export default ContractorCardInfo;
