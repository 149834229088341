import React, {
  FC,
  JSX,
  useRef,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  SchemaType,
  VacancyType,
  VacancyListType,
  ApplicationType,
  ResumesFieldsType,
  ResumesResultsType,
  ApplicationListType,
  DocumentSchemaLabelMapType,
} from "app/types";
import { AppDispatch } from "../../../../store/store";
import { getResumeInfo } from "../../../../actions/alpha/resumes.actions";
import { getVacancyData } from "../../../../actions/alpha/vacancy.actions";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { getApplicationFailure, getApplicationInfo } from "../../../../actions/alpha/application.actions";
import { Card } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ZeroHistory from "../../../Tasks/TaskCards/TaskCardInfo/ZeroHistory/ZeroHistory";
import { renderSkills } from "../../../../utils/RenderSkills/RenderSkills";
import { downloadFile, downloadUrl } from "../../../../utils/downloadFile";
import { documentSchemaLabelsMapper } from "../../../../utils/documentSchemaLabelsMapper";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { INavigationProps } from "../../Resume/CreateResume/CreateResume";
import { roleResolver } from "../../../../utils/roleResolver";
import { JWTPayload } from "jose";
import dayjs from "dayjs";
import css from "./ApplicationCard.module.css";
import ModalAction from "./ModalAction/ModalAction";
import { renderAlphaDocStatus } from "../../../../utils/renderAlphaDocStatus";

interface IApplicationCardProps {
}

const ApplicationCard: FC<IApplicationCardProps> = (): JSX.Element => {
  const refApplicationInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refApplicantContent: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refEmployeeInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refVacancyInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [renderBackToListBtn, setRenderBackToListBtn] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const pathname: string = useLocation()?.pathname;

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const applicationUuid: string = pathname?.split("/")?.slice(-1)?.toString();

    dispatch(getDocumentSchema("supplier-request"));
    dispatch(getApplicationInfo(applicationUuid));
  }, [pathname]);

  const applicationData: ApplicationListType | null
    = useSelector((state: AppStateType) => state.application.applicationData);

  const vacancyData: VacancyListType | null
    = useSelector((state: AppStateType) => state.vacancy.vacancyData);

  const resume: ResumesResultsType | null
    = useSelector((state: AppStateType) => state.resume.resume);

  const documentSchema: SchemaType | null
    = useSelector((state: AppStateType) => state.document.documentSchema);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    financing_source,
    request_comment,
    request_author,
    hiring_manager,
    direction_date,
    desired_date,
    performer,
    customer,
    resource,
    vacancy,
    name,
  } = applicationData?.fields ?? {} as ApplicationType;

  const {
    end_date,
    division,
    project_name,
    vacancy_name,
    published_at,
    working_hours,
    document_owner,
    working_address,
    occupancy_format,
    occupancy_legal_form,
  } = vacancyData?.fields ?? {} as VacancyType;

  const {
    cv_file,
    skills,
  } = resume?.fields ?? {} as ResumesFieldsType;

  useEffect(() => {
    if (vacancy?.length) dispatch(getVacancyData(vacancy?.[0]?.uuid));
    if (resource?.length) dispatch(getResumeInfo(resource?.[0]?.uuid));
  }, [vacancy, resource]);

  const applicationCardLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(documentSchema);

  const actionHistoryHeight: number = (refApplicationInfo?.current?.clientHeight ?? 0) +
    (refApplicantContent?.current?.clientHeight ?? 0) +
    (refEmployeeInfo?.current?.clientHeight ?? 0) +
    (refVacancyInfo?.current?.clientHeight ?? 0);

  //TODO: исправить slug на динамичную схему из документа, когда по каждому доку будет готова схема
  //TODO: частично отрабатывает динамическая схема, но список схем неполный
  const renderApplicationCardBlock = (
    value: string | null,
    slug: string,
    isLastChild = false,
  ): JSX.Element | null | "" => {
    return value && (
      <li className={`flex items-center ${isLastChild ? "mb-2" : ""}`}>
        <span className={css.applicationParamsLabel}>
          {slug}
        </span>
        <span className={css.applicationParamsData}>
          {value}
        </span>
      </li>
    );
  };

  const renderDate = (date: string): string => dayjs(date)?.isValid() ? dayjs(date)?.format("DD.MM.YY") : "";

  const {
    vacancyUuid,
    applicationStatus,
  }: INavigationProps = useLocation()?.state ?? {} as INavigationProps;

  const backToApplicationCard = (): void => {
    navigate("/alpha/applications", {state: applicationStatus});
    dispatch(getApplicationFailure());
  };

  const isAlphaAdmin: boolean = roleResolver(decodedToken).isAlphaAdmin;
  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;

  useEffect(() => {
    setRenderBackToListBtn(isAlphaContractor && !!vacancyUuid || isAlphaAdmin);
  }, []);

  return (
    <div className="container-block">
      <div className="flex justify-between items-center mb-[18px]">
        <div className="flex flex-col">
          {renderBackToListBtn && (
            <ButtonCustom
              className={css.returnBtn}
              type="link"
              text="Назад"
              onClick={() => {
                !vacancyUuid
                  ? backToApplicationCard()
                  : navigate(`/alpha/vacancy/${vacancyUuid}`);
              }}
            >
              <ArrowLeftOutlined />
            </ButtonCustom>
          )}
          <div className="flex">
            <h1 className={css.applicationHeader}>
              Заявка №{applicationData?.fields?.number}
            </h1>
            <span>{renderAlphaDocStatus(applicationData?.fields?.request_status as string)}</span>
          </div>
        </div>
        <div>
          <ButtonCustom
            className="mr-2"
            size="large"
            type="primary"
            text="Действие по заявке"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className={css.applicationCard}>
          <Card ref={refApplicationInfo} className="mb-3">
            <h2 className={css.applicationInfoHeader}>
              О заявке
            </h2>
            {renderApplicationCardBlock(direction_date, "Дата время направления")}
            {renderApplicationCardBlock(performer, "Компания")}
            {renderApplicationCardBlock(customer, "Компания заказчик")}
            {renderApplicationCardBlock(request_author, applicationCardLabel?.request_author?.label)}
            {renderApplicationCardBlock(financing_source, applicationCardLabel?.financing_source?.label)}
            {renderApplicationCardBlock(request_comment, applicationCardLabel?.request_comment?.label)}
          </Card>
          <Card ref={refApplicantContent} className="mb-3">
            <h2 className={css.applicationInfoHeader}>
              Соискатель
            </h2>
            {!!skills?.length && (
              <li className="flex items-center mb-2">
                <span className={css.applicationParamsLabel}>
                  Скиллы
                </span>
                <span className={css.applicationParamsData}>
                  {renderSkills(skills)}
                </span>
              </li>
            )}
            {renderApplicationCardBlock(desired_date, "Желаемый срок выхода на работу")}
            {renderApplicationCardBlock(working_hours, "Режим работы")}
            {renderApplicationCardBlock(working_address, "Адрес места работы")}
            {cv_file && (
              <li className="flex items-center mb-2">
                <span className={css.applicationParamsLabel}>
                  Резюме соискателя
                </span>
                <a className={`${css.applicationParamsData} flex blue-color items-center`}>
                  <AttachFileIcon className="flex-shrink-0 mr-1" />
                  <div
                    className={css.docFile}
                    onClick={() => downloadFile(cv_file, downloadUrl(cv_file))}
                  >
                    Файл резюме
                  </div>
                </a>
              </li>
            )}
          </Card>
          <Card ref={refEmployeeInfo} className="mb-3">
            <h2 className={css.applicationInfoHeader}>
              Наниматель
            </h2>
            {renderApplicationCardBlock(project_name, "Наименование проекта")}
            {renderApplicationCardBlock(name, "Наниматель")}
            {renderApplicationCardBlock(hiring_manager, "Нанимающий менеджер")}
          </Card>
          <Card ref={refVacancyInfo} className="mb-3">
            <h2 className={css.applicationInfoHeader}>
              О вакансии
            </h2>
            {renderApplicationCardBlock(vacancy_name, applicationCardLabel?.vacancy?.label)}
            {renderApplicationCardBlock(occupancy_legal_form?.[0]?.label ?? "", "Форма занятости")}
            {renderApplicationCardBlock(occupancy_format, "Форма найма")}
            {renderApplicationCardBlock(division?.[0]?.label ?? "", applicationCardLabel?.division?.label)}
            {renderApplicationCardBlock(document_owner?.[0]?.label ?? "", "Владелец документа")}
            {renderApplicationCardBlock(
              document_owner?.[0]?.label ?? "",
              "Перечень юр. лиц, кому доступна потребность из справочника юр. лиц")
            }
            {renderApplicationCardBlock(published_at, applicationCardLabel?.published_at?.label)}
            {renderApplicationCardBlock(renderDate(vacancyData?.created_at ?? ""), "Дата создания")}
            {renderApplicationCardBlock(renderDate(vacancyData?.updated_at ?? ""), "Дата обновления")}
            {renderApplicationCardBlock(renderDate(end_date), "Срок окончания работ")}
          </Card>
        </div>
        <Card className={css.applicationCard}>
          <h2 className={css.applicationInfoHeader}>
            История действий
          </h2>
          <ZeroHistory
            heightHistory={actionHistoryHeight}
            isApplication
          />
        </Card>
      </div>
      <ModalAction
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default ApplicationCard;
