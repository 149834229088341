import React, { FC, JSX, Key, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  MenuProps,
  Table,
  TableColumnsType
} from "antd";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings-icon.svg";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import { TableRowSelection } from "antd/es/table/interface";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { SearchOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import DeleteVacancyModal from "./DeleteVacancyModal/DeleteVacancyModal";
import { renderAlphaDocStatus } from "../../../../utils/renderAlphaDocStatus";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { AppDispatch } from "../../../../store/store";
import { getVacanciesList } from "../../../../actions/alpha/vacancy.actions";
import { AlphaListParamsType, OccupancyLegalFormListType, VacancyType } from "app/types";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import css from "./VacancyListing.module.css";
import { INavigationProps } from "../../Resume/CreateResume/CreateResume";
import * as DocumentAPI from "../../../../api/document.api";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IVacancyListingProps {
  vacancyStatus: string | null;
}

interface IVacancyListing {
  key: React.Key;
  vacancy_name: string;
  published_at: string;
  vacancy_status: string;
  occupancy_legal_form?: string;
}

const VacancyListing: FC<IVacancyListingProps> = ({ vacancyStatus }): JSX.Element => {
  const [occupancyFormList, setOccupancyFormList] = useState<OccupancyLegalFormListType[]>([]);
  const [selectedVacancies, setSelectedVacancies] = useState<Key[]>([]);
  const [selectedHiringForm, setSelectedHiringForm] = useState<string[]>([]);
  const [deleteVacancyKey, setDeleteVacancyKey] = useState<Key | null>(null);
  const [deleteVacancyName, setDeleteVacancyName] = useState<string | null>(null);
  const [isDeleteVacancyModalOpen, setDeleteVacancyModalOpen] = useState<boolean>(false);
  const [vacancyListParams, setVacancyListParams] = useState<AlphaListParamsType | null>( {
    page: 1,
    page_size: 5,
    cv_status: vacancyStatus,
  });

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isAlphaContractor: boolean = roleResolver(decodedToken).isAlphaContractor;

  useEffect(() => {
    DocumentAPI.getDocumentList("employment-form")
    .then((response) => setOccupancyFormList(response.data.results))
    .catch((err) => console.error("Get employment-form error:", err));
  }, []);

  const {
    vacancyList,
    vacancyPage,
    vacancyPageSize,
    vacancyTotalDocs,
  } = useSelector((state: AppStateType) => state.vacancy);

  useEffect(() => {
   dispatch(getVacanciesList(vacancyListParams));
  }, [vacancyListParams]);

  const handleVacancyPageChange = (newPage: number): void => {
    setVacancyListParams({
      ...vacancyListParams,
      page: newPage,
    });
  };

  const handleVacancyPageSizeChange = (newPageSize: number): void => {
    setVacancyListParams({
      ...vacancyListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const navigationProps: INavigationProps = {
    vacancyStatus: vacancyStatus === null ? "ALL" : vacancyStatus,
  };

  const renderTableLink = (text: string, record: IVacancyListing) => (
    <Link
      className={css.tableCell}
      to={`/alpha/vacancy/${record?.key}`}
      state={navigationProps}
    >
      {text}
    </Link>
  );

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setVacancyListParams);

  const onSelectVacancyChange = (newSelectedRowKeys: Key[]): void => {
    setSelectedVacancies(newSelectedRowKeys);
  };

  const rowVacancySelection: TableRowSelection<IVacancyListing> | undefined = {
    selectedRowKeys: selectedVacancies,
    onChange: onSelectVacancyChange,
    defaultSelectedRowKeys: selectedVacancies,
    preserveSelectedRowKeys: true,
  };

  const hasSelectedVacancies: boolean = selectedVacancies.length > 0;

  const onHiringFormFilterChange = (e: CheckboxChangeEvent, hiringForm: string): void => {
    const removeHiringFormFilter: string[] =
      selectedHiringForm?.filter((filter: string): boolean => filter !== hiringForm);
    setSelectedHiringForm(e.target.checked ? [...selectedHiringForm, hiringForm] : removeHiringFormFilter);
  };

  const isHiringFormFilterChecked = (taskType: string): boolean => {
    return selectedHiringForm?.includes(taskType);
  };

  const clearHiringFormFilters = (): void => {
    setSelectedHiringForm([]);
  };

  const applyWorkingHoursFilter = (): void => {
    setVacancyListParams({
      ...vacancyListParams,
      cv_status: selectedHiringForm?.length ? `[${selectedHiringForm}]` : null,
    });
  };

  const tableOccupancyFormFilters = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          {occupancyFormList?.map(({ fields }) => (
            <Checkbox
              key={fields.number}
              className="table-checkbox"
              onChange={(e: CheckboxChangeEvent) => onHiringFormFilterChange(e, fields.employment_name)}
              checked={isHiringFormFilterChecked(fields.employment_name)}
            >
              {fields.employment_name}
            </Checkbox>
          ))}
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={clearHiringFormFilters}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={(): void => {
              confirm();
              applyWorkingHoursFilter();
            }}
          />
        </div>
      </div>
    );
  };

  const deleteVacancy = (data: IVacancyListing): void => {
    setDeleteVacancyModalOpen(true);
    setDeleteVacancyKey(data?.key);
    setDeleteVacancyName(data?.vacancy_name);
  };

  const vacancyColumns: TableColumnsType<IVacancyListing> = [
    {
      title: (
        <div className="table-title">
          Наименование вакансии
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "vacancy_name",
      width: "35%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "vacancy_name"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Форма найма
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "occupancy_legal_form",
      width: "35%",
      filtered: !!selectedHiringForm?.length,
      filterDropdown: ({ confirm }) => tableOccupancyFormFilters(confirm),
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "occupancy_legal_form"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Дата публикации
        </div>
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "published_at",
      width: "10%",
      render: renderTableLink,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "published_at"),
      }),
    },
    {
      title: (
        <div className="table-title">
          Статус
        </div>
      ),
      dataIndex: "vacancy_status",
      sorter: true,
      showSorterTooltip: false,
      render: (text: string) => renderAlphaDocStatus(text),
      width: "16%",
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "vacancy_status"),
      }),
    },
    //Кнопки действий моковые, ожидают реализации логики на бэке
    {
      title: "",
      key: "settings",
      width: "4%",
      render: (_, record: IVacancyListing) => {
        const menuItems: MenuProps["items"] = [
          {
            label: <div>Снять с публикации</div>,
            key: "1",
          },
          !isAlphaContractor ? {
            label: <div>Изменить</div>,
            key: "2",
            onClick: () => navigate(`/alpha/update_vacancy/${record?.key}`, { state: navigationProps }),
          } : null,
          {
            label: <div>Удалить</div>,
            key: "3",
            danger: true,
            onClick: () => deleteVacancy(record),
          },
        ];

        return (
          <Dropdown.Button
            menu={{ items: menuItems}}
            buttonsRender={([_leftButton, _rightButton]) => [
              null,
              <Button
                className={css.settingsBtn}
                key={record?.key}
                type="text"
                icon={<SettingsIcon />}
              />,
            ]}
          />
        );
      },
    },
  ];

  const vacancyData: IVacancyListing[] = vacancyList?.map(({ fields }) => {
    const {
      uuid,
      vacancy_name,
      published_at,
      vacancy_status,
      occupancy_legal_form,
    } = fields as VacancyType;

    return {
      key: uuid,
      vacancy_name,
      published_at,
      vacancy_status,
      occupancy_legal_form: occupancy_legal_form?.[0]?.label,
    };
  }) ?? [];

  return (
    <div className="p-[16px] mb-3 bg-white rounded-[8px]">
      <div className="flex items-center justify-between mb-[12px]">
        {hasSelectedVacancies && (
          <div className={`flex items-center ${hasSelectedVacancies? "mr-[6px]" : ""}`}>
            <ButtonCustom
              className="mr-[12px]"
              text="Снять с публикации"
              type="primary"
              size="large"
              ghost
              disabled={!hasSelectedVacancies}
              onClick={() => setSelectedVacancies([])} //в дальнейшем поменять логику onClick
            />
            <ButtonCustom
              className="mr-[12px]"
              text="Удалить выбранные"
              type="primary"
              size="large"
              ghost
              disabled={!hasSelectedVacancies}
              onClick={() => setSelectedVacancies([])} //в дальнейшем поменять логику onClick
            />
          </div>
        )}
        <Input
          className="w-[100%]"
          placeholder="Найти по наименованию вакансии"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVacancyListParams({
            ...vacancyListParams,
            search: e.target.value,
          })}
          suffix={<SearchOutlined />}
          size="large"
          allowClear
        />
      </div>
      <Table
        rowSelection={rowVacancySelection}
        columns={vacancyColumns}
        dataSource={vacancyData}
        pagination={false}
      />
      <PaginationCustom
        className={css.paginationShowTotal}
        total={vacancyTotalDocs}
        showTotal={(total: number, range: number[]): string =>
          `${range[0]}-${range[1]} из ${total}`
        }
        currentPage={vacancyPage}
        defaultPageSize={10}
        pageSize={vacancyPageSize}
        defaultCurrent={1}
        handlePageChange={handleVacancyPageChange}
        handlePageSizeChange={handleVacancyPageSizeChange}
      />
      <DeleteVacancyModal
        isModalOpen={isDeleteVacancyModalOpen}
        setIsModalOpen={setDeleteVacancyModalOpen}
        vacancyName={deleteVacancyName}
      />
    </div>
  );
};

export default VacancyListing;
