import React, { JSX, FC, useState, useEffect } from "react";
import { notification, Table, TableColumnsType, Tooltip } from "antd";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import { AppDispatch } from "../../../store/store";
import {
  getReportsData,
  getReportsPageNumber,
  getReportsPageSize,
  setReportsListingParamsData
} from "../../../actions/reports.action";
import {
  ReportsListingFilterType,
  ReportsListingDataType,
} from "app/types";
import TagCustom from "../../ui-kit/TagCustom/TagCustom";
import css from "./ReportsListing.module.css";
import { downloadFile, downloadUrl } from "../../../utils/downloadFile";
import { useTableSorter } from "../../../utils/handleSorterTable";

interface IReportsListingProps {
}

enum ListingPage {
  firstPage = 1,
}

const ReportsListing: FC<IReportsListingProps> = (): JSX.Element => {
  const [loadingReportId, setLoadingReportId] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();

  const {
    reportsListing,
    pageSize,
    pageNumber,
    totalDocs,
    reportsListingParams
  } = useSelector((state: AppStateType) => state.reports);

  const [paramsData, setParamsData] = useState<ReportsListingFilterType | null>({
      ...reportsListingParams,
      page_size: pageSize,
      page: pageNumber,
      order_by: reportsListingParams?.order_by ? reportsListingParams?.order_by : "-created_at",
  });
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  const handlePageChange = (newPage: number): void => {
    const newParams: ReportsListingFilterType = {
      ...paramsData,
      page: newPage,
    };

    setParamsData(newParams);
    dispatch(setReportsListingParamsData(newParams));
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    const newParams: ReportsListingFilterType = {
      ...paramsData,
      page: ListingPage.firstPage,
      page_size: newPageSize,
    };

    setParamsData(newParams);
    dispatch(setReportsListingParamsData(newParams));
  };

  useEffect(() => {
    setLoadingData(true);
    dispatch(getReportsData(paramsData))
      .finally(() => {
        setLoadingData(false);
      });
    dispatch(setReportsListingParamsData(paramsData));
  }, [dispatch, paramsData]);

  const contentTooltip: JSX.Element = (
    <div className={css.tooltipText}>
      <span>Возникла ошибка при формировании отчёта.</span>
      <span>Пожалуйста, повторите запрос</span>
    </div>
  );

  const downloadReportFailure = (): void => {
    notification.error({
      message: "Ошибка скачивания отчета",
    });
  };

  const downloadReportFile = (reportData: ReportsListingDataType): void => {
    setLoadingReportId(reportData?.file_uuid);
    downloadFile(reportData?.file?.file_name, downloadUrl(reportData?.file_uuid))
      .catch(() => downloadReportFailure)
      .finally(() => setLoadingReportId(null));
  };

  const reportStatusIcon = (text: string, reportData: ReportsListingDataType): JSX.Element | string => {
    const isCurrentLoading: boolean = loadingReportId === reportData?.file_uuid;
    
    switch (text) {
      case ("READY"):
        return (
          <div className="flex justify-between">
            <TagCustom
              text="Готово"
              color="customSuccess"
              className={css.statusDefault}
            />
            <ButtonCustom
              type="primary"
              ghost
              text="Скачать"
              loading={isCurrentLoading}
              disabled={isCurrentLoading}
              onClick={() => downloadReportFile(reportData)}
            />
          </div>
        );
      case ("NEW"):
        return (
          <TagCustom
            text="Новая"
            color="customProcessing"
            className={css.statusDefault}
          />
        );
      case ("IN_PROGRESS"):
        return (
          <TagCustom
            text="В работе"
            color="customWarning"
            className={css.statusDefault}
          />
        );
      case ("ERROR"):
        return (
          <div className="flex justify-between">
            <Tooltip
              placement="top"
              title={contentTooltip}
              overlayStyle={{maxWidth: "500px"}}
            >
              <TagCustom
                text="Ошибка"
                color="customError"
                className={css.statusDefault}
              />
            </Tooltip>
            {/*TODO временно скрываем, т.к нет реализации на бэке*/}
            {/*<ButtonCustom*/}
            {/*  type="primary"*/}
            {/*  danger={true}*/}
            {/*  className={css.buttonError}*/}
            {/*  text="Повторить"*/}
            {/*/>*/}
          </div>
        );
      default:
        return "";
    }
  };

  const dateReports = (text: string): JSX.Element => {
    if (text === null) {
      return <span></span>;
    }

    const [year, month, day]: string[] = text.slice(0, 10).split("-");

    const [hours, minutes]: string[]= text.slice(11, 16).split(":");

    return (
      <div className={`${css.tableCell} flex`}>
        <div>{day}.{month}.{year}</div>
        <div>{hours}:{minutes}</div>
      </div>
    );
  };

  const reportingPeriod = (record: ReportsListingDataType): JSX.Element => {

    const [yearStart, monthStart, dayStart]: string[] = record.date_start.slice(0, 10).split("-");

    const [yearEnd, monthEnd, dayEnd]: string[] = record.date_end.slice(0, 10).split("-");

    return (
      <div className={css.tableCell}>
        <div>{dayStart}.{monthStart}.{yearStart.slice(-2)} - {dayEnd}.{monthEnd}.{yearEnd.slice(-2)}</div>
      </div>
    );
  };

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter("-created_at", setParamsData);

  const organisationColumns: TableColumnsType<ReportsListingDataType> = [
    {
      title: "Дата запроса",
      dataIndex: "created_at",
      key: "date",
      width: "15%",
      className: css.tableHeader,
      showSorterTooltip: false,
      sorter: true,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "created_at"),
      }),
      render: (text: string) => dateReports(text),
    },
    {
      title: "Тип отчета",
      dataIndex: "report_type",
      className: css.tableHeader,
      render: (text: string) => <a className={css.tableCell}>{text}</a>
    },
    {
      title: "Отчетный период",
      dataIndex: ["date_start", "date_end"],
      className: css.tableHeader,
      render: (_text: string, record: ReportsListingDataType) => reportingPeriod(record),
    },
    {
      title: "Статус",
      dataIndex: "status",
      width: "25%",
      className: css.tableHeader,
      showSorterTooltip: false,
      sorter: true,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "status"),
      }),
      render: (text: string, record: ReportsListingDataType) => reportStatusIcon(text, record),
    },
  ];

  return (
    <div>
      <Table
        columns={organisationColumns}
        dataSource={reportsListing ? reportsListing : []}
        loading={isLoadingData}
        pagination={false}
      />
      <PaginationCustom
        className={css.paginationShowTotal}
        total={totalDocs ? totalDocs : 0}
        showTotal={(total: number, range: number[]): string =>
          `${range[0]}-${range[1]} из ${total}`
        }
        currentPage={paramsData?.page}
        defaultPageSize={10}
        pageSize={paramsData?.page_size as number}
        defaultCurrent={1}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default ReportsListing;
