import React, {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./ContractCard.module.css";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../../actions/document.actions";
import dayjs from "dayjs";
import TagCustom from "../../../../ui-kit/TagCustom/TagCustom";
import ZeroClientProfileDocument from "../../../ZeroClientProfileDocument/ZeroClientProfileDocument";
import { DocumentSchemaLabelMapType, SchemaType } from "app/types";
import { documentSchemaLabelsMapper } from "../../../../../utils/documentSchemaLabelsMapper";
import { renderEmployeeItem } from "../EmployeeCard";

interface IContractCardProps {
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  contractUuid: string;
  contractDocType: string;
  hasContractDoc: boolean;
}

const ContractCard: FC<IContractCardProps> = ({
  documentUuid,
  showImageViewer,
  contractUuid,
  contractDocType,
  hasContractDoc,
}): JSX.Element => {
  const [isContractConfirmed, setContractConfirmed] = useState<boolean>(false);
  const [schemaName, setSchemaName] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  const schemaEmployeeProfile: SchemaType | null =
    useSelector((state: AppStateType) => state.employeeProfile.schemaEmployeeProfile);

  const {
    contractDoc,
    contractDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
   if (contractDoc?.fields?.document_status === "APPROVED") {
      setContractConfirmed(true);
    } else {
     setContractConfirmed(false);
   }
  }, [contractDoc]);

  const contractCardDocsLabels = {
    employee_contract: schemaEmployeeProfile?.fields?.find(i => i.name === "employee_contract"),
  };

  const contractCardInfoLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(contractDocSchema);

  const renderConfirmationContract = (): JSX.Element => {
    return contractDoc?.fields.document_status === "APPROVED" ? (
      <TagCustom color="success" text="Согласовано"/>
    ) : (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderCause = (): JSX.Element => {
    return (
      <div className={`${css.cause} mb-5`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {contractDoc?.fields.decline_reason}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (schemaName) {
      dispatch(getEmployeeDocumentData(schemaName, documentUuid));
    }
  }, [dispatch, schemaName]);

  const openImageViewer = (text: string): void => {
    setSchemaName(() => {
      dispatch(getDocumentSchema(text));
      return text;
    });
  };

  return (
    <div>
      <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
        <h1 className="header-text mb-4">
          {contractCardDocsLabels?.employee_contract?.label ?? "Договор"}
        </h1>
        {contractDoc?.fields ? (
          <>
            <ul>
              {!!contractDoc?.fields?.contract_type && renderEmployeeItem(
                  contractCardInfoLabels?.contract_type?.label,
                  contractDoc?.fields?.contract_type,
              )}
              {!!contractDoc?.fields?.contract_date && renderEmployeeItem(
                  contractCardInfoLabels?.contract_date?.label,
                  dayjs(contractDoc?.fields?.contract_date).format("DD.MM.YYYY"),
              )}
              {!!contractDoc?.fields?.expiration_date && renderEmployeeItem(
                  contractCardInfoLabels?.expiration_date?.label,
                  dayjs(contractDoc?.fields?.expiration_date).format("DD.MM.YYYY"),
              )}
              {!!contractDoc?.fields?.position && renderEmployeeItem(
                  contractCardInfoLabels?.position?.label,
                  contractDoc?.fields?.position,
              )}
              {contractDoc?.fields?.rate !== null && renderEmployeeItem(
                  contractCardInfoLabels?.rate?.label,
                  contractDoc?.fields?.rate ?? "",
              )}
              {!!contractDoc?.fields?.salary && renderEmployeeItem(
                  contractCardInfoLabels?.salary?.label,
                  contractDoc?.fields?.salary,
              )}
              {!!contractDoc?.fields?.allowances && renderEmployeeItem(
                  contractCardInfoLabels?.allowances?.label,
                  contractDoc?.fields?.allowances,
              )}
              {!!contractDoc?.fields?.payment_methods && renderEmployeeItem(
                  contractCardInfoLabels?.payment_methods?.label,
                  contractDoc?.fields?.payment_methods,
              )}
              {!!contractDoc?.fields?.workplace && renderEmployeeItem(
                  contractCardInfoLabels?.workplace?.label,
                  contractDoc?.fields?.workplace,
              )}
              {!!contractDoc?.fields?.employment_type && renderEmployeeItem(
                  contractCardInfoLabels?.employment_type?.label,
                  contractDoc?.fields?.employment_type,
              )}
              {!!contractDoc?.fields?.payment_type && renderEmployeeItem(
                  contractCardInfoLabels?.payment_type?.label,
                  contractDoc?.fields?.payment_type,
              )}
            </ul>
            <span className="mb-2">
              {hasContractDoc && (
                <div className="flex items-center mb-5">
                  <ButtonCustom
                    id="checkEmployeeContract"
                    className="w-36 mr-2"
                    type="primary"
                    ghost
                    text="Проверить файлы"
                    onClick={() => {
                      showImageViewer(
                        contractDocType,
                        contractUuid
                      );
                      openImageViewer(contractDocType);
                    }
                    }
                  />
                  {!!contractDoc?.fields.document_status && renderConfirmationContract()}
                </div>
              )}
              {!!contractDoc?.fields.decline_reason && (isContractConfirmed ? null : renderCause())}
            </span>
          </>
        ) : (
          <ZeroClientProfileDocument variant="Договор сотрудника" />
        )}
      </div>
    </div>
  );
};

export default ContractCard;
