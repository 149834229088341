import React, {
  FC,
  JSX,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import DocumentsCard from "./Documents/DocumentsCard";
import BankDetailsCard from "./BankDetails/BankDetailsCard";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "./EmployeeCard.module.css";
import { fetchDocumentEmployee, fetchSchemaEmployee } from "../../../../../../actions/employee.actions";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import {
  getDocumentFailure,
  getDocumentSchema,
  getEmployeeDocumentData,
  updateEmployeeDocumentData
} from "../../../../../../actions/document.actions";
import { ReactComponent as ArrowLeft } from "../../../../../../assets/icons/arrow_left_icon.svg";
import ClientProfileCardImageViewer
  from "../../../../../ClientProfileCard/ClientProfileCardImageViewer/ClientProfileCardImageViewer";
import { DocumentEmployeeFieldsType, DocumentEmployeeType, UpdateDocumentFieldType } from "app/types";
import { setClientCardTagKey } from "../../../../../../actions/client.actions";
import TagCustom from "../../../../../ui-kit/TagCustom/TagCustom";
import LoadingCustom from "../../../../../ui-kit/LoadingCustom/LoadingCustom";

interface IEmployeesCardProps {
}

enum ClientTab {
  employeeTab = "4",
}

const EmployeeCard: FC<IEmployeesCardProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const paramsValue: any = useParams();
  const params = useMemo(() => paramsValue, [paramsValue]);

  const [documentType, setDocumentType] = useState<string>("");
  const [documentUuid, setDocumentUuid] = useState<string>("");
  const [isImageViewerOpen, setImageViewerOpen] = useState<boolean>(false);

  const showImageViewer = (docType: string, uuid: string): void => {
    setDocumentUuid(uuid);
    setDocumentType(docType);
    setImageViewerOpen(true);
  };

  const saveFormData = (docUuid: string, data: UpdateDocumentFieldType | null): void => {
    dispatch(updateEmployeeDocumentData(documentType, docUuid, data));
    setImageViewerOpen(false);
  };

  const closeForm = (): void => {
    setImageViewerOpen(false);
  };

  const [employeeID, setEmployeeID] = useState<string>("");
  const [passportUuid, setPassportUuid] = useState<string>("");
  const [snilsUuid, setSnilsUuid] = useState<string>("");
  const [innUuid, setInnUuid] = useState<string>("");
  const [contractUuid, setContractUuid] = useState<string>("");
  const [contractDocType, setContractDocType] = useState<string>("");
  const [docType, setDocType] = useState<string>("");
  const [migrationDocUuid, setMigrationDocUuid] = useState<string>("");
  const [incomeDocUuid, setIncomeDocUuid] = useState<string>("");
  const [bankDocUuid, setBankDocUuid] = useState<string>("");

  const documentEmployee: DocumentEmployeeType | null =
    useSelector((state: AppStateType) => state.employee.documentEmployee);

  const {
    innDocStatus,
    snilsDocStatus,
    passportDocStatus,
    contractDocStatus,
    migrationDocStatus,
    incomeDocStatus,
    bankDocStatus,
  } = useSelector((state: AppStateType) => state.document);

  useEffect((): void => {
    const { employeeId } = params;
    const currentEmployeeId: string = employeeId;

    setEmployeeID(currentEmployeeId);
    dispatch(setClientCardTagKey(ClientTab.employeeTab));
  }, [paramsValue]);

  useEffect((): void => {
    if (employeeID) {
      dispatch(fetchSchemaEmployee());
      dispatch(fetchDocumentEmployee(employeeID));
    }
  }, [employeeID]);

  useEffect(() => {
    const innDocUuid: string = documentEmployee?.fields?.inn_document?.[0]?.uuid ?? "";
    const snilsDocUuid: string = documentEmployee?.fields?.snils_document?.[0]?.uuid ?? "";
    const passportDocUuid: string = documentEmployee?.fields?.passport_document?.[0]?.uuid ?? "";
    const contractCivilDocUuid: string = documentEmployee?.fields?.contract_civil?.[0]?.uuid ?? "";
    const contractLaborDocUuid: string = documentEmployee?.fields?.contract_labor?.[0]?.uuid ?? "";
    const migrationDocUuid: string = documentEmployee?.fields?.migration_card?.[0]?.uuid ?? "";
    const patentDocUuid: string = documentEmployee?.fields?.patent?.[0]?.uuid ?? "";
    const incomeDocUuid: string = documentEmployee?.fields?.income_tax_deduction?.[0]?.uuid ?? "";
    const bankDocUuid: string = documentEmployee?.fields?.bank_document?.[0]?.uuid ?? "";

    if (innDocUuid) {
      dispatch(getEmployeeDocumentData("inn-schema", innDocUuid));
      dispatch(getDocumentSchema("inn-schema"));
      setInnUuid(innDocUuid);
    }

    if (snilsDocUuid) {
      dispatch(getEmployeeDocumentData("snils-schema", snilsDocUuid));
      dispatch(getDocumentSchema("snils-schema"));
      setSnilsUuid(snilsDocUuid);
    }

    if (passportDocUuid) {
      dispatch(getEmployeeDocumentData("passport-schema", passportDocUuid));
      dispatch(getDocumentSchema("passport-schema"));
      setPassportUuid(passportDocUuid);
    }

    if (contractLaborDocUuid) {
      dispatch(getEmployeeDocumentData("contract-labor-schema", contractLaborDocUuid));
      dispatch(getDocumentSchema("contract-labor-schema"));

      setContractUuid(contractLaborDocUuid);
      setContractDocType("contract-labor-schema");
    }

    if (contractCivilDocUuid) {
      dispatch(getEmployeeDocumentData("contract-civil-schema", contractCivilDocUuid));
      dispatch(getDocumentSchema("contract-civil-schema"));

      setContractUuid(contractCivilDocUuid);
      setContractDocType("contract-civil-schema");
    }

    if (migrationDocUuid) {
      dispatch(getEmployeeDocumentData("migration-card-schema", migrationDocUuid));
      setMigrationDocUuid(migrationDocUuid);
      setDocType("migration-card-schema");
    }

    if (patentDocUuid) {
      dispatch(getEmployeeDocumentData("patent-schema", patentDocUuid));
      setMigrationDocUuid(patentDocUuid);
      setDocType("patent-schema");
    }

    if (incomeDocUuid) {
      dispatch(getEmployeeDocumentData("ndfl-schema", incomeDocUuid));
      dispatch(getDocumentSchema("ndfl-schema"));
      setIncomeDocUuid(incomeDocUuid);
    }

    if (bankDocUuid) {
      dispatch(getEmployeeDocumentData("bank-schema", bankDocUuid));
      dispatch(getDocumentSchema("bank-schema"));
      setBankDocUuid(bankDocUuid);
    }
  }, [documentEmployee]);

  const navigateToTaskQuestionnaire = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    navigate(`/clients/${documentEmployee?.fields?.client?.[0]?.uuid}`);
    dispatch(getDocumentFailure());
  };

  const isDraft: boolean = documentEmployee?.fields?.document_data_status === "DRAFT";

  //логика прокрутки лоудера
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const documentFields: DocumentEmployeeFieldsType | undefined = documentEmployee?.fields;
    const innData: boolean = documentFields?.inn_document?.length ? !!innDocStatus : true;
    const snilsData: boolean = documentFields?.snils_document?.length ? !!snilsDocStatus : true;
    const passportData: boolean = documentFields?.passport_document?.length ? !!passportDocStatus : true;
    const contractData: boolean =
      (documentFields?.contract_labor?.length || documentFields?.contract_civil?.length) ? !!contractDocStatus : true;
    const migrationData: boolean = documentFields?.migration_card?.length ? !!migrationDocStatus : true;
    const incomeData: boolean = documentFields?.income_tax_deduction?.length ? !!incomeDocStatus : true;
    const bankData: boolean = documentFields?.bank_document?.length ? !!bankDocStatus : true;
    const isAllDocumentLoad: boolean =
      innData &&
      snilsData &&
      passportData &&
      contractData &&
      migrationData &&
      incomeData &&
      bankData;

    if (isAllDocumentLoad && documentFields) {
      setLoader(true);
    }
  }, [documentEmployee?.fields, innDocStatus, snilsDocStatus, passportDocStatus, contractDocStatus,
    migrationDocStatus, incomeDocStatus, bankDocStatus]);

  return (
    <div className={css.container}>
      <div className="p-5 mb-3 bg-white rounded-lg border-bottom-gray">
        <div className="mb-2">
          <ButtonCustom
            className={css.buttonReturn}
            type="link"
            text="К списку сотрудников"
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => navigateToTaskQuestionnaire(e)}
          >
            <ArrowLeft />
          </ButtonCustom>
        </div>
        <div className="flex items-center">
          <h1 className={`${css.employeeName} mr-3`}>
            Сотрудник {documentEmployee?.fields?.full_name}
          </h1>
          {isDraft && (
            <TagCustom color="customDraft" text="Черновик"/>
          )}
        </div>
      </div>
      <div>
      {loader ? (
        <>
          <PersonalInformation
            showImageViewer={showImageViewer}
            passportUuid={passportUuid}
            snilsUuid={snilsUuid}
            innUuid={innUuid}
            contractUuid={contractUuid}
            contractDocType={contractDocType}
          />
          <DocumentsCard
            showImageViewer={showImageViewer}
            docType={docType}
            migrationDocUuid={migrationDocUuid}
            incomeDocUuid={incomeDocUuid}
          />
          <BankDetailsCard
            showImageViewer={showImageViewer}
            bankDocUuid={bankDocUuid}
          />
        </>
      ) : (
        <div className={`flex items-center justify-center ${css.container} h-[70vh] bg-white border-bottom-gray`}>
          <LoadingCustom className="w-full" />
        </div>
      )}
      </div>
      {isImageViewerOpen && (
        <ClientProfileCardImageViewer
          closeForm={closeForm}
          saveFormData={saveFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          isImageViewerOpen={isImageViewerOpen}
          isClientEmployee={true}
        />
      )}
    </div>
  );
};

export default EmployeeCard;
