import axios from "../utils/axios";

const API_CLIENT_PROFILE: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_CLIENT_PROFILE;

//ручка получения схемы анкеты
export const getSchemaClientProfile = async (clientProfileSlug: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/schemas/${clientProfileSlug}`);
};

//ручка получения данных задачи по uuid документа
export const getDocumentClientProfile = async(clientProfileSlug: string, uuid: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/${clientProfileSlug}/uuid/${uuid}`);
};

//ручка получения статусов всех документов из анкеты клиента и его сотрудников
export const getNotAgreedDocuments = async (task_uuid: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/documents_statuses/${task_uuid}`);
};
