import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_SCHEMA_CLIENT_PROFILE_SUCCESS,
  GET_SCHEMA_CLIENT_PROFILE_FAILURE,
  GET_DOCUMENT_CLIENT_PROFILE_SUCCESS,
  GET_DOCUMENT_CLIENT_PROFILE_FAILURE,
  GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS,
  GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE,
  GET_UN_AGREED_DOCUMENT,
} from "../constants/actionTypes";
import * as ClientProfileAPI from "../api/clientProfile.api";
import { DocumentClientProfileType, DocumentsValidationErrorsDataType, SchemaType } from "app/types";

export type ClientProfileActionsTypes =
  | GetSchemaClientProfileSuccessType
  | GetSchemaClientProfileFailureType
  | GetDocumentClientProfileSuccessType
  | GetDocumentClientProfileFailureType
  | GetNotAgreedDocumentsProfileSuccessType
  | GetNotAgreedDocumentsProfileFailureType
  | GetUnAgreedDocumentType;

// Логика получения схемы анкеты
export type GetSchemaClientProfileSuccessType = {
  type: typeof GET_SCHEMA_CLIENT_PROFILE_SUCCESS;
  payload: {
    schemaClientProfile: SchemaType;
  };
};

const getSchemaClientProfileSuccess = (schemaClientProfile: SchemaType): GetSchemaClientProfileSuccessType => ({
  type: GET_SCHEMA_CLIENT_PROFILE_SUCCESS,
  payload: {
    schemaClientProfile
  }
});

export type GetSchemaClientProfileFailureType = {
  type: typeof GET_SCHEMA_CLIENT_PROFILE_FAILURE;
  payload: {
    error: any;
  };
};

const getSchemaClientProfileFailure = (error: any): GetSchemaClientProfileFailureType => ({
  type: GET_SCHEMA_CLIENT_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const fetchSchemaClientProfile = (clientProfileSlug: string):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  ClientProfileActionsTypes
> => async (dispatch) => {
  try {
    const response = await ClientProfileAPI.getSchemaClientProfile(clientProfileSlug);

    dispatch(getSchemaClientProfileSuccess(response.data));
  } catch (error: any) {
    dispatch(getSchemaClientProfileFailure(error.message));
  }
};

// Логика получения данных схемы анкеты по uuid документа
export type GetDocumentClientProfileSuccessType = {
  type: typeof GET_DOCUMENT_CLIENT_PROFILE_SUCCESS;
  payload: {
    documentClientProfile: DocumentClientProfileType;
  };
};

const getDocumentClientProfileSuccess = (documentClientProfile: DocumentClientProfileType)
  : GetDocumentClientProfileSuccessType => ({
  type: GET_DOCUMENT_CLIENT_PROFILE_SUCCESS,
  payload: {
    documentClientProfile
  }
});

export type GetDocumentClientProfileFailureType = {
  type: typeof GET_DOCUMENT_CLIENT_PROFILE_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentClientProfileFailure = (error?: any): GetDocumentClientProfileFailureType => ({
  type: GET_DOCUMENT_CLIENT_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const fetchDocumentClientProfile = (
  clientProfileSlug: string,
  uuid: string
): ThunkAction<
   Promise<any>,
   AppStateType,
   undefined,
   ClientProfileActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientProfileAPI.getDocumentClientProfile(clientProfileSlug, uuid);

    dispatch(getDocumentClientProfileSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentClientProfileFailure(error.message));
  }
};

//ручка получения статусов всех документов из анкеты клиента и его сотрудников
export type GetNotAgreedDocumentsProfileSuccessType = {
  type: typeof GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS;
  payload: {
    notAgreedDocuments: DocumentsValidationErrorsDataType;
  };
};

const getNotAgreedDocumentsProfileSuccess = (notAgreedDocuments: DocumentsValidationErrorsDataType)
  : GetNotAgreedDocumentsProfileSuccessType => ({
  type: GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS,
  payload: {
    notAgreedDocuments
  }
});

export type GetNotAgreedDocumentsProfileFailureType = {
  type: typeof GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE;
  payload: {
    error: any;
  };
};

export const getNotAgreedDocumentsProfileFailure = (error?: any): GetNotAgreedDocumentsProfileFailureType => ({
  type: GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const fetchNotAgreedDocumentsProfile = (
  task_uuid: string
): ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  ClientProfileActionsTypes
> => async (dispatch) => {
  try {
    const response = await ClientProfileAPI.getNotAgreedDocuments(task_uuid);

    dispatch(getNotAgreedDocumentsProfileSuccess(response.data));
  } catch (error: any) {
    dispatch(getNotAgreedDocumentsProfileFailure(error.message));
  }
};

//Логика проверки отправки запроса на получения несогласованных документов
export type GetUnAgreedDocumentType = {
  type: typeof GET_UN_AGREED_DOCUMENT;
  payload: {
    sendRequest: boolean;
  };
};

export const getUnAgreedDocumentType = (sendRequest: boolean)
  : GetUnAgreedDocumentType => ({
  type: GET_UN_AGREED_DOCUMENT,
  payload: {
    sendRequest
  }
});
