import React, {
  FC,
  JSX,
  useEffect,
} from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import TagCustom from "../../../ui-kit/TagCustom/TagCustom";
import { downloadFile, downloadUrl } from "../../../../utils/downloadFile";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import css from "./ResumeCard.module.css";
import {
  DocumentSchemaLabelMapType,
  ResumesResultsType,
  ResumesFieldsType,
  SchemaType,
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { AppDispatch } from "../../../../store/store";
import { getDocumentSchema } from "../../../../actions/document.actions";
import { getResumeFailure, getResumeInfo } from "../../../../actions/alpha/resumes.actions";
import { documentSchemaLabelsMapper } from "../../../../utils/documentSchemaLabelsMapper";
import { INavigationProps } from "../CreateResume/CreateResume";

interface IResumeCardProps {
}

const ResumeCard: FC<IResumeCardProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const pathname: string = useLocation()?.pathname;

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const resumeUuid: string = pathname?.split("/")?.slice(-1)?.toString();

    dispatch(getDocumentSchema("cv-schema"));
    dispatch(getResumeInfo(resumeUuid));
  }, [pathname]);

  const resume: ResumesResultsType | null =
    useSelector((state: AppStateType) => state.resume.resume);

  const documentSchema: SchemaType | null =
    useSelector((state: AppStateType) => state.document.documentSchema);

  const resumeCardLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(documentSchema);

  const renderResumeSkills= (skills: string[]): JSX.Element[] => {
    return skills?.map((skill: string, index: number) => (
      <TagCustom
        key={index}
        color="customSkills"
        text={skill}
      />
    )) ?? [];
  };

  const {
    last_name,
    second_name,
    first_name,
    birth_date,
    skills,
    working_hours,
    cv_file,
    uuid,
  } = resume?.fields ?? {} as ResumesFieldsType;

  const navigationProps: INavigationProps = {
    isResumeCard: true,
    resumeUuid: uuid,
  };

  const renderResumeCardBlock = (value: string | null, slug: string) => {
    return value && (
      <li className="flex items-center mb-[8px]">
        <span className={css.resumeParamsLabel}>
          {resumeCardLabel?.[slug]?.label}
        </span>
        <span className={css.resumeParamsData}>
          {value}
        </span>
      </li>
    );
  };

  return (
    <div className={css.container}>
      <div className="flex justify-between items-center mb-[8px]">
        <ButtonCustom
          className={css.returnBtn}
          type="link"
          text="Назад"
          onClick={() => {
            navigate("/alpha/resumes");
            dispatch(getResumeFailure());
          }}
        >
          <ArrowLeftOutlined/>
        </ButtonCustom>
        <div className="flex">
          <ButtonCustom
            className="mr-[8px]"
            size="large"
            type="default"
            text="Удалить"
            ghost
            onClick={() => {}}
          />
          <ButtonCustom
            size="large"
            type="primary"
            text="Изменить резюме"
            onClick={() =>
              navigate(`/alpha/update_resume/${uuid}`, {state: navigationProps})
            }
          />
        </div>
      </div>
      <h1 className={css.resumeCardHeader}>
        {[last_name, first_name, second_name].filter(Boolean).join(" ")}
      </h1>
      <div className={css.resumeParamsBlock}>
        <h2 className={css.resumeParamsHeader}>
          Параметры резюме
        </h2>
        <ul>
          {renderResumeCardBlock(last_name, "last_name")}
          {renderResumeCardBlock(first_name, "first_name")}
          {renderResumeCardBlock(second_name, "second_name")}
          {renderResumeCardBlock(birth_date, "birth_date")}
          {!!skills?.length && (
            <li className="flex items-center mb-[8px]">
              <span className={css.resumeParamsLabel}>
                {resumeCardLabel?.skills?.label}
              </span>
              <span className={css.resumeParamsData}>
                {renderResumeSkills(skills)}
              </span>
            </li>
          )}
          {renderResumeCardBlock(working_hours, "working_hours")}
          {cv_file && (
            <li className="flex items-center mb-[8px]">
              <span className={css.resumeParamsLabel}>
                {resumeCardLabel?.cv_file?.label}
              </span>
              <span className={css.resumeParamsData}>
                <a
                  className="flex blue-color items-center mb-[4px]"
                  onClick={() => downloadFile("cv_file", downloadUrl(cv_file))}
                >
                  <AttachFileIcon className="flex-shrink-0 mr-1" />
                  <div className={css.docFile}>filename.pdf</div>
                </a>
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ResumeCard;
