import React, { JSX } from "react";
import TagCustom from "../components/ui-kit/TagCustom/TagCustom";

export const renderAlphaDocStatus = (text: string): JSX.Element | string => {
  switch (text) {
    case "NEW":
      return <TagCustom color="customProcessing" text="Cоздана"/>;
    case "SENT":
      return <TagCustom color="customSuccess" text="Отправлена"/>;
    case "WAIT_DECISION":
      return <TagCustom color="customProcessing" text="На согласовании"/>;
    case "ACTIVE":
      return <TagCustom color="customSuccess" text="Опубликована"/>;
    case "APPROVE":
      return <TagCustom color="customSuccess" text="Одобрена"/>;
    case "SIGNED":
      return <TagCustom color="customSuccess" text="Подписана"/>;
    case "DEACTIVE":
      return <TagCustom color="customDraft" text="Отозвана"/>;
    case "DECLINE":
      return <TagCustom color="customError" text="Отклонена"/>;
    case "ARCHIVE":
      return <TagCustom color="customDraft" text="Архив"/>;
    case "DRAFT":
      return <TagCustom color="customDraft" text="Черновик"/>;
    default:
      return "";
  }
};
