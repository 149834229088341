import axios from "../../utils/axios";
import { AlphaListParamsType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_TASKS_URL;

//ручка получения листинга контрагентов
export const getContractors = async (params?: AlphaListParamsType | null): Promise<any> => {
  return axios.GET(API_BASE, "api/mdm/documents/v1/d/client-company", params);
};

//ручка получения контрагента
export const getContractor = async (contractorUuid?: string | null): Promise<any> => {
  return axios.GET(API_BASE, `api/mdm/documents/v1/d/client-company/uuid/${contractorUuid}`);
};
