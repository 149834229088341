import React, { JSX } from "react";
import TagCustom from "../components/ui-kit/TagCustom/TagCustom";
import { TagCustomColorType } from "app/types";
import { statusVariant } from "./catalog";

export const parseStatus = (text: string = ""): JSX.Element | string => {
  const status: { color: TagCustomColorType; text: string } = statusVariant[text];
  
  return status ? (
    <TagCustom color={status?.color} text={status?.text} />
  ) : "";
};
