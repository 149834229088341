export const handleSnilsNumberChange = (snilsNumber: string): string => {
  // Удаление всего, кроме цифр
  const inputValue: string = snilsNumber.replace(/\D/g, "");
  // Используем регулярное выражение для извлечения сегментов номера
  const matches: RegExpMatchArray | null = inputValue.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

  let formattedSnils: string = "";

  // Если есть совпадения, добавляем соответствующие части в форматированный номер
  if (matches) {
    // Добавляем первые 3 цифры и разделитель "-"
    formattedSnils += matches[1] ? `${matches[1]}-` : "";

    // Добавляем следующие 3 цифры и разделитель "-"
    formattedSnils += matches[2] ? `${matches[2]}-` : "";

    // Добавляем следующие 3 цифры и разделитель "-"
    formattedSnils += matches[3] ? `${matches[3]} ` : "";

    // Добавляем две последние цифры
    formattedSnils += matches[4];

    // Добавляем разделитель " " и последние две цифры
    formattedSnils += matches[5] ? ` ${matches[5]}` : "";
  }

  return formattedSnils;
};
