import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
} from "react";
import { FormInstance } from "antd";
import { taskType } from "../../../../utils/catalog";
import FiltersComponent from "../FiltersComponent";

interface ITypeFiltersProps {
  form: FormInstance,
  isModalOpen: boolean,
  selectedTaskTypes: string[],
  setSelectedTaskTypes: Dispatch<SetStateAction<string[]>>,
}

const TypeFilters: FC<ITypeFiltersProps> = ({
  form,
  isModalOpen,
  selectedTaskTypes,
  setSelectedTaskTypes
}): JSX.Element => {
  return (
    <FiltersComponent
      key="typeTask"
      form={form}
      isModalOpen={isModalOpen}
      selectedItems={selectedTaskTypes}
      setSelectedItems={setSelectedTaskTypes}
      taskTypes={taskType}
      formFieldName="typeTask"
      headerText="По типу задачи"
    />
  );
};

export default TypeFilters;