import {
  GET_CONTRACTOR_FAILURE,
  GET_CONTRACTOR_SUCCESS,
  GET_CONTRACTORS_LIST_FAILURE,
  GET_CONTRACTORS_LIST_SUCCESS
} from "../../constants/actionTypes";
import { ContractorsActionsTypes } from "../../actions/alpha/contractors.actions";
import { ContractorsListType } from "app/types";

type InitialContractorsCardType = {
  error: any;
  contractorsList: ContractorsListType[];
  contractorsPage: number;
  contractorsTotalDocs: number;
  contractorsPageSize: number;
  isContractorsLoading: boolean,
  contractorData: ContractorsListType | null;
}

const initialState: InitialContractorsCardType = {
  error: null,
  contractorsList: [],
  contractorsPage: 1,
  contractorsTotalDocs: 5,
  contractorsPageSize: 5,
  isContractorsLoading: true,
  contractorData: null,
};

const contractors = (
  state: InitialContractorsCardType = initialState,
  action: ContractorsActionsTypes
): InitialContractorsCardType => {
  switch (action.type) {
    case GET_CONTRACTORS_LIST_SUCCESS:
      return {
        ...state,
        contractorsPage: action.payload.contractorsList.page,
        contractorsList: action.payload.contractorsList.results,
        contractorsPageSize: action.payload.contractorsList.page_size,
        contractorsTotalDocs: action.payload.contractorsList.total_docs,
        isContractorsLoading: false,
        error: null
      };
    case GET_CONTRACTORS_LIST_FAILURE:
      return {
        ...state,
        contractorsPage: 0,
        contractorsList: [],
        contractorsPageSize: 0,
        contractorsTotalDocs: 0,
        isContractorsLoading: false,
        error: action.payload.error
      };
    case GET_CONTRACTOR_SUCCESS:
      return {
        ...state,
        contractorData: action.payload.contractorData,
        error: null
      };
    case GET_CONTRACTOR_FAILURE:
      return {
        ...state,
        contractorData: null,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default contractors;
