import React, { ChangeEvent, FC, JSX, useEffect } from "react";
import css from "./TerminateService.module.css";
import { Form, Input, Select } from "antd";
import ZeroSearch from "../../../../../../ZeroSearch/ZeroSearch";
import { deviationReason } from "../../../contactingToClient";
import { ButtonCustom } from "../../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { IModalApplicationRender } from "../../../../../../../Tasks/TaskCards/ModalApplication/ModalApplication";

const TerminateService: FC<IModalApplicationRender> = ({
  formContent,
  formApplication,
  changeOptions,
  setChangeOptions,
  setRejectApplication,
}): JSX.Element => {
  const { TextArea } = Input;

  useEffect(() => {
    if (formContent) {
      formContent?.setFieldsValue({
        textTerminate: "",
      });
    }
  }, [formContent, formApplication]);

  const commentValue: string = formContent
    ? formContent?.getFieldValue("textTerminate")
    : formApplication?.getFieldValue("textTerminate");
  const commentCount: number = formContent
    ? formContent.getFieldValue("textTerminate")?.length || 0
    : formApplication?.getFieldValue("textTerminate")?.length || 0;

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    if (setRejectApplication) {
      setRejectApplication(!!charsCount);
    }

    formContent
      ? formContent.setFieldValue("textTerminate", value)
      : formApplication?.setFieldValue("textTerminate", value);
  };

  const formFieldRender = (): JSX.Element => {
    const setFieldValue = (name: string, value: string | null): void => formContent
      ? formContent.setFieldValue(name, value)
      : formApplication?.setFieldValue(name, value);

    return (
      <Form.Item
        name="terminateReason"
        className="mb-5"
      >
        <Select
          id="terminateReason"
          placeholder="Выберите причину"
          options={deviationReason}
          listHeight={196}
          showSearch
          allowClear
          size="large"
          onChange={(value: string | null): void => setFieldValue("terminateReason", value)}
          notFoundContent={<ZeroSearch dataFilter/>}
        />
      </Form.Item>
    );
  };

  const textAreaRender = (): JSX.Element => {
    return (
      <Form.Item
        className="mb-5"
        name="textTerminate"
      >
        <TextArea
          id="textTerminate"
          rows={2}
          maxLength={2000}
          className="text-base font-normal leading-5 text-gray-900"
          placeholder="Ваше сообщение"
          value={commentValue}
          onChange={handleTextAreaChange}
        />
        <div className="text-xs font-normal leading-100 text-gray-700 text-right">
          {commentCount}/2000 символов
        </div>
      </Form.Item>
    );
  };

  const taskCardVariant: JSX.Element = (
    changeOptions ? (
      <div>
        <div className={css.terminateTitle}>Причина:</div>
        <div className={css.terminateCause}>
          {formApplication?.getFieldValue("terminateReason")}
        </div>
        <ButtonCustom
          className={css.changeCause}
          type="link"
          text="Изменить причину"
          onClick={(): void => { if (setChangeOptions) setChangeOptions(false); }}
        />
        <div className={css.terminateText}>
          Вы также можете добавить служебный комментарий. Этот комментарий не будет виден клиенту.
        </div>
        {textAreaRender()}
      </div>
    ) : (
      <div>
        <div className={css.terminateTitle}>Причина:</div>
        {formFieldRender()}
        <div className={css.terminateText}>
          Вы также можете добавить служебный комментарий. Этот комментарий не будет виден клиенту.
        </div>
        {textAreaRender()}
      </div>
    )
  );

  //TODO как будет АПИ логику дописать
  return (
    <div>
      {formContent ? (
        <>
          <div className={`${css.terminateText} mt-5`}>
            Для заявки на прекращение обслуживания необходимо указать причину, ссылаясь на оферту.
          </div>
          {formFieldRender()}
          <div className={css.terminateText}>
            Вы также можете добавить служебный комментарий. Этот комментарий не будет виден клиенту.
          </div>
          {textAreaRender()}
        </>
      ) : (
        taskCardVariant
      )}
    </div>
  );
};

export default TerminateService;