import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Input,
  Radio,
  Select,
  Tooltip,
  Divider,
  DatePicker,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import {
  getDocumentSchema,
  getEmployeeDocumentData,
  createNewDocumentFailure,
  createNewDynamicDocument,
} from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import dayjs, { Dayjs } from "dayjs";
import {
  AbstractDocType,
  DocumentClientProfileType,
  DocumentEmployeeProfileType,
  UpdateDocumentFilesType
} from "app/types";
import css from "../ClientProfileCardImageViewer.module.css";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { updateEmployeeProfileData } from "../../../../actions/employeeProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { isDocFieldRequired } from "../../../../utils/isDocFieldRequired";
import { translateContractType } from "../../../../utils/translateContractType";
import { roleResolver } from "../../../../utils/roleResolver";
import { JWTPayload } from "jose";

interface IClientProfileCardImageViewerFormAgreementProps {
  docType: string;
  agreementForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  setDocumentUuid?: Dispatch<SetStateAction<string>>;
  setDocumentType?: Dispatch<SetStateAction<string>>;
}

interface ITypesOfProfessionProps {
  value: string;
  label: string;
}

const ClientProfileCardImageViewerFormAgreement: FC<IClientProfileCardImageViewerFormAgreementProps> = ({
  docType,
  agreementForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  setDocumentUuid,
  setDocumentType,
}) => {
  const [contractType, setContractType] = useState<string>("");
  const [isContractTypeChanged, setContractTypeChanged] = useState<boolean>(false);
  const [isContractTypeChanging, setContractTypeChanging] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentEmployeeProfile: DocumentEmployeeProfileType | null =
    useSelector((state: AppStateType) => state.employeeProfile.documentEmployeeProfile);

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    newDocument,
    contractDoc,
    contractDocSchema,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (!!docApproveStatus && isDocApprovalDisabled || isContactCenter) {
      setDocVerified(true);
    } else {
      setDocVerified(false);
    }
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(contractDocSchema, agreementForm, setDocApprovalDisabled);

    onFormValuesChange();
  }, [agreementForm, contractDocSchema, onFormValuesChange]);

  useEffect(() => {
    const contractCivil: AbstractDocType[] | undefined = documentEmployeeProfile?.fields?.contract_civil;
    const contractLabor: AbstractDocType[] | undefined = documentEmployeeProfile?.fields?.contract_labor;

    if (contractCivil && contractCivil.length > 0) {
      setContractType("Договор ГПХ");
    } else if (contractLabor && contractLabor.length > 0) {
      setContractType("Трудовой договор");
    }
  }, [documentEmployeeProfile]);

  const typesOfContracts: ITypesOfProfessionProps[] = [
    {
      value: "Трудовой договор",
      label: "Трудовой договор"
    },
    {
      value: "Договор ГПХ",
      label: "Договор ГПХ"
    },
  ];

  const typeOfEmployment: ITypesOfProfessionProps[] = [
    {
      value: "Основное место работы",
      label: "Основное место работы"
    },
    {
      value: "По внешнему совместительству",
      label: "По внешнему совместительству"
    },
    {
      value: "По внутреннему совместительству",
      label: "По внутреннему совместительству"
    },
  ];

  const paymentMethods: ITypesOfProfessionProps[] = [
    {
      value: "Ежемесячно",
      label: "Ежемесячно"
    },
    {
      value: "По окончании договора",
      label: "По окончании договора"
    },
  ];

  const typeOfSalaryPayment: ITypesOfProfessionProps[] = [
    {
      value: "Через кассу",
      label: "Через кассу"
    },
    {
      value: "В рамках зарплатного проекта",
      label: "В рамках зарплатного проекта"
    },
    {
      value: "На счета сотрудников",
      label: "На счета сотрудников"
    },
  ];

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = contractDoc?.fields?.decline_reason ?? "";

      if (docMigrationDeclineReason) {
        agreementForm.setFieldValue("declineReason", docMigrationDeclineReason);
      } else {
        agreementForm.setFieldValue(
          "declineReason",
          "Плохое качество документа, нечитаемый скан"
        );
      }

      onFormValuesChange();
    }
  }, [docApproveStatus, contractDoc, agreementForm]);

  useEffect(() => {
    const documentStatus: string = contractDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = contractDoc?.fields?.files
      ?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, contractDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema(docType));
      dispatch(getEmployeeDocumentData(docType, documentUuid));
    }
  }, [dispatch, documentUuid, docType, isNotAgreedDocs]);

  useEffect(() => {
    const docContractRate: string | number | null = contractDoc?.fields?.rate ?? null;
    const docContractType: string = contractDoc?.fields?.contract_type
      ? contractDoc?.fields?.contract_type
      : contractType;
    const docContractSalary: string | number | null = contractDoc?.fields?.salary ?? null;
    const docContractStatus: string = contractDoc?.fields?.document_status ?? "";
    const docContractPosition: string = contractDoc?.fields?.position ?? "";
    const docContractWorkplace: string = contractDoc?.fields?.workplace ?? "";
    const docContractAllowances: string | number | null = contractDoc?.fields?.allowances ?? null;
    const docContractPaymentMethods: string = contractDoc?.fields?.payment_methods ?? "";
    const docContractDeclineReason: string = contractDoc?.fields?.decline_reason ?? "";
    const docContractEmploymentType: string | null = contractDoc?.fields?.employment_type ?? null;
    const docContractConclusionDate: Dayjs = dayjs(contractDoc?.fields?.contract_date);
    const docContractExpirationDate: Dayjs = dayjs(contractDoc?.fields?.expiration_date);
    const docContractSalaryPaymentType: string | number | null = contractDoc?.fields?.payment_type ?? null;
    const shouldUpdateDocField: boolean = !!docContractType
      || !!docContractRate
      || !!docContractSalary
      || !!docContractStatus
      || !!docContractPosition
      || !!docContractWorkplace
      || !!docContractAllowances
      || !!docContractEmploymentType
      || !!docContractConclusionDate
      || !!docContractExpirationDate
      || !!docContractPaymentMethods
      || !!docContractSalaryPaymentType;

    if (shouldUpdateDocField) {
      onApproveChange(docContractStatus);

      handleDigitsOnlyChange(docContractRate, "rate");
      handleDigitsOnlyChange(docContractSalary, "salary");
      handleDigitsOnlyChange(docContractAllowances, "allowances");
      handleLettersOnlyChange(docContractPosition, "position");
      handleLettersOnlyChange(docContractWorkplace, "workplace");
      agreementForm.setFieldValue("isApproved", contractDoc?.fields?.document_status);
      agreementForm.setFieldValue("employment_type", docContractEmploymentType);
      agreementForm.setFieldValue("contract_type", docContractType);
      agreementForm.setFieldValue("payment_type", docContractSalaryPaymentType);
      !!docContractPaymentMethods && handleLettersOnlyChange(docContractPaymentMethods, "payment_methods");
      docContractConclusionDate.isValid()
        ? agreementForm.setFieldValue("contract_date", docContractConclusionDate)
        : agreementForm.setFieldValue("contract_date", null);
      docContractExpirationDate.isValid()
        ? agreementForm.setFieldValue("expiration_date", docContractExpirationDate)
        : agreementForm.setFieldValue("expiration_date", null);
    }

    if (docContractDeclineReason) {
      agreementForm.setFieldValue("declineReason", docContractDeclineReason);
    }
  }, [contractDoc]);

  const handleLettersOnlyChange = (event: React.ChangeEvent<HTMLInputElement> | string, fieldName: string): void => {
    const inputValue: string = typeof event !== "string"
      ? event.target.value.replace(/[^A-Za-zА-Яа-яЁё\s\-_:,"']/g, "")
      : event;

    // Для латиницы используйте /[A-Za-z]/, для кириллицы /[А-Яа-яЁё]/
    agreementForm.setFieldValue(fieldName, inputValue);
  };

  const handleDigitsOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement> | number | string | null,
    fieldName: string
  ): void => {
    let inputValue: string | number | null;

    if (typeof event !== "number" && typeof event !== "string" && event !== null) {
      // Разрешаем только цифры и точки, заменяем запятые на точки
      inputValue = event.target.value
        ?.replace(/[^\d.]/g, "")
        ?.replace(",", ".");
    } else {
      inputValue = event;
    }

    if (inputValue === "0" && fieldName !== "rate") {
      agreementForm.setFieldValue(fieldName, null);
    } else {
      agreementForm.setFieldValue(fieldName, inputValue);
    }
  };

  const handleTariffRateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    let formattedValue: string;

    if (/^0/.test(value)) {
      formattedValue = value.startsWith("0.") ? "0." + value.slice(2).replace(/[^0-9]/g, "").slice(0, 2) : "0.";
    } else {
      formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 4);
    }

    formattedValue = formattedValue.endsWith("0") ? formattedValue.slice(0, -1) : formattedValue;

    agreementForm.setFieldValue("rate", parseFloat(formattedValue) >= 1 ? "1" : formattedValue);
  };

  const onContractTypeChange = (newContractType: string) => {
    setContractType(newContractType);
    setContractTypeChanging(true);

    agreementForm.resetFields(
      [
        "position",
        "employment",
        "rate",
        "allowances",
        "salary",
        "workplace",
        "payment_methods",
        "payment_type",
        "contract_date",
        "expiration_date",
      ]);

    if (documentEmployeeProfile?.fields?.uuid) {
      if (newContractType === "Трудовой договор") {
        const newDocumentFilesField: string[] | undefined =
          contractDoc?.fields?.files.map(({ uuid }) => uuid);

        const newDocumentsFields = {
          rate: null,
          files: newDocumentFilesField,
          salary: null,
          position: null,
          workplace: null,
          payment_type: null,
          allowances: null,
          contract_date: null,
          contract_type: "Трудовой договор",
          decline_reason: null,
          document_status: null,
          employment_type: null,
          expiration_date: null,
          payment_methods: null,

        };

        dispatch(createNewDynamicDocument("contract-labor-schema", newDocumentsFields));
      }

      if (newContractType === "Договор ГПХ") {
        const newDocumentFilesField: string[] | undefined =
          contractDoc?.fields?.files.map(({ uuid }) => uuid);

        const newDocumentsFields = {
          files: newDocumentFilesField,
          position: null,
          payment_type: null,
          contract_date: null,
          contract_type: "Договор ГПХ",
          decline_reason: null,
          contract_amount: null,
          document_status: null,
          employment_type: null,
          expiration_date: null,
          payment_methods: null,
        };

        dispatch(createNewDynamicDocument("contract-civil-schema", newDocumentsFields));
      }
    }
  };

  useEffect(() => {
    if (documentEmployeeProfile?.fields?.uuid && contractType === "Трудовой договор" && newDocument) {
      if (newDocument) {
        const newEmployeeProfileDoc = {
          uuid: documentEmployeeProfile?.fields?.uuid,
          full_name: documentEmployeeProfile?.fields?.full_name,
          inn_document: documentEmployeeProfile?.fields?.inn_document?.[0]?.uuid,
          bank_document: documentEmployeeProfile?.fields?.bank_document?.[0]?.uuid,
          external_uuid: documentEmployeeProfile?.fields?.external_uuid,
          snils_document: documentEmployeeProfile?.fields?.snils_document?.[0]?.uuid,
          migration_card: documentEmployeeProfile?.fields?.migration_card?.[0]?.uuid,
          contract_civil: [],
          contract_labor: newDocument?.fields?.uuid,
          employee_status: documentEmployeeProfile?.fields?.employee_status,
          passport_document: documentEmployeeProfile?.fields?.passport_document?.[0]?.uuid,
          client_profile_ip: documentEmployeeProfile?.fields?.client_profile_ip?.[0]?.uuid,
          client_profile_ooo: documentEmployeeProfile?.fields?.client_profile_ooo?.[0]?.uuid,
          income_tax_deduction: documentEmployeeProfile?.fields?.income_tax_deduction?.[0]?.uuid,
        };

        setDocumentType && setDocumentType("contract-labor-schema");
        setDocumentUuid && setDocumentUuid(newDocument?.fields?.uuid);
        dispatch(updateEmployeeProfileData(documentEmployeeProfile?.fields?.uuid, newEmployeeProfileDoc));
        dispatch(createNewDocumentFailure());
        setTimeout(() => setContractTypeChanged(true), 500);
      }
    }

    if (documentEmployeeProfile?.fields?.uuid && contractType === "Договор ГПХ" && newDocument) {
      const newEmployeeProfileDoc = {
        uuid: documentEmployeeProfile?.fields?.uuid,
        full_name: documentEmployeeProfile?.fields?.full_name,
        inn_document: documentEmployeeProfile?.fields?.inn_document?.[0]?.uuid,
        bank_document: documentEmployeeProfile?.fields?.bank_document?.[0]?.uuid,
        external_uuid: documentEmployeeProfile?.fields?.external_uuid,
        snils_document: documentEmployeeProfile?.fields?.snils_document?.[0]?.uuid,
        migration_card: documentEmployeeProfile?.fields?.migration_card?.[0]?.uuid,
        contract_civil: newDocument?.fields?.uuid,
        contract_labor: [],
        employee_status: documentEmployeeProfile?.fields?.employee_status,
        passport_document: documentEmployeeProfile?.fields?.passport_document?.[0]?.uuid,
        client_profile_ip: documentEmployeeProfile?.fields?.client_profile_ip?.[0]?.uuid,
        client_profile_ooo: documentEmployeeProfile?.fields?.client_profile_ooo?.[0]?.uuid,
        income_tax_deduction: documentEmployeeProfile?.fields?.income_tax_deduction?.[0]?.uuid,
      };

      setDocumentType && setDocumentType("contract-civil-schema");
      setDocumentUuid && setDocumentUuid(newDocument?.fields?.uuid);
      dispatch(updateEmployeeProfileData(documentEmployeeProfile?.fields?.uuid, newEmployeeProfileDoc));
      dispatch(createNewDocumentFailure());
      setTimeout(() => setContractTypeChanged(true), 500);
    }
  }, [newDocument]);

  useEffect(() => {
    if (isContractTypeChanged) {
      setContractTypeChanged(false);
      setContractTypeChanging(false);
      dispatch(getDocumentSchema(docType));
      dispatch(getEmployeeDocumentData(docType, documentUuid));
    }
  }, [dispatch, isContractTypeChanged]);

  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isContractFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;
  const hasAgreementExpirationDateStatus: "" | "error" = agreementForm.getFieldValue("expiration_date") ? "" : "error";

  const typeOfContractFields = (contractType: string): JSX.Element | null => {
    switch (contractType) {
      case("Трудовой договор"):
        return (
          <>
            <Form.Item
              rootClassName="w-full"
              name="contract_date"
              rules={[
                {
                  required: isFormItemRequiredRule("contract_date", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <DatePicker
                id="contract_date"
                className="w-full"
                style={{
                  padding: "6.35px"
                }}
                size="large"
                format="DD.MM.YYYY"
                allowClear={!docApproveStatus}
                inputReadOnly={false}
                onChange={(date: Dayjs | null): void =>
                  date === null
                    ? agreementForm.setFieldValue("contract_date", null)
                    : agreementForm.setFieldValue("contract_date", date)
                }
                placeholder="Дата заключения договора"
                disabledDate={(current: Dayjs) => {
                  return dayjs().isBefore(current);
                }}
                disabled={isContractFormDisabled || isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "contract_date",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              rootClassName="w-full"
              name="expiration_date"
              rules={[
                {
                  required: isFormItemRequiredRule("expiration_date", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <DatePicker
                id="expiration_date"
                className="w-full"
                style={{
                  padding: "6.35px"
                }}
                size="large"
                format="DD.MM.YYYY"
                allowClear
                inputReadOnly={false}
                onChange={(date: Dayjs | null): void =>
                  date === null
                    ? agreementForm.setFieldValue("expiration_date", null)
                    : agreementForm.setFieldValue("expiration_date", date)
                }
                placeholder="Срок действия (если предусмотрен)"
                disabled={isContractFormDisabled || isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "expiration_date",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="position"
              rules={[
                {
                  required: isFormItemRequiredRule("position", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Input
                id="position"
                size="large"
                placeholder="Занимаемая должность"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLettersOnlyChange(e, "position")}
                readOnly={isContractFormDisabled}
                disabled={isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "position",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="employment_type"
              rules={[
                {
                  required: isFormItemRequiredRule("employment_type", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Select
                id="employment_type"
                placeholder="Вид занятости"
                options={typeOfEmployment}
                allowClear={!docApproveStatus}
                size="large"
                disabled={isContractFormDisabled || isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "employment_type",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="rate"
              className="mb-5"
              rules={[
                {
                  required: isFormItemRequiredRule("rate", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Input
                className="w-full"
                id="rate"
                size="large"
                placeholder="Ставка (числа от 0 до 1)"
                onChange={handleTariffRateChange}
                readOnly={isContractFormDisabled}
                disabled={isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "rate",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="salary"
              rootClassName="w-full mr-2"
              rules={[
                {
                  required: isFormItemRequiredRule("salary", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Input
                id="salary"
                size="large"
                addonAfter={<span className="text-gray-400">₽</span>}
                placeholder="Оклад"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDigitsOnlyChange(e, "salary")}
                readOnly={isContractFormDisabled}
                disabled={isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "salary",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="allowances"
              rootClassName="w-full"
              rules={[
                {
                  required: isFormItemRequiredRule("allowances", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Input
                id="allowances"
                size="large"
                addonAfter={<span className="text-gray-400">₽</span>}
                placeholder="Северная надбавка"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDigitsOnlyChange(e, "allowances")}
                readOnly={isContractFormDisabled}
                disabled={isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "allowances",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="workplace"
              className="mb-5"
              rules={[
                {
                  required: isFormItemRequiredRule("workplace", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Input
                id="workplace"
                size="large"
                placeholder="Место работы (город)"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLettersOnlyChange(e, "workPlace")}
                readOnly={isContractFormDisabled}
                disabled={isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "workplace",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
          </>
        );
      case("Договор ГПХ"):
        return (
          <>
            <Form.Item
              rootClassName="w-full"
              name="contract_date"
              rules={[
                {
                  required: isFormItemRequiredRule("contract_date", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <DatePicker
                id="contract_date"
                className="w-full"
                style={{
                  padding: "6.35px"
                }}
                size="large"
                format="DD.MM.YYYY"
                allowClear={!docApproveStatus}
                inputReadOnly={false}
                onChange={(date: Dayjs | null): void =>
                  date === null
                  ? agreementForm.setFieldValue("contract_date", null)
                  : agreementForm.setFieldValue("contract_date", date)
                }
                placeholder="Дата заключения договора"
                disabledDate={(current: Dayjs) => {
                  return dayjs().isBefore(current);
                }}
                disabled={isContractFormDisabled || isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "contract_date",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              rootClassName="w-full"
              name="expiration_date"
              rules={[
                {
                  required: true,
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <DatePicker
                id="expiration_date"
                className="w-full"
                style={{
                  padding: "6.35px"
                }}
                size="large"
                format="DD.MM.YYYY"
                allowClear={!docApproveStatus}
                inputReadOnly={false}
                onChange={(date: Dayjs | null): void =>
                  date === null
                    ? agreementForm.setFieldValue("expiration_date", null)
                    : agreementForm.setFieldValue("expiration_date", date)
                }
                placeholder="Срок действия"
                disabled={isContractFormDisabled || isContractTypeChanging}
                status={!isContractFormDisabled
                  ? hasAgreementExpirationDateStatus
                  : ""
                }
              />
            </Form.Item>
            <Form.Item
              name="salary"
              rootClassName="w-full mr-2"
              rules={[
                {
                  required: isFormItemRequiredRule("salary", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Input
                id="salary"
                size="large"
                addonAfter={<span className="text-gray-400">₽</span>}
                placeholder="Сумма договора"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDigitsOnlyChange(e, "salary")}
                readOnly={isContractFormDisabled}
                disabled={isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "salary",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
            <Form.Item
              name="payment_methods"
              rules={[
                {
                  required: isFormItemRequiredRule("payment_methods", contractDocSchema),
                  message: "",
                }
              ]}
              validateTrigger={validateTriggersArr}
            >
              <Select
                id="payment_methods"
                placeholder="Способы оплаты"
                options={paymentMethods}
                allowClear
                size="large"
                disabled={isContractFormDisabled || isContractTypeChanging}
                status={!isContractFormDisabled ? isDocFieldRequired(
                  contractDocSchema,
                  "payment_methods",
                  agreementForm
                ) : ""}
              />
            </Form.Item>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`${css.agreementData} flex flex-col h-90vh overflow-auto`}>
      <h2 className="mb-2 text-xl font-bold">
        Договор
      </h2>
      {!isContractFormDisabled && (
        <p className="mb-4 gray-color w-full">
          После проверки правильности документа подтвердите его согласованность и заполните соответствующие данные.
        </p>
      )}
      <Form
        form={agreementForm}
        name="agreement-form"
        onChange={onFormValuesChange}
        onClick={onFormValuesChange}
        validateTrigger={validateTriggersArr}
      >
        <Form.Item
          name="contract_type"
          className="mb-5"
          rules={[
            {
              required: isFormItemRequiredRule("contract_type", contractDocSchema),
              message: "",
            }
          ]}
          validateTrigger={validateTriggersArr}
        >
          <Select
            id="contract_type"
            placeholder="Тип договора"
            options={typesOfContracts}
            size="large"
            onChange={onContractTypeChange}
            value={translateContractType(contractType)}
            loading={isContractTypeChanging}
            disabled={isContractFormDisabled || isContractTypeChanging}
            status={!isContractFormDisabled ? isDocFieldRequired(
              contractDocSchema,
              "contract_type",
              agreementForm
            ) : ""}
          />
        </Form.Item>
        {typeOfContractFields(contractType)}
        <Form.Item
          name="payment_type"
          className="mb-5"
          rules={[
            {
              required: isFormItemRequiredRule("payment_type", contractDocSchema),
              message: "",
            }
          ]}
          validateTrigger={validateTriggersArr}
        >
          <Select
            id="payment_type"
            placeholder="Вид выплаты зарплаты"
            options={typeOfSalaryPayment}
            allowClear
            size="large"
            disabled={isContractFormDisabled || isContractTypeChanging}
            status={!isContractFormDisabled ? isDocFieldRequired(
              contractDocSchema,
              "payment_type",
              agreementForm
            ) : ""}
          />
        </Form.Item>
        {!isClientEmployee && !isValidationCompleted && (
          <>
            <Divider className="mt-1" />
            <div className="flex justify-between">
              <Tooltip
                title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              >
                <Form.Item name="isApproved">
                  <Radio.Group
                    onChange={onApproveChange}
                    value={docApproveStatus}
                    disabled={isContractTypeChanging
                      || isContactCenter
                      || isDocApprovalDisabled
                      || (contractType === "Договор ГПХ" && !!hasAgreementExpirationDateStatus)
                     }
                  >
                    <Radio value="APPROVED">Согласовать</Radio>
                    <Radio value="DECLINED">Отклонить</Radio>
                  </Radio.Group>
                </Form.Item>
              </Tooltip>
            </div>
            <div>
              {docApproveStatus === "DECLINED" && (
                <Form.Item
                  name="declineReason"
                  initialValue="Плохое качество документа, нечитаемый скан"
                >
                  <Select
                    placeholder="Причина"
                    size="large"
                    disabled={isContractTypeChanging || isContactCenter}
                    options={[
                      {
                        value: "Необходимо приложить верный документ (приложен не тот документ)",
                        label: "Необходимо приложить верный документ (приложен не тот документ)"
                      },
                      {
                        value: "Плохое качество документа, нечитаемый скан",
                        label: "Плохое качество документа, нечитаемый скан"
                      }
                    ]}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
      </Form>
      <div className="text-right mt-auto">
        <Divider className="mt-0" />
        <ButtonCustom
          id="close"
          className={`${css.buttonBack} mr-3`}
          type="default"
          onClick={closeForm}
          text={isContractFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isContractFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={(): void => {
              setCanSaveDoc(true);
              agreementForm.resetFields();
              dispatch(getUnAgreedDocumentType(true));
            }}
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormAgreement;
